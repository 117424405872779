import { setSolicitudSequenceFinish } from "../../solicitudCredito/js/actions.js";
import { fetchPedidoCotizacionSeguros } from "../../seguroForm/js/actions";

export const OFERTASFORM_INIT = "OFERTASFORM_INIT";
export const OFERTASFORM_LOAD = "OFERTASFORM_LOAD";
export const OFERTASFORM_ISLOADING = "OFERTASFORM_ISLOADING";
export const OFERTASFORM_LOADED = "OFERTASFORM_LOADED";
export const OFERTASFORM_CLEARED = "OFERTASFORM_CLEARED";
export const OFERTASFORM_CAMBIAROFERTA = "OFERTASFORM_CAMBIAROFERTA";
export const STEP_COMMITED = "STEP_COMMITED";
export const SET_OFERTASFORM_COMMITED = "SET_OFERTASFORM_COMMITED";

export const ofertasFormInit = () => ({
  type: OFERTASFORM_INIT,
});

export const ofertasFormLoad = (data) => ({
  type: OFERTASFORM_LOAD,
  payload: {
    data: data,
  },
});

export const ofertasFormIsLoading = (loading) => ({
  type: OFERTASFORM_ISLOADING,
  payload: {
    isLoading: loading,
  },
});
export const setOfertasFormCommited = (commited) => ({
  type: SET_OFERTASFORM_COMMITED,
  payload: {
    commited: commited,
  },
});
export const ofertasLoaded = (data) => ({
  type: OFERTASFORM_LOADED,
  payload: data.formData,
  analytics: {
    eventName: "solicitud-oferta-guardar",
    eventData: data,
  },
});

const tryFetchingOfertas = async (
  tipoCredito,
  dispatch,
  services,
  solicitudId,
  retries,
  backoffTime
) => {
  try {
    dispatch(fetchPedidoCotizacionSeguros(solicitudId));
    const calificacion = await services.api.getEstadoCalificacion(solicitudId);
    if (!calificacion.realizada) {
      if (retries > 0) {
        setTimeout(() => {
          tryFetchingOfertas(
            tipoCredito,
            dispatch,
            services,
            solicitudId,
            --retries,
            backoffTime * 2
          );
        }, backoffTime);
      } else {
        services.errorHandler.logRemoteError(
          new Error(
            "tryFetchingOfertas - Timeout // calificacion: " +
              JSON.stringify(calificacion)
          ),
          tryFetchingOfertas,
          [dispatch, services, solicitudId, retries, backoffTime],
          null
        );
      }
    }
    if (!retries > 0 || calificacion.realizada) {
      const ofertas = await services.api.getOfertas(solicitudId);
      dispatch(ofertasFormIsLoading(false));
      dispatch(ofertasLoaded({ formData: ofertas, tipoCredito, solicitudId }));
    }
  } catch (error) {
    console.error(error);
    services.errorHandler.logRemoteError(
      error,
      tryFetchingOfertas,
      [dispatch, services, solicitudId, retries, backoffTime],
      null
    );
    dispatch(services.errorHandler.checkErrorAction(error));
    dispatch(ofertasFormIsLoading(false));
  }
};

export const fetchOfertas = (solicitudId) => {
  return async (dispatch, getState, services) => {
    const tipoCredito = getState().solicitudCreditoUI.tipoCredito;
    dispatch(ofertasFormIsLoading(true));
    await tryFetchingOfertas(
      tipoCredito,
      dispatch,
      services,
      solicitudId,
      3,
      1000
    ); //try 3 times with exponential backoffs
  };
};

export const changeOfertaSeleccionada = (i) => ({
  type: OFERTASFORM_CAMBIAROFERTA,
  payload: {
    i: i,
  },
});

export const clearOfertas = () => ({
  type: OFERTASFORM_CLEARED,
  payload: {},
});

export function cerrarSinOferta(solicitudId) {
  return async (dispatch, getState, services) => {
    return new Promise(function(resolve, reject) {
      const request = { Id: solicitudId };
      services.api
        .postSolicitudCerrarSinOferta(request)
        .then(() => {
          dispatch(setSolicitudSequenceFinish(true));
          resolve();
        })
        .catch((error) => {
          console.error(error);

          services.errorHandler.logRemoteError(
            error,
            cerrarSinOferta,
            [solicitudId],
            getState()
          );
          dispatch(services.errorHandler.checkErrorAction(error));
          reject();
        });
    });
  };
}
