const getConceptos = () => [
  {
    value: "0",
    label: "Cuota/s"
  },
  {
    value: "1",
    label: "Precancelación"
  },
  {
    value: "2",
    label: "Acuerdo judicial"
  }
];

export default getConceptos;
