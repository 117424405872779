import { combineReducers } from "redux";
import signupAgenciaReducer from "./signup/agenciero/actions";
import signupClienteFinalReducer from "./signup/clienteFinal/actions";
const combinedReducers = combineReducers({
  signupAgenciaReducer,
  signupClienteFinalReducer
});
const authenticationReducers = {
  key: "authenticationReducers",
  value: combinedReducers
};

export default authenticationReducers;
