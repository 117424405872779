import { createActions } from "redux-actions";

export const {
  iniciarSolicitudButton,
  iniciarAutoprendaButton,
  evaluarCompradorButton,
  asegurarAutoButton,
  cotizarAutoButton,
  simularPrestamoButton,
  misSolicitudesButton,
  homeButton,
  contactarComercialButton
} = createActions({
  INICIAR_SOLICITUD_BUTTON: [
    () => ({}), // payload
    isSideBar => ({
      analytics: {
        eventName: "iniciar-solicitud-button",
        eventData: { isSideBar: isSideBar }
      }
    }) // meta
  ],
  INICIAR_AUTOPRENDA_BUTTON: [
    () => ({}), // payload
    isSideBar => ({
      analytics: {
        eventName: "iniciar-autoprenda-button",
        eventData: { isSideBar: isSideBar }
      }
    }) // meta
  ],
  EVALUAR_COMPRADOR_BUTTON: [
    () => ({}), // payload
    isSideBar => ({
      analytics: {
        eventName: "evaluar-comprador-button",
        eventData: { isSideBar: isSideBar }
      }
    }) // meta
  ],
  ASEGURAR_AUTO_BUTTON: [
    () => ({}), // payload
    isSideBar => ({
      analytics: {
        eventName: "asegurar-auto-button",
        eventData: { isSideBar: isSideBar }
      }
    }) // meta
  ],
  COTIZAR_AUTO_BUTTON: [
    () => ({}), // payload
    isSideBar => ({
      analytics: {
        eventName: "cotizar-auto-button",
        eventData: { isSideBar: isSideBar }
      }
    }) // meta
  ],
  SIMULAR_PRESTAMO_BUTTON: [
    () => ({}), // payload
    isSideBar => ({
      analytics: {
        eventName: "simular-prestamo-button",
        eventData: { isSideBar: isSideBar }
      }
    }) // meta
  ],
  MIS_SOLICITUDES_BUTTON: [
    () => ({}), // payload
    isSideBar => ({
      analytics: {
        eventName: "mis-solicitudes-button",
        eventData: { isSideBar: isSideBar }
      }
    }) // meta
  ],
  HOME_BUTTON: [
    () => ({}), // payload
    isSideBar => ({
      analytics: {
        eventName: "home-button",
        eventData: { isSideBar: isSideBar }
      }
    }) // meta
  ],
  CONTACTAR_COMERCIAL_BUTTON: [
    () => ({}), // payload
    isSideBar => ({
      analytics: {
        eventName: "contactar-comercial-button",
        eventData: { isSideBar: isSideBar }
      }
    }) // meta
  ]
});
