import React, { Component } from "react";
import PropTypes from "prop-types";
import { white } from "material-ui/styles/colors";
import Scroll from "react-scroll";

const scroller = Scroll.scroller;
const scrollOptions = {
  duration: 500,
  delay: 100,
  smooth: true,
  offset: -69,
  isDynamic: true
};

class TopBarTitle extends Component {
  constructor() {
    super();
    this.view = null;
  }

  handleTitleMenuClick = targetId => {
    return e => {
      scroller.scrollTo(targetId, scrollOptions);
    };
  };

  render() {
    let { title } = this.props;
    if (!title) return <span>&nbsp;</span>;

    return (
      <div
        style={{
          color: "#663ea8",
          fontSize: 16,
          fontWeight: 500,
          textAlign: "center",
          fontWeight: "bold",
          marginRight: this.props.isMobile ? 40 : 0,
          marginLeft: this.props.isMobile ? 0 : "13%"
        }}
      >
        {this.props.title}
      </div>
    );
  }
}

TopBarTitle.propTypes = {
  title: PropTypes.string
};

export default TopBarTitle;
