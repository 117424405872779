import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useLocationRef = () => {
  const location = useLocation();

  let locationRef = useRef(location);
  useEffect(() => {
    locationRef.current = location;
  }, [location]);

  return locationRef;
};

export default useLocationRef;
