const getAntiguedadesLaborales = () => [
  {
    value: 12,
    label: "Más de 11 meses"
  },
  {
    value: 10,
    label: "10 - 11 meses"
  },
  {
    value: 9,
    label: "Menos de 10 meses"
  }
];

export default getAntiguedadesLaborales;
