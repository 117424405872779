import React from "react";
import { makeStyles } from "@material-ui/styles";
import iconCedula from "~assets/icons/icon-Cedula.svg";
import iconCreditCard from "~assets/icons/icon-CreditCard.svg";
import iconDatos from "~assets/icons/icon-Datos.svg";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { IconButton } from "material-ui";

const AgilizaTramiteModal = ({ showModal, setShowModal, subtitle }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={() => setShowModal(false)}
      aria-labelledby="customized-dialog-title"
      open={showModal}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="customized-dialog-title" style={{ padding: 0 }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <IconButton
            aria-label="close"
            style={{ alignSelf: "flex-end" }}
            onClick={() => setShowModal(false)}
          >
            <CloseIcon color="#757575" />
          </IconButton>
          <span className={classes.title}>AGILIZÁ EL TRAMITE</span>
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ textAlign: "center", margin: "0% 5% 0% 5%" }}>
          <span className={classes.subtitle}>{subtitle}</span>
          <div className={classes.iconsContainer}>
            <Section
              image={iconCedula}
              title={"Dos fotos de la cédula verde del auto a asegurar."}
              subtitle="Frente y dorso"
            />
            <Section
              image={iconCreditCard}
              title={
                "Número de tarjeta de crédito y nombre completo del titular."
              }
              subtitle="O fotografía del frente de la tarjeta"
            />
            <Section
              image={iconDatos}
              title={" Datos personales del titular del seguro."}
              subtitle="DNI y domicilio exacto."
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AgilizaTramiteModal;

const Section = ({ image, title, subtitle }) => {
  const classes = useStyles();
  return (
    <div className={classes.iconHolder}>
      <img alt="" src={image} className={classes.comprobanteIm} />
      <span style={{ textAlign: "center" }} className={classes.textIcon}>
        {title}
      </span>
      <span style={{ textAlign: "center" }} className={classes.textIconSm}>
        {subtitle}
      </span>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  textIcon: {
    margin: "4% 0% 2% 0% ",
    color: theme.palette.primary1Color + "!important",
    fontSize: "0.875rem",
    fontWeight: "bold",
  },
  textIconSm: {
    color: theme.palette.primary1Color + "!important",
    fontSize: "0.75rem",
  },
  paper: {
    margin: "4% 5% 4% 5% !important",
    height: "93%",
    maxHeight: "100% !important",
    borderRadius: "10px !important",
    "@media (min-width: 480px)": {
      width: "60%",
      height: "55%",
      maxWidth: "none !important",
    },
  },
  iconHolder: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "3%",
    "@media (min-width: 480px)": {
      width: "33%",
    },
    "@media (max-width: 480px)": {
      width: "75%",
    },
  },
  iconsContainer: {
    display: "flex",
    justifyContent: "space-around",
    "@media (min-width: 480px)": {
      marginTop: "3%",
    },
    "@media (max-width: 480px)": {
      marginTop: "4%",
    },
    flexWrap: "wrap",
  },
  title: {
    alignSelf: "center",
    color: theme.palette.primary1Color + "!important",
    fontSize: "1rem",
    fontWeight: "bold",
    fontFamily: "Nunito",
  },
  subtitle: {
    color: theme.palette.popupTextBoldColor + "!important",
    fontFamily: "Nunito",
  },
}));
