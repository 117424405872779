import {
  CALCULADOR_ISLOADING,
  CALCULADOR_SELECTANIO,
  CALCULADOR_SELECTPLAZO,
  CALCULADOR_SUBMITFORM,
  CALCULADOR_FETCHRESULT_SUCCESS,
  CALCULADOR_CLEARRESULT
} from "./actions";

const initialState = {
  isLoading: false,
  minPrestamo: 5000,
  maxPrestamo: 900000,
  monto: 5000,
  anio: null,
  plazo: null,
  showResultado: false,
  primeraCuota: 0,
  ultimaCuota: 0,
  cuotaPromedio: 0,
  capital: 0,
  tna: 0,
  cuotas: []
};

const calculadorReducer = (state = initialState, action) => {
  switch (action.type) {
    case CALCULADOR_ISLOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case CALCULADOR_SELECTANIO:
      return {
        ...state,
        anio: action.payload.anio,
        plazo: null
      };
    case CALCULADOR_SELECTPLAZO:
      return {
        ...state,
        plazo: action.payload.plazo
      };
    case CALCULADOR_SUBMITFORM:
      return {
        ...state,
        ...action.payload
      };
    case CALCULADOR_FETCHRESULT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        showResultado: true
      };
    case CALCULADOR_CLEARRESULT:
      return {
        ...state,
        anio: null,
        monto: 5000,
        primeraCuota: 0,
        ultimaCuota: 0,
        cuotaPromedio: 0,
        capital: 0,
        plazo: 0,
        tna: 0,
        cuotas: [],
        showResultado: false
      };
    default:
      return state;
  }
};

export default calculadorReducer;
