//import initialState from './initialState';
import {
  ACTIVIDADFORM_ISLOADING,
  ACTIVIDADFORM_SUBMITFORM,
  ACTIVIDADFORM_BLOCKEDFORM,
  ACTIVIDADFORM_INIT,
  ACTIVIDADFORM_LOAD,
  ACTIVIDADFORM_SELECTACTIVIDAD,
  ACTIVIDADFORM_SELECTRUBRO,
  ACTIVIDADFORM_SELECTANTIGUEDAD,
  ACTIVIDADFORM_SELECTACTIVIDADCONYUGE,
  SET_ACTIVIDADFORM_COMMITED
} from "./actions";
const initialState = {
  isLoading: false,
  disabled: false,
  ingresos: "",
  actividadDeclarada: null,
  rubro: null,
  antiguedad: null,
  actividadConyuge: null,
  ingresosConyuge: "",
  stepCommited: false
};

const actividadFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVIDADFORM_ISLOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case ACTIVIDADFORM_SELECTACTIVIDAD:
      return {
        ...state,
        actividadDeclarada: action.payload.actividadDeclarada
      };
    case ACTIVIDADFORM_SELECTRUBRO:
      return {
        ...state,
        rubro: action.payload.rubro
      };
    case ACTIVIDADFORM_SELECTANTIGUEDAD:
      return {
        ...state,
        antiguedad: action.payload.antiguedad
      };
    case ACTIVIDADFORM_SELECTACTIVIDADCONYUGE:
      return {
        ...state,
        actividadConyuge: action.payload.actividadConyuge
      };
    case ACTIVIDADFORM_SUBMITFORM:
      return {
        ...state,
        ingresos: action.payload.Ingreso,
        ingresosConyuge: action.payload.IngresoConyuge
      };
    case SET_ACTIVIDADFORM_COMMITED:
      return {
        ...state,
        stepCommited: action.payload.commited
      };
    case ACTIVIDADFORM_BLOCKEDFORM:
      return {
        ...state,
        disabled: action.payload.disabled
      };
    case ACTIVIDADFORM_LOAD:
      return {
        ...state,
        ...action.payload.data,
        isLoading: false
      };
    case ACTIVIDADFORM_INIT:
      return initialState;
    default:
      return state;
  }
};

export default actividadFormReducer;
