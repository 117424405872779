import React from "react";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";

const nextYear = new Date().getFullYear() + 1;

const DateInput = ({
  inputRef,
  minYear = 2000,
  maxYear = nextYear,
  ...other
}) => {
  const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/mm/yyyy", {
    minYear,
    maxYear
  });

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      pipe={autoCorrectedDatePipe}
      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"-"}
    />
  );
};

export default DateInput;
