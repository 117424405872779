import {
  FETCH_TAREAS_INIT,
  FETCH_TAREAS_SUCCESS,
  FETCH_TAREAS_FAILED,
  ARCHIVO_TAREA_DOWNLOADED,
  ARCHIVO_TAREA_ADDED,
  ARCHIVO_TAREA_REMOVED,
  TAREA_COMPLETED,
  TAREAS_CLEARED,
  FETCH_SOLICITUD_INITED,
  FETCH_SOLICITUD_COMPLETED,
  FETCH_SOLICITUD_FAILED
} from "./actions";

import objectMap from "../../../libs/objectMap";

const initialState = {
  isLoading: false,
  hasFailed: false,
  tareasIds: {},
  tareasById: {},
  solicitud: {}
};

export default function tareasReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TAREAS_INIT:
      return { ...state, isLoading: true };
    case FETCH_TAREAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tareasIds: action.payload.tareas.map(tarea => tarea.tareaId),
        tareasById: action.payload.tareas.reduce(function(map, tarea) {
          tarea.tempFiles = [];
          map[tarea.tareaId] = tarea;
          return map;
        }, {}),
        hasFailed: false
      };
    case FETCH_TAREAS_FAILED:
      return { ...state, isLoading: false, hasFailed: true };
    case ARCHIVO_TAREA_DOWNLOADED:
      let withArchivosUpdated = objectMap(state.tareasById, function(tarea) {
        return tarea.tareaId === action.payload.archivo.tareaId
          ? {
              ...tarea,
              archivos: tarea.archivos.map(archivo =>
                archivo.id === action.payload.archivo.id
                  ? {
                      ...action.payload.archivo,
                      loaded: true
                    }
                  : archivo
              )
            }
          : tarea;
      });
      return {
        ...state,
        tareasById: withArchivosUpdated
      };
    case ARCHIVO_TAREA_ADDED:
      let withTempFilesAdded = objectMap(state.tareasById, function(tarea) {
        return tarea.tareaId === action.payload.tareaId
          ? {
              ...tarea,
              tempFiles: tarea.tempFiles.concat([
                {
                  fileName: action.payload.fileName,
                  serverId: action.payload.serverId
                }
              ])
            }
          : tarea;
      });
      return {
        ...state,
        tareasById: withTempFilesAdded
      };
    case ARCHIVO_TAREA_REMOVED:
      let withTempFilesRemoved = objectMap(state.tareasById, function(tarea) {
        return tarea.tareaId === action.payload.tareaId
          ? {
              ...tarea,
              tempFiles: tarea.tempFiles.filter(
                t => t.fileName !== action.payload.fileName
              )
            }
          : tarea;
      });

      return {
        ...state,
        tareasById: withTempFilesRemoved
      };
    case TAREA_COMPLETED:
      let withTareasRemoved = objectMap(state.tareasById, function(tarea) {
        return tarea;
      });
      delete withTareasRemoved[action.payload.tareaId];

      return {
        ...state,
        tareasById: withTareasRemoved
      };

    case TAREAS_CLEARED:
      return initialState;

    case FETCH_SOLICITUD_INITED:
      return { ...state, isLoading: true };

    case FETCH_SOLICITUD_COMPLETED:
      const situacionesOk = [
        "En_Analisis_Asignada",
        "En_Verificacion_Telefonica",
        "Con_Tareas_Previas_A_Analisis",
        "Informes_Pendientes",
        "En_Espera_De_Analisis"
      ];

      const titular = action.payload.personas.find(
        persona => persona.tipoRelacion === "Titular"
      );
      const conyuge = action.payload.personas.find(
        persona => persona.tipoRelacion === "Conyuge"
      );
      const codeudor = action.payload.personas.find(
        persona => persona.tipoRelacion === "Codeudor"
      );
      const conyugeCodeudor = action.payload.personas.find(
        persona => persona.tipoRelacion === "Conyuge_del_Codeudor"
      );

      return {
        ...state,
        solicitud: {
          nombresTitular: titular.nombre,
          apellidosTitular: titular.apellido,
          /*           fechaCreacion: action.payload.fechaCreacion,
          importeSolicitado: action.payload.importeNetoAPercibir,
          plazoSolicitado: action.payload.plazoSolicitado, */
          situacionOp: action.payload.situacion,
          opPermitida: situacionesOk.includes(action.payload.situacion),
          conyuge: conyuge,
          codeudor: codeudor,
          conyugeCodeudor: conyugeCodeudor,
          isLoading: false,
          error: false
        }
      };

    case FETCH_SOLICITUD_FAILED:
      return {
        ...state,
        isLoading: false,
        error: true
      };

    default:
      return state;
  }
}
