import React, { useState } from "react";
import Slider from "@material-ui/core/Slider";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import pxToRem from "~libs/pxToRem";

const useStyles = makeStyles(theme => ({
  centeredText: {
    fontSize: pxToRem(24),
    textAlign: "center",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    letterSpacing: pxToRem(1)
  }
}));

const MIN_VALUE = 12;
const MAX_VALUE = 48;

const CuotasSelector = ({
  defaultValue = MIN_VALUE,
  onChange,
  marginBottom
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);

  return (
    <div style={{ textAlign: "center", marginBottom }}>
      <Typography variant="caption" className={classes.centeredText}>
        {value} meses
      </Typography>
      <div style={{ marginLeft: 12, marginRight: 12 }}>
        <StyledSlider
          onChangeCommitted={(event, value) =>
            onChange ? onChange({ target: { value } }) : null
          }
          onChange={(event, value) => setValue(value)}
          valueLabelDisplay="auto"
          defaultValue={defaultValue}
          step={null}
          marks={cuotasOptions}
          min={MIN_VALUE}
          max={MAX_VALUE}
        />
      </div>
    </div>
  );
};

export default CuotasSelector;

const StyledSlider = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  },
  mark: {
    display: "none"
  },
  markActive: {
    display: "none"
  },
  markLabel: {
    top: 30,
    [theme.breakpoints.mobile]: {
      top: 40
    }
  }
}))(Slider);

const cuotasOptions = [
  {
    value: 12,
    label: "12"
  },
  {
    value: 18,
    label: "18"
  },
  {
    value: 24,
    label: "24"
  },
  {
    value: 36,
    label: "36"
  },
  {
    value: 48,
    label: "48"
  }
];
