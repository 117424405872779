import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setEmail, clearEmail } from "../actions";
import { useAuth0 } from "~componentes/Auth0/useAuth";
import localRepository from "~src/localRepository";

import EmailValidator from "email-validator";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

const ENTER_KEY = 13;

const EmailStep = () => {
  const dispatch = useDispatch();
  const { authState } = useAuth0();
  const initialState = localRepository.lastUsedEmail.get()
    ? localRepository.lastUsedEmail.get()
    : authState.user.email;
  const [mail, setMail] = useState(initialState);

  useEffect(() => {
    dispatch(clearEmail());
  }, [dispatch]);
  const canContinue = mail.length > 0 && EmailValidator.validate(mail);
  const onKeyDown = event => {
    if (event.keyCode === ENTER_KEY && canContinue) dispatch(setEmail(mail));
  };

  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={!canContinue}
            label={"Continuar"}
            onClick={() => {
              dispatch(setEmail(mail));
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle label={"Ingresá un correo electrónico"} marginBottom={60} />
      <TextField
        fullWidth={true}
        onKeyDown={onKeyDown}
        autoFocus
        onFocus={event => event.target.select()}
        type="email"
        value={mail}
        onChange={event => setMail(event.target.value)}
        placeholder="Ej: micorreo@mail.com"
      ></TextField>
    </ContentSectionWithActions>
  );
};

export default EmailStep;
