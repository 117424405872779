import { createAction, handleActions } from "redux-actions";
import localRepository from "../../localRepository";

const authStateSuccess = createAction("AUTH_STATE_SYNC", authState => ({
  authState
}));

const authStateSync = authState => {
  return async (dispatch, getState, services) => {
    try {
      localRepository.accessToken.set(authState.accessToken);

      if (!authState.accessToken) localRepository.clear();

      dispatch(authStateSuccess(authState));
    } catch (error) {
      dispatch(signInFail());
      services.sendMessage(
        "No se pudo enviar el mail de confirmación.",
        "error"
      );
    }
  };
};

const signInInit = createAction("SIGN_IN_INIT");
const signInAction = createAction("SIGN_IN");
const signInSuccess = createAction("SIGN_IN_SUCCESS");
const signInConfirming = createAction("SIGN_IN_CONFIRMING");
const signInFail = createAction("SIGN_IN_FAIL");

const signInWithSocial = callback => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(signInAction());

      callback();
    } catch (error) {
      console.error(error);
    }
  };
};

const signInWithEmail = (email, loginPasswordlessEmail) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(signInAction());

      await loginPasswordlessEmail(email);

      dispatch(signInConfirming());
    } catch (error) {
      dispatch(signInFail());
      services.sendMessage(
        "No se pudo enviar el mail de confirmación.",
        "error"
      );
    }
  };
};

const signInWithEmailConfirmed = (
  { email, emailCode },
  confirmPasswordlessEmail
) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(signInAction());

      await confirmPasswordlessEmail(email, emailCode);

      dispatch(signInSuccess());
    } catch (error) {
      dispatch(signInFail());
      services.sendMessage("Código incorrecto o vencido.", "error");
    }
  };
};

const initialState = {
  authState: null,
  isAuthenticated: false,
  isConfirming: false,
  isLoading: false
};

const authReducer = handleActions(
  {
    [authStateSuccess]: (state, action) => {
      return {
        ...state,
        authState: action.payload.authState,
        isLoading: false,
        isAuthenticated: action.payload.authState ? true : false
      };
    },
    [signInInit]: () => {
      return { ...initialState };
    },
    [signInAction]: (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [signInConfirming]: (state, action) => {
      return {
        ...state,
        isConfirming: true,
        isLoading: false
      };
    },
    [signInSuccess]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isConfirming: true
      };
    },
    [signInFail]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isConfirming: false
      };
    }
  },
  initialState
);

export default authReducer;
export {
  authStateSync,
  signInWithSocial,
  signInWithEmail,
  signInWithEmailConfirmed,
  signInInit
};
