import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setDireccion, clearDireccion } from "../actions";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

const ENTER_KEY = 13;

const DireccionStep = () => {
  const dispatch = useDispatch();
  const [direccion, setCampoDireccion] = useState("");

  useEffect(() => {
    dispatch(clearDireccion());
  }, [dispatch]);

  const canContinue = direccion.length > 0 && direccion.includes(" ");

  const handleNext = (value) => {
    dispatch(setDireccion(value));
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue) handleNext(direccion);
  };

  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={!canContinue}
            label={"Continuar"}
            onClick={() => {
              handleNext(direccion);
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle label={"Ingresá tu direccion"} marginBottom={60} />
      <TextField
        fullWidth={true}
        onKeyDown={onKeyDown}
        autoFocus
        onFocus={(event) => event.target.select()}
        value={direccion}
        onChange={(event) => setCampoDireccion(event.target.value)}
        placeholder="Ej: calle XX"
      ></TextField>
    </ContentSectionWithActions>
  );
};

export default DireccionStep;
