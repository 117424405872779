import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCodigoPostal, clearCodigoPostal } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";

import CodigoPostalSelector from "~components/bussines/codigoPostalSelector/CodigoPostalSelector";

const useReduxSelector = () =>
  useSelector(state => {
    const nuevaSolicitudReducer = state.creditosReducers.nuevaSolicitudReducer;

    return {
      provinciaId: nuevaSolicitudReducer.provincia,
      localidad: nuevaSolicitudReducer.localidad
    };
  });

const CodigoPostalStep = () => {
  const dispatch = useDispatch();
  const { provinciaId, localidad } = useReduxSelector();
  useEffect(() => {
    dispatch(clearCodigoPostal());
  }, [dispatch]);

  const handleSelection = event => {
    dispatch(setCodigoPostal(event.target.value));
  };

  return (
    <ContentSectionWithActions>
      <PrimaryTitle label={"Indicá su código postal"} marginBottom={60} />
      <CodigoPostalSelector
        provinciaId={provinciaId}
        localidad={localidad}
        onSelected={handleSelection}
      />
    </ContentSectionWithActions>
  );
};

export default CodigoPostalStep;
