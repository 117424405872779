import muiMGTheme from "../css/muiMGTheme";
import jss from "jss";
import preset from "jss-preset-default";

jss.setup(preset());

export const makeStyles = stylesFunc => {
  const styles = stylesFunc(muiMGTheme);

  const attachedSheet = jss.createStyleSheet(styles).attach();
  return () => {
    return attachedSheet.classes;
  };
};

export const useTheme = () => muiMGTheme;
