import { setSolicitudSequenceFinish } from "../../solicitudCredito/js/actions.js";

export const RESULTADOFORM_ISLOADING = "RESULTADOFORM_ISLOADING";
export const RESULTADOFORM_LOAD = "RESULTADOFORM_LOAD";
export const RESULTADOFORM_INIT = "RESULTADOFORM_INIT";
export const RESULTADO_SUBMIT = "RESULTADO_SUBMIT";
export const RESULTADO_INIT = "RESULTADO_INIT";

export const resultadoFormInit = () => ({
  type: RESULTADOFORM_INIT
});

export const resultadoFormLoad = data => ({
  type: RESULTADOFORM_LOAD,
  payload: {
    data: data
  }
});

export const resultadoFormIsLoading = loading => ({
  type: RESULTADOFORM_ISLOADING,
  payload: {
    isLoading: loading
  }
});

export function submitForm(formData) {
  return function(dispatch, getState, services) {
    return new Promise(function(resolve, reject) {
      try {
        dispatch(resultadoFormIsLoading(true));
        services.api.postSolicitudSeleccionOferta(formData).then(() => {
          dispatch(resultadoFormIsLoading(false));
          dispatch(setSolicitudSequenceFinish());
          resolve();
        });
      } catch (error) {
        console.error(error);

        services.errorHandler.logRemoteError(
          error,
          submitForm,
          [formData],
          getState()
        );
        dispatch(services.errorHandler.checkErrorAction(error));
        dispatch(resultadoFormIsLoading(false));
        reject();
      }
    });
  };
}
