import React, { useState, useEffect } from "react";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  actions: {
    width: "100%",
    paddingTop: pxToRem(32),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const ContentSectionWithActions = ({ children, actions }) => {
  const classes = useStyles();

  return (
    <>
      <section
        style={{
          width: "100%",
          maxWidth: pxToRem(500),
          display: "flex",
          flexDirection: "column"
        }}
      >
        {children}
      </section>
      {actions && <div className={classes.actions}>{actions}</div>}
    </>
  );
};

export default ContentSectionWithActions;

function hasVerticalScroll(node) {
  if (node == undefined) {
    if (window.innerHeight) {
      return document.body.offsetHeight > window.innerHeight;
    } else {
      return (
        document.documentElement.scrollHeight >
          document.documentElement.offsetHeight ||
        document.body.scrollHeight > document.body.offsetHeight
      );
    }
  } else {
    return node.scrollHeight > node.offsetHeight;
  }
}
