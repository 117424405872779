import React, { useEffect } from "react";
import useLocationRef from "~components/app/useLocationRef";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clear, fetchIdentidad } from "./actions";
import MarcaStep from "./steps/MarcaStep";
import AnioStep from "./steps/AnioStep";
import ModeloStep from "./steps/ModeloStep";
import ProcessLayout from "~components/styled/layouts/processLayout/ProcessLayout";
import ProvinciaStep from "./steps/ProvinciaStep";
import LocalidadStep from "./steps/LocalidadStep";
import CodigoPostalStep from "./steps/CodigoPostalStep";
import VehiculoUsoStep from "./steps/VehiculoUsoStep";
import VehiculoTieneGNCStep from "./steps/VehiculoTieneGNCStep";
import FormaDePagoStep from "./steps/FormaDePagoStep";
import DniStep from "./steps/DniStep";
import SexoStep from "./steps/SexoStep";
import EsPersonaValidaStep from "./steps/EsPersonaValidaStep";
import ContactoEmailStep from "./steps/ContactoEmailStep";
import ContactoTelefonoStep from "./steps/ContactoTelefonoStep";
import ContactoNombreStep from "./steps/ContactoNombreStep";
import FechaNacimientoStep from "./steps/FechaNacimientoStep";
import DireccionStep from "./steps/DireccionStep";
import DominioStep from "./steps/DominioStep";
import MotorStep from "./steps/MotorStep";
import NumeroChasisStep from "./steps/NumeroChasisStep";
import ListadoCotizacionesStep from "./steps/ListadoCotizacionesStep";
import DetallePolizaStep from "./steps/DetallePolizaStep";
import DatosPagoStep from "./steps/DatosPagoStep";
import FinalStep from "./steps/FinalStep";
import { isValid } from "date-fns";

const steps = {
  // steps must be string
  marcaStep: "0",
  anioStep: "1",
  modeloStep: "2",
  provinciaStep: "3",
  localidadStep: "4",
  codigoPostalStep: "5",
  usoStep: "6",
  tieneGNCStep: "7",
  formaDePagoStep: "8",

  listadoCotizacionesStep: "9",
  detallePolizaStep: "10",

  dniStep: "11",
  sexoStep: "12",
  esPersonaValidaStep: "13",
  nombreStep: "14",
  fechaNacimientoStep: "15",
  direccionStep: "16",
  emailStep: "17",
  telefonoStep: "18",

  dominioStep: "19",
  motorStep: "20",
  numeroChasisStep: "21",
  datosPagoStep: "22",

  finalStep: "23",
};
const BASE_URL = "/seguros";
const FIRST_STEP = steps.marcaStep;

const useReduxSelector = () =>
  useSelector((state) => {
    const cotizadorReducer = state.segurosReducers.cotizadorReducer;

    return {
      isLoading: cotizadorReducer.isLoading,
      cotizacionId: cotizadorReducer.cotizacionId,
      //datos vehiculo
      marcaSelected: cotizadorReducer.marcaSelected,
      anioSelected: cotizadorReducer.anioSelected,
      modeloSelected: cotizadorReducer.modeloSelected,
      provinciaSelected: cotizadorReducer.provinciaSelected,
      localidadSelected: cotizadorReducer.localidadSelected,
      codigoPostalSelected: cotizadorReducer.codigoPostalSelected,
      usoSelected: cotizadorReducer.usoSelected,
      tieneGNC: cotizadorReducer.tieneGNC,
      formaDePagoSelected: cotizadorReducer.formaDePagoSelected,

      currentPoliza: cotizadorReducer.currentPoliza,

      //datos persona
      dniSelected: cotizadorReducer.dniSelected,
      sexoSelected: cotizadorReducer.sexoSelected,
      esPersonaValida: cotizadorReducer.esPersonaValida,
      direccionSelected: cotizadorReducer.direccionSelected,
      nombreSelected: cotizadorReducer.nombreSelected,
      emailSelected: cotizadorReducer.emailSelected,
      telefonoSelected: cotizadorReducer.telefonoSelected,
      fechaNacimientoSelected: cotizadorReducer.fechaNacimientoSelected,

      //datos vehiculo 2da parte
      dominioSelected: cotizadorReducer.dominioSelected,
      motorSelected: cotizadorReducer.motorSelected,
      numeroChasisSelected: cotizadorReducer.numeroChasisSelected,
      validadorDatosPagoSelected: cotizadorReducer.validadorDatosPagoSelected,
    };
  });

const CotizadorSeguros = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { position = FIRST_STEP } = useParams();

  useEffect(() => {
    dispatch(clear());

    return function cleanup() {
      dispatch(clear());
    };
  }, [dispatch]);

  const currentState = useReduxSelector();
  const currentStep = useCurrentStep(currentState, position);
  const { dniSelected, sexoSelected } = currentState;

  useEffect(() => {
    if (dniSelected && sexoSelected.selected)
      dispatch(fetchIdentidad(dniSelected, sexoSelected.value));
  }, [dispatch, dniSelected, sexoSelected]);

  const isInProcess = [
    steps.marcaStep,
    steps.anioStep,
    steps.modeloStep,
    steps.provinciaStep,
    steps.localidadStep,
    steps.codigoPostalStep,
    steps.usoStep,
    steps.tieneGNCStep,
    steps.formaDePagoStep,

    steps.listadoCotizacionesStep,
    steps.detallePolizaStep,

    steps.dniStep,
    steps.sexoStep,
    steps.esPersonaValidaStep,
    steps.direccionStep,
    steps.nombreStep,
    steps.emailStep,
    steps.telefonoStep,
    steps.fechaNacimientoStep,
    steps.dominioStep,
    steps.motorStep,
    steps.numeroChasisStep,
    steps.datosPagoStep,

    steps.finalStep,
  ].includes(position);

  const validSteps = getValidSteps(currentState);

  const isValidStep = validSteps.find((x) => x.index === position)
    ? true
    : false;

  return (
    <>
      {isInProcess && isValidStep && (
        <ProcessLayout
          title={"Cotizar seguro"}
          progress={(currentStep.order * 100) / Object.keys(steps).length} // porcentage (0-100)
          onBackClick={() => {
            history.goBack();
          }}
          onCloseClick={() => {
            history.push("/");
          }}
          isForward={currentState.isForward}
        >
          {position === steps.marcaStep && <MarcaStep />}
          {position === steps.anioStep && <AnioStep />}
          {position === steps.modeloStep && <ModeloStep />}
          {position === steps.provinciaStep && <ProvinciaStep />}
          {position === steps.localidadStep && <LocalidadStep />}
          {position === steps.codigoPostalStep && <CodigoPostalStep />}
          {position === steps.usoStep && <VehiculoUsoStep />}
          {position === steps.tieneGNCStep && <VehiculoTieneGNCStep />}
          {position === steps.formaDePagoStep && <FormaDePagoStep />}
          {position === steps.listadoCotizacionesStep && (
            <ListadoCotizacionesStep currentState={currentState} />
          )}
          {position === steps.detallePolizaStep && <DetallePolizaStep />}

          {position === steps.dniStep && <DniStep />}
          {position === steps.sexoStep && <SexoStep />}
          {position === steps.esPersonaValidaStep && <EsPersonaValidaStep />}
          {position === steps.fechaNacimientoStep && <FechaNacimientoStep />}
          {position === steps.direccionStep && <DireccionStep />}
          {position === steps.nombreStep && <ContactoNombreStep />}
          {position === steps.emailStep && <ContactoEmailStep />}
          {position === steps.telefonoStep && <ContactoTelefonoStep />}

          {position === steps.dominioStep && <DominioStep />}
          {position === steps.motorStep && <MotorStep />}
          {position === steps.numeroChasisStep && <NumeroChasisStep />}
          {position === steps.datosPagoStep && <DatosPagoStep />}
          {position === steps.finalStep && (
            <FinalStep currentState={currentState} />
          )}
        </ProcessLayout>
      )}
    </>
  );
};

export default CotizadorSeguros;

const useCurrentStep = (currentState, position) => {
  const history = useHistory();
  const locationRef = useLocationRef();

  const validSteps = getValidSteps(currentState);

  const isValidStep = validSteps.find((x) => x.index === position)
    ? true
    : false;

  const currentStep = validSteps.slice().pop();

  useEffect(() => {
    const stepUrl = `${BASE_URL}`;

    // if not valid step, go to first step.
    if (!isValidStep) {
      history.replace(stepUrl);
    }
  }, [history, isValidStep]);

  useEffect(() => {
    const stepUrl = `${BASE_URL}/${currentStep.index}`;

    const isThisUrl = locationRef.current.pathname === stepUrl;
    const isFirstStep =
      locationRef.current.pathname === `${BASE_URL}` &&
      currentStep.index === FIRST_STEP;

    if (!isThisUrl && !isFirstStep && isValidStep) {
      history.push(stepUrl);
    }
    //eslint-disable-next-line
  }, [isValidStep, history, currentStep.index]);

  return currentStep;
};

const getValidSteps = ({
  marcaSelected,
  anioSelected,
  modeloSelected,
  provinciaSelected,
  localidadSelected,
  codigoPostalSelected,
  usoSelected,
  tieneGNC,
  formaDePagoSelected,
  sexoSelected,
  dniSelected,
  nombreSelected,
  emailSelected,
  fechaNacimientoSelected,
  direccionSelected,
  esPersonaValida,
  telefonoSelected,
  dominioSelected,
  motorSelected,
  numeroChasisSelected,
  validadorDatosPagoSelected,
  currentPoliza,
}) => {
  const marcaStep = step(steps.marcaStep, !marcaSelected.value);
  const anioStep = step(steps.anioStep, !anioSelected.value);
  const modeloStep = step(steps.modeloStep, !modeloSelected.value);
  const provinciaStep = step(steps.provinciaStep, !provinciaSelected);
  const localidadStep = step(steps.localidadStep, !localidadSelected);
  const codigoPostalStep = step(steps.codigoPostalStep, !codigoPostalSelected);
  const usoStep = step(steps.usoStep, !usoSelected);
  const tieneGncStep = step(steps.tieneGNCStep, !tieneGNC.selected);
  const formaDePagoStep = step(
    steps.formaDePagoStep,
    !formaDePagoSelected.value
  );

  const sexoStep = step(steps.sexoStep, !sexoSelected.selected);
  const dniStep = step(steps.dniStep, !dniSelected);
  const esPersonaValidaStep = step(
    steps.esPersonaValidaStep,
    !esPersonaValida.selected
  );
  const nombreStep = step(steps.nombreStep, !nombreSelected);
  const emailStep = step(steps.emailStep, !emailSelected.selected);
  const direccionStep = step(steps.direccionStep, !direccionSelected);
  const telefonoStep = step(steps.telefonoStep, !telefonoSelected.value);
  const fechaNacimientoStep = step(
    steps.fechaNacimientoStep,
    !fechaNacimientoSelected.selected
  );

  const dominioStep = step(steps.dominioStep, !dominioSelected.selected);
  const motorStep = step(steps.motorStep, !motorSelected.selected);
  const numeroChasisStep = step(
    steps.numeroChasisStep,
    !numeroChasisSelected.selected
  );
  const datosPagoStep = step(
    steps.datosPagoStep,
    !validadorDatosPagoSelected.selected
  );
  const listadoCotizacionesStep = step(
    steps.listadoCotizacionesStep,
    !currentPoliza.open
  );
  const detallePolizaStep = step(
    steps.detallePolizaStep,
    !currentPoliza.contratada
  );
  const finalStep = step(steps.finalStep, true);

  //set order of steps
  marcaStep
    .next(anioStep)
    .next(modeloStep)
    .next(provinciaStep)
    .next(localidadStep)
    .next(codigoPostalStep)
    .next(usoStep)
    .next(tieneGncStep)
    .next(formaDePagoStep)
    .next(listadoCotizacionesStep)
    .next(detallePolizaStep)
    //
    .next(dniStep)
    .next(sexoStep)
    .next(esPersonaValidaStep)
    .next(fechaNacimientoStep)
    .next(nombreStep)
    .next(direccionStep)
    .next(telefonoStep)
    .next(emailStep)
    .next(dominioStep)
    .next(numeroChasisStep)
    .next(motorStep)
    .next(datosPagoStep)
    //
    .next(finalStep);
  return marcaStep.run();
};

const step = (index, isThisStep) => {
  let next = null;
  let order = 1;

  return {
    index,
    order,
    next(step) {
      if (step) next = { ...step, order: this.order + 1 };
      return next;
    },
    run() {
      return isThisStep ? [this] : [this, ...(next && next.run())];
    },
  };
};
