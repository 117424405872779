const getUsosVehiculo = () => [
  {
    label: "Uso particular",
    value: "Particular"
  },
  {
    label: "Transp. de mercaderias, herramientas y fletes",
    //value: "Mercaderia_Herramientas_Fletes"
    value: "Comercial"
  },
  {
    label: "Transporte de combustibles",
    //value: "Combustibles"
    value: "Comercial"
  },
  {
    label: "Transporte de cargas peligrosas",
    //value: "Cargas_Peligrosas"
    value: "Comercial"
  },
  {
    label: "Servicios especiales de pasajeros",
    //value: "Servicios_Especiales"
    value: "Comercial"
  },
  {
    label: "Taxi o remis",
    //value: "Taxi_Remis"
    value: "Comercial"
  },
  {
    label: "Transporte público",
    //value: "Transporte_Publico"
    value: "Comercial"
  }
];

export default getUsosVehiculo;
