import { createActions, handleActions } from "redux-actions";
import { sendMessageToNative } from "~componentes/nativeAppMessaging/js/actions";
import openFile from "~libs/openFile";
import fileService from "~libs/fileService";
import blobToBase64 from "~libs/blobToBase64";

const {
  creditos: {
    detalleCredito: {
      getDetalleCreditoInited,
      getDetalleCreditoCompleted,
      getDetalleCreditoFailed,
      getComprobanteCreditoAlDiaInited,
      getComprobanteCreditoAlDiaCompleted,
      getComprobanteCreditoAlDiaFailed,
      getCuponPagoSaldoInited,
      getCuponPagoSaldoCompleted,
      getCuponPagoSaldoFailed,
      getMediosDePagoInited,
      getMediosDePagoCompleted,
      getMediosDePagoFailed,
      getNotaCancelacionInited,
      getNotaCancelacionCompleted,
      getNotaCancelacionFailed,
    },
  },
} = createActions({
  creditos: {
    detalleCredito: {
      getDetalleCreditoInited: (value) => ({ value }),
      getDetalleCreditoCompleted: (response) => ({ response }),
      getDetalleCreditoFailed: (error) => ({
        error,
      }),
      getComprobanteCreditoAlDiaInited: () => ({}),
      getComprobanteCreditoAlDiaCompleted: () => ({}),
      getComprobanteCreditoAlDiaFailed: () => ({}),
      getCuponPagoSaldoInited: () => ({}),
      getCuponPagoSaldoCompleted: () => ({}),
      getCuponPagoSaldoFailed: () => ({}),
      getMediosDePagoInited: () => ({}),
      getMediosDePagoCompleted: (response) => ({ response }),
      getMediosDePagoFailed: (error) => ({
        error,
      }),
      getNotaCancelacionInited: () => ({}),
      getNotaCancelacionCompleted: () => ({}),
      getNotaCancelacionFailed: () => ({}),
    },
  },
});

const getDetalleCredito = (operacionId) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getDetalleCreditoInited(operacionId));
      const response = await services.api.getDetalleCredito(operacionId);
      dispatch(getDetalleCreditoCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getDetalleCreditoFailed(error));
    }
  };
};

export function getComprobanteCreditoAlDia(operacionId) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getComprobanteCreditoAlDiaInited());
      const result = await services.api.getComprobanteCreditoAlDia(operacionId);
      const isOnNativeApp = getState().nativeMessagesReducer.isOnNativeApp;

      const fileServicePath = fileService(result.path);
      const response = await fileServicePath.download(
        encodeURIComponent(result.fileName.toLowerCase()).replace(
          /%2520/g,
          "%20"
        )
      );

      if (isOnNativeApp) {
        sendMessageToNative({
          type: "WEB_SHOW_FILE_VIEWER",
          payload: {
            title: "Crédito al día",
            data: null,
            filename: result.fileName,
          },
        });
        const blob = await fetch(response.url).then((response) =>
          response.blob()
        );
        const fileBase64 = await blobToBase64(blob);
        sendMessageToNative({
          type: "WEB_SHOW_FILE_VIEWER",
          payload: {
            title: "Crédito al día",
            data: fileBase64,
            filename: result.fileName,
          },
        });
      } else {
        await openFile(response.url);
      }
      dispatch(getComprobanteCreditoAlDiaCompleted(result));
    } catch (error) {
      console.error(error);
      services.errorHandler.logRemoteError(
        error,
        getComprobanteCreditoAlDia,
        [],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(getComprobanteCreditoAlDiaFailed(error));
    }
  };
}

export function getCuponPagoSaldo(operacionId) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getCuponPagoSaldoInited());
      const result = await services.api.getCuponPagoSaldo(operacionId);
      const isOnNativeApp = getState().nativeMessagesReducer.isOnNativeApp;
      const fileServicePath = fileService(result.path);
      const response = await fileServicePath.download(
        encodeURIComponent(result.fileName.toLowerCase()).replace(
          /%2520/g,
          "%20"
        )
      );

      if (isOnNativeApp) {
        sendMessageToNative({
          type: "WEB_SHOW_FILE_VIEWER",
          payload: {
            title: "Cupón de pago",
            data: null,
            filename: result.fileName,
          },
        });
        const blob = await fetch(response.url).then((response) =>
          response.blob()
        );
        const fileBase64 = await blobToBase64(blob);
        sendMessageToNative({
          type: "WEB_SHOW_FILE_VIEWER",
          payload: {
            title: "Cupón de pago",
            data: fileBase64,
            filename: result.fileName,
          },
        });
      } else {
        await openFile(response.url);
      }
      dispatch(getCuponPagoSaldoCompleted(result));
    } catch (error) {
      console.error(error);
      services.errorHandler.logRemoteError(
        error,
        getComprobanteCreditoAlDia,
        [],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(getCuponPagoSaldoFailed(error));
    }
  };
}

export function getNotaCancelacion(operacionId) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getNotaCancelacionInited());
      const result = await services.api.getNotaCancelacion(operacionId);
      const isOnNativeApp = getState().nativeMessagesReducer.isOnNativeApp;
      const fileServicePath = fileService(result.path);
      const response = await fileServicePath.download(
        encodeURIComponent(result.fileName.toLowerCase()).replace(
          /%2520/g,
          "%20"
        )
      );

      if (isOnNativeApp) {
        sendMessageToNative({
          type: "WEB_SHOW_FILE_VIEWER",
          payload: {
            title: "Nota de cancelación",
            data: null,
            filename: result.fileName,
          },
        });
        const blob = await fetch(response.url).then((response) =>
          response.blob()
        );
        const fileBase64 = await blobToBase64(blob);
        sendMessageToNative({
          type: "WEB_SHOW_FILE_VIEWER",
          payload: {
            title: "Nota de cancelación",
            data: fileBase64,
            filename: result.fileName,
          },
        });
      } else {
        await openFile(response.url);
      }
      dispatch(getNotaCancelacionCompleted(result));
    } catch (error) {
      console.error(error);
      services.errorHandler.logRemoteError(
        error,
        getNotaCancelacion,
        [],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(getNotaCancelacionFailed(error));
    }
  };
}

export function getMediosDePago(empresa) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getMediosDePagoInited());
      const isOnNativeApp = getState().nativeMessagesReducer.isOnNativeApp;
      const preffix = empresa === 1 ? "mg-" : "mutual-";
      const fileName = preffix + "mediosdepago.pdf";
      const fileServicePath = fileService("mediospago");
      const response = await fileServicePath.download(
        encodeURIComponent(fileName.toLowerCase()).replace(/%2520/g, "%20")
      );
      if (isOnNativeApp) {
        sendMessageToNative({
          type: "WEB_SHOW_FILE_VIEWER",
          payload: {
            title: "Medios de pago",
            data: null,
            filename: "mediospago.pdf",
          },
        });
        const blob = await fetch(response.url).then((response) =>
          response.blob()
        );
        const fileBase64 = await blobToBase64(blob);
        sendMessageToNative({
          type: "WEB_SHOW_FILE_VIEWER",
          payload: {
            title: "Medios de pago",
            data: fileBase64,
            filename: "mediospago.pdf",
          },
        });
      } else {
        await openFile(response.url);
      }
      dispatch(getMediosDePagoCompleted());
    } catch (error) {
      console.error(error);
      services.errorHandler.logRemoteError(
        error,
        getMediosDePago,
        [],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(getMediosDePagoFailed(error));
    }
  };
}

const initialState = {
  isLoading: false,
  credito: null,
  creditoAlDiaDownloading: false,
  cuponPagoSaldoDownloading: false,
  notaCancelacionDownloading: false,
  mediosPagoDownloading: false,
};
const detalleCreditoReducer = handleActions(
  {
    [getDetalleCreditoInited]: (state, action) => {
      const operacionId = parseInt(action.payload.value);
      return {
        ...state,
        isLoading: state.credito
          ? operacionId !== state.credito.credito.operacionId
          : true,
      };
    },
    [getDetalleCreditoCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        credito: action.payload.response,
      };
    },
    [getDetalleCreditoFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    [getComprobanteCreditoAlDiaInited]: (state, action) => {
      return {
        ...state,
        creditoAlDiaDownloading: true,
      };
    },
    [getComprobanteCreditoAlDiaCompleted]: (state, action) => {
      return {
        ...state,
        creditoAlDiaDownloading: false,
      };
    },
    [getComprobanteCreditoAlDiaFailed]: (state, action) => {
      return {
        ...state,
        creditoAlDiaDownloading: false,
      };
    },
    [getCuponPagoSaldoInited]: (state, action) => {
      return {
        ...state,
        cuponPagoSaldoDownloading: true,
      };
    },
    [getCuponPagoSaldoCompleted]: (state, action) => {
      return {
        ...state,
        cuponPagoSaldoDownloading: false,
      };
    },
    [getCuponPagoSaldoFailed]: (state, action) => {
      return {
        ...state,
        cuponPagoSaldoDownloading: false,
      };
    },
    [getNotaCancelacionInited]: (state, action) => {
      return {
        ...state,
        notaCancelacionDownloading: true,
      };
    },
    [getNotaCancelacionCompleted]: (state, action) => {
      return {
        ...state,
        notaCancelacionDownloading: false,
      };
    },
    [getNotaCancelacionFailed]: (state, action) => {
      return {
        ...state,
        notaCancelacionDownloading: false,
      };
    },
    [getMediosDePagoInited]: (state, action) => {
      return {
        ...state,
        mediosPagoDownloading: true,
      };
    },
    [getMediosDePagoCompleted]: (state, action) => {
      return {
        ...state,
        mediosPagoDownloading: false,
      };
    },
    [getMediosDePagoFailed]: (state, action) => {
      return {
        ...state,
        mediosPagoDownloading: false,
      };
    },
  },
  initialState
);

export default detalleCreditoReducer;
export { getDetalleCredito };
