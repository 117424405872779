import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import waveBackground from "~images/auth/wave-bg.svg";
import logo from "~images/auth/logo-mg-white.svg";
import Lottie from "lottie-react-web";
import Paper from "@material-ui/core/Paper";

import InfiniteScroll from "~shared/layout/InfiniteScroll";

import loading from "~assets/animations/loading.json";
import Layout from "./Layout";

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  wave: {
    position: "absolute",
    width: "100%",
    minHeight: "100vh",
    margin: 0,
    padding: 0,
    backgroundImage: `url(${waveBackground})`,
    backgroundPosition: "-15vw top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "160vw 125vh",
    [theme.breakpoints.up("lg")]: {
      backgroundPosition: "top",
      backgroundSize: "100%"
    },
    "@media (min-width:1024px) and (orientation:portrait)": {
      backgroundSize: "160vw 100vh",
      backgroundPosition: "0 top"
    },
    "@media (min-width:1024px) and (orientation:landscape)": {
      backgroundSize: "100rem 50rem"
    },
    "@media (min-width:1600px)": {
      backgroundSize: "100% 90% "
    },
    "@media (min-width:1920px)": {
      backgroundSize: "100% 90vh"
    }
  },
  header: {
    zIndex: 1,
    display: "flex",
    textAlign: "left",
    maxWidth: "900px",
    flexDirection: "column",
    padding: "7vh 4vw",
    paddingLeft: "2vw",
    "@media (max-width:1024px)": {
      display: "none"
    }
  },
  brandIcon: {
    objectFit: "contain",
    alignSelf: "flex-start",
    height: "3em"
  },
  titleHeader: {
    zIndex: 1,
    display: "flex",
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: "2.5rem",
    lineHeight: "1.2",
    maxWidth: "35rem",
    marginTop: 30
  },
  subtitleHeader: {
    zIndex: 1,
    color: "#ffffff",
    fontSize: "1.3rem",
    lineHeight: "1.2",
    maxWidth: "26rem",
    marginTop: 30
  },
  loginBox: {
    zIndex: 1,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    width: "28rem",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "90vw",
    objectFit: "contain",
    paddingBottom: 10,
    paddingTop: "4em",
    "@media (max-width:1024px)": {
      paddingTop: 44
    }
  },
  brandIconMobile: {
    height: 60,
    marginBottom: 44,
    "@media (min-width:1024px)": {
      display: "none"
    }
  },
  card: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 40,
    borderRadius: 16,
    width: "90%",
    boxShadow: "0 0 20px 0 #663ea836",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  }
}));

const WaveLayout = ({ isLoading, children, title }) => {
  const classes = useStyles();

  return (
    <Layout>
      <Paper className={classes.card}>
        {title && (
          <Typography
            variant="h6"
            style={{
              padding: 30,
              color: "#503181",
              fontWeight: "bold",
              fontSize: 19
            }}
          >
            {title}
          </Typography>
        )}
        {!isLoading && <React.Fragment>{children}</React.Fragment>}
        {isLoading && (
          <div style={{ width: 60 }}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: loading
              }}
            />
          </div>
        )}
      </Paper>
    </Layout>
  );
};

export default WaveLayout;
