import React from "react";
import { useSelector } from "react-redux";
import LoadingViewWithLoader from "~components/styled/loadingView/LoadingViewWithLoader";
import Success from "./Success";
import Error from "./Error";

const useReduxSelector = () =>
  useSelector(state => {
    const informarPagoReducer = state.creditosReducers.informarPagoReducer;
    return {
      isLoading: informarPagoReducer.isLoading,
      error: informarPagoReducer.error
    };
  });
const FinalStep = () => {
  const { isLoading, error } = useReduxSelector();
  return (
    <>
      {isLoading && <LoadingViewWithLoader />}
      {!isLoading && !error && <Success />}
      {!isLoading && error && <Error />}
    </>
  );
};

export default FinalStep;
