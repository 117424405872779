export const CALCULADOR_ISLOADING = "CALCULADOR_ISLOADING";
export const CALCULADOR_SELECTANIO = "CALCULADOR_SELECTANIO";
export const CALCULADOR_SELECTPLAZO = "CALCULADOR_SELECTPLAZO";
export const CALCULADOR_FETCHRESULT_SUCCESS = "CALCULADOR_FETCHRESULT_SUCCESS";
export const CALCULADOR_SUBMITFORM = "CALCULADOR_SUBMITFORM";
export const CALCULADOR_CLEARRESULT = "CALCULADOR_CLEARRESULT";

export const calculadorIsLoading = loading => ({
  type: CALCULADOR_ISLOADING,
  payload: {
    isLoading: loading
  }
});

export const selectAnio = anio => {
  return {
    type: CALCULADOR_SELECTANIO,
    payload: {
      anio: anio
    }
  };
};

export const selectPlazo = plazo => {
  return {
    type: CALCULADOR_SELECTPLAZO,
    payload: {
      plazo: plazo
    }
  };
};

export const submit = formData => ({
  type: CALCULADOR_SUBMITFORM,
  payload: {
    ...formData
  },
  analytics: {
    eventName: "calculador-calcular",
    eventData: {
      formData
    }
  }
});

export const calculadorFetchResultSuccess = result => ({
  type: CALCULADOR_FETCHRESULT_SUCCESS,
  payload: {
    ...result
  }
});

export const clearCalculadorResult = () => ({
  type: CALCULADOR_CLEARRESULT
});

export function fetchResult(formData) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(calculadorIsLoading(true));
      dispatch(submit(formData));
      const response = await services.api.getCalculadorResult(formData);
      dispatch(calculadorFetchResultSuccess(response));
      dispatch(calculadorIsLoading(false));
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        fetchResult,
        [formData],
        getState()
      );
      dispatch(calculadorIsLoading(false));
      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
}
