import React from "react";
import CircularProgress from "material-ui/CircularProgress";

import Loadable from "react-loadable";

const Loading = () => (
  <div style={{ display: "flex", justifyContent: "center", marginTop: 90 }}>
    <CircularProgress />
  </div>
);

const AsyncLogin = Loadable({
  loader: () =>
    import(/* webpackChunkName: "login" */ "../user/LoginContainer"),
  delay: 300,
  loading: Loading
});

const AsyncRecoverPassword = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "recoverPassword" */ "../user/RecoverPasswordContainer"
    ),
  delay: 300,
  loading: Loading
});

const AsyncPasswordForm = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "passwordForm" */ "../signupAgencia/PasswordFormContainer"
    ),
  delay: 300,
  loading: Loading
});

const AsyncSignupAgencia = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "signupAgencia" */ "../signupAgencia/SignupAgenciaContainer"
    ),
  delay: 300,
  loading: Loading
});

const AsyncSolicitudTabs = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "solicitudTabs" */ "../solicitudTabs/SolicitudTabsContainer"
    ),
  delay: 300,
  loading: Loading
});

const AsyncHome = Loadable({
  loader: () => import(/* webpackChunkName: "home" */ "../home"),
  delay: 300,
  loading: Loading
});

const AsyncTest = Loadable({
  loader: () => import(/* webpackChunkName: "test" */ "../home/TestView"),
  delay: 300,
  loading: Loading
});

const AsyncCotizadorAuto = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "cotizadorAuto" */ "../cotizadorAuto/CotizadorAutoContainer"
    ),
  delay: 300,
  loading: Loading
});

const AsyncCalculador = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "calculador" */ "../calculador/CalculadorContainer"
    ),
  delay: 300,
  loading: Loading
});

const AsyncSolicitudCredito = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "solicitudCredito" */ "../solicitudCredito/SolicitudCreditoContainer"
    ),
  delay: 300,
  loading: Loading
});

const AsyncSolicitudAutoprenda = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "solicitudAutoprenda" */ "../solicitudAutoprenda/SolicitudAutoprendaForm"
    ),
  delay: 300,
  loading: Loading
});

const AsyncListaSolicitudes = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "listaSolicitudes" */ "../listaSolicitudes/ListaSolicitudesContainer"
    ),
  delay: 300,
  loading: Loading
});

const AsyncCotizadorSeguros = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "cotizadorSeguros" */ "../cotizadorSeguros/CotizadorSeguros"
    ),
  delay: 300,
  loading: Loading
});

const AsyncAgregarPersona = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "agregarPersona" */ "../agregarPersona/AgregarPersonaContainer"
    ),
  delay: 300,
  loading: Loading
});

const AsyncCapturarAuto = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "capturarAuto" */ "../capturarAuto/CapturarAuto"
    ),
  delay: 300,
  loading: Loading
});

const AsyncAgregarCodeudor = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "agregarCodeudor" */ "../listaTareas/AgregarCodeudor"
    ),
  delay: 300,
  loading: Loading
});
const AsyncAgregarConyugeCodeudor = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "agregarConyugeCodeudor" */ "../listaTareas/AgregarConyugeCodeudor"
    ),
  delay: 300,
  loading: Loading
});
const AsyncDevInfo = Loadable({
  loader: () => import(/* webpackChunkName: "devInfo" */ "../devInfo/DevInfo"),
  delay: 300,
  loading: Loading
});
const AsyncInfo = Loadable({
  loader: () =>
    import(/* webpackChunkName: "devInfo" */ "../contactoComercial/Info"),
  delay: 300,
  loading: Loading
});

export {
  AsyncLogin,
  AsyncRecoverPassword,
  AsyncPasswordForm,
  AsyncSignupAgencia,
  AsyncSolicitudTabs,
  AsyncHome,
  AsyncTest,
  AsyncCotizadorAuto,
  AsyncCalculador,
  AsyncSolicitudCredito,
  AsyncSolicitudAutoprenda,
  AsyncListaSolicitudes,
  AsyncCotizadorSeguros,
  AsyncAgregarPersona,
  AsyncCapturarAuto,
  AsyncAgregarCodeudor,
  AsyncAgregarConyugeCodeudor,
  AsyncDevInfo,
  AsyncInfo
};
