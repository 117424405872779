import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SolicitudCardButton from "~components/app/agenciero/creditos/listadoSolicitudes/solicitudCardButton/SolicitudCardButton";
import ContentSection from "~components/styled/contentSection/ContentSection";
import LinkButton from "~components/styled/linkButton/LinkButton";
import Typography from "@material-ui/core/Typography";
import pxToRem from "~libs/pxToRem";
import { useHistory } from "react-router-dom";
import { getSolicitudes } from "./actions";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import EmptyStateSolicitud from "~assets/ilustrations/empty-state_solicitud.svg";

const ListadoSolicitudes = () => {
  const { solicitudes, isLoading } = useSelector(state => ({
    isLoading: state.creditosReducers.listadoSolicitudesReducer.isLoading,
    solicitudes: state.creditosReducers.listadoSolicitudesReducer.solicitudes
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSolicitudes(0, 5, "", false));
  }, []);
  const history = useHistory();
  const hasItems = solicitudes.items && solicitudes.items.length > 0;
  const hasMoreItems = solicitudes.total > 5;
  return (
    <ContentSection>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: 14
        }}
      >
        <Typography
          variant="caption"
          noWrap
          style={{
            fontSize: pxToRem(22),
            fontWeight: "bold",
            lineHeight: 1.55,
            color: "#663ea8"
          }}
        >
          {`Mis solicitudes`}
        </Typography>
        {!isLoading && hasMoreItems && (
          <LinkButton
            onClick={() => {
              history.push("/solicitudes");
            }}
            variant="purple"
          >
            ver todas
          </LinkButton>
        )}
      </div>
      {isLoading && <LoadingTemplate length={3} />}
      {!isLoading &&
        hasItems &&
        solicitudes.items.map(solicitud => (
          <SolicitudCardButton
            key={`solicitud-${solicitud.id}`}
            solicitud={solicitud}
            marginBottom={12}
            onClick={() => {
              history.push(`/solicitudes/${solicitud.id}`);
            }}
          />
        ))}
      {!isLoading && hasMoreItems && (
        <div style={{ marginBottom: 12 }}>
          <CallToActionButton
            variant="outlined"
            color="secondary"
            label={"Ver todas"}
            onClick={() => {
              history.push("/solicitudes");
            }}
          />
        </div>
      )}
      {!isLoading && !hasItems && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: pxToRem(16)
          }}
        >
          <img src={EmptyStateSolicitud} />
          <Typography
            variant="caption"
            noWrap
            style={{
              fontSize: pxToRem(16),
              fontWeight: 600,
              lineHeight: 1.38,
              color: "#757575",
              padding: pxToRem(20)
            }}
          >
            {"Todavía no tenés solicitudes"}
          </Typography>
          <LinkButton
            variant="purple"
            onClick={() => {
              history.push("/creditos");
            }}
          >
            ¿Querés iniciar una solicitud?
          </LinkButton>
        </div>
      )}
    </ContentSection>
  );
};

export default ListadoSolicitudes;

const LoadingTemplate = ({ length }) => {
  return Array.apply(null, Array(length)).map((_, index) => (
    <SolicitudCardButton
      key={`loading-${index}`}
      marginBottom={12}
      onClick={null}
      isLoading={true}
    />
  ));
};
