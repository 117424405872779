import isNullOrEmpty from "~libs/isNullOrEmpty";

const FIXED_PHONES_DIGITS_LENGTH = 11;
const MOBILE_PHONES_DIGITS_LENGTH = 13;

const validatePhone = (number = "", isMobile, area) => {
  const maxLen = isMobile
    ? MOBILE_PHONES_DIGITS_LENGTH
    : FIXED_PHONES_DIGITS_LENGTH;

  let error = null;

  if (isNullOrEmpty(number)) {
    error = "Requerido";
  } else if (!area) {
    error = "Código de área no válido";
  } else if (number.length < maxLen) {
    error = "Faltan dígitos";
  }

  return error;
};

export default validatePhone;
