import environment from "../../../environment";

export const SIGNUPAGENCIA_INITED = "SIGNUPAGENCIA_INITED";
export const SIGNUPAGENCIA_VALIDATION_COMPLETED =
  "SIGNUPAGENCIA_VALIDATION_COMPLETED";
export const SIGNUPAGENCIA_VALIDATION_FAILED =
  "SIGNUPAGENCIA_VALIDATION_FAILED";
export const SIGNUPAGENCIA_PASSWORD_COMPLETED =
  "SIGNUPAGENCIA_PASSWORD_COMPLETED";
export const SIGNUPAGENCIA_PASSWORD_FAILED = "SIGNUPAGENCIA_PASSWORD_FAILED";
export const SIGNUPAGENCIA_COMPLETED = "SIGNUPAGENCIA_COMPLETED";
export const SIGNUPAGENCIA_FAILED = "SIGNUPAGENCIA_FAILED";

export const submitSignupAgencia = formData => {
  return async (dispatch, getState, services) => {
    try {
      const authState = getState().authReducer.authState;
      const email = authState && authState.user ? authState.user.email : "";

      const TIPO_AGENCIA_CONCESIONARIA = "1";

      const data = {
        NombreAgencia: formData.nombreAgencia,
        TipoAgencia: formData.tipoAgencia,
        TipoVehiculo: formData.tipoVehiculo,
        Marca:
          formData.tipoAgencia === TIPO_AGENCIA_CONCESIONARIA
            ? formData.marca.value
            : "",
        Cui: formData.cui,
        NombreTitular: formData.titular,
        Direccion: formData.direccion,
        Provincia: formData.provincia.value,
        Localidad: formData.localidad.value,
        CodigoPostal: formData.codPostal.value,
        NombreContacto: formData.nombre,
        EmailContacto: email,
        TelefonoContacto: formData.telefono,
        ComoNosConocio: 1,
        VersionTerminos: environment.terminosYCondicionesVersion
      };

      await services.api.postSignupAgencia(data);
      dispatch(submitAgenciaCompleted());
    } catch (error) {
      services.errorHandler.logRemoteError(
        error,
        submitSignupAgencia,
        [formData],
        getState()
      );
      console.error("ERROR: Signup Agencia", error);
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(submitAgenciaFailed());
    }
  };
};

export function postTokenToConfirmation(token) {
  return async (dispatch, getState, services) => {
    dispatch(submitInited());

    try {
      const response = await services.api.postSingupAgenciaConfirmacion(token);
      dispatch(submitValidationCompleted(response.userId));
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        postTokenToConfirmation,
        [token],
        getState()
      );

      if (
        error.responseStatus &&
        error.responseStatus.errorCode === "Conflict"
      ) {
        var userExpired = true;
      } else if (
        error.responseStatus &&
        error.responseStatus.errorCode === "ArgumentException"
      ) {
        var tokenValid = false;
      } else dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(submitValidationFailed(userExpired, tokenValid));
    }
  };
}

export function submitForm(formData) {
  return async (dispatch, getState, services) => {
    dispatch(submitInited());

    try {
      await services.api.postAgenciaUserPassword(formData);
      dispatch(submitPasswordCompleted());
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        submitForm,
        [formData],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(submitPasswordFailed());
    }
  };
}

export const submitAgenciaCompleted = () => ({
  type: SIGNUPAGENCIA_COMPLETED,
  payload: {
    success: true
  }
});

export const submitAgenciaFailed = () => ({
  type: SIGNUPAGENCIA_FAILED,
  payload: {
    success: false
  }
});

export const submitInited = () => ({
  type: SIGNUPAGENCIA_INITED,
  payload: {}
});

export const submitValidationCompleted = userId => ({
  type: SIGNUPAGENCIA_VALIDATION_COMPLETED,
  payload: {
    userId,
    tokenValid: true
  },
  analytics: {
    eventName: "registrar-agencia-password-enviar",
    eventData: {
      tokenValido: true
    }
  }
});

export const submitValidationFailed = (userExpired, tokenValid) => ({
  type: SIGNUPAGENCIA_VALIDATION_FAILED,
  payload: {
    userExpired,
    tokenValid
  }
});

export const submitPasswordCompleted = () => ({
  type: SIGNUPAGENCIA_PASSWORD_COMPLETED,
  payload: {
    success: true
  },
  analytics: {
    eventName: "solicitud-Agencias-Password-Submit",
    eventData: {
      passwordValida: true
    }
  }
});

export const submitPasswordFailed = () => ({
  type: SIGNUPAGENCIA_PASSWORD_FAILED,
  payload: {
    success: false
  }
});
