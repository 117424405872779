import { conformToMask } from "react-text-mask";
import getArea from "./getArea";

const MIN_PREFIJO_LENGTH = 2;
const MAX_AREACODE_LENGTH = 5;
const FIXED_PHONES_DIGITS_LENGTH = 11;
const MOBILE_PHONES_DIGITS_LENGTH = 13;

const getPhoneText = number => {
  const isMobile =
    number.length === MOBILE_PHONES_DIGITS_LENGTH || number.length === 0;
  const area = getArea(number);

  const areaCode = area ? area.Code : null;

  const value = number.replace(/\s|\(|\)|\-|\_/g, "");
  const maxLen = isMobile
    ? MOBILE_PHONES_DIGITS_LENGTH
    : FIXED_PHONES_DIGITS_LENGTH;

  const areaChars = areaCode
    ? Array.from(Array(areaCode.length - 1), () => /\d/)
    : Array.from(Array(MAX_AREACODE_LENGTH - 1), () => /\d/);

  const mobileChars = isMobile ? [/\d/, /\d/, " "] : [];

  let prefijoChars = [/\d/, /\d/, /\d/, /\d/];
  let abonadoChars = [/\d/, /\d/, /\d/, /\d/];

  let mask = [];

  if (areaCode) {
    if (value.length > areaCode.length) {
      let prefijoLength =
        MIN_PREFIJO_LENGTH + (MAX_AREACODE_LENGTH - areaCode.length);
      let abonadoLength =
        maxLen - areaCode.length - prefijoLength - (isMobile ? 2 : 0);

      prefijoChars = Array.from(Array(prefijoLength), () => /\d/);
      abonadoChars = Array.from(Array(abonadoLength), () => /\d/);
    }

    mask = [
      "(",
      /\d/,
      ...areaChars,
      ")",
      " ",
      ...mobileChars,
      ...prefijoChars,
      "-",
      ...abonadoChars
    ];
  } else {
    prefijoChars = Array.from(Array(number.length - 4), () => /\d/);

    mask = [...mobileChars, ...prefijoChars, "-", ...abonadoChars];
  }

  const phone = conformToMask(number, mask);

  return phone.conformedValue;
};

export default getPhoneText;
