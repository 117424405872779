import { FEATURE_FLAG_CHANGE } from "./actions";
import localRepository from "../../../../localRepository";

const initialState = {
  featureFlags: { ...localRepository.featureFlags.get() }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FEATURE_FLAG_CHANGE:
      const newState = {
        ...state,
        featureFlags: { ...state.featureFlags, ...action.payload.flag }
      };
      localRepository.featureFlags.set(newState.featureFlags);
      return newState;
    default:
      return state;
  }
};

export default reducer;
