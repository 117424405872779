import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useLocationRef from "~components/app/useLocationRef";

import ProcessLayout from "~components/styled/layouts/processLayout/ProcessLayout";

import TipoCreditoStep from "./TipoCreditoStep";
import VehiculoAnioStep from "./VehiculoAnioStep";
import ImporteYPlazoStep from "./ImporteYPlazoStep";

import { clear } from "./actions";
import CreditoStep from "./creditoStep/CreditoStep";

const steps = {
  // steps must be string
  tipoCredito: "0",
  vehiculoAnio: "1",
  importeYPlazo: "2",
  creditoStep: "3"
};
const BASE_URL = "/creditos/calcular";
const FIRST_STEP = steps.tipoCredito;

const useReduxSelector = () =>
  useSelector(state => {
    const calcularCreditoReducer =
      state.creditosReducers.calcularCreditoReducer;

    return {
      tipoCreditoSelected: calcularCreditoReducer.tipoCreditoSelected,
      vehiculoAnioSelected: calcularCreditoReducer.vehiculoAnioSelected,
      importeSelected: calcularCreditoReducer.importeSelected,
      plazoSelected: calcularCreditoReducer.plazoSelected,
      isForward: calcularCreditoReducer.isForward
    };
  });

const CalcularCredito = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { position = FIRST_STEP } = useParams();

  useEffect(() => {
    dispatch(clear());

    return function cleanup() {
      dispatch(clear());
    };
  }, [dispatch]);

  const currentState = useReduxSelector();

  const currentStep = useCurrentStep(currentState, position);

  const isInProcess = [
    steps.tipoCredito,
    steps.vehiculoAnio,
    steps.importeYPlazo
  ].includes(position);

  return (
    <>
      {isInProcess && (
        <ProcessLayout
          title={"Calcular crédito"}
          progress={(currentStep.order * 100) / Object.keys(steps).length} // porcentage (0-100)
          onBackClick={() => {
            history.goBack();
          }}
          onCloseClick={() => {
            history.push("/");
          }}
          isForward={currentState.isForward}
        >
          {position === steps.tipoCredito && <TipoCreditoStep />}
          {position === steps.vehiculoAnio && <VehiculoAnioStep />}
          {position === steps.importeYPlazo && <ImporteYPlazoStep />}
        </ProcessLayout>
      )}
      {!isInProcess && <CreditoStep />}
    </>
  );
};

export default CalcularCredito;

const useCurrentStep = (currentState, position) => {
  const history = useHistory();
  const locationRef = useLocationRef();

  const validSteps = getValidSteps(currentState);

  const isValidStep = validSteps.find(x => x.index === position) ? true : false;

  const currentStep = validSteps.slice().pop();

  useEffect(() => {
    const stepUrl = `${BASE_URL}`;
    // if not valid step, go to first step.
    if (!isValidStep) {
      history.replace(stepUrl);
    }
  }, [history, isValidStep]);

  useEffect(() => {
    const stepUrl = `${BASE_URL}/${currentStep.index}`;
    const isThisUrl = locationRef.current.pathname === stepUrl;
    const isFirstStep =
      locationRef.current.pathname === `${BASE_URL}` &&
      currentStep.index === FIRST_STEP;

    if (!isThisUrl && !isFirstStep && isValidStep) history.push(stepUrl);
  }, [isValidStep, history, currentStep.index]);

  return currentStep;
};

const getValidSteps = ({
  tipoCreditoSelected,
  vehiculoAnioSelected,
  importeSelected,
  plazoSelected
}) => {
  const tipoCreditoStep = step(steps.tipoCredito, !tipoCreditoSelected);
  const vehiculoAnioStep = step(steps.vehiculoAnio, !vehiculoAnioSelected);
  const importeYPlazoStep = step(
    steps.importeYPlazo,
    !importeSelected || !plazoSelected
  );
  const creditoStep = step(steps.creditoStep, true);

  //set order of steps
  tipoCreditoStep
    .next(vehiculoAnioStep)
    .next(importeYPlazoStep)
    .next(creditoStep);

  return tipoCreditoStep.run();
};

const step = (index, isThisStep) => {
  let next = null;
  let order = 1;

  return {
    index,
    order,
    next(step) {
      if (step) next = { ...step, order: this.order + 1 };
      return next;
    },
    run() {
      return isThisStep ? [this] : [this, ...(next && next.run())];
    }
  };
};
