import React from "react";
import pxToRem from "~libs/pxToRem";

const ContentSection = ({ children }) => {
  return (
    <section
      style={{
        width: "100%",
        maxWidth: pxToRem(500),
        display: "flex",
        flexDirection: "column"
      }}
    >
      {children}
    </section>
  );
};

export default ContentSection;
