import React, { Component } from "react";
import PropTypes from "prop-types";
import FontIcon from "material-ui/FontIcon";

import { useTheme } from "../../../libs/styles";

class ResultMessage extends Component {
  render() {
    const theme = useTheme();

    const defaultContainerStyles = {
      marginTop: "5%",
      display: "block",
      boxShadow: "rgb(232, 232, 232) 0px 2px 15px",
      padding: 20,
      lineHeight: 1.8,
      color: theme.palette.textColor,
      margin: "10% auto",
      backgroundColor:
        this.props.backgroundColor || theme.palette.alternateTextColor,
      border: "1px solid " + this.props.borderColor || theme.palette.borderColor
    };

    return (
      <div>
        <div style={{ clear: "both" }} />
        <div
          style={{
            ...defaultContainerStyles,
            ...this.props.style
          }}
        >
          <FontIcon
            className="material-icons"
            style={{
              height: 28,
              verticalAlign: "middle",
              marginRight: 10,
              color: this.props.color || theme.palette.textColor
            }}
          >
            {this.props.fontIcon || "announcement"}
          </FontIcon>
          <span
            style={{
              display: "inline-block",
              fontWeight: 700,
              color: this.props.color || theme.palette.textColor
            }}
          >
            {this.props.title}
          </span>
          <span
            style={{
              display: "block",
              marginTop: 15
            }}
          >
            {this.props.message}
          </span>
          {this.props.options ? this.props.options : ""}
        </div>
      </div>
    );
  }
}

ResultMessage.propTypes = {
  message: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired
};

export default ResultMessage;
