import { createActions, handleActions } from "redux-actions";

export const {
  actividadPersonaActividadDeclaradaSet,
  actividadPersonaRubroSet,
  actividadPersonaAntiguedadSet,
  actividadPersonaIngresosSet,
  actividadPersonaCompleted,
  actividadPersonaClear
} = createActions(
  "ACTIVIDAD_PERSONA_ACTIVIDAD_DECLARADA_SET",
  "ACTIVIDAD_PERSONA_RUBRO_SET",
  "ACTIVIDAD_PERSONA_ANTIGUEDAD_SET",
  "ACTIVIDAD_PERSONA_INGRESOS_SET",
  "ACTIVIDAD_PERSONA_COMPLETED",
  "ACTIVIDAD_PERSONA_CLEAR"
);

const actividadPersonaReducer = handleActions(
  {
    [actividadPersonaActividadDeclaradaSet]: (state, action) => {
      return {
        actividadDeclarada: action.payload.actividadDeclarada,
        ingresos: "",
        rubro: "",
        antiguedad: "",
        complete: false
      };
    },
    [actividadPersonaRubroSet]: (state, action) => {
      return {
        ...state,
        rubro: action.payload.rubro,
        ingresos: "",
        antiguedad: "",
        complete: false
      };
    },
    [actividadPersonaAntiguedadSet]: (state, action) => {
      return {
        ...state,
        antiguedad: action.payload.antiguedad,
        ingresos: "",
        complete: false
      };
    },
    [actividadPersonaIngresosSet]: (state, action) => {
      return {
        ...state,
        ingresos: action.payload.ingresos
      };
    },
    [actividadPersonaCompleted]: (state, action) => {
      return {
        ...state,
        complete: action.payload.complete
      };
    },
    [actividadPersonaClear]: (state, action) => {
      return {
        ...state,
        actividadDeclarada: "",
        ingresos: 0,
        rubro: "",
        antiguedad: "",
        complete: false
      };
    }
  },
  {
    actividadDeclarada: "",
    ingresos: 0,
    rubro: "",
    antiguedad: "",
    complete: false
  }
);

export default actividadPersonaReducer;
