import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setNombreYApellido, clearNombreYApellido } from "../actions";
import { useAuth0 } from "~componentes/Auth0/useAuth";
import localRepository from "~src/localRepository";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

const ENTER_KEY = 13;

const NombreStep = () => {
  const dispatch = useDispatch();
  const { authState } = useAuth0();
  const initialState = localRepository.lastUsedName.get()
    ? localRepository.lastUsedName.get()
    : authState.user.preferred_username;
  const [nombre, setNombre] = useState(initialState);

  useEffect(() => {
    dispatch(clearNombreYApellido());
  }, [dispatch]);

  const onKeyDown = event => {
    if (event.keyCode === ENTER_KEY && nombre)
      dispatch(setNombreYApellido(nombre));
  };
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={!nombre}
            label={"Continuar"}
            onClick={() => {
              dispatch(setNombreYApellido(nombre));
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle
        label={"¿Quién es el titular del crédito?"}
        marginBottom={60}
      />
      <TextField
        fullWidth={true}
        onFocus={event => event.target.select()}
        value={nombre}
        onKeyDown={onKeyDown}
        autoFocus
        onChange={event => setNombre(event.target.value)}
        placeholder="Ej: Juan Perez"
      ></TextField>
    </ContentSectionWithActions>
  );
};

export default NombreStep;
