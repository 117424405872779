import { createActions, handleActions } from "redux-actions";

export const {
  contactoPersonaPhoneFijo,
  contactoPersonaPhoneMobile,
  contactoPersonaClear
} = createActions(
  "CONTACTO_PERSONA_PHONE_FIJO",
  "CONTACTO_PERSONA_PHONE_MOBILE",
  "CONTACTO_PERSONA_CLEAR"
);

const contactoPersonaReducer = handleActions(
  {
    CONTACTO_PERSONA_PHONE_FIJO: (state, action) => {
      return {
        ...state,
        phoneFijo: action.payload.phoneFijo
      };
    },
    CONTACTO_PERSONA_PHONE_MOBILE: (state, action) => {
      return {
        ...state,
        phoneMobile: action.payload.phoneMobile
      };
    },
    CONTACTO_PERSONA_CLEAR: (state, action) => {
      return {
        ...state,
        phoneFijo: "",
        phoneMobile: ""
      };
    }
  },
  {
    phoneFijo: "",
    phoneMobile: ""
  }
);

export default contactoPersonaReducer;
