import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearTipoCredito, setTipoCredito } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import {
    CheckboxButton,
    CheckboxLargeLabel
  } from "~components/styled/checkboxButton/CheckboxButton";

import autoIcon from "~assets/ilustrations/illus-comprar-auto.svg";
import otrosIcon from "~assets/ilustrations/illus-otros-proyectos.svg";

const PRENDARIO = 1;
const AUTOPRENDA = 3;

const TipoCreditoStep = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearTipoCredito());
  }, [dispatch]);


  return (
    <ContentSectionWithActions>
      <PrimaryTitle label={"¿Para qué necesita el crédito tu cliente?"} marginBottom={60} />
      <CheckboxButton
          disabled={false}          
          marginBottom={15}
          onClick={() => dispatch(setTipoCredito(PRENDARIO))}
        >
          <CheckboxLargeLabel
            label={"Comprar un auto"}
            subLabel={"de la agencia"}
            image={autoIcon}
            topLabel={"Credito prendario"}
          />
        </CheckboxButton>
        <CheckboxButton
          disabled={false}
          onClick={() => dispatch(setTipoCredito(AUTOPRENDA))}
        >
          <CheckboxLargeLabel
            label={"Otros proyectos"}
            subLabel={"crédito con auto como garantía"}
            image={otrosIcon}
            topLabel={"Autoprenda"}
          />
        </CheckboxButton>
    </ContentSectionWithActions>
  );
};

export default TipoCreditoStep;