import React, { useEffect, useState } from "react";
import ContentSection from "~components/styled/contentSection/ContentSection";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import Oferta from "./Oferta";
import DetalleOfertaModal from "./DetalleOfertaModal";

const Ofertas = ({ ofertas }) => {
  const [opened, setOpened] = useState(false);
  const [ofertaModal, setOfertaModal] = useState(null);

  const onClick = item => {
    setOfertaModal(item);
    setOpened(true);
  };
  const onClose = () => {
    setOpened(false);
    setOfertaModal(null);
  };

  return (
    <ContentSection>
      <DetalleOfertaModal
        opened={opened}
        onClose={onClose}
        oferta={ofertaModal}
      />
      <PrimaryTitle label={"Elegí una oferta"} marginBottom={40} />

      {ofertas.map((item, i) => (
        <Oferta
          key={`oferta-${i}`}
          oferta={item}
          marginBottom={16}
          onClick={() => onClick(item)}
        />
      ))}
    </ContentSection>
  );
};

export default Ofertas;
