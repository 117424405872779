import { createActions, handleActions } from "redux-actions";
import { sendMessageToNative } from "~componentes/nativeAppMessaging/js/actions";
import openFile from "~libs/openFile";
import fileService from "~libs/fileService";
import blobToBase64 from "~libs/blobToBase64";

const {
  creditos: {
    listadoCuotasCredito: {
      getCuotasCreditoInited,
      getCuotasCreditoCompleted,
      getCuotasCreditoFailed,
      getCuponesCreditoInited,
      getCuponesCreditoCompleted,
      getCuponesCreditoFailed,
      getComprobantesPagoInited,
      getComprobantesPagoCompleted,
      getComprobantesPagoFailed
    }
  }
} = createActions({
  creditos: {
    listadoCuotasCredito: {
      getCuotasCreditoInited: value => ({ value }),
      getCuotasCreditoCompleted: (response, operacionId) => ({
        response,
        operacionId
      }),
      getCuotasCreditoFailed: error => ({
        error
      }),
      getCuponesCreditoInited: cuotasIds => ({ cuotasIds }),
      getCuponesCreditoCompleted: cuotasIds => ({ cuotasIds }),
      getCuponesCreditoFailed: (error, cuotasIds) => ({ error, cuotasIds }),
      getComprobantesPagoInited: cuotasIds => ({ cuotasIds }),
      getComprobantesPagoCompleted: cuotasIds => ({ cuotasIds }),
      getComprobantesPagoFailed: (error, cuotasIds) => ({ error, cuotasIds })
    }
  }
});

const getCuotasCredito = operacionId => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getCuotasCreditoInited(operacionId));
      const response = await services.api.getCuotasCredito(operacionId);
      dispatch(getCuotasCreditoCompleted(response.cuotas, operacionId));
    } catch (error) {
      console.error(error);
      dispatch(getCuotasCreditoFailed(error));
    }
  };
};

export function getCuponesCredito(operacionId, cuotasIds) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getCuponesCreditoInited(cuotasIds));
      const result = await services.api.getCuponesCredito(
        operacionId,
        cuotasIds
      );
      const isOnNativeApp = getState().nativeMessagesReducer.isOnNativeApp;
      const fileServicePath = fileService(result.path);
      const response = await fileServicePath.download(
        encodeURIComponent(result.fileName.toLowerCase()).replace(
          /%2520/g,
          "%20"
        )
      );

      if (isOnNativeApp) {
        const title =
          Array.isArray(cuotasIds) && cuotasIds.length > 1
            ? "Cupones"
            : "Cupón";

        sendMessageToNative({
          type: "WEB_SHOW_FILE_VIEWER",
          payload: {
            title,
            data: null,
            filename: result.fileName
          }
        });
        const blob = await fetch(response.url).then(response =>
          response.blob()
        );
        const fileBase64 = await blobToBase64(blob);
        sendMessageToNative({
          type: "WEB_SHOW_FILE_VIEWER",
          payload: {
            title,
            data: fileBase64,
            filename: result.fileName
          }
        });
      } else {
        await openFile(response.url);
      }
      dispatch(getCuponesCreditoCompleted(cuotasIds));
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        getCuponesCredito,
        [],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(getCuponesCreditoFailed(error, cuotasIds));
    }
  };
}

export function getComprobantesPago(operacionId, cuotasIds) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getComprobantesPagoInited(cuotasIds));
      const result = await services.api.getComprobantesPago(
        operacionId,
        cuotasIds
      );
      const isOnNativeApp = getState().nativeMessagesReducer.isOnNativeApp;

      const fileServicePath = fileService(result.path);
      const response = await fileServicePath.download(
        encodeURIComponent(result.fileName.toLowerCase()).replace(
          /%2520/g,
          "%20"
        )
      );

      if (isOnNativeApp) {
        sendMessageToNative({
          type: "WEB_SHOW_FILE_VIEWER",
          payload: {
            title: "Comprobante de pago",
            data: null,
            filename: result.fileName
          }
        });

        const blob = await fetch(response.url).then(response =>
          response.blob()
        );
        const fileBase64 = await blobToBase64(blob);
        sendMessageToNative({
          type: "WEB_SHOW_FILE_VIEWER",
          payload: {
            title: "Comprobante de pago",
            data: fileBase64,
            filename: result.fileName
          }
        });
      } else {
        await openFile(response.url);
      }
      dispatch(getComprobantesPagoCompleted(cuotasIds));
    } catch (error) {
      console.error(error);
      services.errorHandler.logRemoteError(
        error,
        getComprobantesPago,
        [],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(getComprobantesPagoFailed(error, cuotasIds));
    }
  };
}

const initialState = {
  isLoading: false,
  operacionId: null,
  cuotas: null,
  cuotasIds: []
};
const listadoCuotasCreditoReducer = handleActions(
  {
    [getCuotasCreditoInited]: (state, action) => {
      const operacionId = action.payload.value;
      return {
        ...state,
        /* cuotas: null,
        cuotasIds: [], */
        isLoading: operacionId !== state.operacionId
      };
    },
    [getCuotasCreditoCompleted]: (state, action) => {
      const cuotas = action.payload.response
        ? action.payload.response.sort((a, b) =>
            a.nroCuota > b.nroCuota ? 1 : -1
          )
        : action.payload.response;
      const indexedCuotas = cuotas.reduce(
        (ac, cuota) => ({ ...ac, [cuota.id]: cuota }),
        {}
      );

      const cuotasIds = Object.keys(indexedCuotas);
      return {
        ...state,
        isLoading: false,
        cuotas: indexedCuotas,
        cuotasIds: cuotasIds,
        operacionId: action.payload.operacionId
      };
    },
    [getCuotasCreditoFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    },
    [getCuponesCreditoInited]: (state, action) => {
      const cuotasUpdated = getNewStateCuotas(
        action.payload.cuotasIds,
        state.cuotas,
        true
      );
      return {
        ...state,
        cuotas: cuotasUpdated
      };
    },
    [getCuponesCreditoCompleted]: (state, action) => {
      const cuotasUpdated = getNewStateCuotas(
        action.payload.cuotasIds,
        state.cuotas,
        false
      );
      return {
        ...state,
        cuotas: cuotasUpdated
      };
    },
    [getCuponesCreditoFailed]: (state, action) => {
      const cuotasUpdated = getNewStateCuotas(
        action.payload.cuotasIds,
        state.cuotas,
        false
      );
      return {
        ...state,
        cuotas: cuotasUpdated
      };
    },
    [getComprobantesPagoInited]: (state, action) => {
      const cuotasUpdated = getNewStateCuotas(
        action.payload.cuotasIds,
        state.cuotas,
        true
      );
      return {
        ...state,
        cuotas: cuotasUpdated
      };
    },
    [getComprobantesPagoCompleted]: (state, action) => {
      const cuotasUpdated = getNewStateCuotas(
        action.payload.cuotasIds,
        state.cuotas,
        false
      );
      return {
        ...state,
        cuotas: cuotasUpdated
      };
    },
    [getComprobantesPagoFailed]: (state, action) => {
      const cuotasUpdated = getNewStateCuotas(
        action.payload.cuotasIds,
        state.cuotas,
        false
      );
      return {
        ...state,
        cuotas: cuotasUpdated
      };
    }
  },
  initialState
);

export default listadoCuotasCreditoReducer;
export { getCuotasCredito };

const getNewStateCuotas = (cuotasIds, cuotas, downloading) => {
  const ids = cuotasIds.length ? cuotasIds : [cuotasIds];
  let newState = { ...cuotas };
  ids.forEach(id => (newState[id].downloading = downloading));

  return newState;
};
