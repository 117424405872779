import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListView from "~components/styled/listView/ListView";
import Searcher from "~components/styled/searcher/Searcher";
import filterItems from "~libs/filterItems";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { fetchMarcasVehiculos } from "./actions";

import citroen from "~assets/marcas/citroen.svg";
import fiat from "~assets/marcas/fiat.svg";
import ford from "~assets/marcas/ford.svg";
import honda from "~assets/marcas/honda.svg";
import peugeot from "~assets/marcas/peugeot.svg";
import renault from "~assets/marcas/renault.svg";
import toyota from "~assets/marcas/toyota.svg";
import vw from "~assets/marcas/volkswagen.svg";
import chevrolet from "~assets/marcas/chevrolet.svg";

const useStyles = makeStyles(theme => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-around"
  },
  brandSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },
  brandRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  buttonContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    "@media (min-width:1024px)": {
      height: "100%"
    }
  },
  brandItemContainer: {
    flex: 1
  },
  button: {
    marginTop: 10,
    width: "30%",
    height: 105,
    padding: 0,
    borderRadius: 8,
    background: "#ffffff",
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    marginBottom: "5%",
    "@media (min-width:1024px)": {
      minWidth: "140px",
      minHeight: "140px"
    }
  },
  brandItemLabel: {
    marginBottom: -1,
    borderRadius: "0 0 8px 8px",
    lineHeight: "34px",
    color: "#8e8e8e",
    background: "#e4e4e6",
    fontSize: 13,
    textTransform: "lowercase",
    "&:first-letter": { textTransform: "uppercase" }
  },
  iconContainer: {
    justifyContent: "center",
    "@media (min-width:1024px)": {
      display: "flex",
      justifyContent: "center",
      height: "81%",
      minHeight: "105px",
      alignItems: "center"
    }
  },
  brandIcon: {
    objectFit: "contain",
    padding: 2,
    paddingTop: 10,
    paddingBottom: 10,
    width: 70,
    height: 70,
    marginTop: 4,
    marginBottom: -4
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2
  }
}));

const useReduxSelector = () =>
  useSelector(state => {
    const marcaVehiculoSelectorReducer = state.marcaVehiculoSelectorReducer;

    return {
      marcas: marcaVehiculoSelectorReducer.marcas,
      isLoading: marcaVehiculoSelectorReducer.isLoading
    };
  });
const MarcaVehiculoSelector = ({ value, onSelected }) => {
  const [filter, setFilter] = useState("");
  const { marcas, isLoading } = useReduxSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMarcasVehiculos());
  }, [dispatch]);

  const handleBrandItem = value => {
    setFilter("");
    if (onSelected) onSelected({ target: { value } });
  };

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  const BrandItem = ({ icon, value }) => {
    const classes = useStyles();

    return (
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => handleBrandItem(value)}
      >
        <div className={classes.buttonContent}>
          <div className={classes.iconContainer}>
            <img src={icon} className={classes.brandIcon} alt="" />
          </div>
          <Typography
            color="textPrimary"
            component={"div"}
            className={classes.brandItemLabel}
          >
            {value.label ? value.label : ""}
          </Typography>
        </div>
      </Button>
    );
  };
  const classes = useStyles();
  return (
    <>
      {!value.label && (
        <div className={classes.searcher}>
          <Searcher
            onClear={() => handleBrandItem({ value: null, label: null })}
            filter={filter}
            onChange={handleFilterChange}
            placeholder={"Buscar marca"}
            borderBottomRounded={true}
          />
          {filter === "" ? (
            <div style={{ paddingTop: 24 }}>
              <Typography
                variant="overline"
                display="block"
                style={{ color: "#a7a7a7" }}
              >
                MARCAS DESTACADAS
              </Typography>
              <div className={classes.brandSection}>
                <div className={classes.brandRow}>
                  <BrandItem
                    icon={chevrolet}
                    value={{ value: "CHEVROLET", label: "CHEVROLET" }}
                  />
                  <BrandItem
                    icon={citroen}
                    value={{ value: "CITROEN", label: "CITROEN" }}
                  />
                  <BrandItem
                    icon={fiat}
                    value={{ value: "FIAT", label: "FIAT" }}
                  />
                </div>
                <div className={classes.brandRow}>
                  <BrandItem
                    icon={renault}
                    value={{ value: "RENAULT", label: "RENAULT" }}
                  />
                  <BrandItem
                    icon={vw}
                    value={{ value: "VOLKSWAGEN", label: "VOLKSWAGEN" }}
                  />
                  <BrandItem
                    icon={peugeot}
                    value={{ value: "PEUGEOT", label: "PEUGEOT" }}
                  />
                </div>
                <div className={classes.brandRow}>
                  <BrandItem
                    icon={ford}
                    value={{ value: "FORD", label: "FORD" }}
                  />
                  <BrandItem
                    icon={toyota}
                    value={{ value: "TOYOTA", label: "TOYOTA" }}
                  />
                  <BrandItem
                    icon={honda}
                    value={{ value: "HONDA", label: "HONDA" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <ListView
                isLoading={isLoading}
                onItemSelected={handleBrandItem}
                list={filter === "" ? marcas : filterItems(marcas, filter)}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MarcaVehiculoSelector;
