import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PolizaDetail from "~components/bussines/polizaDetail/PolizaDetail";
import ContentSection from "~components/styled/contentSection/ContentSection";
import {
  clearContratarCurrentPoliza,
  setContratarCurrentPoliza,
} from "../actions";

const useReduxSelector = () =>
  useSelector((state) => {
    const cotizadorReducer = state.segurosReducers.cotizadorReducer;
    return {
      currentPoliza: cotizadorReducer.currentPoliza.value,
    };
  });

const DetallePolizaStep = () => {
  const dispatch = useDispatch();
  const { currentPoliza } = useReduxSelector();

  useEffect(() => {
    dispatch(clearContratarCurrentPoliza());
  }, [dispatch]);

  const handleContratar = () => {
    dispatch(
      setContratarCurrentPoliza({
        value: currentPoliza,
        open: true,
        contratada: true,
      })
    );
  };

  if (currentPoliza)
    return (
      <ContentSection>
        <PolizaDetail poliza={currentPoliza} onContratar={handleContratar} />
      </ContentSection>
    );

  return null;
};

export default DetallePolizaStep;
