import React from "react";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

import { useDispatch, useSelector } from "react-redux";
import { clear } from "../actions";

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import pxToRem from "~libs/pxToRem";
import CurrencyText from "~components/styled/texts/CurrencyText";
import ContentLoader from "react-content-loader";

const useStyles = makeStyles(theme => ({
  caption: {
    fontSize: pxToRem(14),
    color: "#9e9e9e",
    fontWeight: 600,
    textAlign: "center"
  },
  cuotas: {
    display: "block",
    fontSize: pxToRem(18),
    color: "#555555",
    lineHeight: 1.33,
    fontWeight: "bold",
    textAlign: "center"
  },
  modelo: {
    display: "block",
    fontSize: pxToRem(18),
    color: "#4b4b4b",
    lineHeight: 1.33,
    fontWeight: 800,
    textAlign: "center",
    marginBottom: 18
  },
  capital: {
    display: "block",
    fontSize: pxToRem(40),
    color: "#424242",
    lineHeight: 1.33,
    fontWeight: 800,
    textAlign: "center",
    "&::first-letter": {
      fontSize: pxToRem(30)
    }
  },
  enCoutas: {
    display: "block",
    fontSize: pxToRem(18),
    color: "#4b4b4b",
    lineHeight: 1.33,
    fontWeight: 800,
    textAlign: "center",
    marginBottom: 18
  },
  cuotaPromedio: {
    display: "block",
    fontSize: pxToRem(40),
    color: "#424242",
    lineHeight: 1.33,
    fontWeight: 800,
    textAlign: "center",
    marginBottom: 18,
    "&::first-letter": {
      fontSize: pxToRem(30)
    }
  },
  condiciones: {
    fontSize: pxToRem(12),
    color: "#808080"
  }
}));

const useReduxSelector = () =>
  useSelector(state => {
    const calcularCreditoReducer =
      state.creditosReducers.calcularCreditoReducer;

    return {
      tipoCreditoSelected: calcularCreditoReducer.tipoCreditoSelected,
      vehiculoAnioSelected: calcularCreditoReducer.vehiculoAnioSelected,
      importeSelected: calcularCreditoReducer.importeSelected,
      plazoSelected: calcularCreditoReducer.plazoSelected,
      isLoading: calcularCreditoReducer.isLoading,
      credito: calcularCreditoReducer.credito
    };
  });

const CreditoDetalle = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    isLoading,
    vehiculoAnioSelected,
    plazoSelected,
    credito
  } = useReduxSelector();

  const {
    plazo,
    capital,
    cuotaPromedio,
    ultimaCuota,
    primeraCuota,
    tna
  } = credito;

  const cuotas = isLoading ? plazoSelected : plazo;

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#fff",
        padding: pxToRem(16),
        width: "100%",
        alignItems: "center",
        flexDirection: "column"
      }}
    >
      <ContentSectionWithActions
        actions={
          <ContentSection>
            <CallToActionButton
              label={"Calcular otro"}
              onClick={() => {
                dispatch(clear());
              }}
            />
          </ContentSection>
        }
      >
        <Typography variant="caption" className={classes.caption}>
          Vehículo
        </Typography>
        <Typography variant="caption" className={classes.modelo}>
          Modelo {vehiculoAnioSelected}
        </Typography>
        <Typography variant="caption" className={classes.caption}>
          Capital financiado
        </Typography>
        {!isLoading && (
          <Typography variant="caption" className={classes.capital}>
            <CurrencyText value={capital} />
          </Typography>
        )}
        {isLoading && <BigTextLoading />}
        <Typography variant="caption" className={classes.enCoutas}>
          en {cuotas} cuotas
        </Typography>
        <Typography variant="caption" className={classes.caption}>
          Cuota promedio
        </Typography>
        {!isLoading && (
          <Typography variant="caption" className={classes.cuotaPromedio}>
            <CurrencyText value={cuotaPromedio} />
          </Typography>
        )}
        {isLoading && <BigTextLoading marginBottom={18} />}
        <div style={{ display: "flex", marginBottom: 18 }}>
          <div style={{ flex: "1", textAlign: "center" }}>
            <Typography variant="caption" className={classes.caption}>
              Primera cuota
            </Typography>
            {!isLoading && (
              <Typography variant="caption" className={classes.cuotas}>
                <CurrencyText value={primeraCuota} />
              </Typography>
            )}
            {isLoading && <SmallTextLoading />}
          </div>
          <Divider
            orientation="vertical"
            style={{ height: 40, marginTop: 5 }}
          />
          <div style={{ flex: "1", textAlign: "center" }}>
            <Typography variant="caption" className={classes.caption}>
              Última cuota
            </Typography>
            {!isLoading && (
              <Typography variant="caption" className={classes.cuotas}>
                <CurrencyText value={ultimaCuota} />
              </Typography>
            )}
            {isLoading && <SmallTextLoading />}
          </div>
        </div>
        <Typography variant="caption" className={classes.condiciones}>
          Los datos expuestos son estimativos y no tiene carácter contractual,
          pueden variar en función de la calificación del titular. Monto y
          aprobación del préstamo sujeto a análisis crediticio al momento de
          solicitarlo. El monto de cuota no incluye el valor del seguro
          automotor ni gastos de otorgamiento del préstamo. TNA{" "}
          {tna ? tna : "-"}%
        </Typography>
      </ContentSectionWithActions>
    </div>
  );
};

export default CreditoDetalle;

const BigTextLoading = ({ marginBottom }) => (
  <div
    style={{
      height: 53,
      width: 172,
      margin: "0 auto",
      marginBottom
    }}
  >
    <ContentLoader
      height={50}
      width={172}
      speed={1}
      primaryColor="#424242"
      secondaryColor="#cdcdcd"
    >
      <rect x="0" y="0" rx="3" ry="3" width="172" height={pxToRem(40)} />
    </ContentLoader>
  </div>
);

const SmallTextLoading = ({ marginBottom }) => (
  <div
    style={{
      height: 23,
      width: 43,
      margin: "0 auto",
      marginBottom
    }}
  >
    <ContentLoader
      height={24}
      width={43}
      speed={1}
      primaryColor="#424242"
      secondaryColor="#cdcdcd"
    >
      <rect x="0" y="0" rx="3" ry="3" width="43" height={pxToRem(18)} />
    </ContentLoader>
  </div>
);
