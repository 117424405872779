import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearIdentidades } from "../../actions";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import Typography from "@material-ui/core/Typography";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import makeStyles from "@material-ui/styles/makeStyles";
import pxToRem from "~libs/pxToRem";
import NoAptoIcon from "~assets/ilustrations/illus_solic_no-apto.svg";

const useStyles = makeStyles(theme => ({
  img: {
    width: pxToRem(242.5),
    align: "center"
  },
  text: {
    fontSize: pxToRem(16),
    lineHeight: 1.38,
    textAlign: "center",
    color: "#616161"
  },
  content: {
    marginTop: 60,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

const useReduxSelector = () =>
  useSelector(state => {
    const nuevaSolicitudReducer = state.creditosReducers.nuevaSolicitudReducer;
    return {
      identidadPersonas: nuevaSolicitudReducer.identidadPersonas
    };
  });
const NoAptoForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { identidadPersonas } = useReduxSelector();
  const classes = useStyles();
  const cliente =
    identidadPersonas && identidadPersonas.titular
      ? `${identidadPersonas.titular.nombres} ${identidadPersonas.titular.apellido}`
      : "el cliente";
  const conyuge =
    identidadPersonas && identidadPersonas.conyuge
      ? `${identidadPersonas.conyuge.nombres} ${identidadPersonas.conyuge.apellido}`
      : null;

  const handleContinue = () => {
    //ToDo: ver si hay una manera mejor de hacer esto (sin el redirect seria ideal)
    history.push("/creditos/nuevasolicitud/2");
    dispatch(clearIdentidades());
  };
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            label={"Hacer otra consulta"}
            onClick={handleContinue}
          />
        </ContentSection>
      }
    >
      <div className={classes.content}>
        <img src={NoAptoIcon} className={classes.img} />
        <PrimaryTitle label={"No Apto"} marginBottom={20} />
        <Typography className={classes.text}>
          {cliente}
          {conyuge ? ` y ${conyuge}` : ""} no se ajusta{conyuge ? "n" : ""} a
          nuestra política crediticia, no podemos ofrecerle un crédito.
        </Typography>
      </div>
    </ContentSectionWithActions>
  );
};

export default NoAptoForm;
