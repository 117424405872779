import React, { forwardRef } from "react";
import pxToRem from "~libs/pxToRem";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  base: props => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(16),
    color: theme.palette.text.hint,
    lineHeight: 1.5,
    fontWeight: 400,
    marginBottom: props.marginBottom
  }),
  purple: {
    color: `${theme.palette.primary.light} !important`,
    opacity: 0.79,
    fontWeight: `bold !important`,
    lineHeight: `2.13 !important`
  }
}));

const LinkButton = forwardRef(
  ({ children, onClick, variant, marginBottom, ...otherProps }, ref) => {
    const classes = useStyles({ marginBottom });

    const variantClass = classes[variant];
    const fullClasses =
      variantClass && variantClass !== classes.base
        ? [classes.base, variantClass].join(" ")
        : classes.base;

    return (
      <Link
        {...otherProps}
        ref={ref}
        className={fullClasses}
        component="button"
        underline="always"
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }
);

export default LinkButton;
