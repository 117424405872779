import { createActions, handleActions } from "redux-actions";

const {
  business: {
    modeloVehiculo: {
      fetchModelosVehiculosInited,
      fetchModelosVehiculosCompleted,
      fetchModelosVehiculosFailed
    }
  }
} = createActions({
  business: {
    modeloVehiculo: {
      fetchModelosVehiculosInited: () => ({}),
      fetchModelosVehiculosCompleted: response => ({ response }),
      fetchModelosVehiculosFailed: error => ({ error })
    }
  }
});

const fetchModelosVehiculos = (marca, anio) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchModelosVehiculosInited());
      const response = await services.api.getInfoautoModelos(marca, anio);
      dispatch(fetchModelosVehiculosCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(fetchModelosVehiculosFailed(error));
    }
  };
};

const initialState = {
  modelos: [],
  isLoading: false
};
const modeloVehiculoSelectorReducer = handleActions(
  {
    [fetchModelosVehiculosInited]: () => {
      return {
        ...initialState,
        isLoading: true
      };
    },
    [fetchModelosVehiculosCompleted]: (state, action) => {
      const modelos = action.payload.response.items.map(x => ({
        value: x.codia,
        label: x.modelo
      }));
      return {
        ...initialState,
        modelos: modelos,
        isLoading: false
      };
    },
    [fetchModelosVehiculosFailed]: () => {
      return {
        ...initialState,
        isLoading: false
      };
    }
  },
  initialState
);

export default modeloVehiculoSelectorReducer;
export {
  fetchModelosVehiculos,
  fetchModelosVehiculosInited,
  fetchModelosVehiculosCompleted,
  fetchModelosVehiculosFailed
};
