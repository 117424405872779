import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pxToRem from "~libs/pxToRem";
import Typography from "@material-ui/core/Typography";

import { getCuotasCredito } from "./actions";
import { useParams } from "react-router-dom";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { getCuponesCredito, getComprobantesPago } from "./actions";

import CuotaPagada from "./cuotas/CuotaPagada";
import CuotaPagadaVencida from "./cuotas/CuotaPagadaVencida";
import CuotaVencida from "./cuotas/CuotaVencida";
import CuotaProximaAVencer from "./cuotas/CuotaProximaAVencer";
import CuotaLoading from "./cuotas/CuotaLoading";

const useReduxSelector = () =>
  useSelector(state => {
    const listadoCuotasReducer =
      state.creditosReducers.listadoCuotasCreditoReducer;
    const detalleCreditoReducer =
      state.creditosReducers.listadoCuotasCreditoReducer;
    return {
      cuotas: listadoCuotasReducer.cuotas,
      cuotasIds: detalleCreditoReducer.cuotasIds,
      isLoading:
        listadoCuotasReducer.isLoading || detalleCreditoReducer.isLoading
    };
  });

const ListadoCuotas = ({ disableDownloads }) => {
  const dispatch = useDispatch();
  const { cuotas, cuotasIds, isLoading } = useReduxSelector();
  let { operacionId } = useParams();
  useEffect(() => {
    dispatch(getCuotasCredito(operacionId));
  }, [operacionId]);

  const cuotasArr = cuotasIds.map(id => cuotas[id]);
  const cuotasVencidas = cuotasArr.filter(
    cuota => cuota.situacion === "Vencida" && cuota.tieneCuponPago
  );
  const cuotasProximasAVencer = cuotasArr
    .filter(cuota => cuota.situacion === "No_Vencida" && cuota.tieneCuponPago)
    .slice(0, 2);
  const cuotasPagas = cuotasArr.filter(cuota =>
    [
      "Pagada",
      "Pagada_Vencida",
      "Pagada_Parcial",
      "Pagada_Parcial_Vencida",
      "Pagada_Irregular",
      "Pagada_Irregular_Vencida",
      "Pagada_con_Exedente",
      "Pagada_Vencida_Con_Exedente",
      "Descontada"
    ].includes(cuota.situacion)
  );

  const downloadingPagas =
    cuotasPagas.filter(cuota => cuota.downloading === true).length > 1;
  const downloadingVencidas = cuotasVencidas.some(
    cuota => cuota.downloading === true
  );
  const todasTienenCupon = cuotasVencidas.every(c => c.tieneCuponPago);
  const todasTienenComprobante = cuotasPagas.every(c => c.tieneComprobantePago);

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 28 }}>
      {!isLoading && (
        <>
          {cuotasVencidas.length > 0 && (
            <div style={{ marginBottom: 4 }}>
              <CuotasTitle label="CUOTAS VENCIDAS" />
              {cuotasVencidas.map((cuota, index) => (
                <CuotaVencida
                  key={`CuotaVencida-${index}`}
                  operacionId={operacionId}
                  cuota={cuota}
                  disableDownloads={disableDownloads}
                />
              ))}
              {todasTienenCupon && (
                <CallToActionButton
                  variant="outlined"
                  color="secondary"
                  marginBottom={24}
                  disabled={downloadingVencidas || disableDownloads}
                  label={
                    downloadingVencidas ? (
                      <CircularProgress
                        size={18}
                        thickness={4}
                        style={{
                          alignSelf: "center",
                          marginRight: 5,
                          color: "#bdbdbd"
                        }}
                      />
                    ) : (
                      "Ver cupones de cuotas vencidas"
                    )
                  }
                  onClick={() => {
                    dispatch(
                      getCuponesCredito(
                        operacionId,
                        cuotasVencidas.map(cuota => {
                          if (cuota.tieneCuponPago) return cuota.id;
                        })
                      )
                    );
                  }}
                />
              )}
            </div>
          )}
          {cuotasProximasAVencer.length > 0 && (
            <div style={{ marginBottom: 28 }}>
              <CuotasTitle label="PRÓXIMOS VENCIMIENTOS" />
              {cuotasProximasAVencer.map((cuota, index) => (
                <CuotaProximaAVencer
                  key={`CuotaProximaAVencer-${index}`}
                  operacionId={operacionId}
                  cuota={cuota}
                  disableDownloads={disableDownloads}
                />
              ))}
            </div>
          )}
          {cuotasPagas.length > 0 && (
            <>
              <CuotasTitle label="CUOTAS PAGAS" />
              {cuotasPagas.map(
                (cuota, index) =>
                  ((cuota.situacion === "Pagada_Vencida" ||
                    cuota.situacion === "Pagada_Parcial_Vencida" ||
                    cuota.situacion === "Pagada_Irregular_Vencida" ||
                    cuota.situacion === "Pagada_Vencida_Con_Exedente") && (
                    <CuotaPagadaVencida
                      key={`CuotaPagadaVencida-${index}`}
                      operacionId={operacionId}
                      cuota={cuota}
                      disableDownloads={disableDownloads}
                    />
                  )) ||
                  ((cuota.situacion === "Pagada" ||
                    cuota.situacion === "Pagada_Parcial" ||
                    cuota.situacion === "Pagada_Irregular" ||
                    cuota.situacion === "Pagada_con_Exedente" ||
                    cuota.situacion === "Descontada") && (
                    <CuotaPagada
                      key={`CuotaPagada-${index}`}
                      operacionId={operacionId}
                      cuota={cuota}
                      disableDownloads={disableDownloads}
                    />
                  ))
              )}

              {todasTienenComprobante && (
                <CallToActionButton
                  variant="outlined"
                  color="secondary"
                  disabled={downloadingPagas || disableDownloads}
                  marginBottom={24}
                  label={
                    downloadingPagas ? (
                      <CircularProgress
                        size={18}
                        thickness={4}
                        style={{
                          alignSelf: "center",
                          marginRight: 5,
                          color: "#bdbdbd"
                        }}
                      />
                    ) : (
                      "Ver todos los comprobantes"
                    )
                  }
                  onClick={() => {
                    dispatch(
                      getComprobantesPago(
                        operacionId,
                        cuotasPagas.map(cuota => {
                          return cuota.id;
                        })
                      )
                    );
                  }}
                />
              )}
            </>
          )}
        </>
      )}
      {isLoading && <LoadingTemplate length={2} />}
    </div>
  );
};

export default ListadoCuotas;

const LoadingTemplate = ({ length }) => {
  return (
    <>
      <CuotasTitle title="CUOTAS VENCIDAS" />
      {Array.apply(null, Array(length)).map((_, index) => (
        <CuotaLoading key={index} />
      ))}

      <CuotasTitle title="PRÓXIMOS VENCIMIENTOS" />
      {Array.apply(null, Array(length)).map((_, index) => (
        <CuotaLoading key={index} />
      ))}

      <CuotasTitle title="CUOTAS PAGAS" />
      {Array.apply(null, Array(length)).map((_, index) => (
        <CuotaLoading key={index} />
      ))}
    </>
  );
};

const CuotasTitle = ({ label }) => {
  return (
    <>
      <Typography
        noWrap
        style={{
          color: "#bdbdbd",
          fontSize: pxToRem(13),
          fontWeight: "bold",
          lineHeight: 1.38,
          letterSpacing: pxToRem(0.65)
        }}
      >
        {label}
      </Typography>
      <Divider
        style={{
          color: "#bdbdbd",
          marginTop: pxToRem(6),
          marginBottom: pxToRem(24)
        }}
      />
    </>
  );
};
