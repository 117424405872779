import React from "react";
import pxToRem from "~libs/pxToRem";
import ContentLoader from "react-content-loader";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

const LoadingViewWithLoader = () => {
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton label={""} disabled />
        </ContentSection>
      }
    >
      <CircleLoading marginBottom={40} />
      <BigTextLoading marginBottom={26} />
      <SmallTextLoading width={250} marginBottom={4} />
      <SmallTextLoading width={200} marginBottom={4} />
    </ContentSectionWithActions>
  );
};

export default LoadingViewWithLoader;

const CircleLoading = ({ marginBottom }) => (
  <div
    style={{
      height: 140,
      width: 160,
      margin: "0 auto",
      marginTop: "25%",
      marginBottom
    }}
  >
    <ContentLoader
      height={140}
      width={160}
      speed={1}
      primaryColor="#B9A7D7"
      secondaryColor="#f7f8ff"
    >
      <circle cx="80" cy="70" r="70" />
    </ContentLoader>
  </div>
);
const BigTextLoading = ({ marginBottom }) => (
  <div
    style={{
      height: 30,
      width: 120,
      margin: "0 auto",
      marginBottom
    }}
  >
    <ContentLoader
      height={30}
      width={120}
      speed={1}
      /* primaryColor="#bdbdbd"
      secondaryColor="#cdcdcd" */
      primaryColor="#B9A7D7"
      secondaryColor="#f7f8ff"
    >
      <rect x="10" y="0" rx="3" ry="3" width="100" height={pxToRem(30)} />
    </ContentLoader>
  </div>
);

const SmallTextLoading = ({ marginBottom, width }) => (
  <div
    style={{
      height: 14,
      width: width + 20,
      margin: "0 auto",
      marginBottom
    }}
  >
    <ContentLoader
      height={14}
      width={width + 20}
      speed={1}
      primaryColor="#B9A7D7"
      secondaryColor="#f7f8ff"
    >
      <rect x="10" y="0" rx="3" ry="3" width={width} height={14} />
    </ContentLoader>
  </div>
);
