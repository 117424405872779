import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getMediosDePago } from "~components/app/clienteFinal/creditos/detalleCredito/actions";
import { getComprobanteCreditoAlDia } from "~components/app/clienteFinal/creditos/detalleCredito/actions";

import pxToRem from "~libs/pxToRem";
import makeStyles from "@material-ui/styles/makeStyles";
import { emphasize } from "material-ui/utils/colorManipulator";

import ListadoCuotas from "./listadoCuotasCredito/ListadoCuotas";

import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import Modal from "~components/styled/modal/Modal";
import Typography from "@material-ui/core/Typography";
import LinkButton from "~components/styled/linkButton/LinkButton";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import WarningSection from "~components/styled/warningSection/WarningSection";

import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CreditoAlDiaIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import MediosDePagoIcon from "@material-ui/icons/CreditCard";
import CircularProgress from "@material-ui/core/CircularProgress";

const useReduxSelector = () =>
  useSelector((state) => {
    const detalleCreditoReducer = state.creditosReducers.detalleCreditoReducer;
    const cuotasReducer = state.creditosReducers.listadoCuotasCreditoReducer;
    return {
      credito: detalleCreditoReducer.credito,
      isLoading: detalleCreditoReducer.isLoading,
      creditoAlDiaDownloading: detalleCreditoReducer.creditoAlDiaDownloading,
      mediosPagoDownloading: detalleCreditoReducer.mediosPagoDownloading,
      cuotas: cuotasReducer.cuotas,
      cuotasIds: cuotasReducer.cuotasIds,
    };
  });

const CreditoCuotasPendientes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [opened, setOpened] = useState(false);
  const {
    isLoading,
    credito,
    creditoAlDiaDownloading,
    mediosPagoDownloading,
    cuotas,
    cuotasIds,
  } = useReduxSelector();

  const cuotasDownloading =
    cuotasIds && cuotasIds.some((id) => cuotas[id].downloading === true);

  const disableDownloads =
    creditoAlDiaDownloading || mediosPagoDownloading || cuotasDownloading;
  return (
    <>
      <MasInfoModal opened={opened} setOpened={setOpened} />
      {!isLoading && credito && (
        <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 28 }}>
          {credito.cuotasEnMora === 1 && credito.diasDeMora > 0 && (
            <>
              <WarningSection
                variant="warning"
                inlineLink="Más info"
                onClick={() => setOpened(true)}
                marginBottom={12}
              >
                Tenés {credito.diasDeMora} días de mora. Recordá que si pagás la
                cuota después de la fecha de vencimiento generás intereses.
              </WarningSection>
            </>
          )}
          {credito.cuotasEnMora > 1 && credito.interesesPorMora > 0 && (
            <WarningSection
              variant="warning"
              inlineLink="Más info"
              onClick={() => setOpened(true)}
              marginBottom={12}
            >
              Tenés intereses por mora. Se generaron ${credito.interesesPorMora}{" "}
              de intereses por pago fuera de término.
            </WarningSection>
          )}

          {credito.saldoAFavor > 0 && (
            <WarningSection
              variant="success"
              icon={<InfoOutlinedIcon />}
              marginBottom={12}
            >
              Tenés un importe a favor de ${credito.saldoAFavor}, disponible
              para el pago de cuotas.
            </WarningSection>
          )}
          <CallToActionButton
            color="secondaryemphasis"
            label="Informar pago"
            marginBottom={12}
            onClick={() =>
              history.push(
                `/miscreditos/informarpago?operacionId=${credito.credito.operacionId}`
              )
            }
          />

          <ButtonGroup color="primary" fullWidth style={{ minHeight: 52 }}>
            <Button
              onClick={() => {
                dispatch(getMediosDePago(credito.credito.empresa));
              }}
              disabled={disableDownloads}
              className={classes.outlinedsecondary}
              startIcon={
                mediosPagoDownloading ? (
                  <CircularProgress
                    size={18}
                    thickness={4}
                    style={{
                      alignSelf: "center",
                      marginRight: 5,
                      color: "#bdbdbd",
                    }}
                  />
                ) : (
                  <MediosDePagoIcon />
                )
              }
            >
              {!mediosPagoDownloading && "Medios de pago"}
            </Button>
            <Button
              className={classes.outlinedsecondary}
              startIcon={
                creditoAlDiaDownloading ? (
                  <CircularProgress
                    size={18}
                    thickness={4}
                    style={{
                      alignSelf: "center",
                      marginRight: 5,
                      color: "#bdbdbd",
                    }}
                  />
                ) : (
                  <CreditoAlDiaIcon />
                )
              }
              disabled={credito.cuotasEnMora !== 0 || disableDownloads}
              onClick={() => {
                dispatch(
                  getComprobanteCreditoAlDia(credito.credito.operacionId)
                );
              }}
            >
              {!creditoAlDiaDownloading && "Crédito al día"}
            </Button>
          </ButtonGroup>
        </div>
      )}
      {isLoading && LoadingTemplate(classes)}
      <ListadoCuotas disableDownloads={disableDownloads} />
    </>
  );
};
export default CreditoCuotasPendientes;

const MasInfoModal = ({ opened, setOpened }) => {
  const classes = useStyles();
  return (
    <Modal
      opened={opened}
      onClose={() => {
        setOpened(false);
      }}
      title="Más información"
      dialogActions={
        <LinkButton
          variant="purple"
          onClick={() => {
            setOpened(false);
          }}
        >
          Volver
        </LinkButton>
      }
    >
      <Typography
        style={{
          color: "#424242",
          fontSize: pxToRem(20),
          fontWeight: "bold",
          lineHeight: 1.7,
          textAlign: "left",
          paddingBottom: 18,
        }}
      >
        Intereses por mora
      </Typography>
      <Typography className={classes.modalText}>
        Los intereses generados por pagos fuera de término se originan desde el
        vencimiento de tu cuota hasta la fecha efectiva del pago de la misma.
      </Typography>
      <Typography className={classes.modalText}>
        Estos son fijos, no varían ni sufren incrementos o intereses
        adicionales.
      </Typography>
      <Typography className={classes.modalText}>
        Solo podrán ser abonados cuando registres pagas todas las cuotas de tu
        crédito.
      </Typography>
    </Modal>
  );
};

const LoadingTemplate = (classes) => (
  <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 28 }}>
    <CallToActionButton
      color="secondaryemphasis"
      label="Informar pago"
      marginBottom={12}
      disabled
    />
    <ButtonGroup color="primary" fullWidth style={{ minHeight: 52 }}>
      <Button
        disabled
        className={classes.outlinedsecondary}
        startIcon={<MediosDePagoIcon />}
      >
        Medios de pago
      </Button>
      <Button
        className={classes.outlinedsecondary}
        startIcon={<CreditoAlDiaIcon />}
        disabled
      >
        Crédito al día
      </Button>
    </ButtonGroup>
  </div>
);

const useStyles = makeStyles((theme) => ({
  modalText: {
    fontSize: pxToRem(16),
    lineHeight: 1.38,
    textAlign: "left",
    color: "#757575",
    paddingBottom: 18,
  },
  outlinedsecondary: {
    fontSize: pxToRem(14),
    fontWeight: "bold",
    lineHeight: "1.25",
    textTransform: "none",
    paddingLeft: pxToRem(6),
    paddingRight: pxToRem(4),
    borderWidth: pxToRem(2),
    borderRadius: pxToRem(8),
    borderColor: "#7644c9",
    color: "#7644c9",
    "&:hover": {
      backgroundColor: emphasize(theme.palette.primary.dark, 0.5),
      borderColor: emphasize(theme.palette.primary.dark, 0.5),
      color: "#ffffff",
    },
    "&:disabled": {
      color: "#bdbdbd",
      borderColor: "#bdbdbd",
      borderWidth: pxToRem(2),
    },
  },
}));
