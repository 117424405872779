import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import EmptyState from "~assets/ilustrations/empty-state_solicitud.svg";
import { tipoPolizaFiltros } from "~constants/tipoPoliza";
import RangoMontoSelector from "~components/bussines/rangoMontoSelector/RangoMontoSelector";
import Selector from "~components/styled/selector/Selector";
import PolizaCard from "~components/bussines/polizaCard/polizaCard";
import { TODAS_COBERTURAS } from "~constants/tipoPoliza";
import pxToRem from "~libs/pxToRem";
import { useSelector, useDispatch } from "react-redux";
import {
  setFiltroTipoCobertura,
  setMinPrice,
  setMaxPrice,
  setFiltroMinPrice,
  setFiltroMaxPrice,
  setIsFiltering,
} from "./actions";

const useStyles = makeStyles((theme) => ({
  emptyContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${pxToRem(45)} ${pxToRem(16)}`,
  },
  imageContainer: {
    minWidth: pxToRem(156),
    minHeight: pxToRem(110),
    paddingBottom: pxToRem(20),
  },
  text: {
    width: pxToRem(248),
    fontSize: pxToRem(16),
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.38",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#757575",
  },
}));

const COMPLETA = "completa";

const useReduxSelector = () =>
  useSelector((state) => {
    const polizaSelectorReducer = state.polizaSelectorReducer;

    return {
      isFiltering: polizaSelectorReducer.isFiltering,
      filtroTipoCobertura: polizaSelectorReducer.filtroTipoCobertura,
      minPrice: polizaSelectorReducer.minPrice,
      maxPrice: polizaSelectorReducer.maxPrice,
      filtroMinPrice: polizaSelectorReducer.filtroMinPrice,
      filtroMaxPrice: polizaSelectorReducer.filtroMaxPrice,
    };
  });

const PolizaSelector = ({ isPending, polizasCompleted, onOpen }) => {
  const dispatch = useDispatch();
  const {
    isFiltering,
    filtroTipoCobertura,
    minPrice,
    maxPrice,
    filtroMinPrice,
    filtroMaxPrice,
  } = useReduxSelector();

  const [
    polizasTipoCoberturaFiltered,
    setPolizasTipoCoberturaFiltered,
  ] = useState(
    getPolizasTipoCoberturaFiltered(polizasCompleted, filtroTipoCobertura)
  );
  const [polizasFiltered, setPolizasFiltered] = useState(
    getPolizasTipoCoberturaFiltered(polizasCompleted, filtroTipoCobertura)
  );

  useEffect(() => {
    //changes filter by type
    const result = getPolizasTipoCoberturaFiltered(
      polizasCompleted,
      filtroTipoCobertura
    );
    setPolizasTipoCoberturaFiltered(result);
    setPolizasFiltered(result);

    const min = getMinImporte(result);
    const max = getMaxImporte(result);
    if (min && max) {
      dispatch(setMinPrice(min));
      dispatch(setMaxPrice(max));
      dispatch(setFiltroMinPrice(min));
      dispatch(setFiltroMaxPrice(max));
    }
    //eslint-disable-next-line
  }, [isPending, filtroTipoCobertura]);

  useEffect(() => {
    //show new cards when arriving new polling responses
    const result = getPolizasTipoCoberturaFiltered(
      polizasCompleted,
      filtroTipoCobertura
    );
    setPolizasTipoCoberturaFiltered(result);
    setPolizasFiltered(result);
    //eslint-disable-next-line
  }, [polizasCompleted]);

  useEffect(() => {
    if (filtroMinPrice && filtroMaxPrice) {
      const result = getPolizasPriceFiltered(
        polizasTipoCoberturaFiltered,
        filtroMinPrice,
        filtroMaxPrice
      );
      setPolizasFiltered(result);
    }
    //eslint-disable-next-line
  }, [polizasTipoCoberturaFiltered, filtroMinPrice, filtroMaxPrice]);

  useEffect(() => {
    if (isFiltering) dispatch(setIsFiltering(false));
  }, [polizasFiltered]);

  const countComplete = getComplete(polizasCompleted).length;
  const hasNotResults = !isPending && countComplete === 0;

  const classes = useStyles();
  return (
    <>
      <Typography
        variant="overline"
        display="block"
        style={{ color: "#a7a7a7" }}
      >
        RANGO DE PRECIOS
      </Typography>
      <RangoMontoSelector
        disabled={hasNotResults}
        onFilter={() => {
          if (!isFiltering) dispatch(setIsFiltering(true));
        }}
        isLoading={isPending}
        minValue={minPrice}
        maxValue={maxPrice}
        filtroMin={filtroMinPrice}
        filtroMax={filtroMaxPrice}
        step={10}
        marginBottom={8}
        onChange={(min, max) => {
          dispatch(setIsFiltering(false));

          if (min && max) {
            dispatch(setFiltroMinPrice(Math.trunc(min)));
            dispatch(setFiltroMaxPrice(Math.trunc(max)));
          }
        }}
      />
      <Typography
        variant="overline"
        display="block"
        style={{ color: "#a7a7a7" }}
      >
        TIPO DE COBERTURAS
      </Typography>
      <Selector
        disabled={isPending}
        value={filtroTipoCobertura}
        onFilter={() => dispatch(setIsFiltering(true))}
        onChange={(value) => dispatch(setFiltroTipoCobertura(value))}
        options={tipoPolizaFiltros}
      />
      {hasNotResults && (
        <div className={classes.emptyContainer}>
          <div className={classes.imageContainer}>
            <img className={classes.emptyImage} src={EmptyState} alt="" />
          </div>
          <Typography className={classes.text}>
            No encontramos ninguna póliza con esas características.
          </Typography>
        </div>
      )}
      {!hasNotResults && (
        <PolizaList
          key={"poliza-list"}
          isPending={isPending}
          isFiltering={isFiltering}
          polizas={polizasFiltered}
          onPolizaSelected={onOpen}
        />
      )}
    </>
  );
};
export default PolizaSelector;

const PolizaList = ({ isPending, isFiltering, polizas, onPolizaSelected }) => {
  return (
    <>
      {!isFiltering &&
        polizas.map((compania) =>
          compania.cotizaciones.map((poliza) => (
            <div
              key={`div-${compania.companiaId}-${poliza.cotizacionPlanId}`}
              style={{ paddingTop: 16 }}
            >
              <PolizaCard
                key={`${compania.companiaId}-${poliza.cotizacionPlanId}`}
                poliza={poliza}
                compania={compania}
                onClick={onPolizaSelected}
                marginBottom={16}
              />
            </div>
          ))
        )}
      {(isPending || isFiltering) && (
        <>
          <div key="div-loading-poliza-card-1" style={{ paddingTop: 16 }}>
            <PolizaCard key="loading-poliza-card-1" isLoading />
          </div>
          <div key="div-loading-poliza-card-2" style={{ paddingTop: 16 }}>
            <PolizaCard key="loading-poliza-card-2" isLoading />
          </div>
          <div key="div-loading-poliza-card-3" style={{ paddingTop: 16 }}>
            <PolizaCard key="loading-poliza-card-3" isLoading />
          </div>
        </>
      )}
    </>
  );
};

//USEFUL AND AUXILIAR FUNCTIONS RELATED TO POLIZAS
const getComplete = (list) => {
  return list.filter((poliza) => {
    return poliza.estado === COMPLETA;
  });
};

const getPolizasTipoCoberturaFiltered = (polizas, tipoCobertura) => {
  const filteredByTipo = polizas.map((compania) => ({
    ...compania,
    cotizaciones: compania.cotizaciones
      .filter(
        (poliza) =>
          poliza.tipoPlanComercial === tipoCobertura ||
          tipoCobertura === TODAS_COBERTURAS
      )
      .sort(
        (poliza, polizaNext) =>
          parseFloat(poliza.importe) - parseFloat(polizaNext.importe)
      ),
  }));
  return filteredByTipo;
};

const getPolizasPriceFiltered = (polizas, minPrice, maxPrice) => {
  const filteredByPrice = polizas.map((compania) => ({
    ...compania,
    cotizaciones: compania.cotizaciones
      .filter(
        (poliza) =>
          (!minPrice || poliza.importe >= minPrice) &&
          (!maxPrice || poliza.importe <= maxPrice + 1)
      )
      .sort(
        (poliza, polizaNext) =>
          parseFloat(poliza.importe) - parseFloat(polizaNext.importe)
      ),
  }));

  return filteredByPrice;
};

const getMinImporte = (polizas) => {
  const cotizaciones = polizas
    .map((compania) => compania.cotizaciones)
    .flat(1)
    .map((cotizacion) => Math.trunc(cotizacion.importe));

  const min = cotizaciones.length > 0 ? Math.min(...cotizaciones) : null;
  return Math.trunc(min);
};

const getMaxImporte = (polizas) => {
  const cotizaciones = polizas
    .map((compania) => compania.cotizaciones)
    .flat(1)
    .map((cotizacion) => Math.trunc(cotizacion.importe));

  const max = cotizaciones.length > 0 ? Math.max(...cotizaciones) : null;
  return Math.trunc(max);
};
