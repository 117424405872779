const TODAS_COBERTURAS = "TodasCoberturas";
const RC = "RC";
const TC = "TCBase";
const TP = "TCPremium";
const TR = "TR";

const tipoPoliza = {
  //[TC]: "TERCEROS COMPLETO",
  [TC]: "RESPONSIBILIDAD CIVIL",
  [TC]: "INCENDIO TOTAL Y ROBO",
  [TP]: "TERCEROS PREMIUM",
  [TR]: "TODO RIESGO",
};

const tipoPolizaFiltros = {
  [TODAS_COBERTURAS]: "Todas las coberturas",
  [RC]: "Resposabilidad civil",
  [TC]: "Incendio y robo total",
  [TP]: "Terceros premium",
  [TR]: "Todo riesgo",
};

export { TC, TP, TR, TODAS_COBERTURAS, tipoPoliza, tipoPolizaFiltros };
