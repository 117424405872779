export const CONTACTOCOMERCIAL_ISLOADING = "CONTACTOCOMERCIAL_ISLOADING";
export const NEARESTOFFICE_FETCHRESULT_SUCCESS =
  "NEARESTOFFICE_FETCHRESULT_SUCCESS";

export const contactoComercialIsLoading = loading => ({
  type: CONTACTOCOMERCIAL_ISLOADING,
  payload: {
    isLoading: loading
  }
});

export const nearestOfficeFetchResultSuccess = result => ({
  type: NEARESTOFFICE_FETCHRESULT_SUCCESS,
  payload: {
    ...result
  }
});

export function fetchNearestOffice() {
  return async (dispatch, getState, services) => {
    try {
      dispatch(contactoComercialIsLoading(true));
      const response = await services.api.getContactoOffice();
      dispatch(nearestOfficeFetchResultSuccess(response));
      dispatch(contactoComercialIsLoading(false));
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        fetchNearestOffice,
        [],
        getState()
      );
      dispatch(contactoComercialIsLoading(false));
      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
}
