import { toast } from "react-toastify";
import { css } from "glamor";

const classes = {
  default: css({
    fontSize: 16,
    color: "#2196f3 !important"
  }),
  info: css({
    fontSize: 16,
    background: "#3f88c5 !important",
    color: "#edf4f9 !important"
  }),
  success: css({
    fontSize: 16,
    background: "#0c7c59 !important",
    color: "#e8f3ef !important"
  }),
  error: css({
    fontSize: 16,
    background: "#dc2826 !important",
    color: "#fdebeb !important"
  }),
  warning: css({
    fontSize: 16,
    background: "#fff07c !important",
    color: "#746e39 !important"
  })
};

export const sendMessage = (message, type = "success", props = {}) => {
  toast[type](message, {
    className: classes[type],
    position: toast.POSITION.TOP_RIGHT,
    ...props
  });
};

export const sendError = message => sendMessage(message, "error");
