import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import Button from "@material-ui/core/Button";
import { fade } from "material-ui/utils/colorManipulator";

const useStyles = makeStyles(theme => ({
  button: props => ({
    background: props.backgroundColor,
    borderRadius: 4,
    border: 0,
    color: "white",
    height: 53,
    display: "flex",
    padding: "0 35px 0 0",
    textTransform: "none",
    width: "295px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4"
    },
    "&:focus": {
      background: fade(props.backgroundColor, 0.8)
    },
    "&:hover": {
      background: fade(props.backgroundColor, 0.8)
    }
  }),
  buttonIcon: props => ({
    width: 50,
    height: 53,
    padding: 12,
    borderRadius: "4px 0 0 4px",
    background: props.backgroundColorIcon
  })
}));

const SocialButton = ({
  onClick,
  icon,
  label,
  backgroundColor,
  backgroundColorIcon,
  disabled
}) => {
  const classes = useStyles({ backgroundColor, backgroundColorIcon });

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="contained"
      size="large"
      color="primary"
      fullWidth
      className={classes.button}
    >
      <img src={icon} alt="" className={classes.buttonIcon} />
      <Typography
        variant="caption"
        noWrap
        style={{
          flex: 1,
          fontWeight: "bold",
          lineHeight: "1.38",
          letterSpacing: "0.48px"
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};

export default SocialButton;
