import { createActions, handleActions } from "redux-actions";
import { sendMessageToNative } from "~componentes/nativeAppMessaging/js/actions";
import openFile from "~libs/openFile";
import blobToBase64 from "~libs/blobToBase64";

const {
  seguros: {
    detallePoliza: {
      getDetallePolizaInited,
      getDetallePolizaCompleted,
      getDetallePolizaFailed,
      getTarjetaMercosurInited,
      getTarjetaMercosurCompleted,
      getTarjetaMercosurFailed,
      getPolizaInited,
      getPolizaCompleted,
      getPolizaFailed
    }
  }
} = createActions({
  seguros: {
    detallePoliza: {
      getDetallePolizaInited: newPoliza => ({ newPoliza }),
      getDetallePolizaCompleted: response => ({ response }),
      getDetallePolizaFailed: error => ({ error }),
      getTarjetaMercosurInited: () => ({}),
      getTarjetaMercosurCompleted: () => ({}),
      getTarjetaMercosurFailed: () => ({}),
      getPolizaInited: () => ({}),
      getPolizaCompleted: () => ({}),
      getPolizaFailed: () => ({})
    }
  }
});

const getDetallePoliza = idPoliza => {
  return async (dispatch, getState, services) => {
    try {
      const polizas = getState().segurosReducers.listadoPolizasReducer.polizas;
      const newPoliza = polizas.find(poliza => poliza.idPoliza === idPoliza);
      dispatch(getDetallePolizaInited(newPoliza));
      const response = await services.api.getDetallePoliza(idPoliza);
      dispatch(getDetallePolizaCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getDetallePolizaFailed(error));
    }
  };
};

export function getTarjetaMercosur(idPoliza) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getTarjetaMercosurInited());
      const response = await services.api.getTarjetaMercosur(idPoliza);
      const isOnNativeApp = getState().nativeMessagesReducer.isOnNativeApp;
      if (isOnNativeApp) {
        sendMessageToNative({
          type: "WEB_SHOW_FILE_VIEWER",
          payload: {
            title: "Tarjeta mercosur",
            data: null,
            filename: "tarjeta_mercosur.pdf"
          }
        });

        //ToDo: fetch archivo desde el bucket de seguros
        const blob = await fetch(response.path).then(response =>
          response.blob()
        );
        const fileBase64 = await blobToBase64(blob);
        sendMessageToNative({
          type: "WEB_SHOW_FILE_VIEWER",
          payload: {
            title: "Tarjeta mercosur",
            data: fileBase64,
            filename: "tarjeta_mercosur.pdf"
          }
        });
      } else {
        await openFile(response.path);
      }
      dispatch(getTarjetaMercosurCompleted(response));
    } catch (error) {
      console.error(error);
      services.errorHandler.logRemoteError(
        error,
        getTarjetaMercosur,
        [],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(getTarjetaMercosurFailed(error));
    }
  };
}

export function getPoliza(idPoliza) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getPolizaInited());
      const response = await services.api.getPolizaFile(idPoliza);

      const isOnNativeApp = getState().nativeMessagesReducer.isOnNativeApp;
      if (isOnNativeApp) {
        sendMessageToNative({
          type: "WEB_SHOW_FILE_VIEWER",
          payload: {
            title: "Póliza",
            data: null,
            filename: "poliza.pdf"
          }
        });

        //ToDo: fetch archivo desde el bucket de seguros
        const blob = await fetch(response.path).then(response =>
          response.blob()
        );

        const fileBase64 = await blobToBase64(blob);
        sendMessageToNative({
          type: "WEB_SHOW_FILE_VIEWER",
          payload: {
            title: "Póliza",
            data: fileBase64,
            filename: "poliza.pdf"
          }
        });
      } else {
        await openFile(response.path);
      }

      dispatch(getPolizaCompleted());
    } catch (error) {
      console.error(error);
      services.errorHandler.logRemoteError(error, getPoliza, [], getState());
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(getPolizaFailed(error));
    }
  };
}

const initialState = {
  isLoading: false,
  polizaDownloading: false,
  tarjetaDownloading: false,
  poliza: null
};
const detallePolizaReducer = handleActions(
  {
    [getDetallePolizaInited]: (state, action) => {
      return { ...state, isLoading: true, poliza: action.payload.newPoliza };
    },
    [getDetallePolizaCompleted]: (state, action) => {
      return { ...state, poliza: action.payload.response, isLoading: false };
    },
    [getDetallePolizaFailed]: (state, action) => {
      return { ...state, isLoading: false };
    },
    [getTarjetaMercosurInited]: (state, action) => {
      return { ...state, isLoading: false, tarjetaDownloading: true };
    },
    [getTarjetaMercosurCompleted]: (state, action) => {
      return { ...state, isLoading: false, tarjetaDownloading: false };
    },
    [getTarjetaMercosurFailed]: (state, action) => {
      return { ...state, isLoading: false, tarjetaDownloading: false };
    },
    [getPolizaInited]: (state, action) => {
      return { ...state, isLoading: false, polizaDownloading: true };
    },
    [getPolizaCompleted]: (state, action) => {
      return { ...state, isLoading: false, polizaDownloading: false };
    },
    [getPolizaFailed]: (state, action) => {
      return { ...state, isLoading: false, polizaDownloading: false };
    }
  },
  initialState
);

export default detallePolizaReducer;
export { getDetallePoliza };
