import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "~componentes/Auth0/useAuth";
import { useHistory } from "react-router-dom";
import { NavbarLayoutClienteFinal } from "~components/styled/layouts/navbarLayout/NavbarLayout";
import ContentSection from "~components/styled/contentSection/ContentSection";

import {
  ButtonGroup,
  ButtonGroupLabel,
  Button,
} from "~components/styled/toolboxButtons/ButtonGroup";

import checkIcon from "~assets/icons/ic-check.svg";

import { getCreditosVigentes } from "~components/app/clienteFinal/creditos/homeCreditos/actions";
import { getPolizas } from "~components/app/clienteFinal/seguros/homeSeguros/actions";

import pxToRem from "~libs/pxToRem";
import Typography from "@material-ui/core/Typography";

import CreditoCardButton from "~components/app/clienteFinal/creditos/creditoCardButton/CreditoCardButton";
import LinkButton from "~components/styled/linkButton/LinkButton";
import WarningSection from "~components/styled/warningSection/WarningSection";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import PolizaCardButton from "~components/app/clienteFinal/seguros/PolizaCardButton";
import asegurarIcon from "~assets/icons/ic_asegurar_auto.svg";
import { showNativePopup } from "~componentes/nativeAppMessaging/js/actions";
import { vincularOperaciones } from "~components/app/clienteFinal/shared/vinculacion/actions";

import addIcon from "~assets/icons/ic_nuevo.svg";
import addImage from "~assets/ilustrations/image-nueva-solicitud.svg";
import TengoUnProblema from "~components/bussines/tengoUnProblema/TengoUnProblema";
import IllusWIP from "~assets/ilustrations/illus_work-in-progress.svg";

import ModalVincularCreditoDesktop from "~components/bussines/modalVincularCreditoDesktop/ModalVincularCreditoDesktop";

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading:
      state.creditosReducers.listadoCreditosVigentesReducer.isLoading ||
      state.segurosReducers.listadoPolizasReducer.isLoading,
    creditos: state.creditosReducers.listadoCreditosVigentesReducer.creditos,
    polizas: state.segurosReducers.listadoPolizasReducer.polizas,
    isLoadingVinculacion: state.sharedReducers.vinculacionReducer.isLoading,
    isOnNativeApp: state.nativeMessagesReducer.isOnNativeApp,
    isValidacionIdentidadEnabled:
      state.featureFlagReducer.featureFlags["validacion-identidad"],
  }));

const Inicio = () => {
  const [desktopModalOpened, setDesktopModalOpened] = useState(false);
  const history = useHistory();
  const { authState } = useAuth0();
  const {
    creditos,
    polizas,
    isLoading,
    isLoadingVinculacion,
    isOnNativeApp,
    isValidacionIdentidadEnabled,
  } = useReduxSelector();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPolizas());
  }, []);
  useEffect(() => {
    dispatch(getCreditosVigentes());
  }, []);

  const hasCredito = creditos && creditos.length > 0;
  const hasSeguro = polizas && polizas.length > 0;
  const showListadoCreditos = isLoading || hasCredito;
  const showListadoPolizas = hasSeguro;
  const showEmptyState = !isLoading && !hasSeguro && !hasCredito;
  const validatedIdentity = authState.user.validatedIdentity;

  const handleClick = () => {
    if (!isOnNativeApp && !validatedIdentity) setDesktopModalOpened(true);
    if (isOnNativeApp && !validatedIdentity)
      dispatch(showNativePopup("validaridentidad"));
    if (validatedIdentity) dispatch(vincularOperaciones());
  };
  return (
    <NavbarLayoutClienteFinal>
      <ContentSection>
        <ButtonGroup marginBottom={16}>
          <Button onClick={() => history.push("/miscreditos/informarpago")}>
            <ButtonGroupLabel
              label={"Informar"}
              subLabel={"un pago"}
              icon={checkIcon}
            />
          </Button>
          <Button
            onClick={() => {
              history.push("/seguros");
            }}
          >
            <ButtonGroupLabel
              label={"Asegurar"}
              subLabel={"un auto"}
              icon={asegurarIcon}
            />
          </Button>
        </ButtonGroup>

        {showEmptyState && (
          <>
            {isValidacionIdentidadEnabled && !isLoadingVinculacion && (
              <>
                <ButtonGroup marginBottom={16}>
                  <Button onClick={handleClick}>
                    <div
                      style={{
                        backgroundImage: `url(${addImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "right",
                        width: "100%",
                      }}
                    >
                      <ButtonGroupLabel
                        label={"Vincular"}
                        subLabel={"mi crédito"}
                        icon={addIcon}
                      />
                    </div>
                  </Button>
                </ButtonGroup>
                <ModalVincularCreditoDesktop
                  opened={desktopModalOpened}
                  onClose={() => setDesktopModalOpened(false)}
                />
              </>
            )}
            {!isLoadingVinculacion && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: 24,
                }}
              >
                <Typography
                  style={{
                    fontSize: pxToRem(16),
                    fontWeight: 600,
                    lineHeight: 1.38,
                    textAlign: "center",
                    color: "#757575",
                    marginBottom: 16,
                    maxWidth: "80%",
                  }}
                >
                  Vinculá tu crédito para poder visualizarlo.
                </Typography>
                <Typography
                  style={{
                    fontSize: pxToRem(16),
                    fontWeight: 600,
                    lineHeight: 1.38,
                    textAlign: "center",
                    color: "#757575",
                    marginBottom: 24,
                    maxWidth: "80%",
                  }}
                >
                  En el proceso, te vamos a pedir que valides tu identidad desde
                  tu celular.
                </Typography>
                <TengoUnProblema />
              </div>
            )}
            {isLoadingVinculacion && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={IllusWIP}
                  style={{ maxHeight: 190, marginBottom: 22 }}
                />
                <Typography
                  style={{
                    fontSize: pxToRem(16),
                    fontWeight: 600,
                    lineHeight: 1.38,
                    textAlign: "center",
                    color: "#757575",
                    marginBottom: 24,
                    maxWidth: "80%",
                  }}
                >
                  Estamos validando tus datos. Pronto vas a poder ver tus
                  créditos.
                </Typography>
                <TengoUnProblema />
              </div>
            )}
          </>
        )}
        {showListadoCreditos && (
          <ListadoCreditos
            creditos={creditos}
            isLoading={isLoading}
            marginBottom={16}
          />
        )}
        {showListadoPolizas && (
          <ListadoPolizas polizas={polizas} isLoading={isLoading} />
        )}
      </ContentSection>
    </NavbarLayoutClienteFinal>
  );
};

export default Inicio;

const ListadoCreditos = ({ creditos, isLoading, marginBottom = 0 }) => {
  const limit = 3;
  const history = useHistory();
  const hasItems = creditos && creditos.length > 0;
  const hasMoreItems = creditos && creditos.length > limit;
  const hasCuotasVencidas =
    creditos && creditos.find((credito) => credito.estadoCredito === "EnMora");
  const creditosObj =
    limit === 0 ? creditos : creditos && creditos.slice(0, limit);

  return (
    <div style={{ marginBottom: marginBottom, marginTop: 14 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: 14,
        }}
      >
        {hasItems && (
          <Typography
            variant="caption"
            noWrap
            style={{
              fontSize: pxToRem(22),
              fontWeight: "bold",
              lineHeight: 1.55,
              color: "#663ea8",
            }}
          >
            {`Mis créditos`}
          </Typography>
        )}

        {!isLoading && hasMoreItems && (
          <LinkButton
            onClick={() => {
              history.push("/miscreditos");
            }}
            variant="purple"
          >
            ver todos
          </LinkButton>
        )}
      </div>
      {isLoading && <LoadingTemplate length={3} />}
      {!isLoading && hasCuotasVencidas && (
        <WarningSection
          variant="warning"
          icon={<ReportProblemOutlinedIcon />}
          rightLink="más info"
          onClick={() => {
            history.push(`/miscreditos/${hasCuotasVencidas.operacionId}`);
          }}
          marginBottom={16}
        >
          Tenés cuotas vencidas
        </WarningSection>
      )}
      {!isLoading &&
        hasItems &&
        creditosObj.map((credito, index) => (
          <CreditoCardButton
            key={`solicitud-${credito.operacionId}`}
            credito={credito}
            marginBottom={12}
            onClick={() => {
              history.push(`/miscreditos/${credito.operacionId}`);
            }}
          />
        ))}
    </div>
  );
};

const ListadoPolizas = ({ polizas, isLoading }) => {
  const limit = 3;
  const history = useHistory();
  const hasItems = polizas && polizas.length > 0;
  const hasMoreItems = polizas && polizas.length > limit;
  const hasPolizaSuspendida =
    polizas && polizas.find((poliza) => poliza.suspendida);
  const polizasObj = limit === 0 ? polizas : polizas && polizas.slice(0, limit);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: 14,
          marginTop: 14,
        }}
      >
        {hasItems && (
          <Typography
            variant="caption"
            noWrap
            style={{
              fontSize: pxToRem(22),
              fontWeight: "bold",
              lineHeight: 1.55,
              color: "#663ea8",
            }}
          >
            {`Mis pólizas`}
          </Typography>
        )}

        {!isLoading && hasMoreItems && (
          <LinkButton
            onClick={() => {
              history.push("/mispolizas");
            }}
            variant="purple"
          >
            ver todas
          </LinkButton>
        )}
      </div>
      {isLoading && <LoadingTemplate length={3} />}
      {!isLoading && hasPolizaSuspendida && (
        <WarningSection
          variant="danger"
          icon={<ReportProblemOutlinedIcon />}
          rightLink="más info"
          onClick={() => {
            history.push(`/mispolizas/${hasPolizaSuspendida.idPoliza}`);
          }}
          marginBottom={16}
        >
          Tenés seguros suspendidos
        </WarningSection>
      )}
      {!isLoading &&
        hasItems &&
        polizasObj.map((poliza, index) => (
          <PolizaCardButton
            key={`poliza-${index}`}
            poliza={poliza}
            marginBottom={12}
            onClick={() => {
              history.push(`/mispolizas/${poliza.idPoliza}`);
            }}
          />
        ))}
    </>
  );
};

const LoadingTemplate = ({ length }) => {
  return Array.apply(null, Array(length)).map((_, index) => (
    <CreditoCardButton
      key={`loading-${index}`}
      marginBottom={12}
      onClick={null}
      isLoading={true}
    />
  ));
};
