import { createActions, handleActions } from "redux-actions";

const {
  cotizadorSeguros: {
    clear,
    clearVehiculoMarca,
    setVehiculoMarca,
    clearVehiculoAnio,
    setVehiculoAnio,
    clearVehiculoModelo,
    setVehiculoModelo,
    clearProvincia,
    setProvincia,
    clearLocalidad,
    setLocalidad,
    clearCodigoPostal,
    setCodigoPostal,
    clearVehiculoUso,
    setVehiculoUso,
    clearTieneGNC,
    setTieneGNC,
    clearFormaDePago,
    setFormaDePago,

    clearDni,
    setDni,
    clearSexo,
    setSexo,
    clearEsPersonaValida,
    setEsPersonaValida,
    clearEmail,
    setEmail,
    clearTelefono,
    setTelefono,
    clearNombre,
    setNombre,
    clearFechaNacimiento,
    setFechaNacimiento,
    clearDireccion,
    setDireccion,
    clearDominio,
    setDominio,
    clearMotor,
    setMotor,
    clearNumeroChasis,
    setNumeroChasis,
    clearValidadorDatosPago,
    setValidadorDatosPago,

    setInitialCurrentPoliza,
    clearCurrentPoliza,
    setCurrentPoliza,
    clearContratarCurrentPoliza,
    setContratarCurrentPoliza,

    setDetalleCotizacion,
    //fetchIdentidad
    fetchIdentidadInited,
    fetchIdentidadCompleted,
    fetchIdentidadFailed,
    //postPedidoCotizacion
    postPedidoCotizacionInited,
    postPedidoCotizacionCompleted,
    postPedidoCotizacionFailed,
    //postDatosContacto
    postDatosContactoInited,
    postDatosContactoCompleted,
    postDatosContactoFailed,
    //getCotizacionDetalle
    getDetalleCotizacionInited,
    getDetalleCotizacionCompleted,
    getDetalleCotizacionFailed,
  },
} = createActions({
  cotizadorSeguros: {
    clear: () => ({}),
    clearVehiculoMarca: () => ({}),
    setVehiculoMarca: (value) => ({ value }),
    clearVehiculoAnio: () => ({}),
    setVehiculoAnio: (value) => ({ value }),
    clearVehiculoModelo: () => ({}),
    setVehiculoModelo: (value) => ({ value }),
    clearProvincia: () => ({}),
    setProvincia: (value) => ({ value }),
    clearLocalidad: () => ({}),
    setLocalidad: (value) => ({ value }),
    clearCodigoPostal: () => ({}),
    setCodigoPostal: (value) => ({ value }),
    clearVehiculoUso: () => ({}),
    setVehiculoUso: (value) => ({ value }),
    clearTieneGNC: () => ({}),
    setTieneGNC: (value) => ({ value }),
    clearFormaDePago: () => ({}),
    setFormaDePago: (value) => ({ value }),
    clearDni: () => ({}),
    setDni: (value) => ({ value }),
    clearSexo: () => ({}),
    setSexo: (value) => ({ value }),
    clearEsPersonaValida: () => ({}),
    setEsPersonaValida: (value) => ({ value }),
    clearEmail: () => ({}),
    setEmail: (value) => ({ value }),
    clearTelefono: () => ({}),
    setTelefono: (value) => ({ value }),
    clearNombre: () => ({}),
    setNombre: (value) => ({ value }),
    clearFechaNacimiento: () => ({}),
    setFechaNacimiento: (value) => ({ value }),
    clearDireccion: () => ({}),
    setDireccion: (value) => ({ value }),
    clearDominio: () => ({}),
    setDominio: (value) => ({ value }),
    clearMotor: () => ({}),
    setMotor: (value) => ({ value }),
    clearNumeroChasis: () => ({}),
    setNumeroChasis: (value) => ({ value }),
    clearValidadorDatosPago: () => ({}),
    setValidadorDatosPago: (value) => ({ value }),

    setInitialCurrentPoliza: (value) => ({ value }),
    clearCurrentPoliza: () => ({}),
    setCurrentPoliza: (value) => ({ value }),
    clearContratarCurrentPoliza: () => ({}),
    setContratarCurrentPoliza: (value) => ({ value }),

    setDetalleCotizacion: (value) => ({ value }),
    //
    fetchIdentidadInited: () => ({}),
    fetchIdentidadCompleted: (response) => ({ response }),
    fetchIdentidadFailed: (error) => ({ error }),
    //
    postPedidoCotizacionInited: () => ({}),
    postPedidoCotizacionCompleted: (response) => ({ response }),
    postPedidoCotizacionFailed: (error) => ({ error }),
    //
    postDatosContactoInited: () => ({}),
    postDatosContactoCompleted: (response) => ({ response }),
    postDatosContactoFailed: (error) => ({ error }),
    //
    getDetalleCotizacionInited: () => ({}),
    getDetalleCotizacionCompleted: (response) => {
      let detalleCotizacion = null;

      if (isValidResponse(response)) {
        detalleCotizacion = response;
      }

      return { detalleCotizacion };
    },
    getDetalleCotizacionFailed: (error) => ({ error }),
  },
});

const initialState = {
  isLoading: false,
  cotizacionId: null,
  detalleCotizacion: null,
  polizasRequestCount: 0,

  currentPoliza: { value: null, open: false, contratada: false },

  marcaSelected: { value: null, label: null },
  modeloSelected: { value: null, label: null },
  anioSelected: { value: null, label: null },
  provinciaSelected: null,
  localidadSelected: null,
  codigoPostalSelected: null,
  usoSelected: null,
  tieneGNC: { value: null, selected: false },
  formaDePagoSelected: { value: null, label: null },
  dniSelected: null,
  sexoSelected: { value: null, selected: false },
  nombreSelected: null,
  esPersonaValida: { value: null, selected: false },
  emailSelected: { value: null, selected: false },
  telefonoSelected: { value: null, isMobile: true },
  direccionSelected: null,
  dominioSelected: { value: null, selected: false },
  motorSelected: { value: null, selected: false },
  numeroChasisSelected: { value: null, selected: false },
  validadorDatosPagoSelected: { value: null, selected: false },

  fechaNacimientoSelected: { value: null, selected: false },
  isForward: true,
};

export const cotizadorReducer = handleActions(
  {
    [clear]: () => {
      return {
        ...initialState,
        isForward: true,
      };
    },
    [clearVehiculoMarca]: (state, action) => {
      return {
        ...state,
        modeloSelected: { value: null, label: null },
        anioSelected: { value: null, label: null },
        marcaSelected: { value: null, label: null },
        cotizacionId: null,
      };
    },
    [clearVehiculoAnio]: (state, action) => {
      return {
        ...state,
        modeloSelected: { value: null, label: null },
        anioSelected: { value: null, label: null },
        cotizacionId: null,
      };
    },
    [clearVehiculoModelo]: (state, action) => {
      return {
        ...state,
        modeloSelected: { value: null, label: null },
        cotizacionId: null,
      };
    },
    [clearProvincia]: (state, action) => {
      return {
        ...state,
        provinciaSelected: null,
        localidadSelected: null,
        codigoPostalSelected: null,
        cotizacionId: null,
      };
    },
    [clearLocalidad]: (state, action) => {
      return {
        ...state,
        localidadSelected: null,
        codigoPostalSelected: null,
        cotizacionId: null,
      };
    },
    [clearCodigoPostal]: (state, action) => {
      return {
        ...state,
        codigoPostalSelected: null,
        cotizacionId: null,
      };
    },
    [clearVehiculoUso]: (state, action) => {
      return {
        ...state,
        usoSelected: null,
        cotizacionId: null,
      };
    },
    [clearTieneGNC]: (state, action) => {
      return {
        ...state,
        tieneGNC: { value: null, selected: false },
        cotizacionId: null,
      };
    },
    [clearFormaDePago]: (state, action) => {
      return {
        ...state,
        formaDePagoSelected: { value: null, label: null },
        cotizacionId: null,
      };
    },
    [clearDni]: (state, action) => {
      return {
        ...state,
        dniSelected: null,
        sexoSelected: { value: null, selected: false },
      };
    },
    [clearEsPersonaValida]: (state, action) => {
      return {
        ...state,
        esPersonaValida: { value: null, selected: false },
      };
    },
    [clearSexo]: (state, action) => {
      return {
        ...state,
        sexoSelected: { value: null, selected: false },
        esPersonaValida: { value: null, selected: false },
      };
    },
    [clearEmail]: (state, action) => {
      return {
        ...state,
        emailSelected: { value: null, selected: false },
      };
    },
    [clearTelefono]: (state, action) => {
      return {
        ...state,
        telefonoSelected: { value: null, isMobile: true },
      };
    },
    [clearNombre]: (state, action) => {
      return {
        ...state,
        nombreSelected: null,
      };
    },
    [clearDireccion]: (state, action) => {
      return {
        ...state,
        direccionSelected: null,
      };
    },
    [clearDominio]: (state, action) => {
      return {
        ...state,
        dominioSelected: { value: null, selected: false },
      };
    },
    [clearMotor]: (state, action) => {
      return {
        ...state,
        motorSelected: { value: null, selected: false },
      };
    },
    [clearNumeroChasis]: (state, action) => {
      return {
        ...state,
        numeroChasisSelected: { value: null, selected: false },
      };
    },
    [clearValidadorDatosPago]: (state, action) => {
      return {
        ...state,
        validadorDatosPagoSelected: { value: null, selected: false },
      };
    },
    [clearCurrentPoliza]: (state, action) => {
      return {
        ...state,
        currentPoliza: {
          value: state.currentPoliza.value,
          open: false,
          contratada: false,
        },
      };
    },
    [clearContratarCurrentPoliza]: (state, action) => {
      return {
        ...state,
        currentPoliza: {
          value: state.currentPoliza.value,
          open: true,
          contratada: false,
        },
      };
    },

    [setVehiculoMarca]: (state, action) => {
      return {
        ...state,
        marcaSelected: action.payload.value,
      };
    },
    [setVehiculoAnio]: (state, action) => {
      return {
        ...state,
        anioSelected: action.payload.value,
      };
    },
    [setVehiculoModelo]: (state, action) => {
      return {
        ...state,
        modeloSelected: action.payload.value,
      };
    },
    [setProvincia]: (state, action) => {
      return {
        ...state,
        provinciaSelected: action.payload.value,
      };
    },
    [setLocalidad]: (state, action) => {
      return {
        ...state,
        localidadSelected: action.payload.value,
      };
    },
    [setCodigoPostal]: (state, action) => {
      return {
        ...state,
        codigoPostalSelected: action.payload.value,
      };
    },
    [setVehiculoUso]: (state, action) => {
      return {
        ...state,
        usoSelected: action.payload.value,
      };
    },
    [setTieneGNC]: (state, action) => {
      return {
        ...state,
        tieneGNC: action.payload.value,
      };
    },
    [setDni]: (state, action) => {
      return {
        ...state,
        dniSelected: action.payload.value,
      };
    },
    [setSexo]: (state, action) => {
      return {
        ...state,
        sexoSelected: action.payload.value,
      };
    },
    [setFormaDePago]: (state, action) => {
      return {
        ...state,
        formaDePagoSelected: action.payload.value,
      };
    },
    [setEsPersonaValida]: (state, action) => {
      return {
        ...state,
        esPersonaValida: action.payload.value,
      };
    },
    [setNombre]: (state, action) => {
      return {
        ...state,
        nombreSelected: action.payload.value,
      };
    },
    [setEmail]: (state, action) => {
      return {
        ...state,
        emailSelected: action.payload.value,
      };
    },
    [setTelefono]: (state, action) => {
      return {
        ...state,
        telefonoSelected: action.payload.value,
      };
    },
    [setDireccion]: (state, action) => {
      return {
        ...state,
        direccionSelected: action.payload.value,
      };
    },
    [setFechaNacimiento]: (state, action) => {
      return {
        ...state,
        fechaNacimientoSelected: action.payload.value,
      };
    },
    [setDominio]: (state, action) => {
      return {
        ...state,
        dominioSelected: action.payload.value,
      };
    },
    [setMotor]: (state, action) => {
      return {
        ...state,
        motorSelected: action.payload.value,
      };
    },
    [setNumeroChasis]: (state, action) => {
      return {
        ...state,
        numeroChasisSelected: action.payload.value,
      };
    },
    [setValidadorDatosPago]: (state, action) => {
      return {
        ...state,
        validadorDatosPagoSelected: action.payload.value,
      };
    },
    [setInitialCurrentPoliza]: (state, action) => {
      return {
        ...state,
        currentPoliza: action.payload.value,
      };
    },
    [setCurrentPoliza]: (state, action) => {
      return {
        ...state,
        currentPoliza: action.payload.value,
      };
    },
    [setCurrentPoliza]: (state, action) => {
      return {
        ...state,
        currentPoliza: action.payload.value,
      };
    },
    [setContratarCurrentPoliza]: (state, action) => {
      return {
        ...state,
        currentPoliza: action.payload.value,
      };
    },

    //GET MULTICOTIZACIONES
    [getDetalleCotizacionInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [getDetalleCotizacionCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        detalleCotizacion: action.payload.detalleCotizacion,
        polizasRequestCount: state.polizasRequestCount + 1,
      };
    },
    [getDetalleCotizacionFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    //POST MULTICOTIZACIONES
    [postPedidoCotizacionInited]: (state, action) => {
      return {
        ...state,
        cotizacionId: null,
        detalleCotizacion: null,
        polizasRequestCount: 0,
        isLoading: true,
      };
    },
    [postPedidoCotizacionCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        cotizacionId: action.payload.response,
      };
    },
    [postPedidoCotizacionFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    //POST DATOS CONTACTO
    [postDatosContactoInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [postDatosContactoCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    [postDatosContactoFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    //GET IDENTIDAD
    [fetchIdentidadInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        nombreSelected: null,
        fechaNacimientoSelected: { value: null, selected: false },
      };
    },
    [fetchIdentidadCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        nombreSelected: action.payload.response.nombre,
        fechaNacimientoSelected: {
          value: action.payload.response.fechaNacimiento,
          selected: true,
        },
      };
    },
    [fetchIdentidadFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        nombreSelected: null,
        fechaNacimientoSelected: {
          value: null,
          selected: false,
        },
      };
    },
  },
  initialState
);

export default cotizadorReducer;
export {
  clear,
  clearVehiculoMarca,
  setVehiculoMarca,
  clearVehiculoAnio,
  setVehiculoAnio,
  clearVehiculoModelo,
  setVehiculoModelo,
  clearProvincia,
  setProvincia,
  clearLocalidad,
  setLocalidad,
  clearCodigoPostal,
  setCodigoPostal,
  clearVehiculoUso,
  setVehiculoUso,
  clearTieneGNC,
  setTieneGNC,
  clearFormaDePago,
  setFormaDePago,
  clearDni,
  setDni,
  clearSexo,
  setSexo,
  clearEsPersonaValida,
  setEsPersonaValida,
  clearEmail,
  setEmail,
  clearTelefono,
  setTelefono,
  clearNombre,
  setNombre,
  clearFechaNacimiento,
  setFechaNacimiento,
  clearDireccion,
  setDireccion,
  clearDominio,
  setDominio,
  clearMotor,
  setMotor,
  clearNumeroChasis,
  setNumeroChasis,
  clearValidadorDatosPago,
  setValidadorDatosPago,
  //
  setInitialCurrentPoliza,
  clearCurrentPoliza,
  setCurrentPoliza,
  clearContratarCurrentPoliza,
  setContratarCurrentPoliza,
  setDetalleCotizacion,
};

export const fetchIdentidad = (documento, sexo) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchIdentidadInited());
      const response = await services.api.getIdentidad(documento, sexo);

      const nombre = Capitalize(`${response.nombres} ${response.apellido}`);
      const fechaNacimiento = response.fechaNacimiento;
      dispatch(
        fetchIdentidadCompleted({
          nombre,
          fechaNacimiento,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(fetchIdentidadFailed(error));
    }
  };
};

export const postPedidoCotizacion = ({
  anioSelected,
  modeloSelected,
  codigoPostalSelected,
  usoSelected,
  tieneGNC,
  formaDePagoSelected,
}) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(postPedidoCotizacionInited());

      const data = {
        Negocio: "MercadoAbierto",
        Periodo: "Anual",
        ModeloVehiculo: modeloSelected.value,
        AnioVehiculo: anioSelected.value,
        CodigoPostal: codigoPostalSelected,
        UsoDelVehiculo: usoSelected,
        TieneGNC: tieneGNC.value,
        FormaPago: formaDePagoSelected.value,
      };

      const response = await services.api.postSegurosCotizar(data);

      let cotizacionId = null;

      if (isValidResponse(response)) {
        cotizacionId = response.cotizacionId;
      } else {
        throw new Error("fail on postCotizacion");
      }

      dispatch(postPedidoCotizacionCompleted(cotizacionId));

      dispatch(getDetalleCotizacion(cotizacionId));
    } catch (error) {
      console.error(error);
      dispatch(postPedidoCotizacionFailed(error));
    }
  };
};

export const getDetalleCotizacion = (id) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getDetalleCotizacionInited());

      const response = await services.api.getSegurosCotizaciones(id);

      dispatch(getDetalleCotizacionCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getDetalleCotizacionFailed(error));
    }
  };
};

export const postDatosContacto = ({
  cotizacionId,
  nombreSelected,
  dniSelected,
  telefonoSelected,
  currentPoliza,
  direccionSelected,
  fechaNacimientoSelected,
  sexoSelected,
  emailSelected,
  dominioSelected,
  motorSelected,
  numeroChasisSelected,
  validadorDatosPagoSelected,
}) => {
  return async (dispatch, getState, services) => {
    const data = {
      CoberturaSeleccionadaId: currentPoliza.value.cotizacionPlanId,
      Nombre: nombreSelected,
      DNI: dniSelected,
      Email: emailSelected.value,
      Telefono: telefonoSelected.value,
      EsTelefonoMovil: telefonoSelected.isMobile,
      Direccion: direccionSelected,
      FechaNacimiento: fechaNacimientoSelected.value,
      Sexo: sexoSelected.value,
      Dominio: dominioSelected.value,
      NumeroMotor: motorSelected.value,
      NumeroChasis: numeroChasisSelected.value,
      DatosPago: validadorDatosPagoSelected.value,
    };

    try {
      dispatch(postDatosContactoInited());

      const response = await services.api.patchSegurosQuieroQueMeContacten(
        cotizacionId,
        data
      );

      dispatch(postDatosContactoCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(postDatosContactoFailed(error));
    }
  };
};

//private functions
const upperCase = (str) => {
  return str.toUpperCase();
};
const Capitalize = (str) => {
  str = str.toLowerCase();
  var firstLetterRx = /(^|\s)[a-z]/g;
  return str.replace(firstLetterRx, upperCase);
};

const isValidResponse = (response) => {
  return (
    typeof response !== "undefined" &&
    response !== null &&
    response.length !== 0
  );
};
