import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import InfiniteScroll from "~shared/layout/InfiniteScroll"; // dejar de usar en cuanto se pueda
import LinearProgress from "@material-ui/core/LinearProgress";

import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import closeIcon from "~assets/icons/ic-close.svg";
import backIcon from "~assets/icons/ic-back.svg";

import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";

import pxToRem from "~libs/pxToRem";
import ContentSection from "../../contentSection/ContentSection";

const NAV_BAR_HEIGHT = 56;
const TABS_HEIGHT = 48;

const useStyles = makeStyles(theme => ({
  main: ({ addPadding }) => ({
    //overflowX: "hidden",
    backgroundColor: "#f9f7fc",

    paddingTop: 0,
    minHeight: `calc(100vh - ${TABS_HEIGHT}px - ${
      addPadding ? pxToRem(0) : pxToRem(16 * 2)
    })`,

    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "space-between"
  }),
  columnCentered: ({ addPadding }) => ({
    padding: addPadding ? pxToRem(16) : 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    flex: 1
  }),
  logoSize: {
    width: "15vh",
    [theme.breakpoints.mobile]: {
      padding: 12
    }
  },
  logoFigure: {
    padding: theme.typography.pxToRem(16),
    minHeight: "16vh",
    display: "flex",
    margin: 0,
    [theme.breakpoints.mobile]: {
      minHeight: "8vh"
    }
  }
}));

const DetailsLayout = ({
  children,
  addPadding,
  title,
  tabsComponent,
  onBackClick,
  onCloseClick
}) => {
  const classes = useStyles({ addPadding });
  const [hasScrollBar, setHasScrollBar] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setHasScrollBar(hasVerticalScroll());
    }, 500);

    return function cleanup() {
      clearInterval(timer);
    };
  });

  return (
    <>
      <div className={classes.main}>
        <InfiniteScroll /> {/* TODO: remove this when is no needed anymore */}
        <NavBar
          title={title}
          tabsComponent={tabsComponent}
          onBackClick={onBackClick}
          onCloseClick={onCloseClick}
        />
        <div className={classes.columnCentered}>{children}</div>
      </div>
    </>
  );
};

export default DetailsLayout;

const useNavBarStyles = makeStyles(theme => ({
  topbar: ({ withTabs }) => ({
    position: "sticky",
    top: 0,
    background: "#fff",
    boxShadow: withTabs ? "none" : "0 2px 3px 0 #00000029",
    zIndex: 999,
    //marginBottom: 20,
    height: NAV_BAR_HEIGHT,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }),
  tabs: {
    position: "sticky",
    top: 0,
    background: "#fff",
    boxShadow: "0 2px 3px 0 #00000029",
    zIndex: 999,
    //marginBottom: 20,
    height: TABS_HEIGHT,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.2s ease-out"
  },
  content: {
    flex: 1,
    padding: 0,
    display: "flex"
  },
  progress: {
    backgroundColor: "#d7caed",
    height: 5
  }
}));

const NavBar = ({ title, tabsComponent, onBackClick, onCloseClick }) => {
  const classes = useNavBarStyles({ withTabs: tabsComponent ? true : false });

  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  const isScrollTriggered = useScrollTrigger();

  return (
    <>
      <Slide appear={false} direction="down" in={!isScrollTriggered}>
        <div className={classes.topbar}>
          <div className={classes.content}>
            <LightTooltip title={"VOLVER"}>
              <IconButton
                onClick={onBackClick}
                disabled={onBackClick ? false : true}
                style={{ width: 56, height: 56 }}
              >
                <IconFile src={backIcon} />
              </IconButton>
            </LightTooltip>
            <div style={{ flex: 1, textAlign: "center" }}>
              <Typography
                variant={"h1"}
                color={"primary"}
                style={{
                  fontSize: pxToRem(18),
                  fontWeight: "bold",
                  lineHeight: "56px",
                  margin: 0
                }}
              >
                {title}
              </Typography>
            </div>
            {!onCloseClick && <div style={{ width: 56, height: 56 }} />}
            {onCloseClick && (
              <LightTooltip title={"CERRAR"}>
                <IconButton
                  onClick={onCloseClick}
                  style={{ width: 56, height: 56 }}
                >
                  <IconFile src={closeIcon} />
                </IconButton>
              </LightTooltip>
            )}
          </div>
        </div>
      </Slide>
      {tabsComponent && (
        <div
          className={classes.tabs}
          style={{ top: !isScrollTriggered ? NAV_BAR_HEIGHT : 0 }}
        >
          <ContentSection>{tabsComponent()}</ContentSection>
        </div>
      )}
    </>
  );
};

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: "bold",
    marginTop: 5
  }
}))(Tooltip);

const IconFile = ({ src }) => {
  return <img alt="" src={src} style={{ width: 18, height: 18 }} />;
};

function hasVerticalScroll(node) {
  if (node == undefined) {
    if (window.innerHeight) {
      return document.body.offsetHeight > window.innerHeight;
    } else {
      return (
        document.documentElement.scrollHeight >
          document.documentElement.offsetHeight ||
        document.body.scrollHeight > document.body.offsetHeight
      );
    }
  } else {
    return node.scrollHeight > node.offsetHeight;
  }
}
