import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchAptoCredito } from "../../../actions";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import Typography from "@material-ui/core/Typography";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import makeStyles from "@material-ui/styles/makeStyles";
import pxToRem from "~libs/pxToRem";
import ErrorIcon from "~assets/ilustrations/illus_no-internet.svg";

const useStyles = makeStyles(theme => ({
  img: {
    width: pxToRem(142.1),
    align: "center"
  },
  text: {
    fontSize: pxToRem(16),
    lineHeight: 1.38,
    textAlign: "center",
    color: "#616161"
  },
  content: {
    marginTop: 60,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

const ConexionErrorForm = () => {
  const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            label={"Reintentá"}
            onClick={() => dispatch(fetchAptoCredito())}
          />
        </ContentSection>
      }
    >
      <div className={classes.content}>
        <img src={ErrorIcon} className={classes.img} />
        <PrimaryTitle label={"No hay internet"} marginBottom={20} />
        <Typography className={classes.text}>
          Verificá tu conexión y volvé a intentarlo.
        </Typography>
      </div>
    </ContentSectionWithActions>
  );
};

export default ConexionErrorForm;
