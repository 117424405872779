import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { NavbarLayoutClienteFinal } from "~components/styled/layouts/navbarLayout/NavbarLayout";
import TopbarWithTitle from "~components/styled/layouts/navbarLayout/TopbarWithTitle";
import ListadoPolizas from "./ListadoPolizas";
import {
  ButtonGroup,
  ButtonGroupLabel,
  Button
} from "~components/styled/toolboxButtons/ButtonGroup";
import asegurarIcon from "~assets/icons/ic_asegurar_auto.svg";

import { getPolizas } from "./actions";
import ContentSection from "~components/styled/contentSection/ContentSection";

const HomeSeguros = () => {
  const { polizas, isLoading } = useSelector(state => ({
    isLoading: state.segurosReducers.listadoPolizasReducer.isLoading,
    polizas: state.segurosReducers.listadoPolizasReducer.polizas
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getPolizas());
  }, []);
  return (
    <NavbarLayoutClienteFinal
      topbar={() => <TopbarWithTitle title={"Seguros"} />}
    >
      <ContentSection>
        <ButtonGroup marginBottom={17}>
          <Button
            onClick={() => {
              history.push("/seguros");
            }}
          >
            <ButtonGroupLabel
              label={"Asegurar"}
              subLabel={"un auto"}
              icon={asegurarIcon}
            />
          </Button>
        </ButtonGroup>
        <ListadoPolizas polizas={polizas} isLoading={isLoading} />
      </ContentSection>
    </NavbarLayoutClienteFinal>
  );
};

export default HomeSeguros;
