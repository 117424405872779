import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListView from "~components/styled/listView/ListView";
import getEntidadesDePago from "./getEntidadesDePago";

const useStyles = makeStyles(theme => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-around"
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2
  }
}));

const entidadesDePagoList = getEntidadesDePago();

const EntidadDePagoSelector = ({ onSelected }) => {
  const handleItemSelected = item => {
    if (onSelected) onSelected({ target: { value: item.value } });
  };

  const classes = useStyles();
  return (
    <div className={classes.searcher}>
      <ListView
        roundedTop
        onItemSelected={handleItemSelected}
        list={entidadesDePagoList}
      />
    </div>
  );
};

export default EntidadDePagoSelector;
