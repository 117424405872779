import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentSection from "~components/styled/contentSection/ContentSection";

import { AUTOPRENDA, PRENDARIO_TRADICIONAL } from "./tipoCreditoConsts";
import {
  CheckboxButton,
  CheckboxLargeLabel
} from "~components/styled/checkboxButton/CheckboxButton";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import autoIcon from "~assets/ilustrations/illus-comprar-auto.svg";
import otrosIcon from "~assets/ilustrations/illus-otros-proyectos.svg";

import { setTipoCredito, clearTipoCredito } from "./actions";

const TipoCreditoStep = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearTipoCredito());
  }, [dispatch]);

  return (
    <ContentSection>
      <PrimaryTitle label={"¿Para qué necesita el crédito tu cliente?"} />
      <CheckboxButton
        disabled={false}
        checked={false}
        marginBottom={15}
        onClick={() => dispatch(setTipoCredito(PRENDARIO_TRADICIONAL))}
      >
        <CheckboxLargeLabel
          label={"Comprar un auto"}
          subLabel={"de la agencia"}
          image={autoIcon}
          topLabel={"Credito prendario"}
        />
      </CheckboxButton>
      <CheckboxButton
        disabled={false}
        checked={false}
        onClick={() => dispatch(setTipoCredito(AUTOPRENDA))}
      >
        <CheckboxLargeLabel
          label={"Otros proyectos"}
          subLabel={"crédito con auto como garantía"}
          image={otrosIcon}
          topLabel={"Autoprenda"}
        />
      </CheckboxButton>
    </ContentSection>
  );
};

export default TipoCreditoStep;
