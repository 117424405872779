import React, { useEffect, useState } from "react";

import makeStyles from "@material-ui/styles/makeStyles";

import localRepository from "../../localRepository";
import WaveLayout from "./WaveLayout";
import happyEmogi from "~images/auth/happyEmogi.svg";
import Typography from "@material-ui/core/Typography";
import SocialButton from "~shared/SocialButton";
import nextIcon from "~images/auth/next.svg";
import SelectorTipoCuenta from "./SelectorTipoCuenta";
import qs from "query-string";
import { useAuth0 } from "../Auth0/useAuth";
import * as api from "~api";
import LoadingView from "./LoadingView";
import { sendMessageToNative } from "../nativeAppMessaging/js/actions";
import { useSelector } from "react-redux";
import { sendMessage } from "~libs/sendMessage";

const LOADING = 1;
const USER_CONFIRM_IDENTITY = 2;
const YA_TENGO_CUENTA_O_REGISTRAR_AGENCIA = 3;

const useReduxSelector = () =>
  useSelector(state => ({
    isOnNativeApp: state.nativeMessagesReducer.isOnNativeApp
  }));

export const Validating = props => {
  const [currentView, setCurrentView] = useState(LOADING);
  const { renewSession, authState, logout } = useAuth0();
  const { isOnNativeApp } = useReduxSelector();

  const getIdFromParam = () => {
    const parsed = qs.parse(props.location.search);
    if (parsed.idEuro) return parsed.idEuro;
    return null;
  };

  const getIdFromTyC = () => {
    const idEuro = localRepository.terminosYCondiciones.get().idUsuario;
    const userWithTerminosYCondicionesConfirmed =
      idEuro && localRepository.terminosYCondiciones.get().name;
    if (userWithTerminosYCondicionesConfirmed && !isNaN(idEuro)) return idEuro;
    return null;
  };

  useEffect(() => {
    if (!authState.user.email) logout("/signin?passwordless=true");
    let idEuro = getIdFromParam();
    if (!idEuro) idEuro = getIdFromTyC();
    if (!idEuro) idEuro = authState.user.euroUserId;
    api
      .getUserExists(
        idEuro,
        authState.user.email,
        authState.user.sub,
        authState.user.preferred_username
      )
      .then(data => {
        if (data.usuarioExistente) {
          if (isOnNativeApp)
            sendMessageToNative({
              type: "RN_REFRESH_TOKEN"
            });
          else renewSession();
        } else setCurrentView(YA_TENGO_CUENTA_O_REGISTRAR_AGENCIA);
      })
      .catch(error => {
        console.error("error en euro migración", error);
        sendMessage(
          "Ooops... hubo un problema, intentá de nuevo en unos instantes.",
          "error"
        );
        setTimeout(() => {
          logout();
        }, 2000); // TOOD: fix it
      });
  }, []);

  return (
    <React.Fragment>
      {currentView === LOADING && <LoadingView />}
      {currentView === USER_CONFIRM_IDENTITY && (
        <UserConfirmIdentityView
          onSoyOtraPersona={() =>
            setCurrentView(YA_TENGO_CUENTA_O_REGISTRAR_AGENCIA)
          }
        />
      )}
      {currentView === YA_TENGO_CUENTA_O_REGISTRAR_AGENCIA && (
        <SelectorTipoCuenta />
      )}
    </React.Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  link: {
    fontSize: 14,
    color: "#757575",
    textDecoration: "underline !important",
    lineHeight: 1.36
  }
}));

const UserConfirmIdentityView = ({ onSoyOtraPersona }) => {
  const isLoading = false;

  const classes = useStyles();

  return (
    <WaveLayout isLoading={isLoading}>
      <div style={{ height: 20 }} />
      <img alt="" src={happyEmogi} />
      <Typography
        variant="h6"
        style={{
          padding: 10,
          color: "#503181",
          fontWeight: "bold",
          fontSize: 19
        }}
      >
        ¡Hola {localRepository.terminosYCondiciones.get().name.split(" ")[0]}!
      </Typography>
      <Typography
        variant="body2"
        style={{ fontSize: "12px", color: "#757575" }}
      >
        A continuación vamos a actualizar tus credenciales de ingreso para
        mejorar tu experiencia en la aplicación.
      </Typography>
      <div style={{ height: 20 }} />
      <a
        href={"javascript:void(0)"}
        className={classes.link}
        onClick={onSoyOtraPersona}
      >
        ¿Sos otra persona?
      </a>
      <div style={{ height: 20 }} />
      <SocialButton
        onClick={() => {}}
        label={"¡Vamos!"}
        icon={nextIcon}
        backgroundColor={"#4cbfd6"}
        backgroundColorIcon={"#33aec4"}
      />
    </WaveLayout>
  );
};
