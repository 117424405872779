import React from "react";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import HomeSeguros from "~components/app/clienteFinal/seguros/homeSeguros/HomeSeguros";
import DetallePoliza from "~components/app/clienteFinal/seguros/detallePoliza/DetallePoliza";
import { AsyncCotizadorSeguros } from "~componentes/app/LazyComponents";
import DetailsLayout from "~components/styled/layouts/detailsLayout/DetailsLayout";
import { FeatureFlagRender } from "~shared/featureFlags";
import { default as NewCotizadorSeguros } from "./cotizador/CotizadorSeguros";

const SegurosRoutes = (pathSeguros) => {
  const history = useHistory();

  return [
    <Route key="seguros-1" exact path="/mispolizas">
      <HomeSeguros />
    </Route>,
    <Route key="seguros-2" exact path="/mispolizas/:idPoliza">
      <DetallePoliza />
    </Route>,
    <Route key="seguros-3" path={pathSeguros}>
      <FeatureFlagRender flag={"nuevo-cotizador-seguros"} when={false}>
        <DetailsLayout
          title={`Cotizar seguro`}
          onBackClick={() => {
            history.goBack();
          }}
          onCloseClick={() => {
            history.push("/");
          }}
        >
          <div style={{ width: "100%" }}>
            <AsyncCotizadorSeguros />
          </div>
        </DetailsLayout>
      </FeatureFlagRender>
      <FeatureFlagRender flag={"nuevo-cotizador-seguros"} when={true}>
        <NewCotizadorSeguros />
      </FeatureFlagRender>
    </Route>,
  ];
};

export default SegurosRoutes;
