import { createActions, handleActions } from "redux-actions";

const {
  seguros: {
    listadoPolizas: { getPolizasInited, getPolizasCompleted, getPolizasFailed }
  }
} = createActions({
  seguros: {
    listadoPolizas: {
      getPolizasInited: () => ({}),
      getPolizasCompleted: response => ({
        response
      }),
      getPolizasFailed: error => ({
        error
      })
    }
  }
});

const getPolizas = () => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getPolizasInited());
      const response = await services.api.getPolizas();
      dispatch(getPolizasCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getPolizasFailed(error));
    }
  };
};
const initialState = {
  isLoading: false,
  polizas: []
};
const listadoPolizasReducer = handleActions(
  {
    [getPolizasInited]: (state, action) => {
      return {
        ...state,
        isLoading: !state.polizas.length > 0
      };
    },
    [getPolizasCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        polizas: action.payload.response
      };
    },
    [getPolizasFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    }
  },
  initialState
);

export default listadoPolizasReducer;
export { getPolizas };
