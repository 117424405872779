import "date-fns";
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

const DatePicker = ({
  fullWidth,
  onChange,
  onKeyDown,
  value,
  className,
  label,
  id
}) => {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = useState(value);

  const handleDateChange = date => {
    setSelectedDate(date);
    if (onChange) onChange(date);
  };

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  return (
    <div className={className}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <KeyboardDatePicker
            fullWidth={fullWidth}
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id={id}
            label={label}
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
            onKeyDown={onKeyDown}
            autoOk
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatePicker;
