import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import pxToRem from "~libs/pxToRem";

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: pxToRem(35.2),
    color: "#fff",
    marginBottom: pxToRem(20),
    fontWeight: 700,
    letterSpacing: 0
  }
}));

const SecondaryTitle = ({ label }) => {
  const classes = useStyles();

  return (
    <Typography variant="h1" className={classes.root}>
      {label}
    </Typography>
  );
};

export default SecondaryTitle;
