import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSolicitudIniciada, iniciarCalificacion } from "../../actions";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import Typography from "@material-ui/core/Typography";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import makeStyles from "@material-ui/styles/makeStyles";
import pxToRem from "~libs/pxToRem";
import AptoIcon from "~assets/ilustrations/illus_solic_apto.svg";

const useStyles = makeStyles(theme => ({
  img: {
    width: pxToRem(242.5),
    align: "center"
  },
  text: {
    fontSize: pxToRem(16),
    lineHeight: 1.38,
    textAlign: "center",
    color: "#616161"
  },
  content: {
    marginTop: 60,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

const useReduxSelector = () =>
  useSelector(state => {
    const nuevaSolicitudReducer = state.creditosReducers.nuevaSolicitudReducer;
    return {
      identidadPersonas: nuevaSolicitudReducer.identidadPersonas,
      solicitudId: nuevaSolicitudReducer.resultadoAptoCredito.id
    };
  });

const AptoForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { identidadPersonas, solicitudId } = useReduxSelector();
  const cliente =
    identidadPersonas && identidadPersonas.titular
      ? `${identidadPersonas.titular.nombres} ${identidadPersonas.titular.apellido}`
      : "El cliente";
  const conyuge =
    identidadPersonas && identidadPersonas.conyuge
      ? `${identidadPersonas.conyuge.nombres} ${identidadPersonas.conyuge.apellido}`
      : null;

  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            label={"Iniciar Solicitud"}
            onClick={() => {
              dispatch(iniciarCalificacion(solicitudId));
              dispatch(setSolicitudIniciada());
            }}
          />
        </ContentSection>
      }
    >
      <div className={classes.content}>
        <img src={AptoIcon} className={classes.img} />
        <PrimaryTitle label={"Apto crédito"} marginBottom={20} />
        <Typography className={classes.text}>
          {cliente +
            (conyuge ? " y " + conyuge : "") +
            " se ajusta" +
            (conyuge ? "n" : "") +
            " a nuestra política crediticia y podemos ofrecerle" +
            (conyuge ? "s" : "") +
            " un crédito."}
        </Typography>
      </div>
    </ContentSectionWithActions>
  );
};

export default AptoForm;

/*
message={
  
  */
