import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setComentario, clearComentario, informarPago } from "../actions";
import { makeStyles } from "@material-ui/styles";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import moment from "moment";

const useReduxSelector = () =>
  useSelector(state => {
    const informarPagoReducer = state.creditosReducers.informarPagoReducer;

    return {
      nomYape: informarPagoReducer.nombreYApellido,
      dni: informarPagoReducer.dni,
      telefono: informarPagoReducer.telefono,
      email: informarPagoReducer.email,
      conceptoPago: informarPagoReducer.conceptoPago,
      medioDePago: informarPagoReducer.medioDePago,
      entidadDePago: informarPagoReducer.entidadDePago,
      fechaDePago: informarPagoReducer.fechaDePago,
      comentarioCompleted: informarPagoReducer.comentarioCompleted,
      comentario: informarPagoReducer.comentario,
      comprobanteAdjunto: informarPagoReducer.comprobanteAdjunto,
      importe: informarPagoReducer.importe,
      operacionId: informarPagoReducer.operacionId
    };
  });

const ComentarioStep = () => {
  const {
    nomYape,
    dni,
    telefono,
    email,
    conceptoPago,
    medioDePago,
    entidadDePago,
    fechaDePago,
    comprobanteAdjunto,
    importe,
    operacionId
  } = useReduxSelector();
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const classes = useStyles();

  useEffect(() => {
    dispatch(clearComentario());
  }, [dispatch]);

  const submitInformarPago = () => {
    dispatch(
      informarPago({
        NombreYApellido: nomYape,
        Documento: dni,
        Telefono: telefono,
        Email: email,
        ConceptoPago: conceptoPago,
        TipoPago: medioDePago,
        MedioDePago: entidadDePago,
        Comentario: comment,
        Moneda: importe.moneda,
        Importe: importe.monto,
        Comprobantes: comprobanteAdjunto,
        FechaDePago: moment(fechaDePago, "DD/MM/YYYY").format("YYYY-MM-DD"),
        FechaDeAviso: moment().format("YYYY-MM-DD"),
        operacionId: operacionId
      })
    );
  };

  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            label={"Informar pago"}
            onClick={() => {
              dispatch(setComentario(comment));
              submitInformarPago();
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle label={"¿Querés aclarar algo?"} marginBottom={60} />

      <TextField
        fullWidth={true}
        multiline
        rows="4"
        rowsMax={10}
        placeholder={"Ingresá tu comentario acá..."}
        variant="outlined"
        onChange={event => setComment(event.target.value)}
        className={classes.textArea}
        InputProps={{ classes: { notchedOutline: classes.notchedOutline } }}
      ></TextField>
    </ContentSectionWithActions>
  );
};

export default ComentarioStep;

const useStyles = makeStyles(theme => ({
  textArea: {
    backgroundColor: "white"
  },
  notchedOutline: {
    borderColor: "#e3e3e3 !important"
  }
}));
