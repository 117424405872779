import React, { memo, useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Next from "@material-ui/icons/ArrowForward";
import ContentLoader from "react-content-loader";
import InfiniteScroll from "react-infinite-scroller";

const useStyles = makeStyles(theme => ({
  listPaper: props => ({
    borderRadius: props.roundedTop ? "8px 8px 8px 8px" : "0px 0px 8px 8px",
    boxShadow: "0 0 4px 0 #7a6e8e3c"
  }),
  root: {
    minHeight: 30,
    overflow: "auto",
    width: "100%"
  },
  iconButton: {
    color: theme.palette.primary.main,
    padding: 0
  },
  textItem: {
    fontSize: 16,
    fontWeight: 600,
    color: "#9e9e9e",
    "&:hover": {
      backgroundColor: "#f4f1fa !important",
      color: `${theme.palette.primary.main} !important`
    }
  },
  selectedItem: {
    backgroundColor: "#f4f1fa !important",
    color: `${theme.palette.primary.main} !important`
  }
}));

const emptyListText = "No hay resultados.";

const ListView = memo(({ list, onItemSelected, isLoading, roundedTop }) => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const classes = useStyles({ roundedTop });

  useEffect(() => {
    const nextPage = getPage(list, 1);
    setItems(nextPage);
    setHasMore(true);
  }, [list]);

  const loadMore = page => {
    const nextPage = getPage(list, page);
    setItems([...items, ...nextPage]);
    setHasMore(nextPage.length > 0);
  };

  return (
    <Paper className={classes.listPaper}>
      <List className={classes.root} disablePadding={true}>
        {isLoading && <LoadingTemplate length={5} />}
        {!isLoading && list.length > 0 && (
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={<LoadingTemplate key={"loading-infinite"} length={1} />}
          >
            {items.map((item, i) => (
              <ListViewItem
                key={`lisViewItem-${item.value}${i}`}
                value={item.value}
                label={item.label}
                onItemSelected={onItemSelected}
                isLastItem={i === items.length - 1}
              />
            ))}
          </InfiniteScroll>
        )}
        {!isLoading && list.length === 0 && (
          <ListItem>
            <ListItemText
              className={classes.textItem}
              primary={emptyListText}
              disableTypography
            />
          </ListItem>
        )}
      </List>
    </Paper>
  );
});

export default ListView;

const ListViewItem = memo(({ value, label, onItemSelected, isLastItem }) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      divider={!isLastItem}
      classes={{
        root: classes.textItem,
        focusVisible: classes.selectedItem
      }}
      onClick={() => {
        onItemSelected({ value, label });
      }}
    >
      <ListItemText primary={label} disableTypography />
      <IconButton className={classes.iconButton} disabled={true}>
        <Next />
      </IconButton>
    </ListItem>
  );
});

const LoadingTemplate = ({ length }) => {
  const classes = useStyles();

  return Array.apply(null, Array(length)).map((_, index) => (
    <ListItem key={`listViewLoadingItem-${index}`} divider>
      <ListItemText
        primary={
          <ContentLoader
            height={10}
            width={316}
            speed={1}
            primaryColor="#f7f8ff"
            secondaryColor="#d4d4d4"
          >
            <rect x="0" y="0" rx="3" ry="3" width="70" height="10" />
            <rect x="80" y="0" rx="3" ry="3" width="100" height="10" />
            <rect x="190" y="0" rx="3" ry="3" width="10" height="10" />
          </ContentLoader>
        }
        disableTypography
      />
      <IconButton className={classes.iconButton} disabled={true}>
        <Next />
      </IconButton>
    </ListItem>
  ));
};

const getPage = (list, page) => {
  const pageCount = 30;

  const begin = (page - 1) * pageCount;
  // const end =
  //   page * pageCount > list.length - 1 ? list.length - 1 : page * pageCount;

  // console.log("getPage", begin, end);

  if (list && list.length > 0 && list.length > begin)
    return list.slice(begin, pageCount);

  return [];
};
