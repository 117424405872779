import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  getCuponPagoSaldo,
  getNotaCancelacion,
  getMediosDePago,
} from "~components/app/clienteFinal/creditos/detalleCredito/actions";

import pxToRem from "~libs/pxToRem";
import makeStyles from "@material-ui/styles/makeStyles";
import { emphasize } from "material-ui/utils/colorManipulator";
import Typography from "@material-ui/core/Typography";

import IconSquaredCheck from "~assets/icons/ic-squared-check.svg";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import WarningSection from "~components/styled/warningSection/WarningSection";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import MediosDePagoIcon from "@material-ui/icons/CreditCard";
import CircularProgress from "@material-ui/core/CircularProgress";

const useReduxSelector = () =>
  useSelector((state) => {
    const detalleCreditoReducer = state.creditosReducers.detalleCreditoReducer;

    return {
      operacionId: detalleCreditoReducer.credito.credito.operacionId,
      empresa: detalleCreditoReducer.credito.credito.empresa,
      saldoAdeudado: detalleCreditoReducer.credito.saldoAdeudado,
      mediosPagoDownloading: detalleCreditoReducer.mediosPagoDownloading,
      cuponSaldoDownloading: detalleCreditoReducer.cuponPagoSaldoDownloading,
    };
  });

const CreditoSaldoPendiente = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const {
    operacionId,
    empresa,
    saldoAdeudado,
    mediosPagoDownloading,
    cuponSaldoDownloading,
  } = useReduxSelector();

  const disableDownloads = mediosPagoDownloading || cuponSaldoDownloading;

  return (
    <div
      style={{
        padding: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <WarningSection variant="warning" marginBottom={12}>
        Tenés una deuda restante de ${saldoAdeudado}, generada por pagos fuera
        de término.
      </WarningSection>

      <CallToActionButton
        color="secondaryemphasis"
        label="Informar pago"
        marginBottom={12}
        onClick={() => {
          history.push(`/miscreditos/informarpago?operacionId=${operacionId}`);
        }}
      />
      <ButtonGroup color="primary" fullWidth style={{ minHeight: 52 }}>
        <Button
          onClick={() => {
            dispatch(getMediosDePago(empresa));
          }}
          disabled={disableDownloads}
          className={classes.outlinedsecondary}
          startIcon={
            mediosPagoDownloading ? (
              <CircularProgress
                size={18}
                thickness={4}
                style={{
                  alignSelf: "center",
                  marginRight: 5,
                  color: "#bdbdbd",
                }}
              />
            ) : (
              <MediosDePagoIcon />
            )
          }
        >
          {!mediosPagoDownloading && "Medios de pago"}
        </Button>
        <Button
          className={classes.outlinedsecondary}
          disabled={true}
          onClick={() => {
            dispatch(getNotaCancelacion(operacionId));
          }}
        >
          {"Nota de Cancelación"}
        </Button>
      </ButtonGroup>

      <div
        style={{
          paddingTop: 40,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <img alt="img-checked" src={IconSquaredCheck} />
        <Typography
          style={{
            marginTop: 24,
            fontSize: pxToRem(24),
            textAlign: "center",
            fontSeight: "bold",
            lineHeight: 1.25,
            color: "#663ea8",
          }}
        >
          Ya pagaste todas las cuotas de este crédito
        </Typography>
        <Typography
          style={{
            fontSize: pxToRem(16),
            lineHeight: 1.38,
            textAlign: "center",
            color: "#616161",
            marginLeft: "10%",
            marginRight: "10%",
            marginTop: 12,
            marginBottom: 36,
          }}
        >
          Para poder solicitar tu prenda y nota de cancelación, además de tus
          cuotas, tenés que haber saldado los intereses generados por pagos
          fuera de término.
        </Typography>
        <CallToActionButton
          disabled={disableDownloads}
          variant="outlined"
          color="secondary"
          label={
            cuponSaldoDownloading ? (
              <CircularProgress
                size={18}
                thickness={4}
                style={{
                  alignSelf: "center",
                  marginRight: 5,
                  color: "#bdbdbd",
                }}
              />
            ) : (
              "Ver cupón de pago"
            )
          }
          onClick={() => {
            dispatch(getCuponPagoSaldo(operacionId));
          }}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  modalText: {
    fontSize: pxToRem(16),
    lineHeight: 1.38,
    textAlign: "left",
    color: "#757575",
    paddingBottom: 18,
  },
  outlinedsecondary: {
    fontSize: pxToRem(14),
    fontWeight: "bold",
    lineHeight: "1.25",
    textTransform: "none",
    paddingLeft: pxToRem(6),
    paddingRight: pxToRem(4),
    borderWidth: pxToRem(2),
    borderRadius: pxToRem(8),
    borderColor: "#7644c9",
    color: "#7644c9",
    "&:hover": {
      backgroundColor: emphasize(theme.palette.primary.dark, 0.5),
      borderColor: emphasize(theme.palette.primary.dark, 0.5),
      color: "#ffffff",
    },
    "&:disabled": {
      color: "#bdbdbd",
      borderColor: "#bdbdbd",
      borderWidth: pxToRem(2),
    },
  },
}));

export default CreditoSaldoPendiente;
