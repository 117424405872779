import React, { useEffect } from "react";
import { useAuth0 } from "./useAuth";
import Lottie from "lottie-react-web";
import loading from "~assets/animations/loading.json";
import { useSelector } from "react-redux";

const useReduxSelector = () =>
  useSelector(state => ({
    nativeCredentials: state.nativeMessagesReducer.nativeCredentials
  }));

export const Callback = ({ location }) => {
  const { handleAuthentication } = useAuth0();
  const { nativeCredentials } = useReduxSelector();

  useEffect(() => {
    if (/access_token|id_token|error/.test(location.hash)) {
      handleAuthentication();
    }
  }, [handleAuthentication, location, nativeCredentials]);

  const style = {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "white"
  };

  return (
    <div style={style}>
      <div style={{ width: 60 }}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loading
          }}
        />
      </div>
    </div>
  );
};
