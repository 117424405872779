import { createActions, handleActions } from "redux-actions";
import { mockOfertas, mockSeguros } from "./mockData";

export const fetchIdentidad = (
  documento,
  sexo,
  documentoConyuge,
  sexoConyuge
) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchIdentidadInited());
      const responseTitular = await services.api.getIdentidad(documento, sexo);
      let responseConyuge;
      if (documentoConyuge && sexoConyuge)
        responseConyuge = await services.api.getIdentidad(
          documentoConyuge,
          sexoConyuge
        );
      dispatch(
        fetchIdentidadCompleted({
          titular: responseTitular,
          conyuge: responseConyuge
        })
      );
    } catch (error) {
      dispatch(fetchIdentidadFailed(error));
    }
  };
};

export const fetchAptoCredito = (
  canal,
  tipoCredito,
  documento,
  sexo,
  nombres,
  apellidos,
  estadoCivil,
  documentoConyuge,
  sexoConyuge,
  nombresConyuge,
  apellidosConyuge
) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchAptoCreditoInited());
      const request = {
        canal: canal,
        TipoCredito: tipoCredito,
        Documento: documento,
        Sexo: sexo,
        Nombres: nombres,
        Apellidos: apellidos,
        EstadoCivil: estadoCivil,
        DocumentoConyuge: documentoConyuge,
        SexoConyuge: sexoConyuge,
        NombresConyuge: nombresConyuge,
        ApellidosConyuge: apellidosConyuge
      };

      //const response = await services.api.postSolicitudNueva(request);
      const response = {
        id: 221157,
        aptoObtenido: true,
        aptoCredito: true,
        revisar: false
      };
      dispatch(fetchAptoCreditoCompleted(response));
    } catch (error) {
      if (
        error &&
        error.responseStatus &&
        error.responseStatus.errorCode === "GenericError-StatusCode-403"
      )
        error.responseStatus.errorCode = "AgenciaBloqueada";

      dispatch(fetchAptoCreditoFailed(error));
    }
  };
};

export const iniciarCalificacion = solicitudId => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(iniciarCalificacionInited());
      await services.api.postContinuarSolicitud({ Id: solicitudId });
      dispatch(iniciarCalificacionCompleted());
    } catch (error) {
      dispatch(iniciarCalificacionFailed(error));
    }
  };
};

export const fetchValorVehiculo = (codia, anio) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchValorVehiculoInited());
      const response = await services.api.getInfoautoCodiaPrecio(codia);
      const precio = response.precios.find(x => x.item1 == anio);
      dispatch(fetchValorVehiculoCompleted(precio));
    } catch (error) {
      dispatch(fetchValorVehiculoFailed(error));
      console.error("error", error);
    }
  };
};

export const informarVehiculo = () => {
  return async (dispatch, getState, services) => {
    const solicitudData = getState().creditosReducers.nuevaSolicitudReducer;
    const formData = {
      Id: solicitudData.solicitudId,
      Codia: solicitudData.vehiculoModelo && solicitudData.vehiculoModelo.value,
      Anio: solicitudData.vehiculoAnio,
      Es0Km: solicitudData.tipoCredito === 3 ? false : true, //ToDo: change when step is created
      TieneGNC: solicitudData.vehiculoTieneGNC,
      Uso: solicitudData.vehiculoUso,
      ValorDeCompra: solicitudData.valorVehiculo
    };
    try {
      dispatch(informarVehiculoInited());
      await services.api.postSolicitudAuto(formData);
      dispatch(informarVehiculoCompleted());
    } catch (error) {
      dispatch(informarVehiculoFailed(error));
    }
  };
};

export const fetchOfertas = () => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchOfertasInited());
      const response = mockOfertas[0];
      dispatch(fetchOfertasCompleted(response));
    } catch (error) {
      dispatch(fetchOfertasFailed());
    }
  };
};

export const fetchCotizaciones = () => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchCotizacionesInited());
      const response = mockSeguros;
      dispatch(fetchCotizacionesCompleted(response));
    } catch (error) {
      dispatch(fetchCotizacionesFailed());
    }
  };
};

const {
  nuevaSolicitud: {
    clear,
    setTipoCredito,
    clearTipoCredito,
    setCanal,
    clearCanal,
    setDni,
    clearDni,
    setSexo,
    clearSexo,
    setEstadoCivil,
    clearEstadoCivil,
    setDniConyuge,
    clearDniConyuge,
    setSexoConyuge,
    clearSexoConyuge,
    setQueresEvaluar,
    clearQueresEvaluar,
    setSolicitudIniciada,
    clearSolicitudIniciada,
    setSituacionLaboral,
    clearSituacionLaboral,
    setAntiguedadLaboral,
    clearAntiguedadLaboral,
    setProvincia,
    clearProvincia,
    setLocalidad,
    clearLocalidad,
    setCodigoPostal,
    clearCodigoPostal,
    setVehiculoMarca,
    clearVehiculoMarca,
    setVehiculoAnio,
    clearVehiculoAnio,
    setVehiculoModelo,
    clearVehiculoModelo,
    setVehiculoUso,
    clearVehiculoUso,
    setTieneGNC,
    clearTieneGNC,
    setValorVehiculo,
    clearValorVehiculo,
    setImporte,
    clearImporte,
    setPlazo,
    clearPlazo,
    setOferta,
    clearOferta,
    clearPoliza,
    setPoliza,
    setTelefono,
    clearTelefono,
    setFranjaHoraria,
    clearFranjaHoraria,
    setEmail,
    clearEmail,
    fetchIdentidadInited,
    fetchIdentidadCompleted,
    fetchIdentidadFailed,
    fetchValorVehiculoInited,
    fetchValorVehiculoCompleted,
    fetchValorVehiculoFailed,
    fetchOfertasInited,
    fetchOfertasCompleted,
    fetchOfertasFailed,
    fetchCotizacionesInited,
    fetchCotizacionesCompleted,
    fetchCotizacionesFailed,
    fetchAptoCreditoInited,
    fetchAptoCreditoCompleted,
    fetchAptoCreditoFailed,
    iniciarCalificacionInited,
    iniciarCalificacionCompleted,
    iniciarCalificacionFailed,
    informarVehiculoInited,
    informarVehiculoCompleted,
    informarVehiculoFailed,
    clearIdentidades
  }
} = createActions({
  nuevaSolicitud: {
    clear: () => ({}),
    setTipoCredito: value => ({ value }),
    clearTipoCredito: () => ({}),
    setCanal: value => ({ value }),
    clearCanal: () => ({}),
    setDni: value => ({ value }),
    clearDni: () => ({}),
    setSexo: value => ({ value }),
    clearSexo: () => ({}),
    setEstadoCivil: value => ({ value }),
    clearEstadoCivil: () => ({}),
    setDniConyuge: value => ({ value }),
    clearDniConyuge: () => ({}),
    setSexoConyuge: value => ({ value }),
    clearSexoConyuge: () => ({}),
    setQueresEvaluar: value => ({ value }),
    clearQueresEvaluar: () => ({}),
    setSolicitudIniciada: () => ({}),
    clearSolicitudIniciada: () => ({}),
    setSituacionLaboral: value => ({ value }),
    clearSituacionLaboral: () => ({}),
    setAntiguedadLaboral: value => ({ value }),
    clearAntiguedadLaboral: () => ({}),
    setProvincia: value => ({ value }),
    clearProvincia: () => ({}),
    setLocalidad: value => ({ value }),
    clearLocalidad: () => ({}),
    setCodigoPostal: value => ({ value }),
    clearCodigoPostal: () => ({}),
    setVehiculoMarca: value => ({ value }),
    clearVehiculoMarca: () => ({}),
    setVehiculoAnio: value => ({ value }),
    clearVehiculoAnio: () => ({}),
    setVehiculoModelo: value => ({ value }),
    clearVehiculoModelo: () => ({}),
    setVehiculoUso: value => ({ value }),
    clearVehiculoUso: () => ({}),
    setTieneGNC: value => ({ value }),
    clearTieneGNC: () => ({}),
    setValorVehiculo: value => ({ value }),
    clearValorVehiculo: () => ({}),
    setImporte: value => ({ value }),
    clearImporte: () => ({}),
    setPlazo: value => ({ value }),
    clearPlazo: () => ({}),
    setOferta: value => ({ value }),
    clearOferta: () => ({}),
    clearPoliza: () => ({}),
    setPoliza: value => ({ value }),
    setTelefono: value => ({ value }),
    clearTelefono: () => ({}),
    setFranjaHoraria: value => ({ value }),
    clearFranjaHoraria: () => ({}),
    setEmail: value => ({ value }),
    clearEmail: () => ({}),
    /////
    fetchIdentidadInited: () => ({}),
    fetchIdentidadCompleted: value => ({ value }),
    fetchIdentidadFailed: error => ({ error }),
    fetchValorVehiculoInited: () => ({}),
    fetchValorVehiculoCompleted: value => ({ value }),
    fetchValorVehiculoFailed: error => ({ error }),
    fetchOfertasInited: () => ({}),
    fetchOfertasCompleted: value => ({ value }),
    fetchOfertasFailed: error => ({ error }),
    fetchCotizacionesInited: () => ({}),
    fetchCotizacionesCompleted: value => ({ value }),
    fetchCotizacionesFailed: error => ({ error }),
    fetchAptoCreditoInited: () => ({}),
    fetchAptoCreditoCompleted: value => ({ value }),
    fetchAptoCreditoFailed: error => ({ error }),
    iniciarCalificacionInited: () => ({}),
    iniciarCalificacionCompleted: () => ({}),
    iniciarCalificacionFailed: error => ({ error }),
    informarVehiculoInited: () => ({}),
    informarVehiculoCompleted: () => ({}),
    informarVehiculoFailed: error => ({ error }),
    clearIdentidades: () => ({})
  }
});

const initialState = {
  tipoCreditoSelected: null,
  canalSelected: null,
  dni: null,
  sexoSelected: null,
  estadoCivilSelected: null,
  dniConyuge: null,
  sexoConyugeSelected: null,
  identidadLoading: false,
  identidadError: false,
  identidadPersonas: null,
  queresEvaluarSelected: null,
  aptoCreditoLoading: false,
  resultadoAptoCredito: null,
  aptoCreditoError: null,
  solicitudIniciada: false,
  solicitudId: null,
  iniciarCalificacionLoading: false,
  iniciarCalificacionError: null,
  situacionLaboral: null,
  antiguedadLaboral: null,
  provincia: null,
  localidad: null,
  codigoPostal: null,
  vehiculoMarca: { value: null, label: null },
  vehiculoAnio: null,
  vehiculoModelo: null,
  vehiculoUso: null,
  vehiculoTieneGNC: null,
  informarVehiculoLoading: false,
  informarVehiculoError: null,
  valorVehiculo: null,
  valorVehiculoLoading: false,
  valorInfoauto: null,
  importe: null,
  plazo: null,
  ofertas: null,
  ofertasLoading: false,
  ofertaElegida: null,
  cotizaciones: null,
  polizaElegida: null,
  cotizacionesLoading: false,
  contactoTelefono: null,
  contactoFranjaHoraria: null,
  contactoEmail: null,
  isForward: true
};

const nuevaSolicitudReducer = handleActions(
  {
    [clearIdentidades]: (state, action) => {
      return {
        ...state,
        dni: null,
        dniConyuge: null,
        sexoSelected: null,
        sexoConyugeSelected: null,
        estadoCivilSelected: null,
        queresEvaluarSelected: false,
        isForward: true
      };
    },
    [clear]: () => {
      return {
        ...initialState,
        isForward: true
      };
    },
    [setTipoCredito]: (state, action) => {
      return {
        ...state,
        tipoCreditoSelected: action.payload.value,
        isForward: true
      };
    },
    [clearTipoCredito]: (state, action) => {
      return {
        ...state,
        tipoCreditoSelected: null,
        isForward: false
      };
    },
    [setCanal]: (state, action) => {
      return {
        ...state,
        canalSelected: action.payload.value,
        isForward: true
      };
    },
    [clearCanal]: (state, action) => {
      return {
        ...state,
        canalSelected: null,
        isForward: true
      };
    },
    [setDni]: (state, action) => {
      return {
        ...state,
        dni: action.payload.value,
        isForward: true
      };
    },
    [clearDni]: (state, action) => {
      return {
        ...state,
        dni: null,
        isForward: true
      };
    },
    [setSexo]: (state, action) => {
      return {
        ...state,
        sexoSelected: action.payload.value,
        isForward: true
      };
    },
    [clearSexo]: (state, action) => {
      return {
        ...state,
        sexoSelected: null,
        isForward: true
      };
    },
    [setEstadoCivil]: (state, action) => {
      return {
        ...state,
        estadoCivilSelected: action.payload.value,
        isForward: true
      };
    },
    [clearEstadoCivil]: (state, action) => {
      return {
        ...state,
        estadoCivilSelected: null,
        isForward: true
      };
    },
    [clearDniConyuge]: (state, action) => {
      return {
        ...state,
        dniConyuge: null,
        isForward: true
      };
    },
    [setDniConyuge]: (state, action) => {
      return {
        ...state,
        dniConyuge: action.payload.value,
        isForward: true
      };
    },
    [setSexoConyuge]: (state, action) => {
      return {
        ...state,
        sexoConyugeSelected: action.payload.value,
        isForward: true
      };
    },
    [clearSexoConyuge]: (state, action) => {
      return {
        ...state,
        sexoConyugeSelected: null,
        isForward: true
      };
    },
    [setQueresEvaluar]: (state, action) => {
      return {
        ...state,
        queresEvaluarSelected: action.payload.value,
        isForward: true
      };
    },
    [clearQueresEvaluar]: (state, action) => {
      return {
        ...state,
        identidadError: false,
        queresEvaluarSelected: null,
        isForward: true
      };
    },
    [setSolicitudIniciada]: (state, action) => {
      return {
        ...state,
        solicitudIniciada: true,
        isForward: true
      };
    },
    [clearSolicitudIniciada]: (state, action) => {
      return {
        ...state,
        solicitudIniciada: false,
        isForward: true
      };
    },
    [clearSituacionLaboral]: (state, action) => {
      return {
        ...state,
        situacionLaboral: null,
        isForward: true
      };
    },
    [setSituacionLaboral]: (state, action) => {
      return {
        ...state,
        situacionLaboral: action.payload.value,
        isForward: true
      };
    },
    [clearAntiguedadLaboral]: (state, action) => {
      return {
        ...state,
        antiguedadLaboral: null,
        isForward: true
      };
    },
    [setAntiguedadLaboral]: (state, action) => {
      return {
        ...state,
        antiguedadLaboral: action.payload.value,
        isForward: true
      };
    },
    [clearProvincia]: (state, action) => {
      return {
        ...state,
        provincia: null,
        isForward: true
      };
    },
    [setProvincia]: (state, action) => {
      return {
        ...state,
        provincia: action.payload.value,
        isForward: true
      };
    },
    [setLocalidad]: (state, action) => {
      return {
        ...state,
        localidad: action.payload.value,
        isForward: true
      };
    },
    [clearLocalidad]: (state, action) => {
      return {
        ...state,
        localidad: null,
        isForward: true
      };
    },
    [setCodigoPostal]: (state, action) => {
      return {
        ...state,
        codigoPostal: action.payload.value,
        isForward: true
      };
    },
    [clearCodigoPostal]: (state, action) => {
      return {
        ...state,
        codigoPostal: null,
        isForward: true
      };
    },
    [setVehiculoMarca]: (state, action) => {
      return {
        ...state,
        vehiculoMarca: action.payload.value,
        isForward: true
      };
    },
    [clearVehiculoMarca]: (state, action) => {
      return {
        ...state,
        vehiculoMarca: { value: null, label: null },
        isForward: true
      };
    },
    [setVehiculoAnio]: (state, action) => {
      return {
        ...state,
        vehiculoAnio: action.payload.value,
        isForward: true
      };
    },
    [clearVehiculoAnio]: (state, action) => {
      return {
        ...state,
        vehiculoAnio: null,
        isForward: true
      };
    },
    [setVehiculoModelo]: (state, action) => {
      return {
        ...state,
        vehiculoModelo: action.payload.value,
        isForward: true
      };
    },
    [clearVehiculoModelo]: (state, action) => {
      return {
        ...state,
        vehiculoModelo: null,
        isForward: true
      };
    },
    [setVehiculoUso]: (state, action) => {
      return {
        ...state,
        vehiculoUso: action.payload.value,
        isForward: true
      };
    },
    [clearVehiculoUso]: (state, action) => {
      return {
        ...state,
        vehiculoUso: null,
        isForward: true
      };
    },
    [setTieneGNC]: (state, action) => {
      return {
        ...state,
        vehiculoTieneGNC: action.payload.value,
        isForward: true
      };
    },
    [clearTieneGNC]: (state, action) => {
      return {
        ...state,
        vehiculoTieneGNC: null,
        isForward: true
      };
    },
    [setValorVehiculo]: (state, action) => {
      return {
        ...state,
        valorVehiculo: action.payload.value,
        isForward: true
      };
    },
    [clearValorVehiculo]: (state, action) => {
      return {
        ...state,
        valorVehiculo: null,
        isForward: true
      };
    },
    [setImporte]: (state, action) => {
      return {
        ...state,
        importe: action.payload.value,
        isForward: true
      };
    },
    [clearImporte]: (state, action) => {
      return { ...state, importe: null, isForward: true };
    },
    [setPlazo]: (state, action) => {
      return { ...state, plazo: action.payload.value, isForward: true };
    },
    [clearPlazo]: (state, action) => {
      return { ...state, plazo: null, isForward: true };
    },
    [setOferta]: (state, action) => {
      return { ...state, ofertaElegida: action.payload.value, isForward: true };
    },
    [clearOferta]: (state, action) => {
      return { ...state, ofertaElegida: null, isForward: true };
    },
    [clearPoliza]: (state, action) => {
      return { ...state, polizaElegida: null, isForward: true };
    },
    [setPoliza]: (state, action) => {
      return { ...state, polizaElegida: action.payload.value, isForward: true };
    },
    [setTelefono]: (state, action) => {
      return {
        ...state,
        contactoTelefono: action.payload.value,
        isForward: true
      };
    },
    [clearTelefono]: (state, action) => {
      return {
        ...state,
        contactoTelefono: null,
        isForward: false
      };
    },
    [setFranjaHoraria]: (state, action) => {
      return {
        ...state,
        contactoFranjaHoraria: action.payload.value,
        isForward: true
      };
    },
    [clearFranjaHoraria]: (state, action) => {
      return {
        ...state,
        contactoFranjaHoraria: null,
        isForward: false
      };
    },
    [setEmail]: (state, action) => {
      return {
        ...state,
        contactoEmail: action.payload.value,
        isForward: true
      };
    },
    [clearEmail]: (state, action) => {
      return {
        ...state,
        contactoEmail: null,
        isForward: false
      };
    },
    [fetchIdentidadInited]: (state, action) => {
      return {
        ...state,
        identidadLoading: true,
        identidadPersonas: null,
        identidadError: false,
        isForward: true
      };
    },
    [fetchIdentidadCompleted]: (state, action) => {
      return {
        ...state,
        identidadLoading: false,
        identidadPersonas: action.payload.value,
        identidadError: false,
        isForward: true
      };
    },
    [fetchIdentidadFailed]: (state, action) => {
      return {
        ...state,
        identidadLoading: false,
        identidadPersonas: null,
        identidadError: true,
        isForward: true
      };
    },
    [fetchValorVehiculoInited]: (state, action) => {
      return {
        ...state,
        valorVehiculoLoading: true,
        valorInfoauto: null,
        isForward: true
      };
    },
    [fetchValorVehiculoCompleted]: (state, action) => {
      const precio = action.payload.value;
      const valorInfoauto = {
        min: precio.item2 * 0.5,
        max: precio.item2 * 1.5,
        precio: precio.item2
      };

      return {
        ...state,
        valorVehiculoLoading: false,
        valorInfoauto: valorInfoauto,
        isForward: true
      };
    },
    [fetchValorVehiculoFailed]: (state, action) => {
      return {
        ...state,
        valorVehiculoLoading: false,
        valorInfoauto: null,
        isForward: true
      };
    },
    [fetchOfertasInited]: (state, action) => {
      return {
        ...state,
        ofertasLoading: true,
        ofertas: null,
        isForward: true
      };
    },
    [fetchOfertasCompleted]: (state, action) => {
      return {
        ...state,
        ofertasLoading: false,
        ofertas: action.payload.value,
        isForward: true
      };
    },
    [fetchOfertasFailed]: (state, action) => {
      return {
        ...state,
        ofertasLoading: false,
        ofertas: null,
        isForward: true
      };
    },
    [fetchCotizacionesInited]: (state, action) => {
      return {
        ...state,
        polizasLoading: true,
        cotizaciones: null,
        isForward: true
      };
    },
    [fetchCotizacionesCompleted]: (state, action) => {
      return {
        ...state,
        polizasLoading: false,
        cotizaciones: action.payload.value,
        isForward: true
      };
    },
    [fetchCotizacionesFailed]: (state, action) => {
      return {
        ...state,
        polizasLoading: false,
        cotizaciones: null,
        isForward: true
      };
    },
    [fetchAptoCreditoInited]: (state, action) => {
      return {
        ...state,
        aptoCreditoLoading: true,
        resultadoAptoCredito: null,
        aptoCreditoError: null,
        isForward: true
      };
    },
    [fetchAptoCreditoCompleted]: (state, action) => {
      return {
        ...state,
        aptoCreditoLoading: false,
        resultadoAptoCredito: action.payload.value,
        aptoCreditoError: null,
        solicitudId: action.payload.value.id,
        isForward: true
      };
    },
    [fetchAptoCreditoFailed]: (state, action) => {
      const aptoCreditoError =
        action.payload.error && action.payload.error instanceof Error
          ? action.payload.error.message
          : action.payload.error;
      return {
        ...state,
        aptoCreditoLoading: false,
        resultadoAptoCredito: null,
        aptoCreditoError: aptoCreditoError,
        isForward: true
      };
    },
    [iniciarCalificacionInited]: (state, action) => {
      return { ...state, iniciarCalificacionLoading: true };
    },
    [iniciarCalificacionCompleted]: (state, action) => {
      return { ...state, iniciarCalificacionLoading: false };
    },
    [iniciarCalificacionFailed]: (state, action) => {
      return {
        ...state,
        iniciarCalificacionLoading: false,
        iniciarCalificacionError: action.payload.error
      };
    },
    [informarVehiculoInited]: (state, action) => {
      return { ...state, informarVehiculoLoading: true };
    },
    [informarVehiculoCompleted]: (state, action) => {
      return { ...state, informarVehiculoLoading: false };
    },
    [informarVehiculoFailed]: (state, action) => {
      return {
        ...state,
        informarVehiculoLoading: false,
        informarVehiculoError: action.payload.error
      };
    }
  },
  initialState
);

export default nuevaSolicitudReducer;
export {
  clear,
  setTipoCredito,
  clearTipoCredito,
  setCanal,
  clearCanal,
  setDni,
  clearDni,
  setSexo,
  clearSexo,
  setEstadoCivil,
  clearEstadoCivil,
  setDniConyuge,
  clearDniConyuge,
  setSexoConyuge,
  clearSexoConyuge,
  setQueresEvaluar,
  clearQueresEvaluar,
  setSolicitudIniciada,
  clearSolicitudIniciada,
  setSituacionLaboral,
  clearSituacionLaboral,
  setAntiguedadLaboral,
  clearAntiguedadLaboral,
  setProvincia,
  clearProvincia,
  setLocalidad,
  clearLocalidad,
  setCodigoPostal,
  clearCodigoPostal,
  setVehiculoMarca,
  clearVehiculoMarca,
  setVehiculoAnio,
  clearVehiculoAnio,
  setVehiculoModelo,
  clearVehiculoModelo,
  setVehiculoUso,
  clearVehiculoUso,
  setTieneGNC,
  clearTieneGNC,
  setValorVehiculo,
  clearValorVehiculo,
  setImporte,
  clearImporte,
  setPlazo,
  clearPlazo,
  setOferta,
  clearOferta,
  clearPoliza,
  setPoliza,
  setTelefono,
  clearTelefono,
  setFranjaHoraria,
  clearFranjaHoraria,
  setEmail,
  clearEmail,
  fetchIdentidadInited,
  fetchIdentidadCompleted,
  fetchIdentidadFailed,
  fetchValorVehiculoInited,
  fetchValorVehiculoCompleted,
  fetchValorVehiculoFailed,
  fetchOfertasInited,
  fetchOfertasCompleted,
  fetchOfertasFailed,
  fetchCotizacionesInited,
  fetchCotizacionesCompleted,
  fetchCotizacionesFailed,
  fetchAptoCreditoInited,
  fetchAptoCreditoCompleted,
  fetchAptoCreditoFailed,
  iniciarCalificacionInited,
  iniciarCalificacionCompleted,
  iniciarCalificacionFailed,
  informarVehiculoInited,
  informarVehiculoCompleted,
  informarVehiculoFailed,
  clearIdentidades
};
