import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "material-ui/LinearProgress";
import { transparent } from "material-ui/styles/colors";

import { useTheme } from "../../../libs/styles";

class LinearProgressLoading extends React.Component {
  render() {
    const theme = useTheme();

    if (this.props.isLoading) {
      return (
        <LinearProgress
          mode="indeterminate"
          color={theme.palette.accent1Color}
          style={{
            backgroundColor: transparent,
            zIndex: this.props.zIndex ? this.props.zIndex : 100,
            position: this.props.position ? this.props.position : "fixed",
            height: "4px",
            top: this.props.isFixedToTop ? 64 : "initial",
            width: this.props.width ? this.props.width : "100%"
          }}
        />
      );
    } else {
      return <div />;
    }
  }
}

LinearProgressLoading.propTypes = {
  width: PropTypes.number
};

export default LinearProgressLoading;
