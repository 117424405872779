export const SOLICITUDTABS_ISLOADING = "SOLICITUDTABS_ISLOADING";
export const SOLICITUDTABS_SETTABINDEX = "SOLICITUDTABS_SETTABINDEX";
export const SOLICITUDTABS_INIT = "SOLICITUDTABS_INIT";
export const TAREASCOUNT_CLEARED = "TAREASCOUNT_CLEARED";
export const CAMBIOSCOUNT_CLEARED = "CAMBIOSCOUNT_CLEARED";
export const solicitudTabsIsLoading = loading => ({
  type: SOLICITUDTABS_ISLOADING,
  payload: {
    isLoading: loading
  }
});

export const solicitudTabsSetTabIndex = index => ({
  type: SOLICITUDTABS_SETTABINDEX,
  payload: {
    tabIndex: index
  }
});

export const solicitudTabsInit = () => ({
  type: SOLICITUDTABS_INIT
});

export const tareasCountCleared = () => ({
  type: TAREASCOUNT_CLEARED
});

export function clearTareasCount() {
  return async (dispatch, getState, services) => {
    dispatch(tareasCountCleared());
  };
}
export const cambiosCountCleared = () => ({
  type: CAMBIOSCOUNT_CLEARED
});
export function clearCambiosCount() {
  return async (dispatch, getState, services) => {
    dispatch(cambiosCountCleared());
  };
}
