import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSolicitudIniciada } from "../../actions";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import Typography from "@material-ui/core/Typography";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import makeStyles from "@material-ui/styles/makeStyles";
import pxToRem from "~libs/pxToRem";
import InfoIcon from "~assets/ilustrations/illus_solic_info.svg";

const useStyles = makeStyles(theme => ({
  img: {
    width: pxToRem(242.5),
    align: "center"
  },
  text: {
    fontSize: pxToRem(16),
    lineHeight: 1.38,
    textAlign: "center",
    color: "#616161"
  },
  content: {
    marginTop: 60,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

const useReduxSelector = () =>
  useSelector(state => {
    const nuevaSolicitudReducer = state.creditosReducers.nuevaSolicitudReducer;
    return {
      identidadPersonas: nuevaSolicitudReducer.identidadPersonas
    };
  });

const AptoPendienteForm = () => {
  const dispatch = useDispatch();
  const { identidadPersonas } = useReduxSelector();
  const classes = useStyles();
  const cliente =
    identidadPersonas && identidadPersonas.titular
      ? `${identidadPersonas.titular.nombres} ${identidadPersonas.titular.apellido}`
      : "el cliente";
  const conyuge =
    identidadPersonas && identidadPersonas.conyuge
      ? `${identidadPersonas.conyuge.nombres} ${identidadPersonas.conyuge.apellido}`
      : null;
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            label={"Continuar"}
            onClick={() => () => dispatch(setSolicitudIniciada())}
          />
        </ContentSection>
      }
    >
      <div className={classes.content}>
        <img src={InfoIcon} className={classes.img} />
        <PrimaryTitle label={"Evaluación Pendiente"} marginBottom={20} />
        <Typography className={classes.text}>
          Para poder avanzar con la evaluación de {cliente}
          {conyuge ? ` y ${conyuge}` : ""}, necesitamos algunos datos más de la
          operación.
        </Typography>
      </div>
    </ContentSectionWithActions>
  );
};

export default AptoPendienteForm;

/*
        <Typography className={classes.text}>
          No pudimos evaluar al cliente en este momento. Podés continuar con la
          carga de solicitud y luego te informaremos el resultado!
        </Typography>
*/
