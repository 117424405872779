import {
  CONTACTOCOMERCIAL_ISLOADING,
  NEARESTOFFICE_FETCHRESULT_SUCCESS
} from "./actions";

const initialState = {
  isLoading: false,
  nombreSucursal: null,
  direccionSucursal: null,
  administrativo: null,
  telefonoAdministrativo: null,
  telefonoAdministrativoIsWhatsApp: false,
  telefonoAdministrativoFormat: null,
  comercial: null,
  telefonoComercial: null,
  telefonoComercialIsWhatsApp: false,
  telefonoComercialFormat: null
};

let PhoneNumber = require("awesome-phonenumber");

const contactoComercialReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACTOCOMERCIAL_ISLOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case NEARESTOFFICE_FETCHRESULT_SUCCESS:
      let telefonoAdministrativo = null;
      let telefonoAdministrativoIsWhatsApp = false;
      let telefonoAdministrativoFormat = "";
      if (
        action.payload.telefonoAdministrativo &&
        action.payload.telefonoAdministrativo.length > 0
      ) {
        telefonoAdministrativo = new PhoneNumber(
          action.payload.telefonoAdministrativo,
          "AR"
        );
        telefonoAdministrativoIsWhatsApp = telefonoAdministrativo.isMobile();
        telefonoAdministrativoFormat = telefonoAdministrativo
          .getNumber("national")
          .replace(/-/g, " ");
      }

      let telefonoComercial = null;
      let telefonoComercialIsWhatsApp = false;
      let telefonoComercialFormat = "";
      if (
        action.payload.telefonoComercial &&
        action.payload.telefonoComercial.length > 0
      ) {
        telefonoComercial = new PhoneNumber(
          action.payload.telefonoComercial,
          "AR"
        );
        telefonoComercialIsWhatsApp = telefonoComercial.isMobile();
        telefonoComercialFormat = telefonoComercial
          .getNumber("national")
          .replace(/-/g, " ");
      }

      return {
        ...state,
        ...action.payload,
        telefonoAdministrativoIsWhatsApp: telefonoAdministrativoIsWhatsApp,
        telefonoAdministrativoFormat: telefonoAdministrativoFormat,
        telefonoComercialIsWhatsApp: telefonoComercialIsWhatsApp,
        telefonoComercialFormat: telefonoComercialFormat
      };
    default:
      return state;
  }
};

export default contactoComercialReducer;
