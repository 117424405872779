import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLocalidad, clearLocalidad } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import LocalidadSelector from "~components/bussines/localidadSelector/LocalidadSelector";

const useReduxSelector = () =>
  useSelector(state => {
    const nuevaSolicitudReducer = state.creditosReducers.nuevaSolicitudReducer;
    return {
      provinciaId: nuevaSolicitudReducer.provincia
    };
  });

const LocalidadStep = () => {
  const dispatch = useDispatch();
  const { provinciaId } = useReduxSelector();

  useEffect(() => {
    dispatch(clearLocalidad());
  }, [dispatch]);

  const handleSelection = event => {
    dispatch(setLocalidad(event.target.value));
  };

  return (
    <ContentSectionWithActions>
      <PrimaryTitle label={"¿En qué localidad?"} marginBottom={60} />
      <LocalidadSelector
        label={"Ingresá o busca la localidad"}
        onSelected={handleSelection}
        provinciaId={provinciaId}
      />
    </ContentSectionWithActions>
  );
};

export default LocalidadStep;
