import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import pxToRem from "~libs/pxToRem";
import { clearTerminosYCondiciones, setTerminosYCondiciones } from "../actions";
import environment from "../../../../../../environment";
import terminosyCondicionesPath from "~components/app/terminosYCondiciones/terminosYCondiciones.md";
import ReactMarkdown from "react-markdown";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

const appData = environment.terminosYCondiciones;

const TerminosYCondicionesStep = () => {
  const dispatch = useDispatch();
  const [textoTerminos, setTextoTerminos] = useState("");
  useEffect(() => {
    const func = async () => {
      const response = await fetch(terminosyCondicionesPath);
      const text = await response.text();
      setTextoTerminos(text);
    };
    func();
  }, []);

  useEffect(() => {
    dispatch(clearTerminosYCondiciones());
  }, []);
  const handleSelection = () => {
    dispatch(setTerminosYCondiciones());
  };

  return (
    <>
      <ContentSection>
        <div style={{ marginBottom: 80 }}>
          <ReactMarkdown
            source={
              "#### V" +
              appData.version +
              " - Vigencia: " +
              appData.fecha +
              "  \n\n" +
              textoTerminos
            }
          />
        </div>
      </ContentSection>

      <div
        style={{
          padding: pxToRem(24),
          width: "100%",
          maxWidth: pxToRem(500),
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          bottom: 0,
          boxSshadow: " 0 -2px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundImage:
            "linear-gradient(to bottom, rgb(249, 247, 252, 0.5),rgb(249, 247, 252, 0.7), rgb(249, 247, 252, 1))"
        }}
      >
        <CallToActionButton
          label="Aceptar términos"
          onClick={handleSelection}
        />
      </div>
    </>
  );
};

export default TerminosYCondicionesStep;
