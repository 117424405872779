import {
  CONTACTOFORM_ISLOADING,
  CONTACTOFORM_INIT,
  CONTACTOFORM_LOAD,
  CONTACTOFORM_CONTACTOS_SAVED,
  SET_CONTACTOFORM_COMMITED
} from "./actions";

const initialState = {
  isLoading: false,
  telefonos: [],
  email: null,
  stepCommited: false
};

export default function contactoForm(state = initialState, action) {
  switch (action.type) {
    case CONTACTOFORM_INIT:
      return initialState;

    case CONTACTOFORM_ISLOADING:
      let isLoading = action.payload.isLoading;
      return { ...state, isLoading };

    case CONTACTOFORM_LOAD:
      return {
        ...state,
        ...action.payload.data,
        isLoading: false
      };

    case CONTACTOFORM_CONTACTOS_SAVED:
      let email = action.payload.email;
      if (email && email.value.length) email.disabled = true;

      return {
        ...state,
        telefonos: action.payload.telefonos.map(t => {
          t.disabled = t.numero.length > 0;
          return t;
        }),
        email: email
      };
    case SET_CONTACTOFORM_COMMITED:
      return {
        ...state,
        stepCommited: action.payload.commited
      };
    default:
      return state;
  }
}
