import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import qs from "query-string";
import useLocationRef from "~components/app/useLocationRef";

import ProcessLayout from "~components/styled/layouts/processLayout/ProcessLayout";

import DniStep from "./steps/DniStep";
import EmailStep from "./steps/EmailStep";
import NombreStep from "./steps/NombreStep";
import TelefonoStep from "./steps/TelefonoStep";
import ConceptoPagoStep from "./steps/ConceptoPagoStep";
import MedioDePagoStep from "./steps/MedioDePagoStep";
import EntidadDePagoStep from "./steps/EntidadDePagoStep";
import FechaDePagoStep from "./steps/FechaDePagoStep";
import ComentarioStep from "./steps/ComentarioStep";
import FinalStep from "./steps/finalStep/FinalStep";
import ComprobanteStep from "./steps/ComprobanteStep";
import ImporteStep from "./steps/ImporteStep";

import { clear, setOperacionId } from "./actions";

const steps = {
  // steps must be string

  conceptoPago: "concepto",
  medioDePago: "medio",
  entidadDePago: "entidad",
  fechaDePago: "fecha",
  comprobanteAdjunto: "comprobante",
  dni: "dni",
  nombreYApellido: "nombre",
  telefono: "telefono",
  email: "email",
  comentario: "comentario",
  final: "resultado",
  importe: "importe"
};

const BASE_URL = "/miscreditos/informarpago";
const FIRST_STEP = steps.conceptoPago;

const useReduxSelector = () =>
  useSelector(state => {
    const informarPagoReducer = state.creditosReducers.informarPagoReducer;

    return {
      nombreYApellido: informarPagoReducer.nombreYApellido,
      dni: informarPagoReducer.dni,
      telefono: informarPagoReducer.telefono,
      email: informarPagoReducer.email,
      conceptoPago: informarPagoReducer.conceptoPago,
      medioDePago: informarPagoReducer.medioDePago,
      entidadDePago: informarPagoReducer.entidadDePago,
      fechaDePago: informarPagoReducer.fechaDePago,
      comentario: informarPagoReducer.comentarioCompleted,
      comprobanteAdjunto: informarPagoReducer.comprobanteAdjunto,
      importe: informarPagoReducer.importe,
      isForward: informarPagoReducer.isForward
    };
  });

const InformarPago = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { position = FIRST_STEP } = useParams();
  const location = useLocation();
  const { operacionId } = qs.parse(location.search);

  useEffect(() => {
    dispatch(clear());

    return function cleanup() {
      dispatch(clear());
    };
  }, [dispatch]);

  useEffect(() => {
    if (operacionId) dispatch(setOperacionId(operacionId));
  }, [operacionId]);

  const currentState = useReduxSelector();

  const currentStep = useCurrentStep(currentState, position);

  return (
    <ProcessLayout
      title={"Informar pago"}
      progress={(currentStep.order * 100) / Object.keys(steps).length} // porcentage (0-100)
      onBackClick={() => {
        if (position !== steps.final) history.goBack();
        else history.push("/");
      }}
      onCloseClick={() => {
        history.push("/");
      }}
      isForward={currentState.isForward}
    >
      {position === steps.conceptoPago && <ConceptoPagoStep />}
      {position === steps.medioDePago && <MedioDePagoStep />}
      {position === steps.entidadDePago && <EntidadDePagoStep />}
      {position === steps.fechaDePago && <FechaDePagoStep />}
      {position === steps.dni && <DniStep />}
      {position === steps.email && <EmailStep />}
      {position === steps.nombreYApellido && <NombreStep />}
      {position === steps.telefono && <TelefonoStep />}
      {position === steps.comentario && <ComentarioStep />}
      {position === steps.comprobanteAdjunto && <ComprobanteStep />}
      {position === steps.importe && <ImporteStep />}
      {position === steps.final && <FinalStep />}
    </ProcessLayout>
  );
};

export default InformarPago;

const useCurrentStep = (currentState, position) => {
  const history = useHistory();
  const locationRef = useLocationRef();

  const validSteps = getValidSteps(currentState);

  const isValidStep = validSteps.find(x => x.index === position) ? true : false;

  const currentStep = validSteps.slice().pop();

  useEffect(() => {
    const stepUrl = `${BASE_URL}`;
    // if not valid step, go to first step.
    if (!isValidStep) {
      history.replace(stepUrl);
    }
  }, [history, isValidStep]);

  useEffect(() => {
    const stepUrl = `${BASE_URL}/${currentStep.index}`;
    const isThisUrl = locationRef.current.pathname === stepUrl;
    const isFirstStep =
      locationRef.current.pathname === `${BASE_URL}` &&
      currentStep.index === FIRST_STEP;

    if (!isThisUrl && !isFirstStep && isValidStep) history.push(stepUrl);
  }, [isValidStep, history, currentStep.index]);

  return currentStep;
};

const getValidSteps = ({
  nombreYApellido,
  dni,
  telefono,
  email,
  conceptoPago,
  medioDePago,
  entidadDePago,
  fechaDePago,
  comentario,
  comprobanteAdjunto,
  importe
}) => {
  const dniStep = step(steps.dni, !dni);
  const emailStep = step(steps.email, !email);
  const nombreYApellidoStep = step(steps.nombreYApellido, !nombreYApellido);
  const telefonoStep = step(steps.telefono, !telefono);
  const conceptoPagoStep = step(steps.conceptoPago, !conceptoPago);
  const medioDePagoStep = step(steps.medioDePago, !medioDePago);
  const entidadDePagoStep = step(
    steps.entidadDePago,
    !entidadDePago && medioDePago !== "0"
  );
  const fechaDePagoStep = step(steps.fechaDePago, !fechaDePago);
  const comentarioStep = step(steps.comentario, !comentario);
  const comprobanteAdjuntoStep = step(
    steps.comprobanteAdjunto,
    !comprobanteAdjunto
  );
  const importeStep = step(steps.importe, !importe);
  const finalStep = step(steps.final, true);
  //set order of steps
  conceptoPagoStep
    .next(fechaDePagoStep)
    .next(medioDePagoStep)
    .next(entidadDePagoStep)
    .next(importeStep)
    .next(comprobanteAdjuntoStep)
    .next(dniStep)
    .next(nombreYApellidoStep)
    .next(telefonoStep)
    .next(emailStep)
    .next(comentarioStep)
    .next(finalStep);
  return conceptoPagoStep.run();
};

const step = (index, isThisStep) => {
  let next = null;
  let order = 1;

  return {
    index,
    order,
    next(step) {
      if (step) next = { ...step, order: this.order + 1 };
      return next;
    },
    run() {
      return isThisStep ? [this] : [this, ...(next && next.run())];
    }
  };
};
