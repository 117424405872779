import React from "react";
import pxToRem from "~libs/pxToRem";
import CardButton from "~components/styled/cardButton/CardButton";
import Typography from "@material-ui/core/Typography";
import ColorLabel from "~components/styled/colorLabel/ColorLabel";
import Ellipse from "~components/styled/ellipse/Ellipse";
import ContentLoader from "react-content-loader";
import moment from "moment";

const SolicitudCardButton = ({
  solicitud,
  marginBottom,
  onClick,
  isLoading
}) => {
  const labelStyle = getSituacionStyle(solicitud ? solicitud.situacion : null);
  return (
    <CardButton
      marginBottom={marginBottom}
      onClick={onClick}
      color={labelStyle.color}
    >
      {isLoading && renderLoadingCard()}
      {!isLoading && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "start",
            minHeight: pxToRem(136),
            flexGrow: 1,
            paddingTop: pxToRem(20),
            paddingRight: pxToRem(20),
            paddingBottom: pxToRem(20),
            paddingLeft: pxToRem(10) //IF no color passed, set this to 20
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography
              variant="caption"
              noWrap
              style={{
                fontSize: pxToRem(16),
                fontWeight: "bold",
                lineHeight: 1.38,
                textAlign: "left",
                paddingRight: pxToRem(8)
              }}
            >
              {`${solicitud.nombre} ${solicitud.apellido}`}
            </Typography>
            <span style={{ display: "flex", alignItems: "center" }}>
              {solicitud.tareasPendientes > 0 && (
                <Ellipse badgeContent={solicitud.tareasPendientes} />
              )}
              <ColorLabel
                text={labelStyle.label}
                variant={labelStyle.variant}
              />
            </span>
          </div>
          <div
            style={{
              display: "flex",
              paddingTop: pxToRem(8)
            }}
          >
            <Typography
              style={{
                fontSize: pxToRem(14),
                fontWeight: 600,
                lineHeight: 1.36,
                textAlign: "left",
                color: "#757575"
              }}
            >
              {`$${solicitud.importeNetoAPercibir} en ${solicitud.plazo} cuotas`}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              paddingTop: pxToRem(6)
            }}
          >
            <Typography
              noWrap
              style={{
                fontSize: pxToRem(12),
                fontWeight: 600,
                lineHeight: 1.33,
                textAlign: "left",
                color: "#9e9e9e"
              }}
            >
              {solicitud.modelo}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "auto"
            }}
          >
            <Typography
              style={{
                fontSize: pxToRem(12),
                fontWeight: 600,
                lineHeight: 1.33,
                textAlign: "left",
                color: "#bdbdbd",
                paddingRight: pxToRem(3)
              }}
            >
              {`Emisión:`}
            </Typography>
            <Typography
              style={{
                fontSize: pxToRem(12),
                fontWeight: 600,
                lineHeight: 1.33,
                textAlign: "left",
                color: "#9e9e9e",
                paddingRight: pxToRem(23)
              }}
            >
              {moment(moment.utc(solicitud.fechaAltaPedido).local()).fromNow()}
            </Typography>
            <Typography
              style={{
                fontSize: pxToRem(12),
                fontWeight: 600,
                lineHeight: 1.33,
                textAlign: "left",
                color: "#bdbdbd",
                paddingRight: pxToRem(3)
              }}
            >
              {`ID:`}
            </Typography>
            <Typography
              style={{
                fontSize: pxToRem(12),
                fontWeight: 600,
                lineHeight: 1.33,
                textAlign: "left",
                color: "#9e9e9e"
              }}
            >
              {` #${solicitud.id}`}
            </Typography>
          </div>
        </div>
      )}
    </CardButton>
  );
};

export default SolicitudCardButton;

const renderLoadingCard = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        paddingTop: pxToRem(20),
        paddingRight: pxToRem(20),
        paddingBottom: pxToRem(20),
        paddingLeft: pxToRem(10) //IF no color passed, set this to 20
      }}
    >
      <ContentLoader
        height={96}
        width={300}
        speed={1}
        style={{ height: 96 }}
        primaryColor="#f7f8ff"
        secondaryColor="#B9A7D7"
      >
        <rect x="0" y="3" rx="3" ry="3" width="200" height="16" />
        <rect x="225" y="0" rx="3" ry="3" width="75" height="22" />
        <rect x="0" y="28" rx="3" ry="3" width="180" height="14" />
        <rect x="0" y="48" rx="3" ry="3" width="180" height="14" />
        <rect x="0" y="84" rx="3" ry="3" width="65" height="12" />
        <rect x="100" y="84" rx="3" ry="3" width="80" height="12" />
      </ContentLoader>
    </div>
  );
};

const getSituacionStyle = situacion => {
  const enAnalisis = [
    "En_Espera_De_Analisis",
    "En_Analisis_Asignada",
    "Informes_Pendientes"
  ];
  const rechazada = [
    "Rechazado",
    "Rechazado_Definitivamente",
    "Revision_Rechazo_Solicitado",
    "Rechazado_Informe_Veraz",
    "Rechazado_Identidad_Veraz"
  ];

  const otros = "#c7f2fa";
  const info = "#fff7cc";
  const warning = "#f17f00";
  const success = "#d2f6e2";
  const danger = "#ffe0e0";
  if (!situacion)
    return {
      label: "Sin Situacion",
      variant: "",
      color: "#e0e0e0"
    };
  return enAnalisis.includes(situacion)
    ? {
        label: "En Análisis",
        variant: "info",
        color: info
      }
    : rechazada.includes(situacion)
    ? {
        label: "Rechazada",
        variant: "danger",
        color: danger
      }
    : situacion === "Con_Tareas_Previas_A_Analisis"
    ? {
        label: "Falta Doc",
        variant: "warning",
        color: warning
      }
    : situacion === "Aprobado"
    ? {
        label: "Aprobada",
        variant: "success",
        color: success
      }
    : situacion === "Prenda_Confeccionada"
    ? {
        label: "Con Prenda",
        variant: "success",
        color: success
      }
    : situacion === "Carga_Incompleta" ||
      situacion === "Revision_Rechazo_Habilitado"
    ? {
        label: "Incompleta",
        variant: "otros",
        color: otros
      }
    : situacion === "Liquidada"
    ? {
        label: "Falta Prenda",
        variant: "otros",
        color: otros
      }
    : {
        label: "En Análisis",
        variant: "info",
        color: info
      };
};
