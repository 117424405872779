const lang_es = {
  forms: {
    canalForm: "canalForm",
    contactoForm: "contactoForm",
    identidadForm: "identidadForm",
    actividadForm: "actividadForm",
    direccionForm: "direccionForm",
    carForm: "carForm",
    amountForm: "amountForm",
    seguroForm: "seguroForm",
    resultadoForm: "resultadoForm"
  },
  mainMenu: {
    menuLabel: "Menú",
    exitLabel: "Salir"
  },
  bottomNav: {
    backButton: "Regresar",
    nextButton: "Continuar",
    finishButton: "Finalizar"
  },
  common: {
    invalidFormError: "Hay campos inválidos o incompletos en el formulario"
  },
  contactoNameAndEmail: {
    emailLabel: "Email",
    fullnameLabel: "Nombre y apellido",
    invalidNameMessage: "Debe completar el nombre"
  },
  horarioContactoSelector: {
    horarioContactoSelectorLabel: "Disponibilidad",
    allDay: "Todo el día",
    morning: "09-12 hs",
    afternoon: "12-18 hs",
    night: "18-22 hs"
  },
  tipoContactoSelector: {
    tipoContactoSelectorLabel: "Contacto",
    holder: "Particular",
    job: "Laboral",
    partner: "Conyuge",
    parent: "Padre/Madre",
    child: "Hijo/a",
    sibling: "Hermano/a",
    family: "Familiar",
    friend: "Amigo",
    neighbour: "Vecino",
    other: "Otro"
  },
  seguroTable: {
    companyLabel: "Compañía",
    tcLabel: "3ros completo",
    trLabel: "Todo riesgo"
  },
  seguroCards: {
    selectLabel: "Elegir",
    backLabel: "Volver"
  },
  stickyViewer: {
    nextLabel: "Siguiente",
    saveLabel: "Guardar"
  },
  emailField: {
    invalidEmailMessage: "La dirección de correo electrónico no es válida",
    defaultFloatingLabel: "Email"
  },
  phoneField: {
    isRequiredMessage: "Este campo es obligatorio",
    invalidAreaMessage: "El código de área no es válido",
    invalidFieldMessage: "Faltan completar dígitos",
    phoneTypeLabel: "Tipo",
    phoneTypes: {
      mobile: "Móvil",
      landline: "Fijo"
    },
    phoneLabel: "Teléfono"
  },
  contactoForm: {
    title: "Contactos",
    addContactLabel: "Agregar contacto",
    holderLabel: "Particular"
  },
  topBar: {
    menuProfile: "Perfil",
    menuOptions: "Opciones",
    menuExit: "Salir",
    menuTooltipLabel: "Menú",
    notificationsTooltipLabel: "Notificaciones"
  },
  loginForm: {
    title: "Inicia Sesión",
    recoveryPasswordTitle: "Olvidé mi contraseña",
    emailSendedTitle: "Email enviado correctamente",
    emailLabel: "Email",
    passwordLabel: "Contraseña",
    forgotPasswordLabel: "¿ Olvidaste tu contraseña ?",
    submitLabel: "Entrar",
    recoveryPasswordLabel: "Obtener nueva contraseña",
    createAcountLabel: "¡Registrá tu Agencia!",
    backToLogin: "volver al inicio de sesión"
  },
  recoverPasswordForm: {
    title: "Nueva contraseña",
    passwordTitle: "Nueva contraseña",
    passwordRepeatTitle: "Confirmar nueva contraseña",
    save: "Confirmar",
    gotoLoginLabel: "Iniciar Sesión"
  },
  solicitudCreditoSecuence: {
    identidadForm: "Identidad",
    actividadForm: "Actividad",
    contactoForm: "Contactos",
    direccionForm: "Ubicación",
    autoForm: "Vehículo",
    seguroForm: "Seguros",
    resultadoForm: "Respuesta",
    preventNavigationMessage:
      "¿Está seguro que desea cancelar la solicitud actual?",
    preventLeavingMessage:
      "Estas saliendo de la aplicación, ¿Está seguro que desea cancelar la solicitud actual?"
  },
  solicitudCredito: {
    title: ""
  },
  identidadForm: {
    title: "",
    dniInput_label: "DNI*",
    dniInput_hinText: "DNI",
    lastNameInput_label: "Apellido (debe​ ​coincidir​ ​con​ DNI)*",
    lastNameInput_hinText: "Apellido",
    nameInput_label: "Nombre (debe​ ​coincidir​ ​con​ DNI)*",
    nameInput_hinText: "Nombre",
    genreRadio_label: "Sexo*",
    genreOption1_label: "Masculino",
    genreOption2_label: "Femenino",
    submitButton_label: "SIGUIENTE",
    dniInput_lenghError: "El DNI debe contener entre 7 y 8 caracteres",
    lastNameInput_lenghError:
      "El Apellido debe contener entre 2 y 50 caracteres, no se permiten digitos numéricos",
    nameInput_lenghError:
      "El Nombre debe contener entre 2 y 50 caracteres, no se permiten digitos numéricos",
    dniInput_hover:
      "Tamaño debe ser igual a 7 u 8 caracteres. Solo dígitos númericos",
    requiredErrorLabel: "Este campo es requerido"
  },
  actividadForm: {
    ingresosInput_label: "Ingreso Mensual del Cliente *",
    ingresosInput_error: "Ingrese total de ingresos percibidos",
    tipoEmpleoInput_label: "Actividad declarada"
  },
  autoForm: {
    carForm_gncCheckBox_label: "Tiene GNC",
    title: "Consulta Infoauto",
    noMatchFound_label: "No se encontraron coincidencias",
    purchaseValue: "Valor del auto",
    submitButton_label: "SIGUIENTE",
    iva: "Condición ante IVA",
    creditAmount: "Importe del préstamo*",
    floatingLabelMarcas: "Marca*",
    floatingLabelYears: "Año*",
    floatingLabelModelos: "Modelo*",
    hintTextAutocomplete: "Escribe aquí para filtrar",
    requiredErrorLabel: "Este campo es requerido",
    minCreditAmount_errorLabel: "Mínimo Requerido: ",
    maxCreditAmount_errorLabel: "Máximo disponible: ",
    marca_floatingLabelText: "Marca",
    year_floatingLabelText: "Año",
    model_floatingLabelText: "Modelo",
    useType_particularLabel: "Particular",
    useType_comercialLabel: "Comercial",
    plazos_floatingLabelText: "Plazo"
  },
  canalForm: {
    submitButtonLabel: "Iniciar Solicitud",
    title: "Solicitud - Selección de Agencia",
    hintTextAutocompleteCanales: "Escribe aquí para filtrar",
    floatingLabelTextAutocompleteCanales: "Seleccioná una Agencia",
    hintLabel: "Selecciona una Agencia para comenzar una nueva solicitud",
    hintLabel2: "Canal",
    hintLabel3: "No tiene canales vinculados"
  },
  conyugeForm: {
    conyugeDniInput_label: "DNI del Cónyuge *",
    conyugeDniInput_hinText: "DNI",
    conyugenameInput_label: "Nombre",
    conyugelastNameInput_label: "Apellido (como en el DNI) *",
    conyugelastNameInput_hinText: "Apellido",
    ingresosConyugeInput_label: "Ingreso Mensual del Cónyuge *",
    maritalStatusInput_label: "Estado Civil *",
    maritalStatusOption_married: "Casado",
    maritalStatusOption_single: "Soltero",
    maritalStatusOption_concubinage: "En concubinato",
    maritalStatusOption_widower: "Viudo",
    maritalStatusOption_divorced: "Divorciado",
    requiredErrorLabel: "Este campo es requerido",
    flatingLabelTextName: "Nombre (como en el DNI) *"
  },
  direccionForm: {
    provinceInput_label: "Provincia *",
    cityInput_label: "Localidad *",
    addressInput_label: "Dirección *",
    addressInput_hinText: "Coincidente con servicio",
    addressInput_hint: "Debe ser la misma que la del servicio.",
    postalCodeInput_label: "Código Postal *",
    requiredErrorLabel: "Este campo es requerido",
    streetInput_maxLengthErrorText: "Tamaño máximo sobrepasado",
    streetInput_minLengthErrorText:
      "El tamaño de la dirección debe ser mayor a 1 caracter"
  },
  preferenceCarForm: {
    hintTextAutocomplete: "Escribe aquí para filtrar",
    preferenceBrands: "Marcas de Preferencia"
  },
  calculador: {
    amountInput_label: "Monto del Crédito",
    anioSelectFieldValidError: "Selecciona el año del vehículo",
    plazosSelectFieldValidError: "Selecciona el plazo",
    amountInput_error: "Ingresa un valor mayor a 0",
    footer: `Los datos expuestos son estimativos y no tiene carácter contractual, pueden variar en función de la calificación del titular. Monto y aprobación del préstamo sujeto a análisis crediticio al momento de solicitarlo. El monto de cuota no incluye el valor del seguro automotor ni gastos de otorgamiento del préstamo.TNA`,
    formTitle: "Simular Préstamo",
    plazos_floatingLabelText: "Plazo",
    anio_floatingLabelText: "Año del vehículo",
    cuotaPromedio: "Cuota Promedio",
    primeraCuota: "Primera Cuota",
    anioVehiculo: "Año Vehículo",
    ultimaCuota: "Ultima Cuota",
    capital: "Capital",
    plazo: "Plazo",
    calcular: "Calcular",
    volver: "Volver"
  },
  resultadoForm: {
    termsAndConditionsError:
      "Debes aceptar los términos y condiciones para continuar",
    provisonalAprovedTitle: "Aprobado Provisorio",
    documentationButtonLabel: "Documentación a presentar",
    preAprovedResultCardTitle1: "Prestamo",
    preAprovedResultCardTitle2: "Preaprobado!",
    preAprovedResultCardBody:
      "Un representante se comunicará con vos en breve.",
    aprovedResultCardTitle: "Aprobado Automático",
    termsAndConditions: "Términos y condiciones",
    disapprovedResultCardTitle: "No califica por política crediticia",
    disapprovedResultCardBody:
      "La solicitud ha sido rechazada por informe comercial negativo. Si el titular tiene un descargo, comuniquese con su comercial para que le informe los pasos a seguir",
    reportsPendingResultCardTitle1: "Informes",
    reportsPendingResultCardTitle2: "Pendientes",
    reportsPendingResultCardBody:
      "Un representante se comunicará con vos en breve.",
    reportsPendingResultCardDescriptionLink: "¿Qué significa esto?",
    sinRecibo: "Sin demostración de ingresos",
    noIdentityValidationResultTitle:
      "No se ha podido verificar la identidad. Verifica los datos del titular y/o conyuge."
  },
  signupAgenciaSecuence: {
    agenciaForm: "Agencia",
    vehiculoForm: "Vehículo",
    direccionForm: "Ubicación",
    ccontactoForm: "Contacto",
    tycForm: "Term. y Condiciones",
    resultadoForm: "Resultado",
    preventNavigationMessage:
      "¿Está seguro que desea cancelar la carga actual?",
    preventLeavingMessage:
      "Estas saliendo de la aplicación, ¿Está seguro que desea cancelar la carga actual?"
  },
  signupAgencia: {
    title: "Alta de Agencia"
  },
  agenciaForm: {
    title: "Alta de Agencia",
    titularInput_label: "Titular",
    nombreInput_label: "Nombre Agencia",
    cuiInput_label: "CUIT/L",
    submitButton_label: "SIGUIENTE",
    cuiInput_lenghError: "El CUIT/L debe contener entre 10 y 11 caracteres",
    titularInput_lenghError:
      "El titular debe contener entre 2 y 50 caracteres, no se permiten digitos numéricos",
    nombreInput_lenghError:
      "El Nombre de la Agencia debe contener entre 2 y 50 caracteres, no se permiten digitos numéricos",
    cuiInput_hover:
      "Tamaño debe ser igual a 10-11 caracteres. Solo dígitos númericos",
    requiredErrorLabel: "Este campo es requerido"
  },
  SolicitudTareas: {
    EmptyMessage: "No hay tareas.",
    PendingEmptyMessage: "No hay tareas pendientes."
  },
  ListaSolicitudes: {
    EmptyMessage: "Ninguna solicitud corresponde a la búsqueda ingresada."
  },
  ChangeRequestsList: {
    EmptyMessage: "No hay solicitudes de cambio."
  },
  tarea: {
    title: "Tarea"
  },
  uploadFileTask: {
    error: "Debes Seleccionar entre 1 y 5 imagenes",
    fileInputLabel: "Selecciona las imágenes",
    commentsInputHintText: "Escribe tu comentario",
    commentsInputLabel: "Comentarios"
  },
  finalView: {
    Aprobado: {
      info: "",
      title: "El crédito ha sido aprobado!*",
      footer:
        "*Sujeto a aprobación del call back \n" +
        "**si los datos ingresados no son verificados o los mismos no son correctos, la solicitud podría sufrir modificaciones e inclusive quedar sin efecto la aprobación"
    },
    Provisorio: {
      infoWithoutDoc:
        "Un analista de créditos se comunicará a la brevedad con el titular para verificar los datos ingresados y determinará la viabilidad de la operación.Si es necesario solicitará datos, documentación o garantes para reforzar la misma. \n",
      infoWithDoc:
        "Un analista de créditos se comunicará a la brevedad con el titular para verificar los datos ingresados, si no hay diferencias en los datos ingresados y los corroborados, el préstamos quedará a la espera de la presentación y aceptación de la documentación indicada para poder ser aprobado y continuar con la solicitud de préstamo.",
      title: "Solicitud enviada Correctamente",
      body:
        "Un analista de créditos se comunicará a la brevedad con el titular para verificar los datos ingresados y determinará la viabilidad de la operación. Si es necesario solicitará datos, documentación o garantes para reforzar la misma. Recibirá una notificación cuando esto haya sucedido indicándole cómo proseguir."
    },
    Pendiente: {
      info:
        "Lo sentimos, ocurrió un error inesperado y no hemos podido validar la viabilidad del crédito aún, pero no se preocupe, confirme la solicitud y seguiremos intentándolo."
    }
  },
  messages: {
    savedSuccessfully: "Guardado correctamente"
  },
  pedidoCambioTypeSelector: {
    plazoYmontoLabel: "Plazo y Monto",
    fechaNacimientoYnombreLabel: "Fec Nac y Nombre",
    estadoCivilLabel: "Estado Civil",
    domicilioLabel: "Domicilio",
    datosContactoLabel: "Datos de Contacto",
    vehiculoLabel: "Vehículo",
    suguroCoberturaLabel: "Seguro/Cobertura",
    nothingLabel: "Presiona para seleccionar",
    label: "Tipo de Cambio"
  }
};

export default lang_es;
