import React from "react";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@material-ui/styles";

import Typography from "@material-ui/core/Typography";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import WrongIcon from "~assets/ilustrations/illus_state-wrong.svg";
import TengoUnProblema from "~components/bussines/tengoUnProblema/TengoUnProblema";
const Error = ({ onClick }) => {
  const classes = useStyles();
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton label={"Continuar"} onClick={onClick} />
          <TengoUnProblema />
        </ContentSection>
      }
    >
      <img src={WrongIcon} className={classes.img} />
      <Typography className={classes.title}>
        {"No se pudo enviar tu teléfono"}
      </Typography>
      <Typography className={classes.text}>
        {"Por favor, volvé a intentarlo en unos instanstes."}
      </Typography>
    </ContentSectionWithActions>
  );
};

export default Error;

const useStyles = makeStyles(theme => ({
  img: { marginBottom: pxToRem(38), marginTop: "20%", height: 140 },
  title: {
    fontSize: pxToRem(24),
    fontWeight: "bold",
    lineHeight: 1.25,
    textAlign: "center",
    color: theme.palette.primary.main,
    marginBottom: pxToRem(8)
  },
  text: {
    fontSize: pxToRem(16),
    lineHeight: 1.38,
    textAlign: "center",
    color: "#616161",
    marginLeft: pxToRem(25),
    marginRight: pxToRem(25)
  }
}));
