import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import filterItems from "~libs/filterItems";
import getOpcionesDePago from "./getOpcionesDePago";
import ListView from "../../styled/listView/ListView";
import Searcher from "../../styled/searcher/Searcher";

const useStyles = makeStyles(theme => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    paddingTop: 10,
    justifyContent: "space-around"
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2
  }
}));

const opcionesDePagoList = getOpcionesDePago();

const FormaDePagoSelector = ({ value, onChange }) => {
  const [filter, setFilter] = useState("");

  const handleItem = value => {
    setFilter("");
    if (onChange) onChange({ target: { value } });
  };

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  const classes = useStyles();

  return (
    <>
      {!value.label && (
        <div className={classes.searcher}>
          <Searcher
            onClear={() => handleItem({ value: null, label: null })}
            filter={filter}
            onChange={handleFilterChange}
            placeholder={"Buscar año"}
          />
          <ListView
            onItemSelected={handleItem}
            list={
              filter === ""
                ? opcionesDePagoList
                : filterItems(opcionesDePagoList, filter)
            }
          />
        </div>
      )}
    </>
  );
};

export default FormaDePagoSelector;
