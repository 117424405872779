import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "~componentes/Auth0/useAuth";

import { NavbarLayoutClienteFinal } from "~components/styled/layouts/navbarLayout/NavbarLayout";
import TopbarWithTitle from "~components/styled/layouts/navbarLayout/TopbarWithTitle";
import ListadoCreditos from "./ListadoCreditos";
import {
  ButtonGroup,
  ButtonGroupLabel,
  Button
} from "~components/styled/toolboxButtons/ButtonGroup";
import checkIcon from "~assets/icons/ic-check.svg";
import { getCreditosVigentes } from "./actions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import addIcon from "~assets/icons/ic_nuevo.svg";
import addImage from "~assets/ilustrations/image-nueva-solicitud.svg";
import { showNativePopup } from "~componentes/nativeAppMessaging/js/actions";
import { vincularOperaciones } from "~components/app/clienteFinal/shared/vinculacion/actions";
import pxToRem from "~libs/pxToRem";
import TengoUnProblema from "~components/bussines/tengoUnProblema/TengoUnProblema";
import Typography from "@material-ui/core/Typography";
import IllusWIP from "~assets/ilustrations/illus_work-in-progress.svg";

import ModalVincularCreditoDesktop from "~components/bussines/modalVincularCreditoDesktop/ModalVincularCreditoDesktop";

const useReduxSelector = () =>
  useSelector(state => ({
    isLoading: state.creditosReducers.listadoCreditosVigentesReducer.isLoading,
    creditos: state.creditosReducers.listadoCreditosVigentesReducer.creditos,
    isLoadingVinculacion: state.sharedReducers.vinculacionReducer.isLoading,
    isOnNativeApp: state.nativeMessagesReducer.isOnNativeApp,
    isValidacionIdentidadEnabled:
      state.featureFlagReducer.featureFlags["validacion-identidad"]
  }));

const HomeCreditos = () => {
  const [desktopModalOpened, setDesktopModalOpened] = useState(false);
  const {
    creditos,
    isLoading,
    isLoadingVinculacion,
    isOnNativeApp,
    isValidacionIdentidadEnabled
  } = useReduxSelector();
  const dispatch = useDispatch();
  const history = useHistory();
  const { authState } = useAuth0();
  const validatedIdentity = authState.user.validatedIdentity;
  const hasCredito = creditos && creditos.length > 0;
  const showEmptyState = !isLoading && !hasCredito;

  useEffect(() => {
    dispatch(getCreditosVigentes());
  }, []);

  const handleClick = () => {
    if (!isOnNativeApp && !validatedIdentity) setDesktopModalOpened(true);
    if (isOnNativeApp && !validatedIdentity)
      dispatch(showNativePopup("validaridentidad"));
    if (validatedIdentity) dispatch(vincularOperaciones());
  };

  return (
    <NavbarLayoutClienteFinal
      topbar={() => <TopbarWithTitle title={"Créditos"} />}
    >
      <ContentSection>
        <ButtonGroup marginBottom={17}>
          <Button
            onClick={() => {
              history.push("/miscreditos/informarpago");
            }}
          >
            <ButtonGroupLabel
              label={"Informar"}
              subLabel={"un pago"}
              icon={checkIcon}
            />
          </Button>
        </ButtonGroup>

        {showEmptyState && (
          <>
            {isValidacionIdentidadEnabled && !isLoadingVinculacion && (
              <>
                <ButtonGroup marginBottom={16}>
                  <Button onClick={handleClick}>
                    <div
                      style={{
                        backgroundImage: `url(${addImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "right",
                        width: "100%"
                      }}
                    >
                      <ButtonGroupLabel
                        label={"Vincular"}
                        subLabel={"mi crédito"}
                        icon={addIcon}
                      />
                    </div>
                  </Button>
                </ButtonGroup>
                <ModalVincularCreditoDesktop
                  opened={desktopModalOpened}
                  onClose={() => setDesktopModalOpened(false)}
                />
              </>
            )}
            {!isLoadingVinculacion && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: 24
                }}
              >
                <Typography
                  style={{
                    fontSize: pxToRem(16),
                    fontWeight: 600,
                    lineHeight: 1.38,
                    textAlign: "center",
                    color: "#757575",
                    marginBottom: 16,
                    maxWidth: "80%"
                  }}
                >
                  Vinculá tu crédito para poder visualizarlo.
                </Typography>
                <Typography
                  style={{
                    fontSize: pxToRem(16),
                    fontWeight: 600,
                    lineHeight: 1.38,
                    textAlign: "center",
                    color: "#757575",
                    marginBottom: 24,
                    maxWidth: "80%"
                  }}
                >
                  En el proceso, te vamos a pedir que valides tu identidad desde
                  tu celular.
                </Typography>
                <TengoUnProblema />
              </div>
            )}
            {isLoadingVinculacion && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                <img
                  src={IllusWIP}
                  style={{ maxHeight: 190, marginBottom: 22 }}
                />
                <Typography
                  style={{
                    fontSize: pxToRem(16),
                    fontWeight: 600,
                    lineHeight: 1.38,
                    textAlign: "center",
                    color: "#757575",
                    marginBottom: 24,
                    maxWidth: "80%"
                  }}
                >
                  Estamos validando tus datos. Pronto vas a poder ver tus
                  créditos.
                </Typography>
                <TengoUnProblema />
              </div>
            )}
          </>
        )}
        <ListadoCreditos limit={0} creditos={creditos} isLoading={isLoading} />
      </ContentSection>
    </NavbarLayoutClienteFinal>
  );
};

export default HomeCreditos;
