const blobToBase64 = blob => {
  return new Promise(resolve => {
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      var base64data = reader.result;
      resolve(base64data);
    };
  });
};

export default blobToBase64;
