import React from "react";
import { Route } from "react-router-dom";

import OldLayout from "~componentes/app/OldLayout";
import {
  AsyncSolicitudTabs,
  AsyncSolicitudCredito,
  AsyncListaSolicitudes,
  AsyncCalculador
} from "~componentes/app/LazyComponents";

const CreditosRoutes = () => [
  <Route key="creditos-1" exact path="/creditos/calcular">
    <OldLayout>
      <AsyncCalculador />
    </OldLayout>
  </Route>,
  <Route key="creditos-2" path="/creditos">
    <OldLayout>
      <AsyncSolicitudCredito />
    </OldLayout>
  </Route>,
  <Route key="creditos-3" exact path="/solicitudes">
    <OldLayout>
      <AsyncListaSolicitudes />
    </OldLayout>
  </Route>,
  <Route key="creditos-4" path="/solicitudes/:idSolicitud">
    <OldLayout>
      <AsyncSolicitudTabs />
    </OldLayout>
  </Route>
];

export default CreditosRoutes;
