import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { vincularOperaciones } from "~components/app/clienteFinal/shared/vinculacion/actions";
const useReduxSelector = () =>
  useSelector(state => ({
    validatedIdentity: state.nativeMessagesReducer.validatedIdentity
  }));
const useVinculacion = () => {
  const { validatedIdentity } = useReduxSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    if (validatedIdentity) dispatch(vincularOperaciones());
  }, [validatedIdentity]);
};

export default useVinculacion;
