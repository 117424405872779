import { createActions, handleActions } from "redux-actions";

export const {
  pedidoCambioInit,
  toggleFormOpen,
  pedidosCambioUpdated,
  pedidoCambioFetchInited,
  pedidoCambioFetchCompleted,
  pedidoCambioFetchFailed,
  pedidoCambioPostInited,
  pedidoCambioPostCompleted,
  pedidoCambioPostFailed
} = createActions(
  "PEDIDO_CAMBIO_INIT",
  "TOGGLE_FORM_OPEN",
  "PEDIDOS_CAMBIO_UPDATED",
  "PEDIDO_CAMBIO_FETCH_INITED",
  "PEDIDO_CAMBIO_FETCH_COMPLETED",
  "PEDIDO_CAMBIO_FETCH_FAILED",
  "PEDIDO_CAMBIO_POST_INITED",
  "PEDIDO_CAMBIO_POST_COMPLETED",
  "PEDIDO_CAMBIO_POST_FAILED"
);

export function pedidosCambioFetch(solicitudId) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(pedidoCambioFetchInited());
      const responseEstado = await services.api.getEstadoSolicitud(solicitudId);
      const response = await services.api.getchangeRequests(solicitudId);
      dispatch(
        pedidoCambioFetchCompleted({
          pedidosCambio: response,
          estadoSolicitud: responseEstado.estado
        })
      );
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        pedidosCambioFetch,
        [solicitudId],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(pedidoCambioFetchFailed(error));
    }
  };
}

export function pedidoCambioPost(chageRequest) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(pedidoCambioPostInited());
      const response = await services.api.postChangeRequest(chageRequest);

      const changeRequest = {
        estado: "Pendiente",
        tipoPedido: chageRequest.TipoPedido,
        id: response,
        pedido: chageRequest.Pedido,
        solicitante: chageRequest.solicitante,
        fechaCreacion: Date.now(),
        solicitudId: chageRequest.solicitudId
      };
      dispatch(pedidoCambioPostCompleted({ pedidoCambio: changeRequest }));

      let pedidosCambio = getState().pedidoCambioReducer.pedidosCambio;
      setTimeout(function() {
        dispatch(pedidosCambioUpdated({ cambiosCount: pedidosCambio.length }));
      }, 1000);
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        pedidoCambioPost,
        [chageRequest],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(pedidoCambioPostFailed(error));
    }
  };
}

const pedidoCambioReducer = handleActions(
  {
    [pedidoCambioInit]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        pedidosCambio: [],
        estadoSolicitud: null,
        nuevoFormOpen: false,
        error: false
      };
    },
    [toggleFormOpen]: (state, action) => {
      return {
        ...state,
        nuevoFormOpen: action.payload.nuevoFormOpen
      };
    },
    [pedidoCambioFetchInited]: (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [pedidoCambioFetchCompleted]: (state, action) => {
      return {
        ...state,
        pedidosCambio: [...action.payload.pedidosCambio],
        estadoSolicitud: action.payload.estadoSolicitud,
        isLoading: false,
        error: false
      };
    },
    [pedidoCambioFetchFailed]: state => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    },
    [pedidoCambioPostInited]: (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [pedidoCambioPostCompleted]: (state, action) => {
      return {
        ...state,
        pedidosCambio: [...state.pedidosCambio, action.payload.pedidoCambio],
        nuevoFormOpen: false,
        isLoading: false,
        error: false
      };
    },
    [pedidoCambioPostFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    }
  },
  {
    isLoading: false,
    pedidosCambio: [],
    estadoSolicitud: null,
    nuevoFormOpen: false,
    error: false
  }
);

export default pedidoCambioReducer;
