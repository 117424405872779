const getAnios = cantAnios => {
  const year = new Date().getFullYear();

  let result = [];
  for (let i = year; i > year - cantAnios; i--) {
    result.push({
      value: i.toString(),
      label: i.toString()
    });
  }

  return result;
};

export default getAnios;
