import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import ArrowBackIcon from "@material-ui/icons/ChevronLeftRounded";

import closeIcon from "~assets/icons/ic-close.svg";
import backIcon from "~assets/icons/ic-back-white.svg";

import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";

import pxToRem from "~libs/pxToRem";

import SecondaryTitle from "~components/styled/texts/SecondaryTitle";
import ContentSection from "~components/styled/contentSection/ContentSection";

const NAV_BAR_HEIGHT = 44;

const useStyles = makeStyles(theme => ({
  root: {
    position: "sticky",
    top: 0,
    background: "transparent",
    boxShadow: "0 -1px 3px 0 rgba(0, 0, 0, 0.1)",
    zIndex: 999,
    //marginBottom: 20,
    height: NAV_BAR_HEIGHT,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  content: {
    flex: 1,
    padding: 0,
    display: "flex",
    textAlign: "center",
    justifyContent: "center"
  }
}));

const TopbarWithTitle = ({ title }) => {
  const classes = useStyles();

  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  return (
    <>
      <Fade timeout={500} appear={false} in={isScrolled}>
        <div
          className={classes.root}
          style={
            isScrolled
              ? {
                  background: "#663ea8",
                  boxShadow: "0 2px 3px 0 #00000029"
                }
              : null
          }
        >
          <div className={classes.content}>
            <Typography
              variant={"h1"}
              style={{
                fontSize: pxToRem(18),
                fontWeight: "bold",
                lineHeight: "44px",
                margin: 0,
                color: "#fff"
              }}
            >
              {title}
            </Typography>
          </div>
        </div>
      </Fade>
      <div
        style={{
          padding: pxToRem(16),
          paddingTop: 0,
          paddingBottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "column"
        }}
      >
        <ContentSection>
          <SecondaryTitle label={title} />
        </ContentSection>
      </div>
    </>
  );
};

export default TopbarWithTitle;

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: "bold",
    marginTop: 5
  }
}))(Tooltip);

const IconFile = ({ src }) => {
  return <img alt="" src={src} style={{ width: 18, height: 18 }} />;
};
