import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/styles";

import { clearTieneGNC, setTieneGNC } from "../actions";

import checkIcon from "~assets/icons/ic-check.svg";
import closeIcon from "~assets/icons/ic-close.svg";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import { CheckboxButton } from "~components/styled/checkboxButton/CheckboxButton";
import Typography from "@material-ui/core/Typography";
import ContentLoader from "react-content-loader";

const VehiculoTieneGNCStep = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearTieneGNC());
  }, [dispatch]);

  const handleClick = (value) => {
    dispatch(setTieneGNC({ value, selected: true }));
  };

  return (
    <ContentSectionWithActions>
      <PrimaryTitle label={"¿El vehículo tiene GNC?"} marginBottom={60} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: 20,
        }}
      >
        <div style={{ marginRight: 5, width: "100%" }}>
          <CheckboxButton onClick={() => handleClick(true)}>
            <TieneGNCButton image={checkIcon} label="Sí, tiene GNC" />
          </CheckboxButton>
        </div>
        <div style={{ marginLeft: 5, width: "100%" }}>
          <CheckboxButton onClick={() => handleClick(false)}>
            <TieneGNCButton image={closeIcon} label="No, no tiene GNC" />
          </CheckboxButton>
        </div>
      </div>
    </ContentSectionWithActions>
  );
};

export default VehiculoTieneGNCStep;

const TieneGNCButton = ({ label, isLoading, image }) => {
  const classes = useStyles();
  if (isLoading)
    return (
      <div className={classes.container}>
        <div className={classes.loading}>
          <ContentLoader height={60} width={70} speed={1}>
            <rect x="0" y="0" rx="4" ry="4" width="70" height="40" />
            <rect x="15" y="45" rx="4" ry="4" width="40" height="16" />
          </ContentLoader>
        </div>
      </div>
    );

  return (
    <div className={classes.container}>
      <img alt="" src={image} style={{ height: 58, marginBottom: 17 }} />
      <Typography className={classes.btnLabel}>{label}</Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 145,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  btnLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    lineHeight: 1.38,
    letterSpacing: 0.48,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
}));
