import React from "react";
import pxToRem from "~libs/pxToRem";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import { fade, emphasize } from "material-ui/utils/colorManipulator";

const useStyles = makeStyles(theme => ({
  base: props => ({
    fontSize: pxToRem(16),
    fontWeight: "bold",
    lineHeight: "1.38",
    letterSpacing: pxToRem(0.48),
    padding: pxToRem(13),
    minHeight: 52,
    textTransform: "none",
    marginBottom: props.marginBottom
  }),
  primary: {
    backgroundColor: theme.palette.secondary.dark,
    color: "#ffffff",
    "&:hover": {
      backgroundColor: fade(theme.palette.secondary.dark, 0.8)
    },
    "&:disabled": {
      backgroundColor: "#eeeeee",
      color: "#9e9e9e"
    }
  },
  secondary: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: emphasize(theme.palette.primary.dark, 0.5),
      color: "#ffffff"
    },
    "&:disabled": {
      backgroundColor: "#eeeeee",
      color: "#9e9e9e"
    }
  },
  secondaryemphasis: {
    backgroundColor: "#663ea8",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#7c4cd5",
      color: "#ffffff"
    },
    "&:disabled": {
      backgroundColor: "#eeeeee",
      color: "#9e9e9e"
    }
  },
  outlinedprimary: {
    borderColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.dark,
    border: pxToRem(2),
    "&:hover": {
      backgroundColor: fade(theme.palette.secondary.dark, 0.8),
      borderColor: fade(theme.palette.secondary.dark, 0.8),
      color: "#ffffff"
    },
    "&:disabled": {
      color: "#bdbdbd",
      borderColor: "#bdbdbd"
    }
  },
  outlinedsecondary: {
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
    borderWidth: pxToRem(2),
    "&:hover": {
      backgroundColor: emphasize(theme.palette.primary.dark, 0.5),
      borderColor: emphasize(theme.palette.primary.dark, 0.5),
      color: "#ffffff"
    },
    "&:disabled": {
      color: "#bdbdbd",
      borderColor: "#bdbdbd"
    }
  }
}));
const CallToActionButton = ({
  label,
  onClick,
  variant = "",
  color = "primary",
  disabled,
  marginBottom
}) => {
  const classes = useStyles({ marginBottom });
  const fullClasses = [classes.base, classes[`${variant}${color}`]].join(" ");
  return (
    <Button
      fullWidth
      variant={variant === "outlined" ? variant : "text"}
      disabled={disabled}
      className={fullClasses}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default CallToActionButton;
