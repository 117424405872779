import React from "react";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import CurrencyText from "~components/styled/texts/CurrencyText";

import { useSelector } from "react-redux";
import ContentSection from "~components/styled/contentSection/ContentSection";

const useReduxSelector = () =>
  useSelector(state => {
    const calcularCreditoReducer =
      state.creditosReducers.calcularCreditoReducer;

    return {
      credito: calcularCreditoReducer.credito
    };
  });

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    boxShadow: "2px 2px 8px 0 #00000012",
    borderRadius: "12px !important",
    marginTop: 8,
    "&:before": {
      backgroundColor: "transparent"
    }
  },
  heading: {
    color: "#a8a8a8",
    fontSize: theme.typography.pxToRem(16)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    color: "#424242",
    fontWeight: "bold",
    textAlign: "right"
  },
  details: {
    alignItems: "center"
  },
  column: {
    flexBasis: "50%"
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },
  label: {
    color: "#9e9e9e",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    lineHeight: 1.36
  },
  currency: {
    color: "#424242",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    lineHeight: 1.36,
    textAlign: "right"
  }
}));

const CreditoCuotas = () => {
  const { credito } = useReduxSelector();

  const { cuotas } = credito;

  return (
    <ContentSection>
      <div style={{ padding: 16 }}>
        {cuotas.map((cuota, index) => (
          <Cuota key={`credito-cuota-${index}`} {...cuota} />
        ))}
      </div>
    </ContentSection>
  );
};

const Cuota = ({
  nroCuota,
  capital,
  interes,
  gastosAdministrativos,
  seguroDeVida,
  importe,
  iva,
  seguroAuto
}) => {
  const classes = useStyles();

  return (
    <ExpansionPanel className={classes.root} elevation={0}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.column}>
          <Typography className={classes.heading}>Cuota {nroCuota}</Typography>
        </div>
        <div className={classes.column}>
          <Typography className={classes.secondaryHeading}>
            <CurrencyText value={importe} />
          </Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <div className={classes.column}>
          <Typography className={classes.label}>Capital</Typography>
          <Typography className={classes.label}>Interés</Typography>
          <Typography className={classes.label}>Gastos</Typography>
          <Typography className={classes.label}>Seguro del auto</Typography>
          <Typography className={classes.label}>Seguro de vida</Typography>
        </div>
        <div className={classes.column}>
          <Typography className={classes.currency}>
            <CurrencyText value={capital} />
          </Typography>
          <Typography className={classes.currency}>
            <CurrencyText value={interes} />
          </Typography>
          <Typography className={classes.currency}>
            <CurrencyText value={gastosAdministrativos} />
          </Typography>
          <Typography className={classes.currency}>
            <CurrencyText value={seguroAuto} />
          </Typography>
          <Typography className={classes.currency}>
            <CurrencyText value={seguroDeVida} />
          </Typography>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default CreditoCuotas;
