import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentSection from "~components/styled/contentSection/ContentSection";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import FranjaHorariaSelector from "~components/bussines/franjaHorariaSelector/FranjaHorariaSelector";

import { setFranjaHoraria, clearFranjaHoraria } from "../actions";

const ContactoFranjaHorariaStep = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearFranjaHoraria());
  }, [dispatch]);

  return (
    <ContentSection>
      <PrimaryTitle label={"¿En qué horario lo podemos contactar?"} />
      <FranjaHorariaSelector
        onSelected={event => dispatch(setFranjaHoraria(event.target.value))}
      />
    </ContentSection>
  );
};

export default ContactoFranjaHorariaStep;
