import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";

import pxToRem from "~libs/pxToRem";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { getDetallePoliza, getTarjetaMercosur, getPoliza } from "./actions";

import DetailsLayout from "~components/styled/layouts/detailsLayout/DetailsLayout";
import ContentSection from "~components/styled/contentSection/ContentSection";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { getSituacionStyle } from "~components/app/clienteFinal/seguros/PolizaCardButton";
import Typography from "@material-ui/core/Typography";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import ColorLabel from "~components/styled/colorLabel/ColorLabel";
import Divider from "@material-ui/core/Divider";
import LinkButton from "~components/styled/linkButton/LinkButton";
import AutoIcon from "~assets/icons/ic-autos-active-small.svg";
import CoberturaIcon from "~assets/icons/ic-seguros-active-small.svg";
import ContentLoader from "react-content-loader";
import CircularProgress from "@material-ui/core/CircularProgress";
import WarningSection from "~components/styled/warningSection/WarningSection";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import Modal from "~components/styled/modal/Modal";

const useReduxSelector = () =>
  useSelector((state) => {
    const detallePolizaReducer = state.segurosReducers.detallePolizaReducer;
    const poliza = detallePolizaReducer.poliza;
    let dominio = "";
    if (poliza && poliza.dominio) dominio = poliza.dominio;
    if (poliza && poliza.vehiculo) dominio = poliza.vehiculo.dominio;

    return {
      isLoading: detallePolizaReducer.isLoading,
      poliza: detallePolizaReducer.poliza,
      dominio: dominio,
      polizaDownloading: detallePolizaReducer.polizaDownloading,
      tarjetaDownloading: detallePolizaReducer.tarjetaDownloading,
    };
  });
const tiposSeguro = { TR: "Todo riesgo", TC: "Terceros completo" };
const DetallePoliza = ({}) => {
  let { idPoliza } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDetallePoliza(idPoliza));
  }, []);

  return (
    <DetailsLayout
      title="Detalle de póliza"
      onBackClick={() => {
        history.push("/mispolizas");
      }}
    >
      <ContentSection>
        <Content />
      </ContentSection>
    </DetailsLayout>
  );
};

export default DetallePoliza;

const RowSection = ({ children }) => {
  return (
    <>
      <Divider
        style={{
          color: "#bdbdbd",
          marginTop: pxToRem(16),
          marginBottom: pxToRem(16),
        }}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {children}
      </div>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  subheader: {
    fontSize: pxToRem(18),
    fontWeight: "bold",
    lineHeight: 1.33,
    textAlign: "center",
    color: "#424242",
    marginBottom: 16,
  },
  fechaHeader: {
    fontSize: pxToRem(14),
    fontWeight: 500,
    lineHeight: 1.29,
    textAlign: "center",
    color: "#9e9e9e",
    marginBottom: 6,
  },
  fechaContent: {
    fontSize: pxToRem(18),
    fontWeight: "bold",
    lineHeight: 1.33,
    textAlign: "center",
    color: "#424242",
  },
  card: {
    width: "100% !important",
    marginBottom: 28,
    boxShadow: "2px 2px 8px 0 #00000012",
    [theme.breakpoints.mobile]: { boxShadow: "none !important" },
  },
  cardContent: { padding: 20 },
  colLeft: { display: "flex", textAlign: "left" },
  colRight: { display: "flex", textAlign: "right" },
  sectionHeader: {
    fontSize: pxToRem(14),
    fontWeight: "bold",
    lineHeight: 1.36,
    color: "#9e9e9e",
    textAlign: "left",
    paddingLeft: 11,
  },
  rowHeader: {
    fontSize: pxToRem(16),
    paddingLeft: 10,
    fontWeight: 600,
    lineHeight: 1.38,
    color: "#9e9e9e",
  },
  rowContent: {
    fontSize: pxToRem(16),
    fontWeight: 600,
    lineHeight: 1.38,
    color: "#424242",
  },
  downloadingLink: {
    fontSize: pxToRem(16),
    lineHeight: 1.5,
    fontWeight: 400,
    opacity: 0.79,
    fontWeight: "bold",
    lineHeight: 2.13,
    color: theme.palette.primary.light,
  },
  titleAseguradora: {
    fontSize: pxToRem(28),
    fontWeight: 800,
    lineHeight: 1.36,
    textAlign: "center",
    color: "#424242",
  },
}));

const Content = () => {
  const classes = useStyles();
  const {
    poliza,
    dominio,
    isLoading,
    polizaDownloading,
    tarjetaDownloading,
  } = useReduxSelector();

  const dispatch = useDispatch();
  const situacionStyle = poliza && getSituacionStyle(poliza.suspendida);
  const [opened, setOpened] = useState(false);
  return (
    <>
      <ModalInfo onClose={setOpened} opened={opened} />
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          {poliza && poliza.suspendida && poliza.suspendidaPorMora && (
            <WarningSection
              variant="warning"
              icon={<ReportProblemOutlinedIcon />}
              onClick={() => {
                setOpened(true);
              }}
              rightLink="más info"
              marginBottom={12}
            >
              Póliza anulada por mora
            </WarningSection>
          )}
          {!poliza && <TextLoading marginBottom={7} width={230} height={33} />}
          {poliza && (
            <Typography className={classes.titleAseguradora}>
              {poliza.ciaAseguradora}
            </Typography>
          )}
          {!poliza && <TextLoading marginBottom={15} width={160} height={18} />}
          {poliza && (
            <Typography className={classes.subheader}>
              {tiposSeguro[poliza.tipoSeguro]}
            </Typography>
          )}
          {!poliza && <TextLoading marginBottom={15} width={85} height={25} />}
          {poliza && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ColorLabel
                text={situacionStyle.label}
                variant={situacionStyle.variant}
                marginBottom={14}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: 40,
              paddingRight: 40,
              marginBottom: 16,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <Typography className={classes.fechaHeader}>
                {"Válida desde"}
              </Typography>
              <Typography className={classes.fechaContent}>
                {poliza && moment(moment.utc(poliza.fechaInicio)).format("L")}
                {!poliza && (
                  <TextLoading marginBottom={15} width={100} height={18} />
                )}
              </Typography>
            </div>
            <Divider
              orientation="vertical"
              style={{ height: 44, margin: 10 }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <Typography className={classes.fechaHeader}>
                {"Válida hasta"}
              </Typography>
              <Typography className={classes.fechaContent}>
                {poliza && moment(moment.utc(poliza.fechaFin)).format("L")}
                {!poliza && (
                  <TextLoading marginBottom={15} width={100} height={18} />
                )}
              </Typography>
            </div>
          </div>
          <Typography
            style={{
              fontSize: pxToRem(16),
              lineHeight: 1.38,
              textAlign: "center",
              color: "#757575",
              marginBottom: 20,
            }}
          >
            {"Cuota incluida en el crédito"}
          </Typography>

          {poliza && !poliza.suspendida && poliza.tienePDFPoliza && (
            <CallToActionButton
              variant="outlined"
              color="secondary"
              disabled={polizaDownloading}
              label={
                polizaDownloading ? (
                  <CircularProgress
                    size={18}
                    thickness={4}
                    style={{
                      alignSelf: "center",
                      marginRight: 5,
                      color: "#bdbdbd",
                    }}
                  />
                ) : (
                  "Ver póliza"
                )
              }
              marginBottom={28}
              onClick={() => {
                dispatch(getPoliza(poliza.idPoliza));
              }}
            />
          )}

          {poliza && !poliza.suspendida && poliza.tienePDFTarjeta && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {tarjetaDownloading && (
                <>
                  <CircularProgress
                    size={14}
                    thickness={4}
                    style={{ alignSelf: "center", marginRight: 5 }}
                  />
                  <Typography className={classes.downloadingLink}>
                    Descargando...
                  </Typography>
                </>
              )}
              {!tarjetaDownloading && (
                <LinkButton
                  variant="purple"
                  onClick={() => {
                    dispatch(getTarjetaMercosur(poliza.idPoliza));
                  }}
                >
                  Ver tarjeta del mercosur
                </LinkButton>
              )}
            </div>
          )}
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div style={{ display: "flex" }}>
            <img src={AutoIcon} />
            <Typography className={classes.sectionHeader}>
              VEHÍCULO ASEGURADO
            </Typography>
          </div>
          <RowSection>
            <div className={classes.colLeft}>
              <Typography className={classes.rowHeader}>Vehículo</Typography>
            </div>
            <div className={classes.colRight}>
              {(!poliza || !poliza.vehiculo) && (
                <TextLoading width={200} height={22} />
              )}
              {poliza && poliza.vehiculo && (
                <Typography className={classes.rowContent}>
                  {`${poliza.vehiculo.marca} ${poliza.vehiculo.modelo}`}
                </Typography>
              )}
            </div>
          </RowSection>
          <RowSection>
            <div className={classes.colLeft}>
              <Typography className={classes.rowHeader}>Dominio</Typography>
            </div>
            <div className={classes.colRight}>
              {(!poliza || !dominio) && <TextLoading width={150} height={22} />}
              {poliza && dominio && (
                <Typography
                  className={classes.rowContent}
                  style={{ textTransform: "capitalize" }}
                >
                  {dominio}
                </Typography>
              )}
            </div>
          </RowSection>
          <RowSection>
            <div className={classes.colLeft}>
              <Typography className={classes.rowHeader}>Año</Typography>
            </div>
            <div className={classes.colRight}>
              {(!poliza || !poliza.vehiculo) && (
                <TextLoading width={70} height={22} />
              )}
              {poliza && poliza.vehiculo && (
                <Typography className={classes.rowContent}>
                  {poliza.vehiculo.anio}
                </Typography>
              )}
            </div>
          </RowSection>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div style={{ display: "flex" }}>
            <img src={CoberturaIcon} />
            <Typography className={classes.sectionHeader}>
              DETALLE DE COBERTURA
            </Typography>
          </div>

          <RowSection>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
                minHeight: 160,
              }}
            >
              {isLoading && <ParagraphLoading />}

              {!isLoading && poliza && (
                <Typography
                  style={{
                    fontSize: pxToRem(16),
                    lineHeight: 1.5,
                    textAlign: poliza.detalleCobertura ? "left" : "center",
                    color: "#808080",
                  }}
                >
                  {poliza.detalleCobertura
                    ? poliza.detalleCobertura
                    : "No hay detalle de cobertura"}
                </Typography>
              )}
            </div>
          </RowSection>
        </CardContent>
      </Card>
    </>
  );
};

const TextLoading = ({ marginBottom, width, height }) => (
  <div
    style={{
      height: height,
      width: width,
      margin: "0 auto",
      marginBottom,
    }}
  >
    <ContentLoader
      height={height}
      width={width}
      speed={1}
      primaryColor="#424242"
      secondaryColor="#cdcdcd"
    >
      <rect x="0" y="0" rx="3" ry="3" width={width} height={pxToRem(height)} />
    </ContentLoader>
  </div>
);
const ParagraphLoading = () => (
  <div
    style={{
      height: 115,
      width: "100%",
      margin: "0 auto",
    }}
  >
    <ContentLoader
      height={150}
      speed={1}
      primaryColor="#424242"
      secondaryColor="#cdcdcd"
    >
      <rect x="0" y="0" rx="3" ry="3" width={"100%"} height={pxToRem(18)} />
      <rect x="0" y="28" rx="3" ry="3" width={"100%"} height={pxToRem(18)} />
      <rect x="0" y="56" rx="3" ry="3" width={"100%"} height={pxToRem(18)} />
      <rect x="0" y="84" rx="3" ry="3" width={"100%"} height={pxToRem(18)} />
      <rect x="0" y="112" rx="3" ry="3" width={"60%"} height={pxToRem(18)} />
    </ContentLoader>
  </div>
);

const ModalInfo = ({ onClose, opened }) => {
  const classes = useStyles();
  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose(false);
      }}
      title="Más información"
      dialogActions={
        <LinkButton
          variant="purple"
          onClick={() => {
            onClose(false);
          }}
        >
          Volver
        </LinkButton>
      }
    >
      <Typography
        style={{
          color: "#424242",
          fontSize: pxToRem(20),
          fontWeight: "bold",
          lineHeight: 1.7,
          textAlign: "left",
          paddingBottom: 18,
        }}
      >
        Póliza anulada por mora
      </Typography>
      <Typography className={classes.modalText}>
        Te recordamos que una vez alcanzados los 120 días de mora en tu crédito,
        tu póliza se anula automáticamente, pudiendo rehabilitarse encontrándose
        el préstamo sin cuotas vencidas impagas.
      </Typography>
    </Modal>
  );
};
