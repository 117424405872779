import React, { useState } from "react";
import LinkButton from "~components/styled/linkButton/LinkButton";

import Modal from "~components/styled/modal/Modal";
import Typography from "@material-ui/core/Typography";
import pxToRem from "~libs/pxToRem";

import TextField from "@material-ui/core/TextField";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import PhoneTipoSelector from "~components/styled/phone/PhoneTipoSelector";
import PhoneInput from "~components/styled/phone/PhoneInput";
import usePhone from "~components/styled/phone/usePhone";
import { useAuth0 } from "~componentes/Auth0/useAuth";
import { postQuieroQueMeContacten } from "~api";
import LoadingViewWithLoader from "~components/styled/loadingView/LoadingViewWithLoader";
import Error from "./Error";
import Success from "./Success";

const ENTER_KEY = 13;

const STATUS = {
  idle: 0,
  sending: 1,
  completed: 2,
  failed: 3,
};

const TengoUnProblema = () => {
  const [opened, setOpened] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(STATUS.idle);

  const [
    phoneInputRef,
    tel,
    setTelTouched,
    onTelNumberChange,
    onTelNumberBlur,
    onTelIsMobileChange,
  ] = usePhone("");

  const { authState } = useAuth0();

  const solicitudDeContacto = async () => {
    if (authState.user) {
      setCurrentStatus(STATUS.sending);
      try {
        await postQuieroQueMeContacten({
          Email: authState.user.email,
          NombreYApellido: authState.user.preferred_username,
          Telefono: tel.number,
          EuroId: authState.user.euroUserId,
        });

        setCurrentStatus(STATUS.completed);
      } catch (error) {
        setCurrentStatus(STATUS.failed);
      }
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY) {
      setTelTouched(true);
      if (!tel.error) solicitudDeContacto();
    }
  };

  return (
    <>
      <LinkButton
        variant="purple"
        onClick={() => {
          setCurrentStatus(STATUS.idle);
          setOpened(true);
        }}
      >
        ¿Necesitás ayuda?
      </LinkButton>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
        title="Ayuda"
        dialogActions={
          <>
            {currentStatus === STATUS.idle && (
              <LinkButton
                variant="purple"
                onClick={() => {
                  setOpened(false);
                }}
              >
                Volver
              </LinkButton>
            )}
          </>
        }
      >
        <div
          style={{
            width: "100%",
            maxWidth: pxToRem(500),
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {currentStatus === STATUS.idle && (
            <>
              <Typography
                style={{
                  fontSize: pxToRem(22),
                  fontWeight: "bold",
                  lineHeight: 1.3,
                  textAlign: "left",
                  color: "#424242",
                  paddingBottom: 28,
                }}
              >
                Dejanos un teléfono y te contactaremos a la brevedad.
              </Typography>
              <div style={{ display: "flex", height: 90 }}>
                <div>
                  <PhoneTipoSelector
                    value={tel.isMobile}
                    onChange={onTelIsMobileChange}
                  />
                </div>
                <div style={{ flex: 1, paddingLeft: 16 }}>
                  <TextField
                    inputRef={phoneInputRef}
                    label="NÚMERO"
                    fullWidth={true}
                    onFocus={(event) => event.target.select()}
                    onKeyDown={onKeyDown}
                    /* autoFocus */
                    type="tel"
                    value={tel.number}
                    error={tel.touched && tel.error ? true : false}
                    onChange={onTelNumberChange}
                    onBlur={onTelNumberBlur}
                    placeholder={
                      tel.isMobile
                        ? "Ej: (011) 15 2938-2938"
                        : "Ej: (011) 2938-2938"
                    }
                    InputProps={{
                      inputComponent: PhoneInput,
                    }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ isMobile: tel.isMobile }}
                    helperText={
                      tel.touched && tel.error
                        ? tel.error
                        : tel.area
                        ? tel.area.Description
                        : ""
                    }
                  />
                </div>
              </div>
              <CallToActionButton
                disabled={tel.number.length === 0}
                label={"Enviar"}
                onClick={() => {
                  if (!tel.error) solicitudDeContacto();
                }}
              />
            </>
          )}
          {currentStatus === STATUS.sending && <LoadingViewWithLoader />}
          {currentStatus === STATUS.completed && (
            <Success onClick={() => setOpened(false)} />
          )}
          {currentStatus === STATUS.failed && (
            <Error onClick={() => setOpened(false)} />
          )}
        </div>
      </Modal>
    </>
  );
};

export default TengoUnProblema;
