import uuidv4 from "uuid/v4";
import environment from "../environment";
import { buildRequestWithAuth } from "~api/index";
import { errorUnlessOk, buildRequest } from "~api/utils";

const fileService = directory => {
  return {
    upload: async (file, load, progress) => {
      const key = uuidv4();
      const fileExtension = file.name.split(".").pop();
      const fileName = `${key}.${fileExtension}`.toLowerCase();
      let request = buildRequestWithAuth("GET");
      let response = await fetch(
        `${environment.presignerUrl}/sign?key=${directory}/${fileName}&verb=put`,
        request
      );
      response = await errorUnlessOk(response);
      const { url } = await response.json();
      fetchWithProgress(file, fileName, "put", url, load, progress);
      return fileName;
    },
    delete: async fileName => {
      let request = buildRequestWithAuth("GET");
      let response = await fetch(
        `${environment.presignerUrl}/sign?key=${directory}/${fileName}&verb=delete`,
        request
      );
      response = await errorUnlessOk(response);
      const { url } = await response.json();

      const requestDelete = buildRequest("DELETE");
      response = await fetch(url, requestDelete);
      await errorUnlessOk(response);
    },
    download: async fileName => {
      let request = buildRequestWithAuth("GET");
      let response = await fetch(
        `${environment.presignerUrl}/sign?key=${directory}/${fileName}&verb=get`,
        request
      );
      const { url } = await response.json();
      const requestGet = buildRequest("GET");
      response = await fetch(url, requestGet);

      await errorUnlessOk(response);
      return response;
    }
  };
};

const fetchWithProgress = (file, fileName, verb, url, load, progress) => {
  var xhr = new XMLHttpRequest();
  xhr.upload.onprogress = e => {
    progress(e.lengthComputable, e.loaded, e.total);
  };
  xhr.open(verb, url);
  xhr.setRequestHeader("Content-Type", "application/octet-stream");
  xhr.onload = () => {
    if (xhr.status === 200) {
      load(fileName);
    } else {
      throw Error("error at UploadFile - fetchWithProgress");
    }
  };
  xhr.onerror = e => {
    console.error(e);
    throw Error("error at UploadFile - fetchWithProgress");
  };
  xhr.send(file);
};

export default fileService;
