import React from "react";

import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import Typography from "@material-ui/core/Typography";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import makeStyles from "@material-ui/styles/makeStyles";
import pxToRem from "~libs/pxToRem";
import ErrorIcon from "~assets/ilustrations/illus_solic_error.svg";

const useStyles = makeStyles(theme => ({
  img: {
    width: pxToRem(242.5),
    align: "center"
  },
  text: {
    fontSize: pxToRem(16),
    lineHeight: 1.38,
    textAlign: "center",
    color: "#616161"
  },
  content: {
    marginTop: 60,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

const LimiteAlcanzadoForm = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            label={"Ir al inicio"}
            onClick={() => {
              history.push("/");
            }}
          />
        </ContentSection>
      }
    >
      <div className={classes.content}>
        <img src={ErrorIcon} className={classes.img} />
        <PrimaryTitle label={"Límite Alcanzado"} marginBottom={20} />
        <Typography className={classes.text}>
          Alcanzaste tu límite de consultas, para seguir operando
          <Link to={"/contacto"}> contactá a tu comercial. </Link>
        </Typography>
      </div>
    </ContentSectionWithActions>
  );
};

export default LimiteAlcanzadoForm;
