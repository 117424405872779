import React from "react";
import { useHistory } from "react-router-dom";
import pxToRem from "~libs/pxToRem";

import CreditoCardButton from "~components/app/clienteFinal/creditos/creditoCardButton/CreditoCardButton";
import Typography from "@material-ui/core/Typography";
import WarningSection from "~components/styled/warningSection/WarningSection";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

const ListadoCreditos = ({ creditos, isLoading }) => {
  const history = useHistory();
  const hasItems = creditos && creditos.length > 0;
  const hasCuotasVencidas =
    creditos && creditos.find((credito) => credito.estadoCredito === "EnMora");

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: 14,
        }}
      >
        {hasItems && (
          <Typography
            variant="caption"
            noWrap
            style={{
              fontSize: pxToRem(22),
              fontWeight: "bold",
              lineHeight: 1.55,
              color: "#663ea8",
              marginTop: 14,
            }}
          >
            {`Mis créditos`}
          </Typography>
        )}
      </div>
      {isLoading && <LoadingTemplate length={3} />}
      {!isLoading && hasCuotasVencidas && (
        <WarningSection
          variant="warning"
          icon={<ReportProblemOutlinedIcon />}
          rightLink="más info"
          onClick={() => {
            history.push(`/miscreditos/${hasCuotasVencidas.operacionId}`);
          }}
          marginBottom={16}
        >
          Tenés cuotas vencidas
        </WarningSection>
      )}
      {!isLoading &&
        hasItems &&
        creditos.map((credito) => (
          <CreditoCardButton
            key={`solicitud-${credito.operacionId}`}
            credito={credito}
            marginBottom={12}
            onClick={() => {
              history.push(`/miscreditos/${credito.operacionId}`);
            }}
          />
        ))}
    </>
  );
};

export default ListadoCreditos;

const LoadingTemplate = ({ length }) => {
  return Array.apply(null, Array(length)).map((_, index) => (
    <CreditoCardButton
      key={`loading-${index}`}
      marginBottom={12}
      onClick={null}
      isLoading={true}
    />
  ));
};
