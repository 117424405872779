import React from "react";
import MaskedInput from "react-text-mask";

const CuiInput = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={() => {
        let mask = [
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/
        ];

        return mask;
      }}
      placeholderChar={"_"}
    />
  );
};

export default CuiInput;
