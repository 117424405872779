import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import useAsyncReducer from "~components/app/useAsyncReducer";
import LoadingView from "~components/styled/loadingView/LoadingView";

import { AsyncRecoverPassword } from "~componentes/app/LazyComponents";

import { Validating } from "~componentes/auth/Validating";
import LoginFormContainer from "~componentes/user/LoginContainer";
import IngresarAppInfo from "~componentes/auth/IngresarAppInfo";
import IngresarAppSuccess from "~componentes/auth/IngresarAppSuccess";
import SignupAgencia from "./signup/agenciero/SignupAgencia";
import SignupClienteFinal from "./signup/clienteFinal/SignupClienteFinal";
import authenticationReducers from "./Reducers";

const useReduxSelector = () =>
  useSelector(state => ({
    isValidacionIdentidadEnabled:
      state.featureFlagReducer.featureFlags["validacion-identidad"]
  }));
const AuthenticationRoutes = () => {
  const loading = useAsyncReducer([authenticationReducers]);
  const { isValidacionIdentidadEnabled } = useReduxSelector();
  return (
    <>
      {loading && <LoadingView />}
      {!loading && (
        <Switch>
          <Route exact path="/validating" component={Validating} />
          <Route path="/agencias/signup/:position?" component={SignupAgencia} />
          {isValidacionIdentidadEnabled && (
            <Route
              path="/clientes/signup/:position?"
              component={SignupClienteFinal}
            />
          )}
          <Route exact path="/login/old" component={LoginFormContainer} />
          <Route
            exact
            path="/login/password/:token"
            component={AsyncRecoverPassword}
          />
          <Route
            exact
            path="/actualizando/inicio"
            component={IngresarAppInfo}
          />
          <Route
            exact
            path="/actualizando/success"
            component={IngresarAppSuccess}
          />
          <Redirect to="/validating" /> {/*fallback to login*/}
        </Switch>
      )}
    </>
  );
};

export default AuthenticationRoutes;
