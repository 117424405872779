import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setVehiculoModelo, clearVehiculoModelo } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";

import ModeloVehiculoSelector from "~components/bussines/modeloVehiculoSelector/ModeloVehiculoSelector";

const useReduxSelector = () =>
  useSelector(state => {
    const nuevaSolicitudReducer = state.creditosReducers.nuevaSolicitudReducer;
    const vehiculoMarca = nuevaSolicitudReducer.vehiculoMarca;

    return {
      vehiculoMarca: vehiculoMarca ? vehiculoMarca.value : "",
      vehiculoAnio: nuevaSolicitudReducer.vehiculoAnio
    };
  });

const VehiculoModeloStep = () => {
  const dispatch = useDispatch();
  const { vehiculoMarca, vehiculoAnio } = useReduxSelector();
  useEffect(() => {
    dispatch(clearVehiculoModelo());
  }, [dispatch]);

  const handleSelection = event => {
    dispatch(setVehiculoModelo(event.target.value));
  };

  return (
    <ContentSectionWithActions>
      <PrimaryTitle label={"Modelo del vehículo"} marginBottom={60} />
      <ModeloVehiculoSelector
        label="Buscar modelo"
        marca={vehiculoMarca}
        anio={vehiculoAnio}
        onSelected={handleSelection}
      />
    </ContentSectionWithActions>
  );
};

export default VehiculoModeloStep;
