import React from "react";
import { useDispatch } from "react-redux";
import pxToRem from "~libs/pxToRem";
import Modal from "~components/styled/modal/Modal";
import LinkButton from "~components/styled/linkButton/LinkButton";
import Typography from "@material-ui/core/Typography";
import ColorLabel from "~components/styled/colorLabel/ColorLabel";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/styles/makeStyles";
import CurrencyText from "~components/styled/texts/CurrencyText";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import { setOferta } from "../../actions";

const DetalleOfertaModal = ({ onClose, opened, oferta }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const labelStyle = getSituacionStyle(oferta ? oferta.resultado : "");
  return (
    <>
      {oferta && (
        <Modal
          opened={opened}
          onClose={onClose}
          title="Detalle de la oferta"
          dialogActions={
            <div
              style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              <CallToActionButton
                label="Elegir esta oferta"
                marginBottom={8}
                onClick={() => dispatch(setOferta(oferta.id))}
              />
              <LinkButton variant="grey" onClick={onClose}>
                Volver
              </LinkButton>
            </div>
          }
        >
          <div className={classes.content}>
            <span style={{ display: "flex", alignItems: "center" }}>
              <ColorLabel
                text={labelStyle.label}
                variant={labelStyle.variant}
              />
            </span>
          </div>
          <div className={classes.content}>
            <Typography className={classes.title}>Vehículo</Typography>
            <Typography className={classes.textSmall}>Modelo 2019</Typography>
          </div>
          <div className={classes.content}>
            <Typography className={classes.title}>
              Capital financiado
            </Typography>
            <Typography className={classes.textBig}>
              <CurrencyText value={oferta.importeNetoAPercibir} />
            </Typography>
            <Typography className={classes.textSmall}>en 18 cuotas</Typography>
          </div>
          <div className={classes.content}>
            <Typography className={classes.title}>Cuota promedio</Typography>
            <Typography className={classes.textBig}>
              <CurrencyText value={oferta.importeCuotaPromedio} />
            </Typography>
          </div>
          <div className={classes.dividedContainer}>
            <div className={classes.column}>
              <Typography className={classes.title}>
                {"Primer cuota"}
              </Typography>
              <Typography className={classes.textSmall}>
                <CurrencyText value={oferta.importeCuota} />
              </Typography>
            </div>
            <Divider
              orientation="vertical"
              style={{ height: 44, margin: 10 }}
            />
            <div className={classes.column}>
              <Typography className={classes.title}>
                {"Última cuota"}
              </Typography>
              <Typography className={classes.textSmall}>
                <CurrencyText value={oferta.importeCuota} />
              </Typography>
            </div>
          </div>
          <div className={classes.content}>
            <Typography className={classes.text}>
              Recordá que el valor del seguro se sumará a la cuota promedio
              mensual.
            </Typography>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DetalleOfertaModal;
const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: theme.fontFamily,
    fontSize: pxToRem(14),
    fontWeight: "600",
    lineHeight: 1.36,
    textAlign: "center",
    color: "#9e9e9e"
  },
  textBig: {
    fontSize: pxToRem(40),
    fontWeight: "800",
    lineHeight: 1.35,
    textAlign: "center",
    color: "#424242"
  },
  textSmall: {
    fontSize: pxToRem(18),
    fontWeight: "800",
    lineHeight: 1.33,
    textAlign: "center",
    color: "#424242"
  },
  text: {
    fontFamily: theme.fontFamily,
    fontSize: pxToRem(16),
    lineHeight: 1.38,
    textAlign: "center",
    color: "#9e9e9e"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: pxToRem(20)
  },
  dividedContainer: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: 40,
    paddingRight: 40,
    marginBottom: 16
  },
  column: { display: "flex", flexDirection: "column", flexGrow: 1 }
}));
const getSituacionStyle = situacion => {
  const otros = "#c7f2fa";
  const info = "#fff7cc";
  const warning = "#f17f00";
  const success = "#d2f6e2";
  const danger = "#ffe0e0";
  if (!situacion)
    return {
      label: "Sin Situacion",
      variant: "",
      color: "#e0e0e0"
    };
  if (situacion === "AprobadoProvisorio")
    return { label: "Aprobado provisorio", variant: "otros", color: otros };
  if (situacion === "Aprobado")
    return {
      label: "Aprobado definitivo",
      variant: "success",
      color: success
    };
};
