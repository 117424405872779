import { createAction, handleActions } from "redux-actions";
import { clearOfertas } from "../ofertasForm/js/actions";

const PLAZOS_DEFAULT = [12, 18, 24, 36, 48];

export const savePrestamo = createAction(
  "PRESTAMOFORM_SUBMITFORM",
  formData => formData,
  formData => ({
    analytics: {
      eventName: "solicitud-prestamo-guardar",
      eventData: {
        id: formData.Id,
        importe: formData.Importe,
        plazo: formData.Plazo,
        tipoCredito: formData.tipoCredito
      }
    }
  })
);

export const blockedForm = createAction(
  "PRESTAMOFORM_BLOCKEDFORM",
  disabled => ({ disabled })
);

export const prestamoFormInit = createAction("PRESTAMOFORM_INIT");

export const prestamoFormLoad = createAction("PRESTAMOFORM_LOAD", data => ({
  data
}));

export const setPrestamoFormCommited = createAction(
  "SET_PRESTAMOFORM_COMMITED",
  commited => ({
    commited
  })
);

export const prestamoFormSelectPlazo = createAction(
  "PRESTAMOFORM_SELECTPLAZO",
  plazo => ({ plazo })
);

export const prestamoFormGetPlazosInited = createAction(
  "PRESTAMOFORM_GETPLAZOS_INITED"
);
export const prestamoFormGetPlazosFailed = createAction(
  "PRESTAMOFORM_GETPLAZOS_FAILED",
  error => ({ error })
);
//VER payload: {plazos: data}
export const prestamoFormGetPlazosCompleted = createAction(
  "PRESTAMOFORM_GETPLAZOS_COMPLETED",
  plazos => ({ plazos })
);

export const submitPrestamoFormInited = createAction(
  "SUBMIT_PRESTAMOFORM_INITED"
);
export const submitPrestamoFormCompleted = createAction(
  "SUBMIT_PRESTAMOFORM_COMPLETED"
);
export const submitPrestamoFormFailed = createAction(
  "SUBMIT_PRESTAMOFORM_FAILED"
);

export function prestamoFormGetPlazos(solicitudId) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(prestamoFormGetPlazosInited());
      const calificacion = await services.api.getEstadoCalificacion(
        solicitudId
      );
      let plazos;
      if (calificacion.realizada)
        plazos = await services.api.getPlazosPrestamo(solicitudId);

      if (!plazos || plazos.length === 0) plazos = PLAZOS_DEFAULT;

      dispatch(prestamoFormGetPlazosCompleted(plazos));
    } catch (error) {
      console.error(error);
      dispatch(services.errorHandler.checkErrorAction(error));
      services.errorHandler.logRemoteError(
        error,
        prestamoFormGetPlazos,
        [solicitudId],
        getState()
      );
      dispatch(prestamoFormGetPlazosFailed(error));
    }
  };
}

export function submitForm(formData, onComplete) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(submitPrestamoFormInited());
      const prestamoData = {
        Id: formData.Id,
        Importe: formData.Importe,
        Plazo: formData.Plazo
      };
      services.api.postSolicitudImporte(prestamoData);
      dispatch(clearOfertas());
      dispatch(blockedForm(true));
      const tipoCredito = getState().solicitudCreditoUI.tipoCredito;
      dispatch(savePrestamo({ ...formData, tipoCredito }));
      if (onComplete) onComplete();
      dispatch(submitPrestamoFormCompleted());
    } catch (error) {
      services.sendMessage(error.message, "error");
      dispatch(submitPrestamoFormFailed(error));
    }
  };
}

const initialState = {
  isLoading: false,
  importe: 5000,
  minPrestamo: 5000,
  plazo: null,
  plazos: [],
  stepCommited: false
};

const prestamoFormReducer = handleActions(
  {
    [prestamoFormSelectPlazo]: (state, action) => {
      return {
        ...state,
        plazo: action.payload.plazo
      };
    },

    [prestamoFormInit]: (state, action) => {
      return { ...initialState };
    },
    [prestamoFormLoad]: (state, action) => {
      return {
        ...state,
        ...action.payload.data,
        isLoading: false
      };
    },
    [submitPrestamoFormInited]: (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [submitPrestamoFormCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        stepCommited: true
      };
    },
    [submitPrestamoFormFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    },
    [savePrestamo]: (state, action) => {
      return {
        ...state,
        importe: action.payload.Importe,
        plazo: action.payload.Plazo
      };
    },

    [prestamoFormGetPlazosInited]: (state, action) => {
      return {
        ...state,
        plazos: [],

        isLoading: true
      };
    },

    [prestamoFormGetPlazosCompleted]: (state, action) => {
      let indexPlazo = action.payload.plazos.length > 1 ? 1 : 0;
      let plazo = state.plazo ? state.plazo : action.payload.plazos[indexPlazo];
      return {
        ...state,
        isLoading: false,
        plazo: plazo,
        plazos: action.payload.plazos
      };
    },
    [prestamoFormGetPlazosFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        plazos: [12, 18, 24, 36, 48],
        plazo: 18
      };
    },
    [setPrestamoFormCommited]: (state, action) => {
      return {
        ...state,
        stepCommited: action.payload.commited
      };
    }
  },
  { ...initialState }
);

export default prestamoFormReducer;
