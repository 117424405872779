import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/styles";

import { setTipoVehiculo, clearTipoVehiculo } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import { CheckboxButton } from "~components/styled/checkboxButton/CheckboxButton";

import Typography from "@material-ui/core/Typography";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

import AutosImg from "~assets/ilustrations/illus_vehic-auto.svg";
import MotosImg from "~assets/ilustrations/illus_vehic-moto.svg";
import CamionesImg from "~assets/ilustrations/illus_vehic-camion.svg";

const TipoVehiculoStep = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [autosChecked, setAutosChecked] = useState(false);
  const [motosChecked, setMotosChecked] = useState(false);
  const [camionesChecked, setCamionesChecked] = useState(false);

  const canContinue = autosChecked || motosChecked || camionesChecked;
  const handleContinue = () => {
    const suma = autosChecked + motosChecked * 2 + camionesChecked * 4;
    dispatch(setTipoVehiculo(suma));
  };

  useEffect(() => {
    dispatch(clearTipoVehiculo());
  }, [dispatch]);

  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={!canContinue}
            label={"Continuar"}
            onClick={handleContinue}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle
        label={"¿Qué clase de vehículos vende?"}
        marginBottom={20}
      />
      <Typography className={classes.subtitle}>
        Podés seleccionar uno o varios.
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: 20,
        }}
      >
        <div style={{ marginRight: 7, width: "100%" }}>
          <CheckboxButton
            checked={autosChecked}
            onClick={() => setAutosChecked((value) => !value)}
          >
            <div className={classes.container}>
              <img
                alt=""
                src={AutosImg}
                style={{ height: 72, marginBottom: 10 }}
              />
              <Typography className={classes.btnLabel}>Autos</Typography>
            </div>
          </CheckboxButton>
        </div>
        <div style={{ marginRight: 7, width: "100%" }}>
          <CheckboxButton
            checked={motosChecked}
            onClick={() => setMotosChecked((value) => !value)}
          >
            <div className={classes.container}>
              <img
                alt=""
                src={MotosImg}
                style={{ height: 72, marginBottom: 10 }}
              />
              <Typography className={classes.btnLabel}>Motos</Typography>
            </div>
          </CheckboxButton>
        </div>
        <div style={{ width: "100%" }}>
          <CheckboxButton
            checked={camionesChecked}
            onClick={() => setCamionesChecked((value) => !value)}
          >
            <div className={classes.container}>
              <img
                alt=""
                src={CamionesImg}
                style={{ height: 72, marginBottom: 10 }}
              />
              <Typography className={classes.btnLabel}>Camiones</Typography>
            </div>
          </CheckboxButton>
        </div>
      </div>
    </ContentSectionWithActions>
  );
};

export default TipoVehiculoStep;

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 130,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  btnLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    lineHeight: 1.38,
    letterSpacing: 0.48,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  subtitle: {
    marginBottom: 60,
    opacity: 0.75,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500,
    lineHeight: 1.3,
    textAlign: "left",
    color: "#424242",
  },
}));
