import React from "react";
import { Card, CardActions, CardText } from "material-ui/Card";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import IconButton from "material-ui/IconButton";
import BackIcon from "material-ui/svg-icons/navigation/arrow-back";
import { Link } from "react-router-dom";
import ResultMessage from "../shared/layout/ResultMessage";
import MessageViewer from "../../componentes/notifications/MessageViewer";
import { validateEmail } from "../shared/emailField";
import { LinearProgressLoading } from "../shared/progress";
import lang from "../../lang";
import { withRouter } from "react-router-dom";

import { makeStyles, useTheme } from "../../libs/styles";
import Fade from "react-reveal/Fade";
import Layout from "../../componentes/auth/Layout";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  header: {
    height: 64,
    backgroundColor: theme.palette.primary2Color,
    lineHeight: "64px",
    display: "flex",
    position: "fixed",
    width: "100%",
    marginTop: 0
  },
  logo: {
    display: "block",
    maxWidth: 80,
    height: "auto",
    margin: "0 auto"
  },
  text: {
    marginTop: "2%",
    color: "#663ea8",
    fontFamily: "Nunito ",
    fontSize: "1.35rem !important",
    fontWeight: "bold !important"
  }
}));

class LoginForm extends React.Component {
  state = {
    credentials: {
      username: "",
      usernameError: "",
      password: "",
      passwordError: ""
    },
    isEmailValid: false,
    isRecoveryPassword: this.props.location.search.includes("password"),
    height: window.innerHeight
  };

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.oldLoginReducer.logIn && nextProps.oldLoginReducer.user)
      this.props.history.push(
        `/actualizando/success?idEuro=${nextProps.oldLoginReducer.user.sub}`
      );
  }

  handleWindowSizeChange = () => {
    // Control landscape/portrait mode switch
    this.setState({ height: window.innerHeight });
  };

  componentDidMount() {
    this.props.openMainMenu(false);
    this.props.logOut();
  }

  recoverEmailSended = false;

  onChangeUserName = event => {
    const credentials = { ...this.state.credentials };

    credentials.username = event.target.value;
    credentials.usernameError = ""; //grabar en base a validationEmailResult

    const validationEmailResult = validateEmail(credentials.username, true);

    this.setState({
      credentials: credentials,
      isEmailValid: validationEmailResult.valid
    });
  };

  onChangePassword = event => {
    const credentials = { ...this.state.credentials };

    credentials.password = event.target.value;
    credentials.passwordError = "";

    this.setState({ credentials: credentials });
  };

  handleFinalizar = () => {
    this.setState({ isRecoveryPassword: false });
  };

  handleLogin = event => {
    if (event) event.preventDefault();

    let validation = this.validate();
    let isLoading = this.props.oldLoginReducer.isLoading;

    if (!isLoading && validation.usernameValid && validation.passwordValid) {
      this.props.logInUser(this.state.credentials);
    } else {
      let credentials = { ...this.state.credentials };
      credentials.usernameError = validation.usernameValid ? "" : "*";
      credentials.passwordError = validation.passwordValid ? "" : "*";

      this.setState({ credentials: credentials });
    }
  };

  validate = () => {
    let usernameValid =
      this.state.credentials.username &&
      this.state.credentials.username.length > 0;
    //agregar validacion this.state.isEmailValid

    let passwordValid =
      this.state.credentials.password &&
      this.state.credentials.password.length > 0;

    return { usernameValid, passwordValid };
  };

  onKeyPress = event => {
    let keyCode = event.which || event.keyCode;

    if (keyCode === 13) this.handleLogin();
  };

  handleOpenRecoveryPasswordDialog = isRecoveryPassword => () => {
    this.recoverEmailSended = false;
    this.setState({ isRecoveryPassword });
  };

  handleSendRecoveryPassword = event => {
    if (event) event.preventDefault();

    let validation = this.validate();
    let isLoading = this.props.oldLoginReducer.isLoading;

    if (!isLoading && validation.usernameValid) {
      this.recoverEmailSended = true;

      this.props.sendRecoveryPasswordEmail(this.state.credentials.username);
    }
  };

  renderLoginForm = () => {
    const { height } = this.state;
    const showRegistrarAgencia = height >= 450;
    const classes = useStyles();

    return (
      <Layout>
        <LinearProgressLoading
          isLoading={this.props.oldLoginReducer.isLoading}
        />
        <Card
          style={{
            maxWidth: 450,
            margin: "auto",
            height: "70vh",
            padding: 20,
            backgroundColor: "#fff",
            borderRadius: 8,
            boxShadow: "rgba(0, 0, 0, 0.12) 7px 12px 20px 5px"
          }}
          containerStyle={{ marginTop: 20 }}
        >
          <Typography className={classes.text}>
            Ingresá tu Email y contraseña anteriores
          </Typography>
          <CardText>
            <TextField
              autoFocus
              disabled={this.props.oldLoginReducer.isLoading}
              floatingLabelFixed={true}
              floatingLabelText={lang.loginForm.emailLabel}
              floatingLabelShrinkStyle={{ textTransform: "uppercase" }}
              fullWidth={true}
              value={this.state.credentials.username}
              onChange={this.onChangeUserName}
              onKeyPress={this.onKeyPress}
              errorText={this.state.credentials.usernameError}
              type="email"
            />
            <TextField
              disabled={this.props.oldLoginReducer.isLoading}
              type="password"
              floatingLabelFixed={true}
              floatingLabelText={lang.loginForm.passwordLabel}
              floatingLabelShrinkStyle={{ textTransform: "uppercase" }}
              fullWidth={true}
              value={this.state.credentials.password}
              onChange={this.onChangePassword}
              onKeyPress={this.onKeyPress}
              onBlur={this.onBlurFields}
              errorText={this.state.credentials.passwordError}
            />
          </CardText>
          <CardActions>
            <RaisedButton
              label={lang.loginForm.submitLabel}
              secondary
              onClick={this.handleLogin}
              disabled={this.props.oldLoginReducer.isLoading}
              fullWidth={true}
              style={{
                margin: "0 8px",
                borderRadius: "0 !important",
                height: 50,
                display: "block !important",
                boxShadow: "0px 3px 10px 0px rgba(0,0,0,0.3) !important",
                minWidth: "0 !important"
              }}
            />
          </CardActions>
          <span
            style={{
              cursor: "pointer",
              color: "#337ab7",
              fontSize: 14,
              display: "block",
              textAlign: "center",
              marginTop: 15
            }}
            onClick={
              !this.props.oldLoginReducer.isLoading
                ? this.handleOpenRecoveryPasswordDialog(true)
                : null
            }
          >
            {lang.loginForm.forgotPasswordLabel}
          </span>
        </Card>
        <div
          style={{
            padding: "20px 0",
            fontSize: 14,
            borderTop: "1px solid #ddd",
            textAlign: "center",
            maxWidth: 390,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: -80,
            display: showRegistrarAgencia ? "block" : "none"
          }}
        >
          <span style={{ display: "inline-block" }}>¿No tenés usuario?</span>
          <Link
            to={`/agencias/signup`}
            style={{
              display: "inline-block",
              marginLeft: 10
            }}
          >
            <span style={{ cursor: "pointer", color: "#337ab7" }}>
              {lang.loginForm.createAcountLabel}
            </span>
          </Link>
        </div>
      </Layout>
    );
  };

  renderRecoveryPasswordForm = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isRecoverPasswordError = this.props.oldLoginReducer.error
      ? true
      : false;
    this.recoverEmailSended = isRecoverPasswordError
      ? false
      : this.recoverEmailSended;

    return (
      <div>
        <React.Fragment>
          <div className={classes.header}>
            <div>
              <IconButton
                onClick={
                  !this.props.oldLoginReducer.isLoading
                    ? this.handleOpenRecoveryPasswordDialog(false)
                    : null
                }
              >
                <BackIcon color="#fff" />
              </IconButton>
            </div>
            <div style={{ textAlign: "center", flex: 1 }}>
              <span
                style={{
                  color: "#fff",
                  fontSize: 16
                }}
              >
                Olvidé mi contraseña
              </span>
            </div>
            <div style={{ textAlign: "right", width: 48 }} />
          </div>
        </React.Fragment>
        {(!this.recoverEmailSended || this.props.oldLoginReducer.isLoading) && (
          <div style={{ display: "block", paddingTop: 64 }}>
            <div style={{ padding: 20, paddingTop: 0 }}>
              <Fade>
                <LinearProgressLoading
                  isLoading={this.props.oldLoginReducer.isLoading}
                  fixedHeight={true}
                />
                <TextField
                  value={this.state.credentials.username}
                  disabled={this.props.oldLoginReducer.isLoading}
                  floatingLabelFixed={true}
                  floatingLabelText={lang.loginForm.emailLabel}
                  floatingLabelShrinkStyle={{
                    textTransform: "uppercase",
                    marginTop: -2
                  }}
                  fullWidth={true}
                  autoFocus={true}
                  underlineShow={true}
                  onChange={this.onChangeUserName}
                  onKeyPress={this.onKeyPress}
                  onBlur={this.onBlurFields}
                  errorText={this.state.credentials.usernameError}
                  type="email"
                  inputStyle={{ fontSize: 20, marginTop: 12 }}
                  errorStyle={{
                    display: "table",
                    float: "right",
                    color: theme.palette.errorColor
                  }}
                />
              </Fade>
            </div>
            <div
              style={{
                display: "block",
                position: "fixed",
                bottom: "20px",
                height: 50,
                zIndex: 99,
                width: "100%"
              }}
            >
              <RaisedButton
                label={lang.loginForm.recoveryPasswordLabel}
                secondary={true}
                disabled={!this.state.isEmailValid}
                onClick={this.handleSendRecoveryPassword}
                fullWidth={true}
                style={{
                  margin: "0 15px 0 15px",
                  borderRadius: 0,
                  height: 50,
                  display: "block",
                  boxShadow: "0px 3px 10px 0px rgba(0,0,0,0.3)",
                  minWidth: "0"
                }}
              />
            </div>
          </div>
        )}
        {this.recoverEmailSended && !this.props.oldLoginReducer.isLoading && (
          <div style={{ display: "block", paddingTop: 64 }}>
            <div style={{ padding: 20, display: "block" }}>
              <Fade>
                <ResultMessage
                  color={theme.palette.success7Color}
                  backgroundColor={theme.palette.success6Color}
                  borderColor={theme.palette.success8Color}
                  fontIcon={"done"}
                  title={"Email enviado"}
                  message={
                    <React.Fragment>
                      Te hemos enviado un correo para que puedas cambiar tu
                      contraseña. Seguí las instrucciones indicadas en el mismo.
                      <br />
                      <br />
                    </React.Fragment>
                  }
                />
              </Fade>
            </div>
            <div
              style={{
                display: "block",
                position: "fixed",
                bottom: "20px",
                height: 50,
                zIndex: 99,
                width: "100%"
              }}
            >
              <RaisedButton
                label="Finalizar"
                onClick={this.handleFinalizar}
                secondary={true}
                fullWidth={true}
                style={{
                  margin: "0 15px 0 15px",
                  borderRadius: 0,
                  height: 50,
                  display: "block",
                  boxShadow: "0px 3px 10px 0px rgba(0,0,0,0.3)",
                  minWidth: "0"
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div data-testid={"login-form"}>
        <MessageViewer />
        {!this.state.isRecoveryPassword && this.renderLoginForm()}
        {this.state.isRecoveryPassword && this.renderRecoveryPasswordForm()}
      </div>
    );
  }
}

export default withRouter(LoginForm);
