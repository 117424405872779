import React from "react";
import { useDispatch } from "react-redux";
import pxToRem from "~libs/pxToRem";
import Modal from "~components/styled/modal/Modal";
import LinkButton from "~components/styled/linkButton/LinkButton";
import Typography from "@material-ui/core/Typography";

import makeStyles from "@material-ui/styles/makeStyles";
import CurrencyText from "~components/styled/texts/CurrencyText";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import LogoBerkley from "~assets/companiasDetalle/logo-berkley.svg";
import LogoMercantilAndina from "~assets/companiasDetalle/logo-mercantilAndina.svg";
import LogoRUS from "~assets/companiasDetalle/logo-rus.svg";
import LogoSancor from "~assets/companiasDetalle/logo-sancor.svg";
import LogoZurich from "~assets/companiasDetalle/logo-zurich.svg";
import { tipoPolizaDescription } from "./tipoPoliza";
import { setPoliza } from "../../actions";

const DetallePolizaModal = ({ onClose, opened, poliza }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const companyLogo = poliza && getCompanyLogo[poliza.companiaId];
  const tipoPoliza = poliza && tipoPolizaDescription[poliza.tipoPlan];
  return (
    <>
      {poliza && (
        <Modal
          opened={opened}
          onClose={onClose}
          title="Detalle de la póliza"
          dialogActions={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <CallToActionButton
                label="Elegir esta póliza"
                marginBottom={8}
                onClick={() => dispatch(setPoliza(poliza))}
              />
              <LinkButton variant="grey" onClick={onClose}>
                Volver
              </LinkButton>
            </div>
          }
        >
          {companyLogo && (
            <div className={classes.content}>
              <img src={companyLogo} alt="compania" />
            </div>
          )}
          <div className={classes.content}>
            <Typography className={classes.tipoPoliza}>{tipoPoliza}</Typography>
            <Typography className={classes.nombrePoliza}>
              {poliza.nombre}
            </Typography>
          </div>
          <div
            className={classes.content}
            style={{ flexDirection: "row", justifyContent: "center" }}
          >
            <Typography className={classes.cuotaPoliza}>
              <CurrencyText value={poliza.importe} />
            </Typography>
            <Typography className={classes.nombrePoliza}>/mes</Typography>
          </div>
          <div className={classes.content}>
            <Typography className={classes.detallePoliza}>
              {poliza.descripcion}
            </Typography>
          </div>
        </Modal>
      )}
    </>
  );
};

const getCompanyLogo = {
  9: LogoBerkley,
  19: LogoMercantilAndina,
  20: LogoRUS,
  11: LogoSancor,
  15: LogoZurich,
};

export default DetallePolizaModal;

const useStyles = makeStyles((theme) => ({
  tipoPoliza: {
    fontSize: pxToRem(14),
    fontWeight: "800",
    lineHeight: 2.5,
    textAlign: "center",
    color: "#a7a7a7",
  },
  nombrePoliza: {
    fontSize: pxToRem(20),
    fontWeight: "800",
    lineHeight: 1.2,
    textAlign: "center",
    color: "#45459c",
  },
  detallePoliza: {
    fontSize: pxToRem(18),
    fontWeight: 500,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#5b5d60",
  },
  cuotaPoliza: {
    fontSize: pxToRem(48),
    fontWeight: 800,
    lineHeight: 1.65,
    textAlign: "center",
    color: "#45459c",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: pxToRem(20),
  },
}));
