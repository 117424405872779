import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import pxToRem from "~libs/pxToRem";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import Typography from "@material-ui/core/Typography/Typography";
//import {   } from "../../actions";

const SituacionNoFinanciable = () => {
  const dispatch = useDispatch();
  const handleCargarContacto = () => {};
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            label={"Cargar contacto"}
            onClick={handleCargarContacto}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle
        label={
          "Ninguno de nuestros productos predefinidos se ajusta al perfil de tu cliente"
        }
        marginBottom={60}
      />
      <div>
        <Typography
          style={{
            opacity: 0.75,
            fontSize: pxToRem(16),
            lineHeight: 1.38,
            textAlign: "left",
            color: "#000000",
            marginBottom: 35
          }}
        >
          Te vamos a pedir que nos dejes un contacto del cliente para evaluar
          una solución personalizada. En caso de poder brindársela, podrás
          visualizar esta solicitud en tu lista de Solicitudes.
        </Typography>
      </div>
    </ContentSectionWithActions>
  );
};

export default SituacionNoFinanciable;
