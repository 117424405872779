import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "~componentes/Auth0/useAuth";

import { makeStyles } from "@material-ui/styles";
import pxToRem from "~libs/pxToRem";

import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

import WaveLayout from "~components/styled/layouts/WaveLayout";
import BigCard from "~components/styled/bigCard/BigCard";
import LinkButton from "~components/styled/linkButton/LinkButton";
import Typography from "@material-ui/core/Typography";
import { submitSignupClienteFinal } from "./actions";
import loading from "~assets/animations/loading.json";
import Lottie from "lottie-react-web";
import NewUserIllus from "~assets/ilustrations/illus_cuenta-pers_sm.svg";
const useReduxSelector = () =>
  useSelector(state => {
    const signupClienteFinalReducer =
      state.authenticationReducers.signupClienteFinalReducer;

    return {
      isLoading: signupClienteFinalReducer.isLoading
    };
  });

const SignupClienteFinal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { authState } = useAuth0();
  const { isLoading } = useReduxSelector();
  const handleContinue = () => {
    if (authState.user && !isLoading) {
      const userName = authState.user.name
        ? authState.user.name
        : authState.user.email;
      dispatch(submitSignupClienteFinal(userName, () => history.push("/")));
    }
  };

  return (
    <WaveLayout>
      <ContentSection>
        <BigCard
          title="Nueva cuenta personal"
          content={
            <>
              {isLoading && (
                <div className={classes.loadingContainer}>
                  <div style={{ width: 60 }}>
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: loading
                      }}
                    />
                  </div>
                </div>
              )}
              {!isLoading && (
                <>
                  <img
                    src={NewUserIllus}
                    style={{ height: 161, marginBottom: 32 }}
                  />
                  <Typography className={classes.text}>
                    Accedé a toda la info sobre tus créditos y pólizas, informá
                    tus pagos, descargá tus comprobantes y mucho más.
                  </Typography>
                </>
              )}
            </>
          }
          footer={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
              }}
            >
              <CallToActionButton
                disabled={isLoading}
                label={"¡VAMOS!"}
                marginBottom={12}
                onClick={handleContinue}
              />
              <LinkButton
                onClick={() => {
                  !isLoading && history.push("/");
                }}
              >
                Volver
              </LinkButton>
            </div>
          }
        />
      </ContentSection>
    </WaveLayout>
  );
};

export default SignupClienteFinal;

const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(16),
    lineHeight: 1.38,
    textAlign: "center",
    color: "#616161"
  },
  loadingContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1
  }
}));
