import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListView from "~components/styled/listView/ListView";
import Searcher from "~components/styled/searcher/Searcher";
import filterItems from "~libs/filterItems";
import { fetchCodigosPostales } from "./actions";
const useStyles = makeStyles(theme => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-around"
  }
}));

const useReduxSelector = () =>
  useSelector(state => {
    const codigoPostalSelectorReducer = state.codigoPostalSelectorReducer;

    return {
      codigosPostales: codigoPostalSelectorReducer.codigosPostales,
      isLoading: codigoPostalSelectorReducer.isLoading
    };
  });
const CodigoPostalSelector = ({
  label,
  onSelected,
  provinciaId,
  localidad = ""
}) => {
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();
  const { codigosPostales, isLoading } = useReduxSelector();

  useEffect(() => {
    if (provinciaId) dispatch(fetchCodigosPostales(provinciaId, localidad));
  }, []);

  const handleItemSelected = item => {
    setFilter("");
    if (onSelected) onSelected({ target: { value: item.value } });
  };

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  const classes = useStyles();

  return (
    <div className={classes.searcher}>
      <Searcher
        onClear={() => handleItemSelected({ value: null, label: null })}
        filter={filter}
        onChange={handleFilterChange}
        placeholder={label}
      />
      <ListView
        isLoading={isLoading}
        onItemSelected={handleItemSelected}
        list={
          filter === "" ? codigosPostales : filterItems(codigosPostales, filter)
        }
      />
    </div>
  );
};

export default CodigoPostalSelector;
