import { createAction, handleActions } from "redux-actions";
import {
  changeSolicitudId,
  setSolicitudSequenceFinish,
  setFinishComponent
} from "../solicitudCredito/js/actions";
import { submitForm } from "../autoForm/actions";
import { RN_SCAN_DNI } from "../nativeAppMessaging/js/actions";

export const resetForm = createAction("IDENTIDADFORM_CLEAR");
export const identidadFormInit = createAction("IDENTIDADFORM_INIT");
export const identidadFormLoad = createAction("IDENTIDADFORM_LOAD", data => ({
  data
}));

export const setIdentidadFormCommited = createAction(
  "SET_IDENTIDADFORMFORM_COMMITED",
  commited => ({ commited })
);

export const submitIdentidad = createAction(
  "IDENTIDADFORM_SUBMITFORM",
  formData => ({ formData })
);

export const blockedForm = createAction(
  "IDENTIDADFORM_BLOCKEDFORM",
  disabled => ({ disabled })
);

export const selectDni = createAction("IDENTIDADFORM_SELECTDNI", dni => ({
  dni
}));

export const selectSexo = createAction("IDENTIDADFORM_SELECTSEXO", sexo => ({
  sexo
}));

export const selectEstadoCivil = createAction(
  "IDENTIDADFORM_SELECTESTADOCIVIL",
  estadoCivil => ({ estadoCivil })
);

export const selectDniConyuge = createAction(
  "IDENTIDADFORM_SELECTDNICONYUGE",
  dni => ({ dni })
);

export const selectSexoConyuge = createAction(
  "IDENTIDADFORM_SELECTSEXOCONYUGE",
  sexo => ({ sexo })
);

const fetchIdentidadInited = createAction("FETCHIDENTIDAD_INITED");
const fetchIdentidadCompleted = createAction(
  "FETCHIDENTIDAD_COMPLETED",
  response => ({ response })
);
const fetchIdentidadFailed = createAction("FETCHIDENTIDAD_FAILED", error => ({
  error
}));
const fetchIdentidadConyugeInited = createAction(
  "FETCHIDENTIDADCONYUGE_INITED"
);
const fetchIdentidadConyugeCompleted = createAction(
  "FETCHIDENTIDADCONYUGE_COMPLETED",
  response => ({ response })
);
const fetchIdentidadConyugeFailed = createAction(
  "FETCHIDENTIDADCONYUGE_FAILED",
  error => ({ error })
);

const fetchAptoCreditoInited = createAction("FETCHAPTOCREDITO_INITED");
const fetchAptoCreditoCompleted = createAction(
  "FETCHAPTOCREDITO_COMPLETED",
  response => ({ ...response }),
  response => ({
    analytics: {
      eventName: "solicitud-identidad-guardar",
      eventData: response
    }
  })
);
const fetchAptoCreditoFailed = createAction(
  "FETCHAPTOCREDITO_FAILED",
  error => ({ error })
);

const submitIdentidadFormInited = createAction("SUBMITIDENTIDAD_INITED");
const submitIdentidadFormCompleted = createAction("SUBMITIDENTIDAD_COMPLETED");
const submitIdentidadFormFailed = createAction(
  "SUBMITIDENTIDAD_FAILED",
  error => ({ error })
);

export const fetchIdentidad = (documento, sexo) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchIdentidadInited());
      const response = await services.api.getIdentidad(documento, sexo);
      dispatch(fetchIdentidadCompleted(response));
    } catch (error) {
      dispatch(fetchIdentidadFailed(error));
    }
  };
};

export const fetchIdentidadConyuge = (documento, sexo) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchIdentidadConyugeInited());
      const response = await services.api.getIdentidad(documento, sexo);
      dispatch(fetchIdentidadConyugeCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(fetchIdentidadConyugeFailed(error));
    }
  };
};

export const fetchAptoCredito = (
  canal,
  tipoCredito,
  documento,
  sexo,
  nombres,
  apellidos,
  estadoCivil,
  documentoConyuge,
  sexoConyuge,
  nombresConyuge,
  apellidosConyuge
) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchAptoCreditoInited());
      const request = {
        canal: canal,
        TipoCredito: tipoCredito,
        Documento: documento,
        Sexo: sexo,
        Nombres: nombres,
        Apellidos: apellidos,
        EstadoCivil: estadoCivil,
        DocumentoConyuge: documentoConyuge,
        SexoConyuge: sexoConyuge,
        NombresConyuge: nombresConyuge,
        ApellidosConyuge: apellidosConyuge
      };
      const response = await services.api.postSolicitudNueva(request);
      dispatch(changeSolicitudId(response.id));
      dispatch(submitIdentidad(request));

      if (tipoCredito === 3) {
        const vehiculo = getState().autoForm;
        const requestAuto = {
          Id: response.id,
          Codia: vehiculo.selectedModelo.value,
          Anio: vehiculo.selectedAnio.value,
          Es0Km: false,
          TieneGNC: vehiculo.selectedGnc.value,
          Uso: vehiculo.selectedTipoUso.value,
          ValorDeCompra: vehiculo.valorAuto
        };
        dispatch(submitForm(requestAuto));
      }
      dispatch(fetchAptoCreditoCompleted({ response, tipoCredito }));
    } catch (error) {
      dispatch(services.errorHandler.checkErrorAction(error));
      if (
        error &&
        error.responseStatus &&
        error.responseStatus.errorCode === "GenericError-StatusCode-403"
      )
        error.responseStatus.errorCode = "AgenciaBloqueada";
      dispatch(fetchAptoCreditoFailed(error));
    }
  };
};

export function submitIdentidadForm(solicitudId, onSucces) {
  return async (dispatch, getState, services) => {
    dispatch(submitIdentidadFormInited());
    try {
      const request = { Id: solicitudId };
      await services.api.postContinuarSolicitud(request);
      dispatch(blockedForm(true));
      dispatch(submitIdentidadFormCompleted());
      if (onSucces) onSucces();
    } catch (error) {
      if (
        error &&
        error.responseStatus &&
        error.responseStatus.errorCode === "SolicitudPreviaEnAnalisis"
      ) {
        dispatch(setSolicitudSequenceFinish(true));
        dispatch(
          setFinishComponent(
            "SolicitudPreviaEnAnalisis",
            error.responseStatus.meta
          )
        );
      } else {
        console.error(error);

        services.errorHandler.logRemoteError(
          error,
          submitIdentidadForm,
          [solicitudId],
          getState()
        );
        dispatch(services.errorHandler.checkErrorAction(error));
      }
      dispatch(submitIdentidadFormFailed(error));
    }
  };
}

const initialState = {
  isLoading: false,
  disabled: false,
  dni: null,
  sexo: null,
  errorIdentidad: null,
  nombre: null,
  apellido: null,
  estadoCivil: null,
  nombreConyuge: null,
  apellidoConyuge: null,
  dniConyuge: null,
  sexoConyuge: null,
  isAptoCredito: null,
  revisar: null,
  isAptoObtenido: null,
  errorAptoCredito: null,
  fechaNacimiento: null,
  stepCommited: false
};

const identidadFormReducer = handleActions(
  {
    [submitIdentidadFormInited]: (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [submitIdentidadFormCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        stepCommited: true
      };
    },
    [submitIdentidadFormFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    },
    [selectDni]: (state, action) => {
      return {
        ...state,
        dni: action.payload.dni,
        isAptoCredito: null,
        errorAptoCredito: null
      };
    },
    [selectSexo]: (state, action) => {
      return {
        ...state,
        sexo: action.payload.sexo,
        isAptoCredito: null,
        errorAptoCredito: null
      };
    },
    [RN_SCAN_DNI]: (state, action) => {
      return {
        ...state,
        dni: action.payload.dni,
        sexo: {
          label: action.payload.sexo === "M" ? "Masculino" : "Femenino",
          value: action.payload.sexo
        },
        nombre: action.payload.nombre,
        apellido: action.payload.apellido,
        isAptoCredito: null,
        errorAptoCredito: null
      };
    },
    [selectEstadoCivil]: (state, action) => {
      return {
        ...state,
        estadoCivil: action.payload.estadoCivil,
        isAptoCredito: null,
        errorAptoCredito: null
      };
    },
    [selectDniConyuge]: (state, action) => {
      return {
        ...state,
        dniConyuge: action.payload.dni,
        isAptoCredito: null,
        errorAptoCredito: null
      };
    },
    [selectSexoConyuge]: (state, action) => {
      return {
        ...state,
        sexoConyuge: action.payload.sexo,
        isAptoCredito: null,
        errorAptoCredito: null
      };
    },
    [fetchIdentidadInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        nombre: null,
        apellido: null,
        errorIdentidad: null
      };
    },
    [fetchIdentidadCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        nombre: action.payload.response.nombres,
        apellido: action.payload.response.apellido,
        fechaNacimiento: action.payload.response.fechaNacimiento,
        errorIdentidad: null
      };
    },
    [fetchIdentidadFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        nombre: null,
        apellido: null,
        errorIdentidad: action.payload.error
      };
    },
    [fetchIdentidadConyugeInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        nombreConyuge: null,
        apellidoConyuge: null,
        errorIdentidad: null
      };
    },
    [fetchIdentidadConyugeCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        nombreConyuge: action.payload.response.nombres,
        apellidoConyuge: action.payload.response.apellido,
        errorIdentidad: null
      };
    },
    [fetchIdentidadConyugeFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        nombreConyuge: null,
        apellidoConyuge: null,
        errorIdentidad: action.payload.error
      };
    },
    [resetForm]: (state, action) => {
      return {
        ...state,
        dni: null,
        sexo: null,
        estadoCivil: null,
        nombre: null,
        apellido: null,
        dniConyuge: null,
        sexoConyuge: null,
        nombreConyuge: null,
        apellidoConyuge: null,
        isAptoCredito: null,
        disabled: false
      };
    },
    [fetchAptoCreditoInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        isAptoCredito: null,
        errorAptoCredito: null
      };
    },
    [fetchAptoCreditoCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isAptoCredito: action.payload.response.aptoCredito,
        isAptoObtenido: action.payload.response.aptoObtenido,
        revisar: action.payload.response.revisar,
        errorAptoCredito: null
      };
    },
    [fetchAptoCreditoFailed]: (state, action) => {
      const errorAptoCredito =
        action.payload.error && action.payload.error instanceof Error
          ? action.payload.error.message
          : action.payload.error;
      return {
        ...state,
        isLoading: false,
        disabled: true,
        isAptoCredito: null,
        errorAptoCredito: errorAptoCredito
      };
    },
    [submitIdentidad]: (state, action) => {
      return {
        ...state
      };
    },
    [blockedForm]: (state, action) => {
      return {
        ...state,
        disabled: action.payload.disabled
      };
    },
    [identidadFormLoad]: (state, action) => {
      return {
        ...state,
        ...action.payload.data,
        isLoading: false
      };
    },
    [setIdentidadFormCommited]: (state, action) => {
      return {
        ...state,
        stepCommited: action.payload.commited
      };
    },
    [identidadFormInit]: (state, action) => {
      return initialState;
    }
  },
  { ...initialState }
);

export default identidadFormReducer;
