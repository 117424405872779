import { combineReducers } from "redux";
import cotizadorReducer from "./cotizador/actions";

const combinedReducers = combineReducers({
  cotizadorReducer,
});

const segurosReducers = {
  key: "segurosReducers",
  value: combinedReducers,
};

export default segurosReducers;
