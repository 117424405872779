import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import DetailsTabs from "~components/styled/layouts/detailsLayout/DetailsTabs";
import DetailsLayout from "~components/styled/layouts/detailsLayout/DetailsLayout";
import ContentSection from "~components/styled/contentSection/ContentSection";

import DetalleCreditoTab from "~components/app/clienteFinal/creditos/detalleCredito/DetalleCreditoTab";
import ResumenCreditoTab from "~components/app/clienteFinal/creditos/detalleCredito/ResumenCreditoTab";

const useReduxSelector = () =>
  useSelector(state => {
    const detalleCreditoReducer = state.creditosReducers.detalleCreditoReducer;
    const listadoCuotasReducer =
      state.creditosReducers.listadoCuotasCreditoReducer;
    return {
      credito: detalleCreditoReducer.credito,
      isLoadingCuotas: listadoCuotasReducer.isLoading
    };
  });

const tipoCreditoOptions = {
  "1": "prendario",
  "3": "autoprenda"
};

const DetalleCredito = () => {
  const { credito, isLoadingCuotas } = useReduxSelector();
  const [index, setIndex] = useState(0);
  const history = useHistory();

  const tipoCredito =
    credito && tipoCreditoOptions[`${credito.credito.tipoCredito}`];

  return (
    <DetailsLayout
      title={`Crédito ${tipoCredito ? tipoCredito : ""}`}
      tabsComponent={() => (
        <DetailsTabs
          disabled={isLoadingCuotas}
          defaultValue={0}
          labels={["RESUMEN", "DETALLE"]}
          onChange={event => {
            setIndex(event.target.value);
          }}
        />
      )}
      onBackClick={() => {
        history.push("/miscreditos");
      }}
    >
      <ContentSection>
        {index === 0 && <ResumenCreditoTab />}
        {index === 1 && <DetalleCreditoTab />}
      </ContentSection>
    </DetailsLayout>
  );
};

export default DetalleCredito;
