import { createActions, handleActions } from "redux-actions";

const {
  creditos: {
    listadoCreditosVigentes: {
      getCreditosVigentesInited,
      getCreditosVigentesCompleted,
      getCreditosVigentesFailed
    }
  }
} = createActions({
  creditos: {
    listadoCreditosVigentes: {
      getCreditosVigentesInited: () => ({}),
      getCreditosVigentesCompleted: response => ({ response }),
      getCreditosVigentesFailed: error => ({
        error
      })
    }
  }
});

const getCreditosVigentes = () => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getCreditosVigentesInited());
      const response = await services.api.getCreditosVigentes();
      dispatch(getCreditosVigentesCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getCreditosVigentesFailed(error));
    }
  };
};

const initialState = {
  isLoading: false,
  creditos: []
};
const listadoCreditosVigentesReducer = handleActions(
  {
    [getCreditosVigentesInited]: (state, action) => {
      return {
        ...state,
        isLoading: !state.creditos.length > 0
      };
    },
    [getCreditosVigentesCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        creditos: action.payload.response
      };
    },
    [getCreditosVigentesFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    }
  },
  initialState
);

export default listadoCreditosVigentesReducer;
export { getCreditosVigentes };
