import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setVehiculoAnio, clearVehiculoAnio } from "../actions";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import AnioSelector from "~components/bussines/anioSelector/AnioSelector";

const AnioStep = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearVehiculoAnio());
  }, [dispatch]);

  return (
    <ContentSectionWithActions>
      <PrimaryTitle label={"Elegí el año del vehículo"} marginBottom={60} />
      <AnioSelector
        label={"Buscar año"}
        cantAnios={16}
        onSelected={event =>
          dispatch(
            setVehiculoAnio({
              label: event.target.value,
              value: event.target.value
            })
          )
        }
      />
    </ContentSectionWithActions>
  );
};

export default AnioStep;
