import { combineReducers } from "redux";
import listadoPolizasReducer from "./homeSeguros/actions";
import detallePolizaReducer from "./detallePoliza/actions";
import cotizadorReducer from "./cotizador/actions";

const combinedReducers = combineReducers({
  listadoPolizasReducer,
  detallePolizaReducer,
  cotizadorReducer,
});
const segurosReducers = {
  key: "segurosReducers",
  value: combinedReducers,
};

export default segurosReducers;
