import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import icon_tc_incendio from "~assets/icons/icon_tc_incendio.svg";
import icon_tr from "~assets/icons/icon_tr.svg";
import CompaniaGroup from "./CompaniaGroup";
import DetallePolizaModal from "./DetallePolizaModal";

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: "flex",
    position: "sticky",
    top: 0,
    zIndex: 1,
    alignItems: "flex-end",
    width: "100%",
    justifyContent: "center",
    background: "#ffffff"
  },
  iconTextContainer: {
    flex: 1,
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  headerColumn: {
    flex: 1,
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
    //borderBottom: "5px solid #45459c"
  },
  headerColumnMiddle: {
    flex: 1,
    textAlign: "center",
    display: "flex",
    alignItems: "center"
    //borderBottom: "5px solid #6868bf"
  },
  divider: {
    width: 1,
    height: 90
  },
  headerTitle: {
    textAlign: "center",
    fontWeight: 800,
    paddingTop: 5,
    color: "#45459c",
    lineHeight: 1.1,
    display: "block",
    flex: 1,
    whiteSpace: "pre-wrap"
  },
  polizasContainer: {
    paddingBottom: 56
  },
  contratar: {
    display: "flex",
    position: "absolute",
    bottom: "0",
    height: "56px",
    width: 250,
    color: "#ffffff",
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    borderRadius: 50,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4"
    }
  }
}));

const PENDIENTE = "pendiente";

const PolizaSelector = ({ cotizaciones }) => {
  const [opened, setOpened] = useState(false);
  const [polizaModal, setPolizaModal] = useState(null);
  const classes = useStyles();
  const handleOpen = item => {
    setPolizaModal(item);
    setOpened(true);
  };
  const onClose = () => {
    setOpened(false);
    setPolizaModal(null);
  };

  return (
    <div
      style={{
        position: "relative",
        minHeight: "calc( 100vh - 120px )"
      }}
    >
      <DetallePolizaModal
        onClose={onClose}
        opened={opened}
        poliza={polizaModal}
      />
      <Paper className={classes.headerContainer} elevation={1} square>
        <div className={classes.headerColumn}>
          <img src={icon_tc_incendio} alt="" />
          <Typography className={classes.headerTitle}>
            {"Terceros\ncompleto"}
          </Typography>
        </div>
        <div className={classes.headerColumn}>
          <img src={icon_tr} alt="" />
          <Typography className={classes.headerTitle}>
            {"Todo\nRiesgo"}
          </Typography>
        </div>
      </Paper>
      <div className={classes.polizasContainer}>
        <PolizaList
          companias={cotizaciones.companias}
          onPolizaSelected={handleOpen}
        />
        {/* {countComplete < MAX_LOADING && (
              <PolizaList
                polizas={polizasCompleted}
                count={MAX_LOADING}
                onPolizaSelected={handleOpen}
              />
            )}
            {countComplete >= MAX_LOADING &&
              countComplete < polizasCompleted.length && (
                <PolizaList
                  polizas={polizasCompleted}
                  count={countComplete + 1}
                  onPolizaSelected={handleOpen}
                />
              )}
            {countComplete === polizas.length && (
              <PolizaList
                polizas={polizasCompleted}
                count={countComplete}
                onPolizaSelected={handleOpen}
              />
            )} */}
      </div>
    </div>
  );
};
export default PolizaSelector;

const PolizaList = ({ companias, onPolizaSelected }) => {
  return companias.map(compania => {
    return (
      <CompaniaGroup
        key={compania.companiaId}
        isLoading={compania.estado === PENDIENTE}
        compania={compania}
        onPolizaSelected={onPolizaSelected}
      />
    );
  });
};
