import { createActions, handleActions } from "redux-actions";
export const SEGUROS_QUIERO_QUE_ME_CONTACTEN =
  "SEGUROS_QUIERO_QUE_ME_CONTACTEN";
export const { datosAseguradoSet, datosAutoSet, initContacto } = createActions(
  "DATOS_ASEGURADO_SET",
  "DATOS_AUTO_SET",
  "INIT_CONTACTO"
);

export const sendContactoData = () => {
  return async (dispatch, getState, services) => {
    const contactoData = getState().contactoSegurosReducer;
    const cotizacionData = getState().cotizadorSegurosReducer;

    const cotizacionId = cotizacionData.cotizacionId;
    const nombre = contactoData.datosAsegurado.nombre;
    const dni = contactoData.datosAsegurado.dni;
    const telefono = contactoData.datosAsegurado.telefono;
    const telefonoMovil = contactoData.datosAsegurado.telefonoMovil;
    const coberturaSeleccionadaId =
      cotizacionData.seguroSelected.cotizacionPlanId;
    const direccion = contactoData.datosAsegurado.direccion;
    const fechaNacimiento = contactoData.datosAsegurado.fechaNacimiento;

    const sexo = contactoData.datosAsegurado.sexo;

    //optional fields
    const email =
      contactoData.datosAsegurado.email &&
      contactoData.datosAsegurado.email.value
        ? contactoData.datosAsegurado.email.value
        : "";
    const dominio = contactoData.datosAuto.patente;
    const numeroMotor = contactoData.datosAuto.nroMotor;
    const numeroChasis = contactoData.datosAuto.nroChasis;

    const data = {
      CoberturaSeleccionadaId: coberturaSeleccionadaId,
      Nombre: nombre,
      DNI: dni,
      Email: email,
      Telefono: telefono,
      EsTelefonoMovil: telefonoMovil,
      Direccion: direccion,
      FechaNacimiento: fechaNacimiento,
      Sexo: sexo,
      Dominio: dominio,
      NumeroMotor: numeroMotor,
      NumeroChasis: numeroChasis
    };

    try {
      dispatch({
        type: SEGUROS_QUIERO_QUE_ME_CONTACTEN,
        analytics: {
          eventName: "cotizador-seguros-quiero-que-me-contacten",
          eventData: data
        }
      });

      await services.api.patchSegurosQuieroQueMeContacten(cotizacionId, data);
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        sendContactoData,
        [data],
        getState()
      );

      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};

const initialState = {
  datosAsegurado: "",
  datosAuto: "",
  complete: false
};

const contactoSegurosReducer = handleActions(
  {
    [datosAseguradoSet]: (state, action) => {
      return {
        ...state,
        datosAsegurado: action.payload.datosAsegurado,
        complete: false
      };
    },
    [datosAutoSet]: (state, action) => {
      return {
        ...state,
        datosAuto: action.payload.datosAuto,
        complete: false
      };
    },
    [initContacto]: (state, action) => {
      return {
        ...initialState
      };
    }
  },
  initialState
);

export default contactoSegurosReducer;
