const openFile = async url => {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 0);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
};

export default openFile;
