import { createActions, handleActions } from "redux-actions";
import localRepository from "~src/localRepository";

const informarPago = pago => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(postInformarPagoInited(pago));
      const response = await services.api.postInformarPago(pago);
      dispatch(postInformarPagoCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(postInformarPagoFailed(error));
    }
  };
};

const {
  informarPago: {
    clear,
    setNombreYApellido,
    clearNombreYApellido,
    setDni,
    clearDni,
    setTelefono,
    clearTelefono,
    setEmail,
    clearEmail,
    setConceptoPago,
    clearConceptoPago,
    setMedioDePago,
    clearMedioDePago,
    setEntidadDePago,
    clearEntidadDePago,
    setFechaDePago,
    clearFechaDePago,
    setComentario,
    clearComentario,
    setComprobante,
    clearComprobante,
    setImporte,
    clearImporte,
    postInformarPagoInited,
    postInformarPagoCompleted,
    postInformarPagoFailed,
    setOperacionId
  }
} = createActions({
  informarPago: {
    clear: () => ({}),
    setNombreYApellido: value => ({ value }),
    clearNombreYApellido: () => ({}),
    setDni: value => ({ value }),
    clearDni: () => ({}),
    setTelefono: value => ({ value }),
    clearTelefono: () => ({}),
    setEmail: value => ({ value }),
    clearEmail: () => ({}),
    setConceptoPago: value => ({ value }),
    clearConceptoPago: () => ({}),
    setMedioDePago: value => ({ value }),
    clearMedioDePago: () => ({}),
    setEntidadDePago: value => ({ value }),
    clearEntidadDePago: () => ({}),
    setFechaDePago: value => ({ value }),
    clearFechaDePago: () => ({}),
    setComentario: value => ({ value }),
    clearComentario: () => ({}),
    setComprobante: value => ({ value }),
    clearComprobante: () => ({}),
    setImporte: value => ({ value }),
    clearImporte: () => ({}),
    postInformarPagoInited: props => ({ props }),
    postInformarPagoCompleted: credito => ({ credito }),
    postInformarPagoFailed: error => ({ error }),
    setOperacionId: value => ({ value })
  }
});

const initialState = {
  operacionId: null,
  nombreYApellido: null,
  dni: null,
  telefono: null,
  email: null,
  conceptoPago: null,
  medioDePago: null,
  entidadDePago: null,
  fechaDePago: null,
  comentario: null,
  comentarioCompleted: false,
  comprobanteAdjunto: null,
  importe: null,
  isForward: true,
  isLoading: false,
  error: false
};

const informarPagoReducer = handleActions(
  {
    [clear]: () => {
      return {
        ...initialState,
        isForward: true
      };
    },
    [setNombreYApellido]: (state, action) => {
      localRepository.lastUsedName.set(action.payload.value);
      return {
        ...state,
        nombreYApellido: action.payload.value,
        isForward: true
      };
    },
    [clearNombreYApellido]: (state, action) => {
      return {
        ...state,
        nombreYApellido: null,
        isForward: false
      };
    },
    [setDni]: (state, action) => {
      localRepository.lastUsedDni.set(action.payload.value);
      return {
        ...state,
        dni: action.payload.value,
        isForward: true
      };
    },
    [clearDni]: (state, action) => {
      return {
        ...state,
        dni: null,
        isForward: false
      };
    },
    [setTelefono]: (state, action) => {
      localRepository.lastUsedPhone.set(action.payload.value);
      return {
        ...state,
        telefono: action.payload.value,
        isForward: true
      };
    },
    [clearTelefono]: (state, action) => {
      return {
        ...state,
        telefono: null,
        isForward: false
      };
    },
    [setEmail]: (state, action) => {
      localRepository.lastUsedEmail.set(action.payload.value);
      return {
        ...state,
        email: action.payload.value,
        isForward: true
      };
    },
    [clearEmail]: (state, action) => {
      return {
        ...state,
        email: null,
        isForward: false
      };
    },
    [setConceptoPago]: (state, action) => {
      return {
        ...state,
        conceptoPago: action.payload.value,
        isForward: true
      };
    },
    [clearConceptoPago]: (state, action) => {
      return {
        ...state,
        conceptoPago: null,
        isForward: false
      };
    },
    [setMedioDePago]: (state, action) => {
      return {
        ...state,
        medioDePago: action.payload.value,
        isForward: true
      };
    },
    [clearMedioDePago]: (state, action) => {
      return {
        ...state,
        medioDePago: null,
        isForward: false
      };
    },
    [setEntidadDePago]: (state, action) => {
      return {
        ...state,
        entidadDePago: action.payload.value,
        isForward: true
      };
    },
    [clearEntidadDePago]: (state, action) => {
      return {
        ...state,
        entidadDePago: null,
        isForward: false
      };
    },
    [setFechaDePago]: (state, action) => {
      return {
        ...state,
        fechaDePago: action.payload.value,
        isForward: true
      };
    },
    [clearFechaDePago]: (state, action) => {
      return {
        ...state,
        fechaDePago: null,
        isForward: false
      };
    },
    [setComentario]: (state, action) => {
      return {
        ...state,
        comentario: action.payload.value,
        comentarioCompleted: true,
        isForward: true
      };
    },
    [clearComentario]: (state, action) => {
      return {
        ...state,
        comentario: null,
        comentarioCompleted: false,
        isForward: false
      };
    },
    [setComprobante]: (state, action) => {
      return {
        ...state,
        comprobanteAdjunto: action.payload.value,
        isForward: true
      };
    },
    [clearComprobante]: (state, action) => {
      return {
        ...state,
        comprobanteAdjunto: null,
        isForward: false
      };
    },
    [setImporte]: (state, action) => {
      return {
        ...state,
        importe: action.payload.value,
        isForward: true
      };
    },
    [clearImporte]: (state, action) => {
      return {
        ...state,
        importe: null,
        isForward: false
      };
    },
    [setOperacionId]: (state, action) => {
      return {
        ...state,
        operacionId: action.payload.value
      };
    },
    [postInformarPagoInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    },
    [postInformarPagoCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: false
      };
    },
    [postInformarPagoFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    }
  },
  initialState
);

export default informarPagoReducer;
export {
  clear,
  setNombreYApellido,
  clearNombreYApellido,
  setDni,
  clearDni,
  setTelefono,
  clearTelefono,
  setEmail,
  clearEmail,
  setConceptoPago,
  clearConceptoPago,
  setMedioDePago,
  clearMedioDePago,
  setEntidadDePago,
  clearEntidadDePago,
  setFechaDePago,
  clearFechaDePago,
  setComentario,
  clearComentario,
  setComprobante,
  clearComprobante,
  informarPago,
  setImporte,
  clearImporte,
  setOperacionId
};
