import React from "react";
import pxToRem from "~libs/pxToRem";
import Modal from "~components/styled/modal/Modal";
import Typography from "@material-ui/core/Typography/Typography";
import QRCode from "~assets/qrCode/qr-descarga-app.svg";
import TengoUnProblema from "~components/bussines/tengoUnProblema/TengoUnProblema";
const ModalVincularCreditoDesktop = ({ opened, onClose }) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Vincular crédito"
      dialogActions={<TengoUnProblema />}
    >
      <div style={{ marginBottom: pxToRem(17) }}>
        <Typography
          style={{
            fontSize: pxToRem(22),
            fontWeight: "bold",
            lineHeight: 1.55,
            textAlign: "left",
            color: "#424242",
            marginBottom: pxToRem(18)
          }}
        >
          Vinculá tu crédito de manera segura
        </Typography>
        <Typography
          style={{
            fontSize: pxToRem(16),
            lineHeight: 1.38,
            textAlign: "left",
            color: "#757575"
          }}
        >
          Mejoramos nuestro portal de clientes para darte mucha más información
          sobre tus créditos y nuevas funcionalidades.
          <br /> <br />
          Por eso, y porque cuidar tus datos es muy importante para nosotros, te
          pedimos que{" "}
          <b>
            valides tu identidad desde la App para Clientes MG en tu teléfono o
            tablet.
          </b>{" "}
          Una vez que lo hayas hecho, vas a poder ingresar tanto desde el
          teléfono como desde la computadora.
        </Typography>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            style={{
              fontSize: pxToRem(18),
              fontWeight: "bold",
              lineHeight: 1.89,
              textAlign: "left",
              color: "#424242",
              marginBottom: pxToRem(18)
            }}
          >
            Cómo descargar la app
          </Typography>
          <Typography
            style={{
              fontSize: pxToRem(16),
              lineHeight: 1.38,
              textAlign: "left",
              color: "#757575"
            }}
          >
            <b>
              Descarga la app abriendo la cámara de tu teléfono y encuadrando el
              código QR que ves a la derecha.
            </b>
            <br />
            <br />
            También podés buscarla en la <b>Tienda de aplicaciones</b> de tu
            celular con el nombre de <b>“MG App”.</b>
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: pxToRem(40)
          }}
        >
          <img src={QRCode} style={{ width: pxToRem(130) }} />
        </div>
      </div>
    </Modal>
  );
};

export default ModalVincularCreditoDesktop;
