import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentSection from "~components/styled/contentSection/ContentSection";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import EstadoCivilSelector from "~components/bussines/estadoCivilSelector/EstadoCivilSelector";

import { setEstadoCivil, clearEstadoCivil } from "../actions";

const EstadoCivilStep = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearEstadoCivil());
  }, [dispatch]);

  return (
    <ContentSection>
      <PrimaryTitle label={"¿Cuál es su estado civil?"} />
      <EstadoCivilSelector
        onSelected={event => dispatch(setEstadoCivil(event.target.value))}
      />
    </ContentSection>
  );
};

export default EstadoCivilStep;
