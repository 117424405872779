import {
  SIGNUPAGENCIA_COMPLETED,
  SIGNUPAGENCIA_FAILED,
  SIGNUPAGENCIA_INITED,
  SIGNUPAGENCIA_PASSWORD_COMPLETED,
  SIGNUPAGENCIA_PASSWORD_FAILED,
  SIGNUPAGENCIA_VALIDATION_COMPLETED,
  SIGNUPAGENCIA_VALIDATION_FAILED
} from "./actions";

const initialState = {
  isLoading: false,
  tokenValid: null,
  userExpired: null,
  userId: null,
  success: false
};

const signupAgenciaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUPAGENCIA_COMPLETED:
      return {
        ...state,
        success: action.payload.success
      };
    case SIGNUPAGENCIA_FAILED:
      return {
        ...state,
        success: action.payload.success
      };
    case SIGNUPAGENCIA_INITED:
      return {
        ...state,
        isLoading: true
      };
    case SIGNUPAGENCIA_VALIDATION_COMPLETED:
      return {
        ...state,
        tokenValid: action.payload.tokenValid,
        userId: action.payload.userId,
        isLoading: false
      };
    case SIGNUPAGENCIA_VALIDATION_FAILED:
      return {
        ...state,
        tokenValid: action.payload.tokenValid,
        userExpired: action.payload.userExpired,
        isLoading: false
      };
    case SIGNUPAGENCIA_PASSWORD_COMPLETED:
      return {
        ...state,
        success: action.payload.success,
        isLoading: false
      };
    case SIGNUPAGENCIA_PASSWORD_FAILED:
      return {
        ...state,
        success: action.payload.success,
        isLoading: false
      };
    default:
      return state;
  }
};

export default signupAgenciaReducer;
