import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "material-ui/TextField";
import { validateEmail } from "./validateEmail";

import { useTheme } from "../../../libs/styles";

class EmailField extends Component {
  state = {
    email: this.props.email ? this.props.email : "",
    emailErrorText: this.props.errorText ? this.props.errorText : "",
    disabled: this.props.disabled
  };

  handleEmailChange = event => {
    let email = event.target.value;
    this.setState({ email }, this.informChange);
  };

  handleEmailBlur = () => {
    const validationEmailResult = validateEmail(
      this.state.email,
      this.props.required
    );

    this.setState({ emailErrorText: validationEmailResult.error });
  };

  handleEmailFocus = () => {
    this.setState({ emailErrorText: "" });
  };

  informChange = () => {
    if (this.props.onChange) {
      const validationEmailResult = validateEmail(
        this.state.email,
        this.props.required
      );

      this.props.onChange({
        value: this.state.email,
        isValid: validationEmailResult.valid,
        isEmpty: validationEmailResult.empty,
        disabled: this.state.disabled
      });
    }
  };

  render() {
    const theme = useTheme();
    const { email, emailErrorText, disabled } = this.state;

    const label = this.props.label ? this.props.label : "Email";
    return (
      <div>
        <fieldset disabled={disabled}>
          <div style={{ ...{ marginBottom: "10px" }, ...this.props.style }}>
            <TextField
              autoFocus={this.props.autoFocus}
              value={email}
              placeholder={this.props.placeholder}
              onChange={this.handleEmailChange}
              onBlur={this.handleEmailBlur}
              onFocus={this.handleEmailFocus}
              id={`emailFieldReference${this.props.index}`}
              type="email"
              floatingLabelFixed={true}
              floatingLabelText={label}
              floatingLabelShrinkStyle={{
                ...{
                  color: this.props.disabled
                    ? theme.palette.disabledColor
                    : theme.palette.textColor,
                  textTransform: "uppercase",
                  fontSize: 14
                },
                ...this.props.labelStyle
              }}
              fullWidth={true}
              disabled={disabled}
              errorText={
                this.props.errorText ? this.props.errorText : emailErrorText
              }
              errorStyle={{
                display: "table",
                float: "right",
                color: theme.palette.errorColor
              }}
              onKeyDown={event => {
                if (this.props.onKeyDown)
                  this.props.onKeyDown(event.key === "Enter");
              }}
            />
          </div>
        </fieldset>
      </div>
    );
  }
}

EmailField.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func
};

export default EmailField;
