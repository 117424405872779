import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProvincia, clearProvincia } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ProvinciaSelector from "~components/bussines/provinciaSelector/ProvinciaSelector";

const ProvinciaStep = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearProvincia());
  }, [dispatch]);

  const handleSelection = (event) => {
    dispatch(setProvincia(event.target.value));
  };

  return (
    <ContentSectionWithActions>
      <PrimaryTitle label={"¿En qué provincia vive?"} marginBottom={32} />
      <ProvinciaSelector
        label={"Ingresá o buscá la provincia"}
        onSelected={handleSelection}
      />
    </ContentSectionWithActions>
  );
};

export default ProvinciaStep;
