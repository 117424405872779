import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import waveImageDesktop from "~assets/shapes/wave-bg.svg";
import waveMobile from "~assets/shapes/wave-mobile.svg";
import logo from "~assets/logos/logo-mg-white.svg";

import InfiniteScroll from "~shared/layout/InfiniteScroll"; // dejar de usar en cuanto se pueda

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: "#f9f7fc",
    padding: "8vh 10vh",
    minHeight: "100vh",
    backgroundImage: `url(${waveImageDesktop})`,
    backgroundSize: "100vw 100vh",
    backgroundRepeat: "no-repeat",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
    [theme.breakpoints.mobile]: {
      backgroundImage: `url(${waveMobile})`,
      padding: theme.typography.pxToRem(16),
      paddingTop: 0,
      backgroundSize: "100vw calc(31vh - 30px)"
    }
  },
  columnLeft: {
    width: "49%",
    [theme.breakpoints.mobile]: {
      display: "none"
    }
  },
  columnRight: {
    width: "49%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.mobile]: {
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "center"
    }
  },
  columnCentered: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  logoSize: {
    width: "15vh"
    // [theme.breakpoints.mobile]: {
    //   padding: 12
    // }
  },
  logoFigure: {
    marginTop: "3vh",
    marginBottom: "3vh",
    //padding: theme.typography.pxToRem(16),
    minHeight: "16vh",
    display: "flex",
    margin: 0,
    [theme.breakpoints.mobile]: {
      minHeight: "8vh"
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.desktop]: { display: "none" }
  }
}));

const WaveLayout = ({ title, subtitle, children }) => {
  const classes = useStyles();
  const theme = useTheme();

  const isTwoColumnsLayout = title || subtitle;

  return (
    <React.Fragment>
      <div className={classes.main}>
        <InfiniteScroll /> {/* TODO: remove this when is no needed anymore */}
        {isTwoColumnsLayout && (
          <div className={classes.columnLeft}>
            <img
              src={logo}
              alt="MG Web App"
              className={classes.logoSize}
              style={{ marginBottom: theme.typography.pxToRem(38) }}
            />
            {title && (
              <Typography variant={"h1"} style={{ color: "#fff" }}>
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography
                variant={"h3"}
                style={{ maxWidth: "80%", color: "#fff", fontWeight: 300 }}
              >
                {subtitle}
              </Typography>
            )}
          </div>
        )}
        <div
          className={
            isTwoColumnsLayout ? classes.columnRight : classes.columnCentered
          }
        >
          <figure
            className={
              isTwoColumnsLayout
                ? [classes.logoFigure, classes.hideOnDesktop].join(" ")
                : classes.logoFigure
            }
          >
            <img src={logo} alt="MG Web App" className={classes.logoSize} />
          </figure>
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default WaveLayout;
