import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import pxToRem from "~libs/pxToRem";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import { clearVehiculoMarca } from "../../actions";
import Typography from "@material-ui/core/Typography/Typography";
//import {   } from "../../actions";

const InformesPendientes = () => {
  const dispatch = useDispatch();
  const handleCargarContacto = () => {};
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            label={"Cargar contacto"}
            onClick={handleCargarContacto}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle label={"Informes pendientes"} marginBottom={60} />
      <div>
        <Typography
          style={{
            opacity: 0.75,
            fontSize: pxToRem(16),
            lineHeight: 1.38,
            textAlign: "left",
            color: "#000000",
            marginBottom: 35
          }}
        >
          No pudimos obtener los informes de crédito del cliente. Finalizá la
          solicitud para que pueda ser revisada por un analista de crédito.
        </Typography>
      </div>
    </ContentSectionWithActions>
  );
};

export default InformesPendientes;
