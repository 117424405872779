import React from "react";
import { useDispatch } from "react-redux";
import makeStyles from "@material-ui/styles/makeStyles";
import pxToRem from "~libs/pxToRem";
import moment from "moment";
import NumberFormat from "react-number-format";

import CardButton from "~components/styled/cardButton/CardButton";
import Typography from "@material-ui/core/Typography";
import { getComprobantesPago } from "../actions";
import ColorLabel from "~components/styled/colorLabel/ColorLabel";
import cuponIcon from "~assets/icons/ic-cupon.svg";
import LinearProgress from "@material-ui/core/LinearProgress";

const CuotaPagada = ({ isLoading, operacionId, cuota, disableDownloads }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(getComprobantesPago(operacionId, cuota.id));
  };

  const disabled = isLoading || cuota.downloading || disableDownloads;

  return (
    <CardButton
      marginBottom={16}
      title={
        <Typography
          noWrap
          style={{
            fontSize: pxToRem(12),
            fontWeight: 600,
            lineHeight: 4,
            color: "#9e9e9e"
          }}
        >
          {`CUOTA Nº ${cuota.nroCuota}`}
        </Typography>
      }
      addCollapse
      color={"#9ADDCF"}
    >
      <div className={classes.container}>
        <div style={{ display: "flex", marginBottom: 8 }}>
          <ColorLabel variant="success" text="Pagada en término" />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 8
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              noWrap
              className={classes.title}
              style={{ textAlign: "left" }}
            >
              {"Vencimiento"}
            </Typography>
            <Typography
              noWrap
              className={classes.content}
              style={{ textAlign: "left" }}
            >
              {moment(moment.utc(cuota.fechaVencimiento)).format("L")}
            </Typography>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              noWrap
              className={classes.title}
              style={{ textAlign: "right" }}
            >
              {"Fecha de pago"}
            </Typography>
            <Typography
              noWrap
              className={classes.content}
              style={{ textAlign: "right" }}
            >
              {moment(moment.utc(cuota.fechaPago)).format("L")}
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              noWrap
              className={classes.title}
              style={{ textAlign: "left" }}
            >
              {"Monto"}
            </Typography>
            <Typography
              noWrap
              className={classes.content}
              style={{ textAlign: "left" }}
            >
              <NumberFormat
                value={cuota.importe}
                displayType={"text"}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$ "}
              />
            </Typography>
          </div>
        </div>

        {cuota.tieneComprobantePago && (
          <div
            onClick={disabled ? null : onClickHandler}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: 10,
              paddingTop: 8,
              paddingBottom: 8
            }}
          >
            <IconFile src={cuponIcon} />
            <Typography
              variant={"h1"}
              color={"primary"}
              style={{
                fontSize: pxToRem(16),
                fontWeight: "bold",
                margin: 0,
                cursor: "pointer"
              }}
            >
              Ver comprobante
            </Typography>
          </div>
        )}
      </div>

      <div style={{ height: 4 }}>
        {cuota.downloading && (
          <LinearProgress style={{ height: 4 }} color="secondary" />
        )}
      </div>
    </CardButton>
  );
};

export default CuotaPagada;

const useStyles = makeStyles(theme => ({
  container: {
    padding: 16,
    display: "flex",
    flexDirection: "column"
  },
  title: {
    fontSize: `${pxToRem(12)} !important`,
    fontWeight: "600 !important",
    lineHeight: "1.33 !important",
    color: "#bdbdbd"
  },
  content: {
    fontSize: `${pxToRem(16)} !important`,
    fontWeight: "bold !important",
    lineHeight: "1.38 !important",
    color: "#424242"
  },
  expandedIcon: { transform: "rotate(180deg)" },
  notExpandedIcon: { transform: "rotate(0deg)" }
}));

const IconFile = ({ src }) => {
  return (
    <img
      alt=""
      src={src}
      style={{ width: 18, height: 18, marginRight: 2, cursor: "pointer" }}
    />
  );
};
