import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setVehiculoMarca, clearVehiculoMarca } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";

import VehiculoMarcaSelector from "~components/bussines/marcaVehiculoSelector/MarcaVehiculoSelector";

const useReduxSelector = () =>
  useSelector(state => {
    const nuevaSolicitudReducer = state.creditosReducers.nuevaSolicitudReducer;

    return { vehiculoMarca: nuevaSolicitudReducer.vehiculoMarca };
  });

const VehiculoMarcaStep = () => {
  const dispatch = useDispatch();
  const { vehiculoMarca } = useReduxSelector();
  useEffect(() => {
    dispatch(clearVehiculoMarca());
  }, [dispatch]);

  const handleSelection = event => {
    dispatch(setVehiculoMarca(event.target.value));
  };

  return (
    <ContentSectionWithActions>
      <PrimaryTitle label={"Marca del vehículo"} marginBottom={60} />
      <VehiculoMarcaSelector
        value={vehiculoMarca}
        onSelected={handleSelection}
      />
    </ContentSectionWithActions>
  );
};

export default VehiculoMarcaStep;
