const getEstadosCiviles = () => [
  {
    label: "Soltero",
    value: "Soltero"
  },
  {
    label: "Casado",
    value: "Casado"
  },
  {
    label: "Divorciado",
    value: "Divorciado"
  },
  {
    label: "Viudo",
    value: "Viudo"
  }
];

export default getEstadosCiviles;
