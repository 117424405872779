import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentSection from "~components/styled/contentSection/ContentSection";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import EntidadDePagoSelector from "~components/bussines/entidadDePagoSelector/EntidadDePagoSelector";

import { setEntidadDePago, clearEntidadDePago } from "../actions";

const EntidadDePagoStep = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearEntidadDePago());
  }, [dispatch]);

  return (
    <ContentSection>
      <PrimaryTitle label={"¿A qué banco le hiciste el pago?"} />
      <EntidadDePagoSelector
        onSelected={event => dispatch(setEntidadDePago(event.target.value))}
      />
    </ContentSection>
  );
};

export default EntidadDePagoStep;
