import React from "react";
import Layout from "./Layout";
import makeStyles from "@material-ui/styles/makeStyles";
import { Paper, Typography } from "@material-ui/core";
import { useAuth0 } from "../Auth0/useAuth";
import { useHistory } from "react-router-dom";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  card: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "8%",
    borderRadius: 16,
    width: "100%",
    boxShadow: "0 0 20px 0 #663ea836",
    height: "50vh",
    [theme.breakpoints.mobile]: {
      height: "70vh"
    }
  },
  brandIcon: {
    height: 125
  },
  text: {
    marginTop: "2%",
    color: "#663ea8",
    fontFamily: "Nunito",
    fontSize: "1.25rem",
    fontWeight: "bold"
  },
  textVolver: {
    fontSize: 14,
    color: "#757575",
    textDecoration: "underline !important",
    lineHeight: 1.36
  },
  text: {
    fontSize: theme.typography.pxToRem(16),
    color: "#757575",
    lineHeight: 1.38,
    color: "#616161"
  },
  titleText: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: "bold",
    lineHeight: 1.33,
    marginBottom: "5%"
  },
  button: {
    width: "100%",
    height: "7vh",
    boxShadow: "none",
    backgroundColor: "#efebf6"
  },
  textButton: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.38,
    textTransform: "none"
  }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    isValidacionIdentidadEnabled:
      state.featureFlagReducer.featureFlags["validacion-identidad"]
  }));

const SelectorTipoCuenta = () => {
  const classes = useStyles();
  const history = useHistory();
  const { logout, authState } = useAuth0();
  const { isValidacionIdentidadEnabled } = useReduxSelector();
  return (
    <Layout>
      <Paper className={classes.card}>
        <div style={{ marginBottom: "20%" }}>
          <Typography className={classes.titleText} color="textPrimary">
            ¡Hola {authState.user ? authState.user.preferred_username : ""}!
          </Typography>
          <Typography className={classes.text}>
            ¿Qué tipo de cuenta querés crear?
          </Typography>
        </div>
        <div style={{ marginBottom: "15%" }}>
          {/* <Button
            style={{
              marginBottom: "5%"
            }}
            classes={{ root: classes.button, text: classes.textButton }}
            onClick={() => history.push("/actualizando/inicio")}
          >
            Ya tengo cuenta
          </Button> */}
          <CallToActionButton
            label="Cuenta Agencia"
            color="secondary"
            marginBottom={12}
            onClick={() => history.push("/agencias/signup")}
          />
          {isValidacionIdentidadEnabled && (
            <CallToActionButton
              label="Cuenta Personal"
              color="secondary"
              marginBottom={12}
              onClick={() => history.push("/clientes/signup")}
            />
          )}
          {!isValidacionIdentidadEnabled && (
            <CallToActionButton
              label="Ya tengo cuenta"
              color="secondary"
              marginBottom={12}
              onClick={() => history.push("/actualizando/inicio")}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            alignItems: "flex-end"
          }}
        >
          <Typography
            className={classes.textVolver}
            onClick={() => {
              logout();
            }}
          >
            Volver
          </Typography>
        </div>
      </Paper>
    </Layout>
  );
};

export default SelectorTipoCuenta;
