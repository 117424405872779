import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setValidadorDatosPago, clearValidadorDatosPago } from "../actions";
import {
  isCreditCardNumberValid,
  getCreditCardType,
} from "~libs/creditCardValidator";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import makeStyles from "@material-ui/styles/makeStyles";
import CreditCardInput from "~components/styled/input/CreditCardInput";
import pxToRem from "~libs/pxToRem";
import visaIcon from "~images/logos/visa-icon.png";
import mastercardIcon from "~images/logos/mastercard-icon.png";
import amexIcon from "~images/logos/amex-icon.png";
import Avatar from "@material-ui/core/Avatar";
import InputAdornment from "@material-ui/core/InputAdornment";

const ENTER_KEY = 13;

const useStyles = makeStyles((theme) => ({
  secondaryText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(16),
    fontWeight: "bold",
    lineHeight: "1.36",
    textAlign: "left",
    color: "#7644c9",
  },
  logoSize: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const TarjetaCreditoStep = () => {
  const dispatch = useDispatch();
  const [tarjetaCredito, setCampoTarjeta] = useState("");
  const classes = useStyles();

  useEffect(() => {
    dispatch(clearValidadorDatosPago());
  }, [dispatch]);

  const canContinue =
    tarjetaCredito.length > 0 && isCreditCardNumberValid(tarjetaCredito);

  const handleNext = (value) => {
    dispatch(setValidadorDatosPago({ value, selected: true }));
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue) handleNext(tarjetaCredito);
  };

  const getCardIcon = (type) => {
    switch (type) {
      case "mastercard":
        return mastercardIcon;
        break;

      case "visa":
        return visaIcon;
        break;

      case "amex":
        return amexIcon;
        break;

      default:
        return null;
    }
  };

  let creditCardType = getCreditCardType(tarjetaCredito);
  let cardTypeIcon = getCardIcon(creditCardType);

  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={!canContinue}
            label={"Continuar"}
            onClick={() => {
              handleNext(tarjetaCredito);
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle
        label={
          "Ingresá el número de tarjeta de crédito con la que realizas el pago"
        }
        marginBottom={60}
      />
      <TextField
        fullWidth={true}
        onKeyDown={onKeyDown}
        autoFocus
        onFocus={(event) => event.target.select()}
        type="tel"
        value={tarjetaCredito}
        onChange={(event) => {
          const value = event.target.value.replace(/\s/g, "");

          setCampoTarjeta(value);
        }}
        placeholder="Ej: 1111 2222 3333 4444"
        InputProps={{
          inputComponent: CreditCardInput,
          startAdornment: (
            <InputAdornment position="start">
              {creditCardType !== null && (
                <Avatar
                  alt={creditCardType}
                  src={cardTypeIcon}
                  className={classes.logoSize}
                />
              )}
            </InputAdornment>
          ),
        }}
      ></TextField>
    </ContentSectionWithActions>
  );
};

export default TarjetaCreditoStep;
