import React from "react";
import pxToRem from "~libs/pxToRem";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
const useStyles = makeStyles((theme) => ({
  base: (props) => ({
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 8,
    width: "100%",
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 14,
    paddingRight: 16,
    marginBottom: props.marginBottom,
  }),
  text: {
    fontFamily: theme.typography.fontFamily,
    paddingLeft: 8,
    fontSize: pxToRem(16),
    fontWeight: "bold !important",
    lineHeight: "1.38 !important",
    textAlign: "left",
  },
  otros: (props) => ({
    backgroundColor: props.otros.background,
    color: props.otros.text,
  }),
  success: (props) => ({
    backgroundColor: props.success.background,
    color: props.success.text,
  }),
  info: (props) => ({
    backgroundColor: props.info.background,
    color: props.info.text,
  }),
  warning: (props) => ({
    backgroundColor: props.warning.background,
    color: props.warning.text,
  }),
  danger: (props) => ({
    backgroundColor: props.danger.background,
    color: props.danger.text,
  }),
  linkButton: {
    textDecoration: "underline",
    textAlign: "right",
    whiteSpace: "nowrap",
  },
}));

const WarningSection = ({
  variant,
  children,
  rightLink,
  inlineLink,
  icon,
  onClick,
  marginBottom,
}) => {
  const colors = {
    otros: { text: "#009bba", background: "#c7f2fa" },
    success: { text: "#069661", background: "#d2f6e2" },
    info: { text: "#ee9d00", background: "#fff7cc" },
    warning: { text: "#f17f00", background: "#ffeece" },
    danger: { text: "#dd1111", background: "#ffe0e0" },
  };
  const classes = useStyles({ ...colors, marginBottom });

  const variantClass = classes[variant];
  const fullClasses =
    variantClass && variantClass !== classes.base
      ? [classes.base, variantClass].join(" ")
      : classes.base;
  return (
    <div
      className={fullClasses}
      onClick={onClick}
      style={onClick ? { cursor: "pointer" } : null}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: 30,
          flexGrow: 0,
        }}
      >
        {icon}
      </div>
      <div>
        <Typography className={classes.text}>
          {children}
          <b style={{ marginLeft: 8, whiteSpace: "noWrap" }}>
            <u>{inlineLink}</u>
          </b>
        </Typography>
      </div>
      <div style={{ flexGrow: 3 }}>
        {rightLink && (
          <Typography
            className={[classes.text, variantClass, classes.linkButton].join(
              " "
            )}
          >
            {rightLink}
          </Typography>
        )}
      </div>
    </div>
  );
};
export default WarningSection;
