import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import pxToRem from "~libs/pxToRem";
import moment from "moment";
import NumberFormat from "react-number-format";

import CardButton from "~components/styled/cardButton/CardButton";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";

const CuotaVencida = ({ cuota }) => {
  const classes = useStyles();
  return (
    <CardButton
      marginBottom={16}
      title={
        <Typography
          noWrap
          style={{
            fontSize: pxToRem(12),
            fontWeight: 600,
            lineHeight: 4,
            color: "#9e9e9e"
          }}
        >
          {`CUOTA Nº ${cuota.nroCuota}`}
        </Typography>
      }
      color={"#FA8F82"}
    >
      <div className={classes.container}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              noWrap
              className={classes.title}
              style={{ textAlign: "left" }}
            >
              {"Vencimiento"}
            </Typography>
            <Typography
              noWrap
              className={classes.content}
              style={{ textAlign: "left" }}
            >
              {moment(moment.utc(cuota.fechaVencimiento)).format("L")}
            </Typography>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              noWrap
              className={classes.title}
              style={{ textAlign: "right" }}
            >
              {"Monto"}
            </Typography>
            <Typography
              noWrap
              className={classes.content}
              style={{ textAlign: "right" }}
            >
              <NumberFormat
                value={cuota.importe}
                displayType={"text"}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$ "}
              />
            </Typography>
          </div>
        </div>
      </div>
      <div style={{ height: 4 }}>
        {cuota.downloading && (
          <LinearProgress style={{ height: 4 }} color="secondary" />
        )}
      </div>
    </CardButton>
  );
};

export default CuotaVencida;

const useStyles = makeStyles(theme => ({
  container: {
    padding: 16,
    display: "flex",
    flexDirection: "column"
  },
  title: {
    fontSize: `${pxToRem(12)} !important`,
    fontWeight: "600 !important",
    lineHeight: "1.33 !important",
    color: "#bdbdbd"
  },
  content: {
    fontSize: `${pxToRem(16)} !important`,
    fontWeight: "bold !important",
    lineHeight: "1.38 !important",
    color: "#424242"
  }
}));
