import React, { Fragment } from "react";
import { useAuth0 } from "~componentes/Auth0/useAuth";
import { useSelector, useDispatch } from "react-redux";

import PolizaCardButton from "~components/app/clienteFinal/seguros/PolizaCardButton";
import LinkButton from "~components/styled/linkButton/LinkButton";
import Typography from "@material-ui/core/Typography";
import pxToRem from "~libs/pxToRem";
import { useHistory } from "react-router-dom";
import WarningSection from "~components/styled/warningSection/WarningSection";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import EmptyStateSolicitud from "~assets/ilustrations/empty-state_solicitud.svg";
import { showNativePopup } from "~componentes/nativeAppMessaging/js/actions";

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoadingVinculacion: state.sharedReducers.vinculacionReducer.isLoading,
    isOnNativeApp: state.nativeMessagesReducer.isOnNativeApp,
    isValidacionIdentidadEnabled:
      state.featureFlagReducer.featureFlags["validacion-identidad"],
  }));
const ListadoPolizas = ({ polizas, isLoading }) => {
  const history = useHistory();
  const { authState } = useAuth0();
  const dispatch = useDispatch();
  const {
    isLoadingVinculacion,
    isOnNativeApp,
    isValidacionIdentidadEnabled,
  } = useReduxSelector();
  const hasItems = polizas && polizas.length > 0;
  const hasPolizaSuspendida =
    polizas && polizas.find((poliza) => poliza.suspendida);
  const validatedIdentity = authState.user.validatedIdentity;
  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: 14,
        }}
      >
        {hasItems && (
          <Typography
            variant="caption"
            noWrap
            style={{
              fontSize: pxToRem(22),
              fontWeight: "bold",
              lineHeight: 1.55,
              color: "#663ea8",
              marginTop: 14,
            }}
          >
            {`Mis pólizas`}
          </Typography>
        )}
      </div>
      {isLoading && <LoadingTemplate length={3} />}
      {!isLoading && hasPolizaSuspendida && (
        <WarningSection
          variant="danger"
          icon={<ReportProblemOutlinedIcon />}
          rightLink="más info"
          onClick={() => {
            history.push(`/mispolizas/${hasPolizaSuspendida.idPoliza}`);
          }}
          marginBottom={16}
        >
          Tenés un seguro anulado
        </WarningSection>
      )}
      {!isLoading &&
        hasItems &&
        polizas.map((poliza, index) => (
          <PolizaCardButton
            key={`poliza-${index}`}
            poliza={poliza}
            marginBottom={12}
            onClick={() => {
              history.push(`/mispolizas/${poliza.idPoliza}`);
            }}
          />
        ))}
      {!isLoading && !hasItems && !isLoadingVinculacion && validatedIdentity && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 24,
          }}
        >
          <img
            src={EmptyStateSolicitud}
            alt="emptyState1"
            style={{ marginBottom: 20 }}
          />
          <Typography
            style={{
              fontSize: pxToRem(16),
              fontWeight: 600,
              lineHeight: 1.38,
              textAlign: "center",
              color: "#757575",
              marginBottom: 24,
              maxWidth: "80%",
            }}
          >
            No tenés pólizas activas.
          </Typography>

          <LinkButton variant="purple" onClick={() => history.push("/seguros")}>
            ¿Querés asegurar un auto?
          </LinkButton>
        </div>
      )}
      {!isLoading && !hasItems && !validatedIdentity && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 24,
          }}
        >
          <img
            src={EmptyStateSolicitud}
            alt="emptyState2"
            style={{ marginBottom: 20 }}
          />
          <Typography
            style={{
              fontSize: pxToRem(16),
              fontWeight: 600,
              lineHeight: 1.38,
              textAlign: "center",
              color: "#757575",
              marginBottom: 24,
              maxWidth: "80%",
            }}
          >
            ¡Ups! No encontramos ningúna póliza.
          </Typography>

          {isValidacionIdentidadEnabled && isOnNativeApp && (
            <LinkButton
              variant="purple"
              onClick={() => dispatch(showNativePopup("validaridentidad"))}
            >
              ¿Ya tenés un crédito con nosotros?
            </LinkButton>
          )}
          {!isOnNativeApp && (
            <LinkButton
              variant="purple"
              onClick={() => {
                history.push("/seguros");
              }}
            >
              ¿Querés asegurar un auto?
            </LinkButton>
          )}
        </div>
      )}
      {!isLoading && !hasItems && isLoadingVinculacion && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 24,
          }}
        >
          <img
            src={EmptyStateSolicitud}
            alt="emptyState3"
            style={{ marginBottom: 20 }}
          />
          <Typography
            style={{
              fontSize: pxToRem(16),
              fontWeight: 600,
              lineHeight: 1.38,
              textAlign: "center",
              color: "#757575",
              marginBottom: 24,
              maxWidth: "80%",
            }}
          >
            Estamos validando tus datos. Pronto vas a poder ver tus créditos.
          </Typography>
        </div>
      )}
    </Fragment>
  );
};

export default ListadoPolizas;

const LoadingTemplate = ({ length }) => {
  return Array.apply(null, Array(length)).map((_, index) => (
    <PolizaCardButton
      key={`loading-${index}`}
      marginBottom={12}
      onClick={null}
      isLoading={true}
    />
  ));
};
