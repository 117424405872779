import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DetailsLayout from "~components/styled/layouts/detailsLayout/DetailsLayout";
import DetailsTabs from "~components/styled/layouts/detailsLayout/DetailsTabs";
import CreditoDetalle from "./CreditoDetalle";
import CreditoCuotas from "./CreditoCuotas";
import { simularCredito } from "../actions";

const tabs = {
  detalle: 0,
  cuotas: 1
};

const useReduxSelector = () =>
  useSelector(state => {
    const calcularCreditoReducer =
      state.creditosReducers.calcularCreditoReducer;

    return {
      tipoCreditoSelected: calcularCreditoReducer.tipoCreditoSelected,
      vehiculoAnioSelected: calcularCreditoReducer.vehiculoAnioSelected,
      importeSelected: calcularCreditoReducer.importeSelected,
      plazoSelected: calcularCreditoReducer.plazoSelected,
      isLoading: calcularCreditoReducer.isLoading
    };
  });

const CreditoStep = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    tipoCreditoSelected,
    vehiculoAnioSelected,
    importeSelected,
    plazoSelected,
    isLoading
  } = useReduxSelector();

  const [currentTab, setCurrentTab] = useState(tabs.detalle);

  useEffect(() => {
    if (
      tipoCreditoSelected &&
      vehiculoAnioSelected &&
      importeSelected &&
      plazoSelected
    ) {
      dispatch(
        simularCredito(
          tipoCreditoSelected,
          vehiculoAnioSelected,
          importeSelected,
          plazoSelected
        )
      );
    }
  }, [
    tipoCreditoSelected,
    vehiculoAnioSelected,
    importeSelected,
    plazoSelected
  ]);

  return (
    <DetailsLayout
      title={"Calcular crédito"}
      tabsComponent={() => (
        <DetailsTabs
          disabled={isLoading}
          defaultValue={0}
          labels={["DETALLE", "CUOTAS"]}
          onChange={event => setCurrentTab(event.target.value)}
        />
      )}
      onBackClick={() => {
        history.goBack();
      }}
      onCloseClick={() => {
        history.push("/");
      }}
    >
      {currentTab === tabs.detalle && <CreditoDetalle />}
      {currentTab === tabs.cuotas && <CreditoCuotas />}
    </DetailsLayout>
  );
};

export default CreditoStep;
