import { createAction, handleActions } from "redux-actions";

const getSolicitudesInited = createAction("GET_SOLICITUDES_INITED");

const getSolicitudesCompleted = createAction(
  "GET_SOLICITUDES_COMPLETED",
  response => ({ response })
);

const getSolicitudesFailed = createAction("GET_SOLICITUDES_FAILED", error => ({
  error
}));

const getSolicitudes = (skip, take, filterText, soloPendientes) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(
        getSolicitudesInited({ skip, take, filterText, soloPendientes })
      );

      const response = await services.api.getSolicitudes(
        skip,
        take,
        filterText,
        soloPendientes
      );
      dispatch(getSolicitudesCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getSolicitudesFailed(error));
    }
  };
};
const initialState = {
  isLoading: false,
  solicitudes: {}
};
const listadoSolicitudesReducer = handleActions(
  {
    [getSolicitudesInited]: (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [getSolicitudesCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        solicitudes: action.payload.response
      };
    },
    [getSolicitudesFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    }
  },
  initialState
);

export default listadoSolicitudesReducer;
export { getSolicitudes };
