export const FEATURE_FLAG_CHANGE = "FEATURE_FLAG_CHANGE";

export const changeFlag = flag => {
  return {
    type: FEATURE_FLAG_CHANGE,
    payload: {
      flag: flag
    }
  };
};
