import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProvinciaAgencia, clearProvinciaAgencia } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ProvinciaSelector from "~components/bussines/provinciaSelector/ProvinciaSelector";

const ProvinciaStep = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearProvinciaAgencia());
  }, [dispatch]);

  const handleSelection = event => {
    dispatch(setProvinciaAgencia(event.target.value));
  };

  return (
    <ContentSectionWithActions>
      <PrimaryTitle
        label={"¿En qué provincia está ubicada la agencia?"}
        marginBottom={60}
      />
      <ProvinciaSelector
        label={"Ingresá o busca la provincia"}
        onSelected={handleSelection}
      />
    </ContentSectionWithActions>
  );
};

export default ProvinciaStep;
