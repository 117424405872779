import { createAction, handleActions } from "redux-actions";
import { datosAutoClear } from "./datosAutosStep/actions";
import { cotizarSegurosDatosPersonaClear } from "./datosPersonaStep/actions";
import { segurosCotizarDatosVehiculoClear } from "./datosVehiculoStep/actions";
import { initContacto } from "./contactoSeguros/actions";

const polizaSolicitadaSelect = createAction(
  "SEGUROS_POLIZA_SOLICITADA_SELECT",
  polizaSelected => ({ polizaSelected })
);

const seguroSelect = createAction(
  "SEGUROS_COTIZACIONES_SEGURO_SELECT",
  seguroSelected => ({ seguroSelected })
);

const clearCotizacionesSeguros = createAction("SEGUROS_COTIZACIONES_CLEAR");

//POST MULTICOTIZACION
const fetchPedidoCotizacionInited = createAction(
  "SEGUROS_PEDIDO_COTIZACION_INITED"
);

const fetchPedidoCotizacionCompleted = createAction(
  "SEGUROS_PEDIDO_COTIZACION_COMPLETED",
  cotizacionId => ({ cotizacionId })
);

const fetchPedidoCotizacionFailed = createAction(
  "SEGUROS_PEDIDO_COTIZACION_FAILED",
  error => ({ error })
);

const fetchPedidoCotizacionSeguros = (data, successCallback) => {
  return async (dispatch, getState, services) => {
    try {
      let cotizacionId = null;
      dispatch(fetchPedidoCotizacionInited());

      const negocio = "MercadoAbierto";
      const periodo = "Anual";

      const { anio, modelo, codigoPostal, uso, gnc, formaPago } = data;

      const response = await services.api.postSegurosCotizar({
        AnioVehiculo: anio,
        ModeloVehiculo: modelo,
        CodigoPostal: codigoPostal,
        UsoDelVehiculo: uso,
        TieneGNC: gnc,
        FormaPago: formaPago,
        Negocio: negocio,
        Periodo: periodo
      });

      if (
        typeof response !== "undefined" &&
        response !== null &&
        response.length !== 0 &&
        response.cotizacionId
      ) {
        cotizacionId = response.cotizacionId;
      }

      dispatch(fetchPedidoCotizacionCompleted(cotizacionId));

      if (response && response.cotizacionId)
        dispatch(
          fetchCotizacionesCompaniasSeguros(
            response.cotizacionId,
            successCallback
          )
        );
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        fetchPedidoCotizacionSeguros,
        [data, successCallback],
        getState()
      );
      dispatch(fetchPedidoCotizacionFailed(error));

      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};

//SEND QUIERO ESTA POLIZA
const sendQuieroEstaPolizaInited = createAction(
  "SEGUROS_SEND_QUIERO_ESTA_POLIZA_INITED"
);

const sendQuieroEstaPolizaCompleted = createAction(
  "SEGUROS_SEND_QUIERO_ESTA_POLIZA_COMPLETED"
);

const sendQuieroEstaPolizaFailed = createAction(
  "SEGUROS_SEND_QUIERO_ESTA_POLIZA_FAILED",
  error => ({ error })
);

export const sendQuieroEstaPoliza = data => {
  return async (dispatch, getState, services) => {
    try {
      const { cotizacionId, cotizacionPlanId } = data;
      dispatch(sendQuieroEstaPolizaInited());

      await services.api.postSegurosQuieroEstaPoliza({
        IdCotizacion: cotizacionId,
        CotizacionPlanId: cotizacionPlanId
      });

      dispatch(sendQuieroEstaPolizaCompleted());
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        sendQuieroEstaPoliza,
        [data],
        getState()
      );
      dispatch(sendQuieroEstaPolizaFailed(error));

      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};

//SEND QUIERO QUE ME CONTACTEN
const sendQuieroQueMeContactenInited = createAction(
  "SEGUROS_SEND_QUIERO_QUE_ME_CONTACTEN_INITED"
);

const sendQuieroQueMeContactenCompleted = createAction(
  "SEGUROS_SEND_QUIERO_QUE_ME_CONTACTEN_COMPLETED"
);

const sendQuieroQueMeContactenFailed = createAction(
  "SEGUROS_SEND_QUIERO_QUE_ME_CONTACTEN_FAILED",
  error => ({ error })
);

const sendQuieroQueMeContacten = ({
  telefono,
  telefonoMovil,
  nombre,
  email,
  cotizacionId,
  cotizacionPlanId
}) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(sendQuieroEstaPolizaInited());

      await services.api.patchSegurosQuieroQueMeContacten({
        Nombre: nombre,
        Celular: telefono,
        Email: email,
        IdCotizacion: cotizacionId,
        CotizacionPlanId: cotizacionPlanId
      });

      dispatch(sendQuieroEstaPolizaCompleted());
    } catch (error) {
      console.error(error);

      dispatch(sendQuieroEstaPolizaFailed(error));
      services.errorHandler.logRemoteError(
        error,
        sendQuieroQueMeContacten,
        [
          {
            telefono,
            telefonoMovil,
            nombre,
            email,
            cotizacionId,
            cotizacionPlanId
          }
        ],
        getState()
      );

      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};

//GET COTIZACIONES
const fetchCotizacionesCompaniasSegurosInited = createAction(
  "SEGUROS_GET_COTIZACIONES_COMPANIAS_INITED"
);

const fetchCotizacionesCompaniasSegurosCompleted = createAction(
  "SEGUROS_GET_COTIZACIONES_COMPANIAS_COMPLETED",
  seguros => ({ seguros })
);

const fetchCotizacionesCompaniasSegurosFailed = createAction(
  "SEGUROS_GET_COTIZACIONES_COMPANIAS_FAILED",
  error => ({ error })
);

const fetchCotizacionesCompaniasSeguros = (cotizacionId, successCallback) => {
  return async (dispatch, getState, services) => {
    try {
      let seguros = { cotizacionId: null, companias: [] };
      dispatch(fetchCotizacionesCompaniasSegurosInited());

      const response = await services.api.getSegurosCotizaciones(cotizacionId);

      if (
        typeof response !== "undefined" &&
        response !== null &&
        response.length !== 0 &&
        response.companias &&
        response.companias.length > 0
      )
        seguros = response;

      dispatch(fetchCotizacionesCompaniasSegurosCompleted(response));

      if (successCallback) successCallback();
    } catch (error) {
      console.error(error);

      dispatch(fetchCotizacionesCompaniasSegurosFailed());

      services.errorHandler.logRemoteError(
        error,
        fetchCotizacionesCompaniasSeguros,
        [cotizacionId, successCallback],
        getState()
      );

      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};

//nueva cotizacion
const nuevaCotizacionSeguros = () => {
  return async (dispatch, getState, services) => {
    dispatch(initContacto());
    dispatch(datosAutoClear());
    dispatch(cotizarSegurosDatosPersonaClear());
    dispatch(clearCotizacionesSeguros());
    dispatch(segurosCotizarDatosVehiculoClear());
  };
};

const initialState = {
  polizaSolicitada: false,
  seguroSelected: null,
  isLoading: false,
  seguros: null,
  cotizacionesCounter: 0,
  cotizacionId: null,
  finalizado: false
};

const cotizadorSegurosReducer = handleActions(
  {
    [clearCotizacionesSeguros]: (state, action) => {
      return { ...initialState };
    },
    [polizaSolicitadaSelect]: (state, action) => {
      return { ...state, polizaSolicitada: action.payload.polizaSelected };
    },
    [seguroSelect]: (state, action) => {
      return { ...state, seguroSelected: action.payload.seguroSelected };
    },
    //POST MULTICOTIZACION
    [fetchPedidoCotizacionInited]: (state, action) => {
      return {
        ...initialState,
        isLoading: true
      };
    },
    [fetchPedidoCotizacionCompleted]: (state, action) => {
      return {
        ...state,
        cotizacionId: action.payload.cotizacionId,
        isLoading: false
      };
    },
    [fetchPedidoCotizacionFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    },
    //SEND QUIERO ESTA POLIZA
    [sendQuieroEstaPolizaInited]: (state, action) => {
      return {
        ...state,
        finalizado: false
      };
    },
    [sendQuieroEstaPolizaCompleted]: (state, action) => {
      return {
        ...state,
        finalizado: true
      };
    },
    [sendQuieroEstaPolizaCompleted]: (state, action) => {
      return {
        ...state,
        finalizado: false
      };
    },
    //SEND QUIERO QUE ME CONTACTEN
    [sendQuieroQueMeContactenInited]: (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [sendQuieroQueMeContactenCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        finalizado: true
      };
    },
    [sendQuieroQueMeContactenFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    },
    //SEND QUIERO QUE ME CONTACTEN
    [fetchCotizacionesCompaniasSegurosInited]: (state, action) => {
      return {
        ...state
      };
    },
    [fetchCotizacionesCompaniasSegurosCompleted]: (state, action) => {
      return {
        ...state,
        cotizacionesCounter: state.cotizacionesCounter + 1,
        seguros: action.payload.seguros
      };
    },
    [fetchCotizacionesCompaniasSegurosFailed]: (state, action) => {
      return {
        ...state
      };
    }
  },
  initialState
);

export default cotizadorSegurosReducer;
export {
  clearCotizacionesSeguros,
  seguroSelect,
  polizaSolicitadaSelect,
  fetchCotizacionesCompaniasSeguros,
  fetchPedidoCotizacionSeguros,
  nuevaCotizacionSeguros
};
