import React from "react";
import pxToRem from "~libs/pxToRem";
import CardButton from "~components/styled/cardButton/CardButton";
import Typography from "@material-ui/core/Typography";
import logoSancor from "~assets/companiasDetalle/logo-sancor.svg";
import logoBerkley from "~assets/companiasDetalle/logo-berkley.svg";
import logoMercantilAndina from "~assets/companiasDetalle/logo-mercantilAndina.svg";
import logoRus from "~assets/companiasDetalle/logo-rus.svg";
import logoZurich from "~assets/companiasDetalle/logo-zurich.svg";
import ContentLoader from "react-content-loader";
import { makeStyles } from "@material-ui/core";
import { tipoPolizaFiltros } from "~constants/tipoPoliza";
import ColorLabel from "../../styled/colorLabel/ColorLabel";
import { Divider } from "material-ui";
import iconRight from "~assets/icons/ic_arrow-right.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "start",
    minHeight: pxToRem(136),
    flexGrow: 1,
    paddingTop: pxToRem(20),
    paddingRight: pxToRem(20),
    paddingBottom: pxToRem(20),
    paddingLeft: pxToRem(20),
  },
  columnLeft: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-around",
    alignItems: "flex-start",
    paddingRight: pxToRem(8),
    paddingBottom: pxToRem(8),
  },
  columnRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    width: "100%",
    justifyContent: "space-around",
    paddingLeft: pxToRem(8),
    paddingBottom: pxToRem(8),
  },
  importe: {
    fontFamily: "Nunito",
    fontSize: pxToRem(24),
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "right",
    color: "#4d4d4d",
  },
}));

const PolizaCard = ({ poliza, compania, marginBottom, onClick, isLoading }) => {
  const classes = useStyles();
  return (
    <CardButton
      marginBottom={marginBottom}
      onClick={() => {
        if (onClick) onClick({ ...poliza, ...compania });
      }}
    >
      {isLoading && renderLoadingCard()}
      {!isLoading && (
        <div className={classes.root}>
          <div className={classes.columnLeft}>
            <div style={{ paddingBottom: pxToRem(8) }}>
              <img alt="" src={getLogo(compania.companiaId)} />
            </div>
            <Typography
              style={{
                fontSize: pxToRem(15),
                fontWeight: "bold",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.33,
                letterSpacing: "normal",
                textAlign: "left",
                color: "#757575",
              }}
            >
              {tipoPolizaFiltros[poliza.tipoPlanComercial]}
            </Typography>
          </div>

          <Divider style={{ width: 2, height: 100 }} orientation="vertical" />

          <div className={classes.columnRight}>
            <Typography className={classes.importe}>
              {`$${Math.trunc(poliza.importe)}`}
            </Typography>
            <div style={{ maxWidth: 100 }}>
              <ColorLabel
                text={"Detalle"}
                variant={"custom"}
                textColor="#7644c9"
                backgroundColor="#eae4f3"
                icon={iconRight}
              />
            </div>
          </div>
        </div>
      )}
    </CardButton>
  );
};

export default PolizaCard;

const renderLoadingCard = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        paddingTop: pxToRem(20),
        paddingRight: pxToRem(20),
        paddingBottom: pxToRem(20),
        paddingLeft: pxToRem(20),
      }}
    >
      <div style={{ width: "50%" }}>
        <ContentLoader
          height={96}
          width={150}
          speed={1}
          style={{ width: "100%", height: 96 }}
          primaryColor="#f7f8ff"
          secondaryColor="#bdbdbd"
        >
          <rect x="0" y="3" rx="3" ry="3" width="100" height="32" />
          <rect x="0" y="50" rx="3" ry="3" width="100" height="16" />
        </ContentLoader>
      </div>
      <Divider style={{ width: 2, height: 100 }} orientation="vertical" />
      <div style={{ width: "50%" }}>
        <ContentLoader
          height={96}
          width={150}
          speed={1}
          style={{ width: "100%", height: 96 }}
          primaryColor="#f7f8ff"
          secondaryColor="#bdbdbd"
        >
          <rect x="75" y="0" rx="3" ry="3" width="75" height="22" />
          <rect x="70" y="44" rx="3" ry="3" width="80" height="26" />
        </ContentLoader>
      </div>
    </div>
  );
};

const getLogo = (companiaId) => {
  if (companiaId === "Sancor") return logoSancor;
  if (companiaId === "Zurich") return logoZurich;
  if (companiaId === "MercantilAndina") return logoMercantilAndina;
  if (companiaId === "RUS") return logoRus;
  if (companiaId === "Berkley") return logoBerkley;
};
