import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotaCancelacion } from "~components/app/clienteFinal/creditos/detalleCredito/actions";
import pxToRem from "~libs/pxToRem";
import Typography from "@material-ui/core/Typography";
import IconCheck from "~assets/icons/icon-check-big.svg";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import CircularProgress from "@material-ui/core/CircularProgress";

const useReduxSelector = () =>
  useSelector(state => {
    const detalleCreditoReducer = state.creditosReducers.detalleCreditoReducer;
    const operacionId =
      detalleCreditoReducer.credito.credito &&
      detalleCreditoReducer.credito.credito.operacionId;

    return {
      operacionId,
      notaCancelacionDownloading:
        detalleCreditoReducer.notaCancelacionDownloading
    };
  });

const CreditoSaldado = () => {
  const dispatch = useDispatch();
  const { operacionId, notaCancelacionDownloading } = useReduxSelector();

  return (
    <div
      style={{
        padding: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}
    >
      <img src={IconCheck} style={{ marginTop: 40 }} />
      <Typography
        style={{
          marginTop: 61,
          fontSize: pxToRem(24),
          fontSeight: "bold",
          lineHeight: 1.25,
          textAlign: "center",
          color: "#663ea8"
        }}
      >
        Ya cancelaste este crédito
      </Typography>
      <Typography
        style={{
          fontSize: pxToRem(16),
          lineHeight: 1.38,
          textAlign: "center",
          color: "#616161",
          marginLeft: "10%",
          marginRight: "10%",
          marginTop: 22,
          marginBottom: 42
        }}
      >
        Ahora podés solicitar tu prenda y ver la nota de cancelación.
      </Typography>
      <CallToActionButton
        disabled={notaCancelacionDownloading}
        variant="outlined"
        color="secondary"
        label={
          notaCancelacionDownloading ? (
            <CircularProgress
              size={18}
              thickness={4}
              style={{ alignSelf: "center", marginRight: 5, color: "#bdbdbd" }}
            />
          ) : (
            "Ver nota de cancelación"
          )
        }
        marginBottom={12}
        onClick={() => {
          dispatch(getNotaCancelacion(operacionId));
        }}
      />
      {/* <CallToActionButton
        variant="outlined"
        disabled
        label="Solicitar prenda"
        marginBottom={12}
        onClick={() => {}}
      /> */}
    </div>
  );
};
export default CreditoSaldado;
