let createPostRecoverPassword = ({
  fetch,
  environment,
  buildRequest,
  errorUnlessOk
}) => data => {
  let request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  };

  return fetch(
    `${environment.authUrl}/controldeusuarios/password/confirmacion`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createSessionLogin = ({
  fetch,
  environment,
  buildRequest,
  errorUnlessOk
}) => credentials => {
  credentials.UseTokenCookie = true;

  let request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    //credentials: 'include',
    body: JSON.stringify(credentials)
  };

  return fetch(`${environment.authUrl}/auth/login`, request)
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createSessionSendRecoveryPasswordEmail = ({
  fetch,
  environment,
  buildRequest,
  errorUnlessOk
}) => email => {
  let request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ UserEmail: email })
  };

  return fetch(`${environment.authUrl}/controldeusuarios/password`, request)
    .then(errorUnlessOk)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createGetPublicIp = ({
  fetch,
  environment,
  buildRequest,
  errorUnlessOk
}) => () => {
  let request = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
    //credentials: 'include',
    //body: JSON.stringify(formData)
  };

  return fetch(`https://api.ipify.org?format=json`, request)
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createPostVincularOperaciones = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => () => {
  let request = buildRequestWithAuth("POST");

  return fetch(
    `${environment.catalogosUrl}/apiclientes/creditos/vinculacion`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export {
  createPostRecoverPassword,
  createSessionLogin,
  createSessionSendRecoveryPasswordEmail,
  createGetPublicIp,
  createPostVincularOperaciones
};
