import React from "react";
import { useSelector } from "react-redux";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import Typography from "@material-ui/core/Typography";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import makeStyles from "@material-ui/styles/makeStyles";
import pxToRem from "~libs/pxToRem";
import { useHistory } from "react-router-dom";
import InfoIcon from "~assets/ilustrations/illus_solic_info.svg";

const useStyles = makeStyles(theme => ({
  img: {
    width: pxToRem(242.5),
    align: "center"
  },
  text: {
    fontSize: pxToRem(16),
    lineHeight: 1.38,
    textAlign: "center",
    color: "#616161"
  },
  content: {
    marginTop: 60,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

const useReduxSelector = () =>
  useSelector(state => {
    const nuevaSolicitudReducer = state.creditosReducers.nuevaSolicitudReducer;
    return {
      identidadPersonas: nuevaSolicitudReducer.identidadPersonas
    };
  });

const SolicitudEnAnalisisForm = () => {
  const { identidadPersonas } = useReduxSelector();
  const history = useHistory();
  const classes = useStyles();
  const cliente =
    identidadPersonas && identidadPersonas.titular
      ? `${identidadPersonas.titular.nombres} ${identidadPersonas.titular.apellido}`
      : "este cliente";
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            label={"Ir a la solicitud"}
            onClick={() => {
              history.push("/missolicitudes/221155");
            }}
          />
        </ContentSection>
      }
    >
      <div className={classes.content}>
        <img src={InfoIcon} className={classes.img} />
        <PrimaryTitle label={"Solicitud en Análisis"} marginBottom={20} />
        <Typography className={classes.text}>
          Ya existe una solicitud previa para {cliente} en análisis.
        </Typography>
      </div>
    </ContentSectionWithActions>
  );
};

export default SolicitudEnAnalisisForm;

/*
        <Typography className={classes.text}>
          No pudimos evaluar al cliente en este momento. Podés continuar con la
          carga de solicitud y luego te informaremos el resultado!
        </Typography>
*/
