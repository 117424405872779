import { createActions, handleActions } from "redux-actions";
import environment from "../../../../../environment";
const {
  authentication: {
    signupClienteFinal: {
      submitSignupClienteFinalInited,
      submitSignupClienteFinalCompleted,
      submitSignupClienteFinalFailed
    }
  }
} = createActions({
  authentication: {
    signupClienteFinal: {
      submitSignupClienteFinalInited: () => ({}),
      submitSignupClienteFinalCompleted: () => ({}),
      submitSignupClienteFinalFailed: () => ({})
    }
  }
});

const initialState = {
  isLoading: false
};

const submitSignupClienteFinal = (userName, onComplete) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(submitSignupClienteFinalInited());
      await services.api.postSignupClienteFinal({
        NombreUsuario: userName
        //VersionTerminos: environment.terminosYCondiciones.version
      });
      dispatch(submitSignupClienteFinalCompleted());
      onComplete();
    } catch (error) {
      console.error(error);
      dispatch(submitSignupClienteFinalFailed());
    }
  };
};

const signupClienteFinalReducer = handleActions(
  {
    [submitSignupClienteFinalInited]: () => {
      return {
        ...initialState,
        isLoading: true
      };
    },
    [submitSignupClienteFinalCompleted]: (state, action) => {
      return {
        ...initialState,
        isLoading: false
      };
    },
    [submitSignupClienteFinalFailed]: (state, action) => {
      return {
        ...initialState,
        isLoading: false
      };
    }
  },
  initialState
);

export default signupClienteFinalReducer;
export { submitSignupClienteFinal };
