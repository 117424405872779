import { createActions, handleActions } from "redux-actions";
import { AUTOPRENDA, PRENDARIO_TRADICIONAL } from "./tipoCreditoConsts";

const {
  calcularCredito: {
    clear,
    setTipoCredito,
    clearTipoCredito,
    setVehiculoAnio,
    clearVehiculoAnio,
    setImporte,
    clearImporte,
    setPlazo,
    clearPlazo,
    calcularInited,
    calcularCompleted,
    calcularFailed
  }
} = createActions({
  calcularCredito: {
    clear: () => ({}),
    setTipoCredito: value => ({ value }),
    clearTipoCredito: () => ({}),
    setVehiculoAnio: value => ({ value }),
    clearVehiculoAnio: () => ({}),
    setImporte: value => ({ value }),
    clearImporte: () => ({}),
    setPlazo: value => ({ value }),
    clearPlazo: () => ({}),
    calcularInited: props => ({ props }),
    calcularCompleted: credito => ({ credito }),
    calcularFailed: error => ({ error })
  }
});

const simularCredito = (tipoCredito, anio, importe, plazo) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(calcularInited({ anio, importe, plazo }));

      const response = await services.api.getCalculadorResult({
        AnioAuto: anio,
        Plazo: plazo,
        Capital: importe,
        Tipocredito: tipoCredito === AUTOPRENDA ? 3 : null
      });

      dispatch(calcularCompleted(response));
    } catch (error) {
      console.error(error);
      services.sendMessage(
        "Ha ocurrido un error. Por favor, esperá unos segundos e intentá nuevamente. Si el problema persiste, en el menú podés acceder a la opción para contactarte con tu comercial.",
        "error"
      );
      dispatch(calcularFailed(error));
    }
  };
};

const initialState = {
  tipoCreditoSelected: null,
  vehiculoAnioSelected: null,
  importeSelected: null,
  plazoSelected: null,
  isForward: true,
  isLoading: false,
  credito: {}
};

const calcularCreditoReducer = handleActions(
  {
    [clear]: () => {
      return {
        ...initialState,
        isForward: true
      };
    },
    [setTipoCredito]: (state, action) => {
      return {
        ...state,
        tipoCreditoSelected: action.payload.value,
        isForward: true
      };
    },
    [clearTipoCredito]: (state, action) => {
      return {
        ...state,
        tipoCreditoSelected: null,
        isForward: false
      };
    },
    [setVehiculoAnio]: (state, action) => {
      return {
        ...state,
        vehiculoAnioSelected: action.payload.value,
        isForward: true
      };
    },
    [clearVehiculoAnio]: (state, action) => {
      return {
        ...state,
        vehiculoAnioSelected: null,
        isForward: false
      };
    },
    [setImporte]: (state, action) => {
      return {
        ...state,
        importeSelected: action.payload.value,
        isForward: true
      };
    },
    [clearImporte]: (state, action) => {
      return {
        ...state,
        importeSelected: null,
        isForward: false
      };
    },
    [setPlazo]: (state, action) => {
      return {
        ...state,
        plazoSelected: action.payload.value,
        isForward: true
      };
    },
    [clearPlazo]: (state, action) => {
      return {
        ...state,
        plazoSelected: null,
        isForward: false
      };
    },
    [calcularInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        credito: {},
        isForward: false
      };
    },
    [calcularCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        credito: action.payload.credito
      };
    },
    [calcularFailed]: (state, action) => {
      return {
        ...initialState,
        isForward: true
      };
    }
  },
  initialState
);

export default calcularCreditoReducer;
export {
  clear,
  setTipoCredito,
  clearTipoCredito,
  setVehiculoAnio,
  clearVehiculoAnio,
  setImporte,
  clearImporte,
  setPlazo,
  clearPlazo,
  simularCredito
};
