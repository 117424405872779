import React from "react";
import { Route } from "react-router-dom";
import { default as NewCotizadorSeguros } from "./cotizador/CotizadorSeguros";

import OldLayout from "~componentes/app/OldLayout";
import { AsyncCotizadorSeguros } from "~componentes/app/LazyComponents";
import { FeatureFlagRender } from "~shared/featureFlags";

const SegurosRoutes = (pathSeguros) => [
  <Route key="seguros-1" path={pathSeguros}>
    <FeatureFlagRender flag={"nuevo-cotizador-seguros"} when={false}>
      <OldLayout>
        <AsyncCotizadorSeguros />
      </OldLayout>
    </FeatureFlagRender>
    <FeatureFlagRender flag={"nuevo-cotizador-seguros"} when={true}>
      <NewCotizadorSeguros />
    </FeatureFlagRender>
  </Route>,
];

export default SegurosRoutes;

/*
  <Route key="seguros-2" exact path={"/cotizadorSeguros/:position?"}>
    <NewCotizadorSeguros />
  </Route>
*/
