import React, { useEffect, useState } from "react";
import qs from "query-string";
import SigninWithSocialNetworks from "./SigninWithSocialNetworks";
import SigninWithEmail from "./SigninWithEmail";
import { sendError } from "../../libs/sendMessage";

const SIGNIN_SOCIAL = 1;
const SIGNIN_EMAIL_PASSWORD = 2;

const Signin = props => {
  const [currentView, setCurrentView] = useState(SIGNIN_SOCIAL);

  useEffect(() => {
    const parsed = qs.parse(props.location.search);
    if (parsed.passwordless) {
      setCurrentView(SIGNIN_EMAIL_PASSWORD);
      sendError("No detectamos email asociado, por favor ingresá aquí");
    }
  }, [props.location.search]);

  return (
    <React.Fragment>
      {currentView === SIGNIN_SOCIAL && (
        <SigninWithSocialNetworks
          onChangeToEmailLogin={() => setCurrentView(SIGNIN_EMAIL_PASSWORD)}
        />
      )}
      {currentView === SIGNIN_EMAIL_PASSWORD && (
        <SigninWithEmail
          onChangeToSocialNetworks={() => setCurrentView(SIGNIN_SOCIAL)}
        />
      )}
    </React.Fragment>
  );
};

export default Signin;
