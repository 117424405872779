import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useLocationRef from "~components/app/useLocationRef";

import ProcessLayout from "~components/styled/layouts/processLayout/ProcessLayout";

import { clear, setCanal } from "./actions";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

import TipoCreditoStep from "./steps/TipoCreditoStep";
import DniStep from "./steps/DniStep";
import SexoStep from "./steps/SexoStep";
import EstadoCivilStep from "./steps/EstadoCivilStep";
import QueresEvaluarStep from "./steps/QueresEvaluarStep";
import ResultadoEvaluacionStep from "./steps/ResultadoEvaluacionStep/resultadoEvaluacionStep";
import DniConyugeStep from "./steps/DniConyugeStep";
import SexoConyugeStep from "./steps/SexoConyugeStep";
import SituacionLaboralStep from "./steps/SituacionLaboralStep";
import AntiguedadLaboralStep from "./steps/AntiguedadLaboralStep";
import ProvinciaStep from "./steps/ProvinciaStep";
import LocalidadStep from "./steps/LocalidadStep";
import CodigoPostalStep from "./steps/CodigoPostalStep";
import VehiculoMarcaStep from "./steps/VehiculoMarcaStep";
import VehiculoModeloStep from "./steps/VehiculoModeloStep";
import VehiculoAnioStep from "./steps/VehiculoAnioStep";
import VehiculoUsoStep from "./steps/VehiculoUsoStep";
import VehiculoTieneGNCStep from "./steps/VehiculoTieneGNCStep";
import ValorVehiculoStep from "./steps/ValorVehiculoStep";
import ImporteYPlazoStep from "./steps/ImporteYPlazoStep";
import OfertasStep from "./steps/ofertasStep/OfertasStep";
import SegurosStep from "./steps/segurosStep/SegurosStep";
import ContactoTelefonoStep from "./steps/ContactoTelefonoStep";
import ContactoFranjaHorariaStep from "./steps/ContactoFranjaHorariaStep";
import ContactoEmailStep from "./steps/ContactoEmailStep";

const steps = {
  // steps must be string
  tipoCreditoStep: "0",
  canalStep: "1",
  dniStep: "2",
  sexoStep: "3",
  estadoCivilStep: "4",
  dniConyugeStep: "5",
  sexoConyugeStep: "6",
  queresEvaluarStep: "7",
  resultadoEvaluacionStep: "8",
  situacionLaboralStep: "9",
  antiguedadLaboralStep: "10",
  provinciaStep: "11",
  localidadStep: "12",
  codigoPostalStep: "13",
  vehiculoMarcaStep: "14",
  vehiculoModeloStep: "15",
  vehiculoAnioStep: "16",
  vehiculoUsoStep: "17",
  vehiculoTieneGNCStep: "18",
  valorVehiculoStep: "19",
  importeYPlazoStep: "20",
  contactoTelefonoStep: "21",
  contactoFranjaHorariaStep: "22",
  contactoEmailStep: "23",
  ofertasStep: "24",
  segurosStep: "25",
  finalStep: "999"
};
const BASE_URL = "/creditos/nuevasolicitud";
const FIRST_STEP = steps.tipoCreditoStep;

const useReduxSelector = () =>
  useSelector(state => {
    const nuevaSolicitudReducer = state.creditosReducers.nuevaSolicitudReducer;

    return {
      tipoCreditoSelected: nuevaSolicitudReducer.tipoCreditoSelected,
      canalSelected: nuevaSolicitudReducer.canalSelected,
      dni: nuevaSolicitudReducer.dni,
      sexoSelected: nuevaSolicitudReducer.sexoSelected,
      estadoCivilSelected: nuevaSolicitudReducer.estadoCivilSelected,
      dniConyuge: nuevaSolicitudReducer.dniConyuge,
      sexoConyugeSelected: nuevaSolicitudReducer.sexoConyugeSelected,
      queresEvaluarSelected: nuevaSolicitudReducer.queresEvaluarSelected,
      solicitudIniciada: nuevaSolicitudReducer.solicitudIniciada,
      situacionLaboral: nuevaSolicitudReducer.situacionLaboral,
      antiguedadLaboral: nuevaSolicitudReducer.antiguedadLaboral,
      provincia: nuevaSolicitudReducer.provincia,
      localidad: nuevaSolicitudReducer.localidad,
      codigoPostal: nuevaSolicitudReducer.codigoPostal,
      vehiculoMarca: nuevaSolicitudReducer.vehiculoMarca,
      vehiculoModelo: nuevaSolicitudReducer.vehiculoModelo,
      vehiculoAnio: nuevaSolicitudReducer.vehiculoAnio,
      vehiculoUso: nuevaSolicitudReducer.vehiculoUso,
      vehiculoTieneGNC: nuevaSolicitudReducer.vehiculoTieneGNC,
      valorVehiculo: nuevaSolicitudReducer.valorVehiculo,
      importe: nuevaSolicitudReducer.importe,
      plazo: nuevaSolicitudReducer.plazo,
      ofertaElegida: nuevaSolicitudReducer.ofertaElegida,
      polizaElegida: nuevaSolicitudReducer.polizaElegida,
      contactoTelefono: nuevaSolicitudReducer.contactoTelefono,
      contactoFranjaHoraria: nuevaSolicitudReducer.contactoFranjaHoraria,
      contactoEmail: nuevaSolicitudReducer.contactoEmail,
      isForward: nuevaSolicitudReducer.isForward
    };
  });

const NuevaSolicitud = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { position = FIRST_STEP } = useParams();

  useEffect(() => {
    dispatch(clear());

    return function cleanup() {
      dispatch(clear());
    };
  }, [dispatch]);

  const currentState = useReduxSelector();
  const currentStep = useCurrentStep(currentState, position);

  const isInProcess = [
    steps.tipoCreditoStep,
    steps.canalStep,
    steps.dniStep,
    steps.sexoStep,
    steps.estadoCivilStep,
    steps.dniConyugeStep,
    steps.sexoConyugeStep,
    steps.queresEvaluarStep,
    steps.resultadoEvaluacionStep,
    steps.situacionLaboralStep,
    steps.antiguedadLaboralStep,
    steps.provinciaStep,
    steps.localidadStep,
    steps.codigoPostalStep,
    steps.vehiculoMarcaStep,
    steps.vehiculoModeloStep,
    steps.vehiculoAnioStep,
    steps.vehiculoUsoStep,
    steps.vehiculoTieneGNCStep,
    steps.valorVehiculoStep,
    steps.importeYPlazoStep,
    steps.contactoTelefonoStep,
    steps.contactoFranjaHorariaStep,
    steps.contactoEmailStep,
    steps.ofertasStep,
    steps.segurosStep,
    steps.finalStep
  ].includes(position);

  return (
    <>
      {isInProcess && (
        <ProcessLayout
          title={"Nueva solicitud"}
          progress={(currentStep.order * 100) / Object.keys(steps).length} // porcentage (0-100)
          onBackClick={() => {
            history.goBack();
          }}
          onCloseClick={() => {
            history.push("/");
          }}
          isForward={currentState.isForward}
        >
          {position === steps.tipoCreditoStep && <TipoCreditoStep />}
          {position === steps.canalStep && (
            <>
              CANAL STEP
              <CallToActionButton
                label="CLICK"
                onClick={() => dispatch(setCanal(1))}
              />
            </>
          )}
          {position === steps.dniStep && <DniStep />}
          {position === steps.sexoStep && <SexoStep />}
          {position === steps.estadoCivilStep && <EstadoCivilStep />}
          {position === steps.dniConyugeStep && <DniConyugeStep />}
          {position === steps.sexoConyugeStep && <SexoConyugeStep />}
          {position === steps.queresEvaluarStep && <QueresEvaluarStep />}
          {position === steps.resultadoEvaluacionStep && (
            <ResultadoEvaluacionStep />
          )}
          {position === steps.situacionLaboralStep && <SituacionLaboralStep />}
          {position === steps.antiguedadLaboralStep && (
            <AntiguedadLaboralStep />
          )}
          {position === steps.provinciaStep && <ProvinciaStep />}
          {position === steps.localidadStep && <LocalidadStep />}
          {position === steps.codigoPostalStep && <CodigoPostalStep />}
          {position === steps.vehiculoMarcaStep && <VehiculoMarcaStep />}
          {position === steps.vehiculoModeloStep && <VehiculoModeloStep />}
          {position === steps.vehiculoAnioStep && <VehiculoAnioStep />}
          {position === steps.vehiculoUsoStep && <VehiculoUsoStep />}
          {position === steps.vehiculoTieneGNCStep && <VehiculoTieneGNCStep />}
          {position === steps.valorVehiculoStep && <ValorVehiculoStep />}
          {position === steps.importeYPlazoStep && <ImporteYPlazoStep />}
          {position === steps.contactoTelefonoStep && <ContactoTelefonoStep />}
          {position === steps.contactoFranjaHorariaStep && (
            <ContactoFranjaHorariaStep />
          )}
          {position === steps.contactoEmailStep && <ContactoEmailStep />}
          {position === steps.ofertasStep && <OfertasStep />}
          {position === steps.segurosStep && <SegurosStep />}
          {position === steps.finalStep && (
            <>
              ULTIMO PASO
              <CallToActionButton
                label="SALIR"
                onClick={() => history.push("/")}
              />
            </>
          )}
        </ProcessLayout>
      )}
      {!isInProcess && <>RESULT???</>}
    </>
  );
};

export default NuevaSolicitud;

const useCurrentStep = (currentState, position) => {
  const history = useHistory();
  const locationRef = useLocationRef();

  const validSteps = getValidSteps(currentState);

  const isValidStep = validSteps.find(x => x.index === position) ? true : false;

  const currentStep = validSteps.slice().pop();

  useEffect(() => {
    const stepUrl = `${BASE_URL}`;
    // if not valid step, go to first step.
    if (!isValidStep) {
      history.replace(stepUrl);
    }
  }, [history, isValidStep]);

  useEffect(() => {
    const stepUrl = `${BASE_URL}/${currentStep.index}`;
    const isThisUrl = locationRef.current.pathname === stepUrl;
    const isFirstStep =
      locationRef.current.pathname === `${BASE_URL}` &&
      currentStep.index === FIRST_STEP;

    if (!isThisUrl && !isFirstStep && isValidStep) history.push(stepUrl);
  }, [isValidStep, history, currentStep.index]);

  return currentStep;
};

const getValidSteps = ({
  tipoCreditoSelected,
  canalSelected,
  dni,
  sexoSelected,
  estadoCivilSelected,
  dniConyuge,
  sexoConyugeSelected,
  queresEvaluarSelected,
  solicitudIniciada,
  situacionLaboral,
  antiguedadLaboral,
  provincia,
  localidad,
  codigoPostal,
  vehiculoMarca,
  vehiculoModelo,
  vehiculoAnio,
  vehiculoUso,
  vehiculoTieneGNC,
  valorVehiculo,
  importe,
  plazo,
  ofertaElegida,
  polizaElegida,
  contactoTelefono,
  contactoFranjaHoraria,
  contactoEmail
}) => {
  const tipoCreditoStep = step(steps.tipoCreditoStep, !tipoCreditoSelected);
  const canalStep = step(steps.canalStep, !canalSelected);
  const dniStep = step(steps.dniStep, !dni);
  const sexoStep = step(steps.sexoStep, !sexoSelected);
  const estadoCivilStep = step(steps.estadoCivilStep, !estadoCivilSelected);
  const dniConyugeStep = step(
    steps.dniConyugeStep,
    estadoCivilSelected === "Casado" && !dniConyuge
  );
  const sexoConyugeStep = step(
    steps.sexoConyugeStep,
    estadoCivilSelected === "Casado" && !sexoConyugeSelected
  );
  const queresEvaluarStep = step(
    steps.queresEvaluarStep,
    !queresEvaluarSelected
  );
  const resultadoEvaluacionStep = step(
    steps.resultadoEvaluacionStep,
    !solicitudIniciada
  );
  const situacionLaboralStep = step(
    steps.situacionLaboralStep,
    !situacionLaboral
  );
  const antiguedadLaboralStep = step(
    steps.antiguedadLaboralStep,
    !antiguedadLaboral
  );
  const provinciaStep = step(steps.provinciaStep, !provincia);
  const localidadStep = step(steps.localidadStep, !localidad);
  const codigoPostalStep = step(steps.codigoPostalStep, !codigoPostal);
  const vehiculoMarcaStep = step(steps.vehiculoMarcaStep, !vehiculoMarca.value);
  const vehiculoModeloStep = step(steps.vehiculoModeloStep, !vehiculoModelo);
  const vehiculoAnioStep = step(steps.vehiculoAnioStep, !vehiculoAnio);
  const vehiculoUsoStep = step(steps.vehiculoUsoStep, !vehiculoUso);
  const vehiculoTieneGNCStep = step(
    steps.vehiculoTieneGNCStep,
    vehiculoTieneGNC === null
  );
  const valorVehiculoStep = step(steps.valorVehiculoStep, !valorVehiculo);
  const importeYPlazoStep = step(steps.importeYPlazoStep, !importe || !plazo);
  const contactoTelefonoStep = step(
    steps.contactoTelefonoStep,
    !contactoTelefono
  );
  const contactoFranjaHorariaStep = step(
    steps.contactoFranjaHorariaStep,
    !contactoFranjaHoraria
  );
  const contactoEmailStep = step(
    steps.contactoEmailStep,
    contactoEmail === null
  );
  const ofertasStep = step(steps.ofertasStep, !ofertaElegida);
  const segurosStep = step(steps.segurosStep, !polizaElegida);
  ///
  const finalStep = step(steps.finalStep, true);
  //set order of steps
  tipoCreditoStep
    .next(canalStep)
    .next(dniStep)
    .next(sexoStep)
    .next(estadoCivilStep)
    .next(dniConyugeStep)
    .next(sexoConyugeStep)
    .next(queresEvaluarStep)
    .next(resultadoEvaluacionStep)
    .next(situacionLaboralStep)
    .next(antiguedadLaboralStep)
    .next(provinciaStep)
    .next(localidadStep)
    .next(codigoPostalStep)
    .next(vehiculoMarcaStep)
    .next(vehiculoAnioStep)
    .next(vehiculoModeloStep)
    .next(vehiculoUsoStep)
    .next(vehiculoTieneGNCStep)
    .next(valorVehiculoStep)
    .next(importeYPlazoStep)
    .next(ofertasStep)
    .next(segurosStep)
    .next(contactoTelefonoStep)
    .next(contactoFranjaHorariaStep)
    .next(contactoEmailStep)
    ///
    .next(finalStep);

  return tipoCreditoStep.run();
};

const step = (index, isThisStep) => {
  let next = null;
  let order = 1;

  return {
    index,
    order,
    next(step) {
      if (step) next = { ...step, order: this.order + 1 };
      return next;
    },
    run() {
      return isThisStep ? [this] : [this, ...(next && next.run())];
    }
  };
};
