import React from "react";
import pxToRem from "~libs/pxToRem";
import moment from "moment";

import CardButton from "~components/styled/cardButton/CardButton";
import Typography from "@material-ui/core/Typography";
import ColorLabel from "~components/styled/colorLabel/ColorLabel";
import ContentLoader from "react-content-loader";

const PolizaCardButton = ({ poliza, marginBottom, onClick, isLoading }) => {
  const labelStyle = getSituacionStyle(poliza ? poliza.suspendida : null);
  const tiposSeguro = { TC: "Terceros completo", TR: "Todo riesgo" };
  return (
    <CardButton
      marginBottom={marginBottom}
      onClick={onClick}
      color={labelStyle.color}
    >
      {isLoading && renderLoadingCard()}
      {!isLoading && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "start",
            minHeight: pxToRem(136),
            flexGrow: 1,
            paddingTop: pxToRem(20),
            paddingRight: pxToRem(20),
            paddingBottom: pxToRem(20),
            paddingLeft: pxToRem(10)
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography
              variant="caption"
              noWrap
              style={{
                fontSize: pxToRem(16),
                fontWeight: "bold",
                lineHeight: 1.38,
                textAlign: "left",
                paddingRight: pxToRem(8),
                textTransform: "capitalize"
              }}
            >
              {poliza.ciaAseguradora.toLowerCase()}
            </Typography>
            <span style={{ display: "flex", alignItems: "center" }}>
              <ColorLabel
                text={labelStyle.label}
                variant={labelStyle.variant}
              />
            </span>
          </div>
          <div
            style={{
              display: "flex",
              paddingTop: pxToRem(8)
            }}
          >
            <Typography
              style={{
                fontSize: pxToRem(14),
                fontWeight: 600,
                lineHeight: 1.36,
                color: "#757575"
              }}
            >
              {tiposSeguro[poliza.tipoSeguro]}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              paddingTop: pxToRem(6)
            }}
          >
            <Typography
              noWrap
              style={{
                fontSize: pxToRem(12),
                fontWeight: 600,
                lineHeight: 1.33,
                textAlign: "left",
                color: "#9e9e9e"
              }}
            >
              {`DOM.: ${poliza.dominio}`}
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              marginTop: "auto"
            }}
          >
            <Typography
              noWrap
              style={{
                fontSize: pxToRem(12),
                fontWeight: 600,
                lineHeight: 1.33,
                textAlign: "left",
                color: "#9e9e9e"
              }}
            >
              {"Válida desde el"}
            </Typography>
            <Typography
              noWrap
              style={{
                fontSize: pxToRem(12),
                fontWeight: "bold",
                lineHeight: 1.33,
                color: "#9e9e9e"
              }}
            >
              {`: ${moment(moment.utc(poliza.fechaInicio)).format("L")}`}
            </Typography>

            <Typography
              noWrap
              style={{
                paddingLeft: 15,
                fontSize: pxToRem(12),
                fontWeight: 600,
                lineHeight: 1.33,
                textAlign: "left",
                color: "#9e9e9e"
              }}
            >
              {"hasta el"}
            </Typography>
            <Typography
              noWrap
              style={{
                fontSize: pxToRem(12),
                fontWeight: "bold",
                lineHeight: 1.33,
                color: "#9e9e9e"
              }}
            >
              {`: ${moment(moment.utc(poliza.fechaFin)).format("L")}`}
            </Typography>
          </div>
        </div>
      )}
    </CardButton>
  );
};

export default PolizaCardButton;

const renderLoadingCard = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        paddingTop: pxToRem(20),
        paddingRight: pxToRem(20),
        paddingBottom: pxToRem(20),
        paddingLeft: pxToRem(10) //IF no color passed, set this to 20
      }}
    >
      <ContentLoader
        height={96}
        width={300}
        speed={1}
        style={{ height: 96 }}
        primaryColor="#f7f8ff"
        secondaryColor="#B9A7D7"
      >
        <rect x="0" y="3" rx="3" ry="3" width="200" height="16" />
        <rect x="225" y="0" rx="3" ry="3" width="75" height="22" />
        <rect x="0" y="28" rx="3" ry="3" width="180" height="14" />
        <rect x="0" y="48" rx="3" ry="3" width="180" height="14" />
        <rect x="0" y="84" rx="3" ry="3" width="65" height="12" />
        <rect x="100" y="84" rx="3" ry="3" width="80" height="12" />
      </ContentLoader>
    </div>
  );
};

export const getSituacionStyle = suspendida => {
  const otros = "#4CBFD6";
  const info = "#FFE4A3";
  const warning = "#FAB673";
  const success = "#9ADDCF";
  const danger = "#FA8F82";
  const grey = "#e0e0e0";
  /*   if (!suspendida)
    return {
      label: "",
      variant: "",
      color: grey
    }; */

  if (suspendida === true)
    return {
      label: "Anulada",
      variant: "danger",
      color: danger
    };
  if (suspendida === false)
    return {
      label: "Vigente",
      variant: "otros",
      color: otros
    };

  return {
    label: "",
    variant: "",
    color: grey
  };
};
