import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import pxToRem from "~libs/pxToRem";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import { clearVehiculoMarca } from "../../actions";
import Typography from "@material-ui/core/Typography/Typography";
//import {   } from "../../actions";

const AnioNoFinanciable = () => {
  const dispatch = useDispatch();
  const handleElegirOtroVehiculo = () => {
    dispatch(clearVehiculoMarca());
    /*  dispatch(clearVehiculoAnio());
    dispatch(clearVehiculoModelo());
    dispatch(clearVehiculoUso());
    dispatch(clearValorVehiculo()); */
  };
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            label={"Elegir otro vehículo"}
            onClick={handleElegirOtroVehiculo}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle label={"Año no financiable"} marginBottom={60} />
      <div>
        <Typography
          style={{
            opacity: 0.75,
            fontSize: pxToRem(16),
            lineHeight: 1.38,
            textAlign: "left",
            color: "#000000",
            marginBottom: 35
          }}
        >
          Lo sentimos, pero no tenemos un producto para la antigüedad del auto y
          el perfil del cliente.
        </Typography>
      </div>
    </ContentSectionWithActions>
  );
};

export default AnioNoFinanciable;
