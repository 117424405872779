import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import InfiniteScroll from "~shared/layout/InfiniteScroll"; // dejar de usar en cuanto se pueda
import LinearProgress from "@material-ui/core/LinearProgress";

import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import closeIcon from "~assets/icons/ic-close.svg";
import backIcon from "~assets/icons/ic-back.svg";

import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";

import { useLocation } from "react-router-dom";

import pxToRem from "~libs/pxToRem";

const NAV_BAR_HEIGHT = 61;
const ACTIONS_BAR_HEIGHT = 78;

const useStyles = makeStyles(theme => ({
  main: {
    //overflowX: "hidden",
    backgroundColor: "#f9f7fc",

    paddingTop: 0,
    minHeight: `calc(100vh - ${ACTIONS_BAR_HEIGHT}px)`,

    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "space-between"
  },
  columnCentered: {
    padding: theme.typography.pxToRem(16),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    flex: 1
  },
  logoSize: {
    width: "15vh",
    [theme.breakpoints.mobile]: {
      padding: 12
    }
  },
  logoFigure: {
    padding: theme.typography.pxToRem(16),
    minHeight: "16vh",
    display: "flex",
    margin: 0,
    [theme.breakpoints.mobile]: {
      minHeight: "8vh"
    }
  }
}));

const ProcessLayout = ({
  children,
  title,
  progress = 0,
  onBackClick,
  onCloseClick,
  isForward = true
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.main}>
        <InfiniteScroll /> {/* TODO: remove this when is no needed anymore */}
        <NavBar
          title={title}
          progress={progress}
          onBackClick={onBackClick}
          onCloseClick={onCloseClick}
        />
        <SlideAnimation isForward={isForward} firstLoad={true}>
          <div className={classes.columnCentered}>{children}</div>
        </SlideAnimation>
      </div>
    </>
  );
};

export default ProcessLayout;

const SlideAnimation = ({ isForward, children }) => {
  const location = useLocation();

  return (
    <Slide
      key={location.key}
      direction={isForward ? "left" : "right"}
      in={true}
    >
      {children}
    </Slide>
  );
};

const useNavBarStyles = makeStyles(theme => ({
  root: {
    position: "sticky",
    top: 0,
    background: "#faf7fc",
    boxShadow: "0 -1px 3px 0 rgba(0, 0, 0, 0.1)",
    zIndex: 999,
    height: NAV_BAR_HEIGHT,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  content: {
    flex: 1,
    padding: 0,
    display: "flex"
  },
  progress: {
    backgroundColor: "#d7caed",
    height: 5
  }
}));

const NavBar = ({ title, progress, onBackClick, onCloseClick }) => {
  const classes = useNavBarStyles();

  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  const isScrollTriggered = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!isScrollTriggered}>
      <div
        className={classes.root}
        style={
          isScrolled
            ? {
                boxShadow: "0 2px 3px 0 #00000029"
              }
            : null
        }
      >
        <div className={classes.content}>
          <LightTooltip title={"VOLVER"}>
            <IconButton
              onClick={onBackClick}
              disabled={onBackClick ? false : true}
              style={{ width: 56, height: 56 }}
            >
              <IconFile src={backIcon} />
            </IconButton>
          </LightTooltip>
          <div style={{ flex: 1, textAlign: "center" }}>
            <Typography
              variant={"h1"}
              color={"primary"}
              style={{
                fontSize: pxToRem(18),
                fontWeight: "bold",
                lineHeight: "56px",
                margin: 0
              }}
            >
              {title}
            </Typography>
          </div>
          <LightTooltip title={"CANCELAR"}>
            <IconButton
              onClick={onCloseClick}
              disabled={onCloseClick ? false : true}
              style={{ width: 56, height: 56 }}
            >
              <IconFile src={closeIcon} />
            </IconButton>
          </LightTooltip>
        </div>
        <LinearProgress
          value={progress}
          variant={"determinate"}
          color={"primary"}
          classes={{
            determinate: classes.progress
          }}
        />
      </div>
    </Slide>
  );
};

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: "bold",
    marginTop: 5
  }
}))(Tooltip);

const IconFile = ({ src }) => {
  return <img alt="" src={src} style={{ width: 18, height: 18 }} />;
};
