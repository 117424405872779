import React from "react";
import CardButton from "~components/styled/cardButton/CardButton";
import ContentLoader from "react-content-loader";

const CuotaLoading = () => (
  <CardButton marginBottom={16} color={"#E0E0E0"}>
    <ContentLoader
      speed={1}
      primaryColor="#f7f8ff"
      secondaryColor="#B9A7D7"
      style={{ height: 86, width: "100%" }}
    >
      <rect x="16" y="20" rx="3" ry="3" width="20%" height="18" />
      <rect x="16" y="64" rx="3" ry="3" width="90%" height="15" />
      <rect x="16" y="84" rx="3" ry="3" width="90%" height="24" />
    </ContentLoader>
  </CardButton>
);

export default CuotaLoading;
