import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentSection from "~components/styled/contentSection/ContentSection";
import { useHistory } from "react-router-dom";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import { CheckboxButton } from "~components/styled/checkboxButton/CheckboxButton";
import multimarcasIcon from "~assets/icons/ic_multimarcas.svg";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import {
  setQueresEvaluar,
  clearQueresEvaluar,
  fetchIdentidad
} from "../actions";

const useReduxSelector = () =>
  useSelector(state => {
    const nuevaSolicitudReducer = state.creditosReducers.nuevaSolicitudReducer;

    return {
      dniTitular: nuevaSolicitudReducer.dni,
      sexoTitular: nuevaSolicitudReducer.sexoSelected,
      dniConyuge: nuevaSolicitudReducer.dniConyuge,
      sexoConyuge: nuevaSolicitudReducer.sexoConyugeSelected,
      identidadLoading: nuevaSolicitudReducer.identidadLoading,
      identidadPersonas: nuevaSolicitudReducer.identidadPersonas,
      identidadError: nuevaSolicitudReducer.identidadError
    };
  });

const QueresEvaluarStep = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const {
    dniTitular,
    sexoTitular,
    dniConyuge,
    sexoConyuge,
    identidadLoading,
    identidadPersonas,
    identidadError
  } = useReduxSelector();
  useEffect(() => {
    dispatch(clearQueresEvaluar());
  }, [dispatch]);

  useEffect(() => {
    if (dniTitular && sexoTitular)
      dispatch(
        fetchIdentidad(dniTitular, sexoTitular, dniConyuge, sexoConyuge)
      );
  }, [
    sexoTitular,
    dniTitular,
    dniConyuge,
    sexoConyuge,
    dispatch,
    fetchIdentidad
  ]);

  const textHasConyuge =
    identidadPersonas && identidadPersonas.conyuge
      ? ` y a ${identidadPersonas.conyuge.nombres} ${identidadPersonas.conyuge.apellido}`
      : "";
  return (
    <ContentSection>
      {identidadLoading && <></>}
      {identidadError && <>Documento y/o sexo inválido</>}
      {!identidadLoading && identidadPersonas && (
        <>
          <PrimaryTitle
            label={`¿Querés evaluar a ${identidadPersonas.titular.nombres} ${identidadPersonas.titular.apellido}${textHasConyuge}?`}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: 20
            }}
          >
            <div style={{ marginRight: 5, width: "100%" }}>
              <CheckboxButton onClick={() => history.push("/")}>
                <div className={classes.container}>
                  <img
                    src={multimarcasIcon}
                    style={{ height: 58, marginBottom: 17 }}
                  />
                  <Typography className={classes.btnLabel}>
                    No, volver
                  </Typography>
                </div>
              </CheckboxButton>
            </div>
            <div style={{ marginLeft: 5, width: "100%" }}>
              <CheckboxButton onClick={() => dispatch(setQueresEvaluar(true))}>
                <div className={classes.container}>
                  <img
                    src={multimarcasIcon}
                    style={{ height: 63, marginBottom: 17 }}
                  />
                  <Typography className={classes.btnLabel}>
                    Sí, evaluar
                  </Typography>
                </div>
              </CheckboxButton>
            </div>
          </div>
        </>
      )}
    </ContentSection>
  );
};

export default QueresEvaluarStep;

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 145,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  },
  btnLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    lineHeight: 1.38,
    letterSpacing: 0.48,
    textAlign: "center",
    color: theme.palette.primary.main
  }
}));
