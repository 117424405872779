import React from "react";
import NumberFormat from "react-number-format";

/*

InputProps={{
          inputComponent: CurrencyInput,
        }}

*/

const CurrencyInput = ({ inputRef, onChange, ...other }) => {
  return (
    <NumberFormat
      type={"tel"}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator={"."}
      decimalSeparator={","}
      decimalScale={0}
      isNumericString
      prefix="$"
      {...other}
    />
  );
};

export default CurrencyInput;
