import React from "react";
import { Route } from "react-router-dom";
import OldLayout from "~componentes/app/OldLayout";
import {
  AsyncCotizadorAuto,
  AsyncCapturarAuto
} from "~componentes/app/LazyComponents";

const AutosRoutes = () => [
  <Route key="autos-1" path="/autos">
    <OldLayout>
      <AsyncCotizadorAuto />
    </OldLayout>
  </Route>,
  <Route key="autos-2" path="/capturarauto">
    <OldLayout>
      <AsyncCapturarAuto />
    </OldLayout>
  </Route>
];
export default AutosRoutes;
