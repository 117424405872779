import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getDetalleCredito } from "~components/app/clienteFinal/creditos/detalleCredito/actions";
import makeStyles from "@material-ui/styles/makeStyles";

import pxToRem from "~libs/pxToRem";

import moment from "moment";
import NumberFormat from "react-number-format";
import { getSituacionStyle } from "~components/app/clienteFinal/creditos/creditoCardButton/CreditoCardButton";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Typography from "@material-ui/core/Typography";

import ColorLabel from "~components/styled/colorLabel/ColorLabel";
import LinkButton from "~components/styled/linkButton/LinkButton";

import PersonIcon from "~assets/icons/ic-perfil-active.svg";
import ContactIcon from "~assets/icons/ic-contacto-active.svg";
import AutoIcon from "~assets/icons/ic-autos-active-small.svg";
import Divider from "@material-ui/core/Divider";

import ContentLoader from "react-content-loader";
import getPhoneText from "~components/styled/phone/getPhoneText";

const useReduxSelector = () =>
  useSelector(state => ({
    isLoading: state.creditosReducers.detalleCreditoReducer.isLoading,
    credito: state.creditosReducers.detalleCreditoReducer.credito
  }));

const DetalleCreditoTab = () => {
  const { isLoading, credito } = useReduxSelector();
  let { operacionId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDetalleCredito(operacionId));
  }, []);

  return (
    <>
      {!isLoading && credito && (
        <>
          <CreditoInfo {...credito.credito} />
          <TitularInfo {...credito.titular} />
          <ContactoInfo {...credito.titular} />
          <VehiculoInfo {...credito.vehiculo} />
          <PolizaInfo {...credito.vehiculo} />
        </>
      )}
      {isLoading && <LoadingTemplate length={3} />}
    </>
  );
};
export default DetalleCreditoTab;

const ESTADO_CREDITO_ALDIA = "AlDia";

const CreditoInfo = ({
  operacionId,
  estadoCredito,
  importeFinanciado,
  plazo,
  fechaOtorgamiento
}) => {
  const classes = useStyles();
  const situacionStyle = getSituacionStyle(estadoCredito);

  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography
            style={{
              paddingTop: 12,
              fontSize: pxToRem(14),
              fontWeight: 600,
              lineHeight: 1.36,
              color: "#9e9e9e",
              textAlign: "center"
            }}
          >
            Capital financiado
          </Typography>
          <Typography
            style={{
              fontSize: pxToRem(40),
              fontWeight: "bold",
              lineHeight: 1.35,
              color: "#424242",
              textAlign: "center"
            }}
          >
            <NumberFormat
              value={importeFinanciado}
              displayType={"text"}
              decimalScale={0}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$ "}
            />
          </Typography>

          <Typography
            style={{
              fontSize: pxToRem(18),
              fontWeight: "bold",
              lineHeight: 1.33,
              textAlign: "center",
              color: "#424242",
              marginBottom: 18
            }}
          >
            En {plazo} cuotas
          </Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ColorLabel
              text={situacionStyle.label}
              variant={situacionStyle.variant}
              marginBottom={14}
            />
          </div>
          <Typography
            style={{
              fontSize: pxToRem(16),
              lineHeight: 1.38,
              textAlign: "center",
              color: "#757575",
              marginBottom: 8
            }}
          >
            Otorgado el
            {`: ${moment(moment.utc(fechaOtorgamiento)).format("L")}`}
          </Typography>
          <Typography
            style={{
              fontSize: pxToRem(16),
              lineHeight: 1.38,
              textAlign: "center",
              color: "#757575",
              marginBottom: 20
            }}
          >
            ID: {operacionId}
          </Typography>
          {estadoCredito === ESTADO_CREDITO_ALDIA && (
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            ></div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

const TitularInfo = ({ nombre, apellido, dni, conyuge, codeudor }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div style={{ display: "flex" }}>
          <img src={PersonIcon} alt="person-icon" />
          <Typography className={classes.sectionHeader}>
            DATOS PERSONALES
          </Typography>
        </div>
        <RowSection>
          <div className={classes.colLeft}>
            <Typography className={classes.rowHeader}>Titular</Typography>
          </div>
          <div className={classes.colRight}>
            <Typography
              className={classes.rowContent}
              style={{ textTransform: "capitalize" }}
            >
              {`${nombre} ${apellido}`.toLowerCase()}
            </Typography>
          </div>
        </RowSection>
        <RowSection>
          <div className={classes.colLeft}>
            <Typography className={classes.rowHeader}>Dni</Typography>
          </div>
          <div className={classes.colRight}>
            <Typography className={classes.rowContent}>
              <NumberFormat
                id="documento"
                displayType={"text"}
                value={dni}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={0}
              />
            </Typography>
          </div>
        </RowSection>
        {conyuge && (
          <RowSection>
            <div className={classes.colLeft}>
              <Typography className={classes.rowHeader}>Cónyuge</Typography>
            </div>
            <div className={classes.colRight}>
              <Typography
                className={classes.rowContent}
                style={{ textTransform: "capitalize" }}
              >
                {conyuge.toLowerCase()}
              </Typography>
            </div>
          </RowSection>
        )}
        {codeudor && (
          <RowSection>
            <div className={classes.colLeft}>
              <Typography className={classes.rowHeader}>Codeudor</Typography>
            </div>
            <div className={classes.colRight}>
              <Typography
                className={classes.rowContent}
                style={{ textTransform: "capitalize" }}
              >
                {codeudor.toLowerCase()}
              </Typography>
            </div>
          </RowSection>
        )}
      </CardContent>
    </Card>
  );
};

const ContactoInfo = ({ telefonoPrincipal, telefonoSecundario, domicilio }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div style={{ display: "flex" }}>
          <img src={ContactIcon} alt="contact-icon" />
          <Typography className={classes.sectionHeader}>CONTACTO</Typography>
        </div>
        {telefonoPrincipal && (
          <RowSection>
            <div className={classes.colLeft}>
              <Typography className={classes.rowHeader}>
                Telefono principal
              </Typography>
            </div>
            <div className={classes.colRight}>
              <Typography className={classes.rowContent}>
                {getPhoneText(telefonoPrincipal)}
              </Typography>
            </div>
          </RowSection>
        )}

        {telefonoSecundario && (
          <RowSection>
            <div className={classes.colLeft}>
              <Typography className={classes.rowHeader}>
                Telefono secundario
              </Typography>
            </div>
            <div className={classes.colRight}>
              <Typography className={classes.rowContent}>
                {getPhoneText(telefonoSecundario)}
              </Typography>
            </div>
          </RowSection>
        )}

        <RowSection>
          <div className={classes.colLeft}>
            <Typography className={classes.rowHeader}>Domicilio</Typography>
          </div>
          <div className={classes.colRight}>
            <Typography
              className={classes.rowContent}
              style={{ textTransform: "capitalize" }}
            >
              {domicilio.direccion.toLowerCase()}
            </Typography>
          </div>
        </RowSection>
        <RowSection>
          <div className={classes.colLeft}>
            <Typography className={classes.rowHeader}>Localidad</Typography>
          </div>
          <div className={classes.colRight}>
            <Typography
              className={classes.rowContent}
              style={{ textTransform: "capitalize" }}
            >
              {`${domicilio.localidad}, ${domicilio.provincia}`.toLowerCase()}
            </Typography>
          </div>
        </RowSection>
        <RowSection>
          <div className={classes.colLeft}>
            <Typography className={classes.rowHeader}>C.P.</Typography>
          </div>
          <div className={classes.colRight}>
            <Typography
              className={classes.rowContent}
              style={{ textTransform: "capitalize" }}
            >
              {domicilio.codigoPostal}
            </Typography>
          </div>
        </RowSection>
      </CardContent>
    </Card>
  );
};

const VehiculoInfo = ({
  marca,
  modelo,
  anio,
  dominio,
  esNuevo,
  uso,
  tieneGnc
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div style={{ display: "flex" }}>
          <img src={AutoIcon} alt="auto-icon" />
          <Typography className={classes.sectionHeader}>
            DATOS DEL AUTO
          </Typography>
        </div>
        <RowSection>
          <div className={classes.colLeft}>
            <Typography className={classes.rowHeader}>Vehículo</Typography>
          </div>
          <div className={classes.colRight}>
            <Typography className={classes.rowContent}>
              {`${marca} ${modelo}`}
            </Typography>
          </div>
        </RowSection>
        <RowSection>
          <div className={classes.colLeft}>
            <Typography className={classes.rowHeader}>Año</Typography>
          </div>
          <div className={classes.colRight}>
            <Typography className={classes.rowContent}>{anio}</Typography>
          </div>
        </RowSection>
        <RowSection>
          <div className={classes.colLeft}>
            <Typography className={classes.rowHeader}>Dominio</Typography>
          </div>
          <div className={classes.colRight}>
            <Typography
              className={classes.rowContent}
              style={{ textTransform: "capitalize" }}
            >
              {dominio}
            </Typography>
          </div>
        </RowSection>
        <RowSection>
          <div className={classes.colLeft}>
            <Typography className={classes.rowHeader}>Condición</Typography>
          </div>
          <div className={classes.colRight}>
            <Typography className={classes.rowContent}>
              {esNuevo ? "Nuevo" : "Usado"}
            </Typography>
          </div>
        </RowSection>
        <RowSection>
          <div className={classes.colLeft}>
            <Typography className={classes.rowHeader}>Tipo de uso</Typography>
          </div>
          <div className={classes.colRight}>
            <Typography className={classes.rowContent}>{uso}</Typography>
          </div>
        </RowSection>
        <RowSection>
          <div className={classes.colLeft}>
            <Typography className={classes.rowHeader}>GNC</Typography>
          </div>
          <div className={classes.colRight}>
            <Typography className={classes.rowContent}>
              {tieneGnc ? "Con GNC" : "Sin GNC"}
            </Typography>
          </div>
        </RowSection>
      </CardContent>
    </Card>
  );
};

const PolizaInfo = ({ idPoliza, aseguradora, tipoCobertura }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div style={{ display: "flex" }}>
            <img src={AutoIcon} alt="auto-icon" />
            <Typography className={classes.sectionHeader}>SEGURO</Typography>
          </div>
          <LinkButton
            variant="purple"
            onClick={() => {
              history.push(`/mispolizas/${idPoliza}`);
            }}
          >
            ver póliza
          </LinkButton>
        </div>
        <RowSection>
          <div className={classes.colLeft}>
            <Typography className={classes.rowHeader}>Compañía</Typography>
          </div>
          <div className={classes.colRight}>
            <Typography className={classes.rowContent}>
              {aseguradora}
            </Typography>
          </div>
        </RowSection>
        <RowSection>
          <div className={classes.colLeft}>
            <Typography className={classes.rowHeader}>Cobertura</Typography>
          </div>
          <div className={classes.colRight}>
            <Typography className={classes.rowContent}>
              {tipoCobertura}
            </Typography>
          </div>
        </RowSection>
      </CardContent>
    </Card>
  );
};

const RowSection = ({ children }) => {
  return (
    <>
      <Divider
        style={{
          color: "#bdbdbd",
          marginTop: pxToRem(16),
          marginBottom: pxToRem(16)
        }}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {children}
      </div>
    </>
  );
};

export const LoadingTemplate = ({ length }) => {
  const classes = useStyles();
  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <ContentLoader
            height={300}
            width={350}
            speed={1}
            primaryColor="#f7f8ff"
            secondaryColor="#B9A7D7"
            style={{ height: 300, width: "100%" }}
          >
            <rect x="35%" y="12" rx="3" ry="3" width="30%" height="14" />
            <rect x="22%" y="35" rx="3" ry="3" width="56%" height="50" />
            <rect x="36%" y="95" rx="3" ry="3" width="27%" height="20" />
            <rect x="37%" y="120" rx="3" ry="3" width="25%" height="20" />
            <rect x="22%" y="157" rx="3" ry="3" width="56%" height="16" />
            <rect x="0%" y="195" rx="3" ry="3" width="100%" height="52" />
            <rect x="20%" y="282" rx="3" ry="3" width="60%" height="18" />
          </ContentLoader>
        </CardContent>
      </Card>
      {Array.apply(null, Array(length)).map((_, index) => (
        <Card key={`loading-${index}`} className={classes.card}>
          <CardContent className={classes.cardContent}>
            <ContentLoader
              height={176}
              width={300}
              speed={1}
              primaryColor="#f7f8ff"
              secondaryColor="#B9A7D7"
              style={{ height: 176, width: "100%" }}
            >
              <rect x="0" y="0" rx="3" ry="3" width="150" height="32" />
              <rect x="0" y="48" rx="3" ry="3" width="300" height="32" />
              <rect x="0" y="96" rx="3" ry="3" width="300" height="32" />
              <rect x="0" y="144" rx="3" ry="3" width="300" height="32" />
            </ContentLoader>
          </CardContent>
        </Card>
      ))}
    </>
  );
};
const useStyles = makeStyles(theme => ({
  card: {
    width: "100% !important",
    marginBottom: 28,
    boxShadow: "2px 2px 8px 0 #00000012",
    [theme.breakpoints.mobile]: { boxShadow: "none !important" }
  },
  cardContent: { padding: 20 },
  colLeft: { display: "flex", textAlign: "left" },
  colRight: { display: "flex", textAlign: "right" },
  sectionHeader: {
    fontSize: pxToRem(14),
    fontWeight: "bold",
    lineHeight: 1.36,
    color: "#9e9e9e",
    textAlign: "left",
    paddingLeft: 11
  },
  rowHeader: {
    fontSize: pxToRem(16),
    paddingLeft: 10,
    fontWeight: 600,
    lineHeight: 1.38,
    color: "#9e9e9e"
  },
  rowContent: {
    fontSize: pxToRem(16),
    fontWeight: 600,
    lineHeight: 1.38,
    color: "#424242"
  }
}));
