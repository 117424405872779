import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentSection from "~components/styled/contentSection/ContentSection";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import UsoVehiculoSelector from "~components/bussines/usoVehiculoSelector/UsoVehiculoSelector";

import { setVehiculoUso, clearVehiculoUso } from "../actions";

const VehiculoUsoStep = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearVehiculoUso());
  }, [dispatch]);

  return (
    <ContentSection>
      <PrimaryTitle label={"¿Qué uso le dará al vehículo?"} marginBottom={60} />
      <UsoVehiculoSelector
        label={"Elegí una opción"}
        onSelected={(event) => dispatch(setVehiculoUso(event.target.value))}
      />
    </ContentSection>
  );
};

export default VehiculoUsoStep;
