import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import {
  setImporte,
  clearImporte,
  setPlazo,
  clearPlazo,
  informarVehiculo
} from "../actions";
import CuotasSelector from "~components/bussines/cuotasSelector/CuotasSelector";
import MontoSelector from "~components/bussines/montoSelector/MontoSelector";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

const useReduxSelector = () =>
  useSelector(state => {
    const nuevaSolicitudReducer = state.creditosReducers.nuevaSolicitudReducer;
    return {
      solicitudId: nuevaSolicitudReducer.solicitudId,
      modelo: nuevaSolicitudReducer.vehiculoModelo,
      anio: nuevaSolicitudReducer.vehiculoAnio
    };
  });

const ImporteYPlazoStep = () => {
  const dispatch = useDispatch();
  const { solicitudId, modelo, anio } = useReduxSelector();
  const [importeSelected, setImporteSelected] = useState(5000);
  const [plazoSelected, setPlazoSelected] = useState(18);

  useEffect(() => {
    dispatch(clearImporte());
    dispatch(clearPlazo());
  }, []);
  useEffect(() => {
    if (solicitudId && modelo && anio) dispatch(informarVehiculo());
  }, [solicitudId, modelo, anio, dispatch]);
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={importeSelected === null || plazoSelected === null}
            label={"Continuar"}
            onClick={() => {
              dispatch(setImporte(importeSelected));
              dispatch(setPlazo(plazoSelected));
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle label={"Elegí importe y plazo"} marginBottom={60} />
      <MontoSelector
        defaultValue={importeSelected}
        marginBottom={30}
        onChange={event => setImporteSelected(event.target.value)}
      />
      <CuotasSelector
        defaultValue={plazoSelected}
        marginBottom={30}
        onChange={event => setPlazoSelected(event.target.value)}
      />
    </ContentSectionWithActions>
  );
};

export default ImporteYPlazoStep;
