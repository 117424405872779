export const LISTASOLICITUDES_ISLOADING = "LISTASOLICITUDES_ISLOADING";
export const LISTASOLICITUDES_CLEAR_LISTITEMS =
  "LISTASOLICITUDES_CLEAR_LISTITEMS";
export const LISTASOLICITUDES_FETCHSUCCESS = "LISTASOLICITUDES_FETCHSUCCESS";
export const LISTASOLICITUDES_SELECT = "LISTASOLICITUDES_SELECT";

export const selectSolicitud = id => ({
  type: LISTASOLICITUDES_SELECT,
  payload: {
    solicitudId: id
  }
});

export const fetchSolicitudesSuccess = (
  page,
  filterText,
  items,
  lastSolicitud
) => ({
  type: LISTASOLICITUDES_FETCHSUCCESS,
  payload: {
    page,
    filterText,
    solicitudes: items,
    lastSolicitud
  }
});

export const listaSolicitudesIsLoading = loading => ({
  type: LISTASOLICITUDES_ISLOADING,
  payload: {
    isLoading: loading
  }
});

export const listaSolicitudesClearListItems = () => ({
  type: LISTASOLICITUDES_CLEAR_LISTITEMS
});

export function fetchSolicitudes(page, take, filterText, soloPendientes) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(listaSolicitudesIsLoading(true));
      const skip = page * take;
      if (skip === 0)
        // if first page, clear items
        dispatch(listaSolicitudesClearListItems());
      const response = await services.api.getSolicitudes(
        skip,
        take,
        filterText,
        soloPendientes
      );
      if (
        typeof response === "undefined" ||
        response === null ||
        response.length === 0
      ) {
        console.error("error getSolicitudes, response is undefined");
      } else {
        dispatch(
          fetchSolicitudesSuccess(
            page,
            filterText,
            response,
            services.localStorage.lastSolicitud
          )
        );
        dispatch(listaSolicitudesIsLoading(false));
      }
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        fetchSolicitudes,
        [page, take, filterText, soloPendientes],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(listaSolicitudesIsLoading(false));
    }
  };
}
