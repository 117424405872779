import React, { useState } from "react";
import pxToRem from "~libs/pxToRem";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardHeader from "@material-ui/core/CardHeader";

import { makeStyles, useTheme } from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles(theme => ({
  focusWhite: {
    background: "#fff",
    "&:focus": {
      background: "#fff",
      outline: "none"
    }
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  headerAction: {
    margin: 0,
    marginRight: -16
  },
  headerRoot: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

const CardButton = ({
  onClick,
  title,
  addCollapse,
  color,
  marginBottom,
  children
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <Card
      className={classes.focusWhite}
      style={{
        width: "100%",
        borderRadius: 10,
        boxShadow: "2px 2px 8px 0 #00000012",
        borderLeft: `10px solid ${color}`,
        marginBottom
      }}
    >
      {title && (
        <CardHeader
          classes={{ action: classes.headerAction, root: classes.headerRoot }}
          onClick={() => addCollapse && setExpanded(value => !value)}
          action={
            addCollapse && (
              <IconButton
                className={
                  expanded
                    ? classes.expand
                    : [classes.expand, classes.expandOpen].join(" ")
                }
              >
                <ExpandMoreIcon />
              </IconButton>
            )
          }
          title={title}
        />
      )}
      <Collapse in={addCollapse ? expanded : true} timeout="auto" unmountOnExit>
        <CardActionArea
          disableRipple={!onClick}
          disableTouchRipple={!onClick}
          //disabled={!onClick}
          classes={{
            focusVisible: classes.focusWhite,
            focusHighlight: classes.focusWhite
          }}
          style={{
            minHeight: pxToRem(30),
            cursor: onClick ? "pointer" : "default"
          }}
          onClick={onClick}
        >
          {children}
        </CardActionArea>
      </Collapse>
    </Card>
  );
};

export default CardButton;
