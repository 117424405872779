import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  button: props => ({
    background: "#fff",
    borderRadius: 14,
    border: 0,
    color: "#000",
    height: 154,
    display: "flex",
    marginBottom: props.marginBottom,
    boxShadow: "0 6px 6px 0 #a28ac933",
    padding: 21,
    textTransform: "none",
    backgroundImage: `url(${props.image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    "&:disabled": {
      backgroundColor: "#616161",
      color: "#C4C4C4"
    },
    "&:focus": {
      backgroundColor: "#fff"
    },
    "&:hover": {
      boxShadow: "0 6px 6px 0 #a28ac933",
      backgroundColor: "#fff"
    }
  }),
  buttonLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end"
  },
  buttonIcon: {
    width: 31,
    height: 31,
    marginBottom: 35
  }
}));

const SingleButton = ({
  onClick,
  icon,
  image,
  label,
  subLabel,
  marginBottom,
  disabled
}) => {
  const classes = useStyles({
    marginBottom,
    image
  });
  const theme = useTheme();
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="contained"
      size="large"
      color="primary"
      fullWidth
      className={classes.button}
      classes={{
        label: classes.buttonLabel
      }}
    >
      <img src={icon} alt="" className={classes.buttonIcon} />
      <Typography
        variant="caption"
        noWrap
        style={{
          fontWeight: "bold",
          lineHeight: "1.5",
          fontSize: theme.typography.pxToRem(20),
          letterSpacing: 0.2,
          textAlign: "left"
        }}
      >
        {label}
      </Typography>
      <Typography
        variant="caption"
        noWrap
        style={{
          fontWeight: 600,
          lineHeight: "1.5",
          fontSize: theme.typography.pxToRem(12),
          letterSpacing: 0.2,
          color: "#424242",
          textAlign: "left"
        }}
      >
        {subLabel}
      </Typography>
    </Button>
  );
};

export default SingleButton;
