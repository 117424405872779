import React, { useState, useEffect } from "react";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";
import pxToRem from "~libs/pxToRem";
import ContentLoader from "react-content-loader";

const DEFAULT_MIN = 5000;
const DEFAULT_MAX = 900000;

const RangoMontoSelector = ({
  disabled,
  isLoading,
  minValue = DEFAULT_MIN,
  maxValue = DEFAULT_MAX,
  filtroMin,
  filtroMax,
  step = DEFAULT_MIN,
  onChange,
  onFilter,
  marginBottom,
}) => {
  const [min, setMin] = useState(filtroMin);
  const [max, setMax] = useState(filtroMax);

  useEffect(() => {
    setMin(filtroMin);
    setMax(filtroMax);
  }, [filtroMin, filtroMax]);

  useEffect(() => {
    if (!filtroMin) setMin(minValue);
  }, [minValue]);
  useEffect(() => {
    if (!filtroMax) setMax(maxValue);
  }, [maxValue]);

  let marks = getMarks({
    isLoading,
    disabled,
    minValue,
    maxValue,
    min,
    max,
    precission: 0.15,
  });

  const handleChange = (min, max) => {
    console.log("envio por change");

    if (!disabled && !isLoading && min !== max) {
      setMin(min);
      setMax(max);
      if (onFilter) onFilter();
    }
  };

  return (
    <div style={{ textAlign: "center", marginBottom }}>
      <div style={{ marginLeft: 24, marginRight: 24 }}>
        <StyledSlider
          key={"slider"}
          onChange={(event, value) => handleChange(value[0], value[1])}
          onChangeCommitted={(event, value) => {
            console.log("envio", value, min, max);
            if (min !== max) onChange(value[0], value[1]);
          }}
          marks={marks}
          valueLabelDisplay="off"
          step={step}
          value={min && max ? [min, max] : [0, 100]}
          min={minValue ? minValue : 0}
          max={maxValue ? maxValue : 100}
        />
      </div>
      {isLoading && <LoadingRangoMonto />}
    </div>
  );
};

export default RangoMontoSelector;

const LoadingRangoMonto = () => {
  return (
    <div style={{ width: "100%" }}>
      <ContentLoader
        height={16}
        width={150}
        speed={1}
        style={{ width: "100%", height: 16 }}
        primaryColor="#f7f8ff"
        secondaryColor="#bdbdbd"
      >
        <rect x="0" y="3" rx="2" ry="2" width="16" height="12" />
        <rect x="88%" y="3" rx="2" ry="2" width="16" height="12" />
      </ContentLoader>
    </div>
  );
};

const StyledSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  mark: {
    display: "none",
  },
  markLabel: {
    top: 30,
    fontSize: pxToRem(16),
    fontWeight: 800,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#bdbdbd",
    [theme.breakpoints.mobile]: {
      top: 40,
    },
  },
  markLabelActive: {
    top: 30,
    fontSize: pxToRem(16),
    fontWeight: 800,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#663ea8",
    [theme.breakpoints.mobile]: {
      top: 40,
    },
  },
}))(Slider);

const createMark = (label, value) => ({ label, value });
const getMarks = ({
  isLoading,
  disabled,
  minValue,
  maxValue,
  min,
  max,
  precission,
}) => {
  if (isLoading || !min || !max) return [];

  if (disabled) return [createMark("$-", 0), createMark("$-", 100)];

  const percentageMin = (min - minValue) / (maxValue - minValue);
  const percentageMax = (max - minValue) / (maxValue - minValue);
  const showMin = percentageMin > precission;
  const showMax = percentageMax < 1 - precission;

  const marksMin = [createMark(`$${minValue}`, minValue)];
  const marksMiddle =
    (max - min) / (maxValue - minValue) <= precission
      ? [createMark(`$${min}-$${max}`, min), createMark("", max + 1)]
      : [createMark(`$${min}`, min), createMark(`$${max}`, max)];
  const marksMax = [createMark(`$${maxValue}`, maxValue)];

  let result = showMin ? marksMin.concat(marksMiddle) : marksMiddle;

  result = showMax ? result.concat(marksMax) : result;
  return result;
};
