import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import StyledSelect from "~components/styled/select/StyledSelect";

const PhoneTipoSelector = ({ key, value, onChange }) => {
  const labelId = `moneda-label-${key ? key : "0"}`;

  return (
    <FormControl>
      <InputLabel id={labelId}>TIPO</InputLabel>
      <StyledSelect
        labelId={labelId}
        value={value}
        style={{ minWidth: 81 }}
        onChange={onChange}
      >
        <MenuItem value={true}>MÓVIL</MenuItem>
        <MenuItem value={false}>FIJO</MenuItem>
      </StyledSelect>
    </FormControl>
  );
};

export default PhoneTipoSelector;
