import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListView from "~components/styled/listView/ListView";
import getFranjaHoraria from "./getFranjaHoraria";

const useStyles = makeStyles(theme => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-around"
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2
  }
}));

const franjaHorariaList = getFranjaHoraria();

const FranjaHorariaSelector = ({ onSelected }) => {
  const handleItemSelected = item => {
    if (onSelected) onSelected({ target: { value: item.value } });
  };
  const classes = useStyles();
  return (
    <div className={classes.searcher}>
      <ListView
        roundedTop
        onItemSelected={handleItemSelected}
        list={franjaHorariaList}
      />
    </div>
  );
};

export default FranjaHorariaSelector;
