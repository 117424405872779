const environment = {
  name: process.env.REACT_APP_ENV,

  authUrl: process.env.REACT_APP_API_AUTH_URL,
  originacionUrl: process.env.REACT_APP_API_ORIGINACION_URL,
  catalogosUrl: process.env.REACT_APP_API_CATALOGOS_URL,
  creditRiskUrl: process.env.REACT_APP_API_CREDITRISK_URL,
  segurosUrl: process.env.REACT_APP_API_SEGUROS_URL,
  presignerUrl: process.env.REACT_APP_API_PRESIGNER_URL,

  isWIP: JSON.parse(process.env.REACT_APP_WIP),

  aplitudeAPIkey: process.env.REACT_APP_AMPLITUDE_API_KEY,

  launchDarkly: {
    sdkKey: process.env.REACT_APP_LAUNCH_DARKLY_SDKKEY
  },

  terminosYCondiciones: {
    appId: process.env.REACT_APP_NAME,
    version: process.env.REACT_APP_TERMINOS_Y_CONDICIONES_VERSION,
    fecha: process.env.REACT_APP_TERMINOS_Y_CONDICIONES_FECHA
  },

  awsLogs: {
    apiKey: process.env.REACT_APP_AWS_LOGS_API_KEY,
    apiSecret: process.env.REACT_APP_AWS_LOGS_API_SECRET
  },

  aws: {
    cognitoUserPoolWebClientId:
      process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID
  },

  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENTID
  }
};

export default environment;
