import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ProcessLayout from "~components/styled/layouts/processLayout/ProcessLayout";
import ContentSection from "~components/styled/contentSection/ContentSection";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";

import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

import {
  CheckboxButton,
  CheckboxLargeLabel
} from "~components/styled/checkboxButton/CheckboxButton";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import autoIcon from "~assets/ilustrations/illus-comprar-auto.svg";
import otrosIcon from "~assets/ilustrations/illus-otros-proyectos.svg";

const PRENDARIO_TRADICIONAL = 1;
const AUTOPRENDA = 2;

const NuevaSolicitud = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selected, setSelected] = useState(null);

  return (
    <ProcessLayout
      title={"Nueva solicitud"}
      progress={0} // porcentage (0-100)
      onBackClick={() => {
        history.goBack();
      }}
      onCloseClick={() => {
        history.push("/");
      }}
    >
      <ContentSectionWithActions
        actions={
          <ContentSection>
            <CallToActionButton
              disabled={selected === null}
              label={"Continuar"}
              onClick={() => {
                const url =
                  selected === PRENDARIO_TRADICIONAL
                    ? "/creditos"
                    : "/autoprenda";

                history.push(url);
              }}
            />
          </ContentSection>
        }
      >
        <PrimaryTitle label={"¿Para qué necesita el crédito tu cliente?"} />
        <CheckboxButton
          disabled={false}
          checked={selected === PRENDARIO_TRADICIONAL}
          marginBottom={15}
          onClick={() => setSelected(PRENDARIO_TRADICIONAL)}
        >
          <CheckboxLargeLabel
            label={"Comprar un auto"}
            subLabel={"de la agencia"}
            image={autoIcon}
            topLabel={"Credito prendario"}
          />
        </CheckboxButton>
        <CheckboxButton
          disabled={false}
          checked={selected === AUTOPRENDA}
          onClick={() => setSelected(AUTOPRENDA)}
        >
          <CheckboxLargeLabel
            label={"Otros proyectos"}
            subLabel={"crédito con auto como garantía"}
            image={otrosIcon}
            topLabel={"Autoprenda"}
          />
        </CheckboxButton>
      </ContentSectionWithActions>
    </ProcessLayout>
  );
};

export default NuevaSolicitud;
