import React from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import pxToRem from "~libs/pxToRem";
import { Link } from "react-router-dom";
import ListSubheader from "@material-ui/core/ListSubheader";

import { showNativePopup } from "~componentes/nativeAppMessaging/js/actions";
import IdentityIcon from "@material-ui/icons/PhonelinkLock";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ActiveIcon from "@material-ui/icons/ChevronRight";
import CopyTextIcon from "@material-ui/icons/FileCopyOutlined";

import { useSelector, useDispatch } from "react-redux";

import copyTextToClipboard from "~libs/copyTextToClipboard";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: pxToRem(12),
    backgroundColor: theme.palette.background.paper
  },
  bold: {
    fontWeight: 600
  }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    isOnNativeApp: state.nativeMessagesReducer.isOnNativeApp,
    isBetaModeEnabled: state.featureFlagReducer.featureFlags["beta-mode"]
  }));

const BetaInfoList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isOnNativeApp, isBetaModeEnabled } = useReduxSelector();
  if (!isBetaModeEnabled) return null;
  else
    return (
      <>
        <ListSubheader>BETA</ListSubheader>
        <List className={classes.root} component="nav" style={{ padding: 0 }}>
          <ListItem
            style={{ borderRadius: `0 0 12px 12px` }}
            button
            disabled={!isOnNativeApp}
            onClick={() => dispatch(showNativePopup("validaridentidad"))}
          >
            <ListItemIcon style={{ minWidth: 0, paddingRight: 16 }}>
              <IdentityIcon
                style={{
                  color: theme.palette.primary.main
                }}
              />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.bold }}
              primary="Validar Identidad"
            />
            <ListItemSecondaryAction>
              <ActiveIcon
                style={
                  isOnNativeApp
                    ? { color: theme.palette.primary.main, marginTop: 3 }
                    : { color: "#cdcdcd", marginTop: 3 }
                }
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem
            style={{ borderRadius: `0 0 12px 12px` }}
            button
            onClick={() => copyTextToClipboard("esto es una prueba")}
          >
            <ListItemIcon style={{ minWidth: 0, paddingRight: 16 }}>
              <CopyTextIcon
                style={{
                  color: theme.palette.primary.main
                }}
              />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.bold }}
              primary="Copiar texto"
            />
            <ListItemSecondaryAction>
              <ActiveIcon
                style={{ color: theme.palette.primary.main, marginTop: 3 }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          {/* <Divider />
           */}
        </List>
      </>
    );
};

export default BetaInfoList;

const ListItemLink = props => {
  return <ListItem button component={Link} {...props} />;
};

const ListItemA = props => {
  return <ListItem button component="a" {...props} />;
};
