import React from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import pxToRem from "~libs/pxToRem";
import { Link } from "react-router-dom";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListSubheader from "@material-ui/core/ListSubheader";

import Switch from "@material-ui/core/Switch";
import MobileIcon from "@material-ui/icons/MobileFriendly";
import ScanIcon from "@material-ui/icons/Fullscreen";

import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: pxToRem(12),
    backgroundColor: theme.palette.background.paper
  },
  bold: {
    fontWeight: 600
  }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    isOnNativeApp: state.nativeMessagesReducer.isOnNativeApp,
    isDevModeEnabled: state.featureFlagReducer.featureFlags["dev-mode"],
    isScanCedulaEnabled: state.featureFlagReducer.featureFlags["scan-cedula"]
  }));

const DevInfoList = () => {
  const classes = useStyles();
  const {
    isOnNativeApp,
    isDevModeEnabled,
    isScanCedulaEnabled
  } = useReduxSelector();
  const theme = useTheme();

  if (!isDevModeEnabled) return null;
  else
    return (
      <>
        <ListSubheader>DEV INFO</ListSubheader>
        <List className={classes.root} component="nav" style={{ padding: 0 }}>
          <ListItem>
            <ListItemIcon style={{ minWidth: 0, paddingRight: 16 }}>
              <MobileIcon
                style={{
                  color: theme.palette.primary.main
                }}
              />
            </ListItemIcon>
            <ListItemText
              id="isOnNativeApp"
              classes={{ primary: classes.bold }}
              primary="Is On Native App"
            />
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                disableRipple={true}
                disableFocusRipple={true}
                disableTouchRipple={true}
                checked={isOnNativeApp}
                inputProps={{ "aria-labelledby": "isOnNativeApp" }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <ListItem style={{ borderRadius: `0 0 12px 12px` }}>
            <ListItemIcon style={{ minWidth: 0, paddingRight: 16 }}>
              <ScanIcon
                style={{
                  color: theme.palette.primary.main
                }}
              />
            </ListItemIcon>
            <ListItemText
              id="isScanCedulaEnabled"
              classes={{ primary: classes.bold }}
              primary="Is Scan Cedula Enabled"
            />
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                disableRipple={true}
                disableFocusRipple={true}
                disableTouchRipple={true}
                checked={isScanCedulaEnabled}
                inputProps={{ "aria-labelledby": "isScanCedulaEnabled" }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </>
    );
};

export default DevInfoList;

const ListItemLink = props => {
  return <ListItem button component={Link} {...props} />;
};

const ListItemA = props => {
  return <ListItem button component="a" {...props} />;
};
