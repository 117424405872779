import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCuiTitular, clearCuiTitular } from "../actions";
import { useHistory } from "react-router-dom";
import pxToRem from "~libs/pxToRem";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/styles/makeStyles";
import CuiInput from "~components/styled/input/CuiInput";

const useReduxSelector = () =>
  useSelector(state => ({
    isValidacionIdentidadEnabled:
      state.featureFlagReducer.featureFlags["validacion-identidad"]
  }));

const ENTER_KEY = 13;

const CuiStep = () => {
  const dispatch = useDispatch();
  const [cui, setCui] = useState("");
  const history = useHistory();
  const classes = useStyles();
  const { isValidacionIdentidadEnabled } = useReduxSelector();
  useEffect(() => {
    dispatch(clearCuiTitular());
  }, [dispatch]);
  const onKeyDown = event => {
    if (event.keyCode === ENTER_KEY && cui) dispatch(setCuiTitular(cui));
  };
  const canContinue = cui.length === 11;
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={!canContinue}
            label={"Continuar"}
            onClick={() => {
              dispatch(setCuiTitular(cui));
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle
        label={"Ingresá el CUIT/CUIL del titular de la agencia"}
        marginBottom={60}
      />
      <TextField
        style={{ marginBottom: 9 }}
        fullWidth={true}
        onFocus={event => event.target.select()}
        autoFocus
        value={cui}
        type="tel"
        onKeyDown={onKeyDown}
        onChange={event => {
          const value = event.target.value.replace(/_|-|\s/g, "");
          setCui(value);
        }}
        placeholder="Ej: 20-14556025-0"
        InputProps={{
          inputComponent: CuiInput
        }}
      ></TextField>
      {isValidacionIdentidadEnabled && (
        <div style={{ display: "flex", justifyConent: "space-around" }}>
          <Typography className={classes.secondaryText}>
            ¿Ya tenés tu agencia registrada?
            <Link
              className={classes.secondaryText}
              style={{ marginLeft: 5 }}
              component="button"
              underline="always"
              onClick={() => history.push("/actualizando/inicio")}
            >
              Ingresá acá
            </Link>
          </Typography>
        </div>
      )}
    </ContentSectionWithActions>
  );
};

export default CuiStep;

const useStyles = makeStyles(theme => ({
  secondaryText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(16),
    fontWeight: "bold",
    lineHeight: "1.36",
    textAlign: "left",
    color: "#7644c9"
  }
}));
