import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setNombreContacto, clearNombreContacto } from "../actions";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

const ENTER_KEY = 13;

const NombreContactoStep = () => {
  const dispatch = useDispatch();
  const [nombre, setNombre] = useState("");

  useEffect(() => {
    dispatch(clearNombreContacto());
  }, [dispatch]);
  const onKeyDown = event => {
    if (event.keyCode === ENTER_KEY && nombre)
      dispatch(setNombreContacto(nombre));
  };
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={!nombre}
            label={"Continuar"}
            onClick={() => {
              dispatch(setNombreContacto(nombre));
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle label={"Ingresá tu nombre"} marginBottom={60} />
      <TextField
        style={{ marginBottom: 9 }}
        fullWidth={true}
        onFocus={event => event.target.select()}
        value={nombre}
        onKeyDown={onKeyDown}
        autoFocus
        onChange={event => setNombre(event.target.value)}
        placeholder="Ej: Carlos Perez"
      ></TextField>
    </ContentSectionWithActions>
  );
};

export default NombreContactoStep;
