import React from "react";
import ContentLoader from "react-content-loader";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    background: "#ffffff",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
    height: 53,
    borderRight: "1px solid #d5d5d5",
    borderTop: "1px solid #d5d5d5"
  },
  buttonLoading: {
    display: "flex",
    background: "#ffffff",
    alignItems: "baseline",
    justifyContent: "center",
    width: "100%",
    paddingRight: 0,
    paddingLeft: 0,
    height: "100%",
    borderRadius: 0
  },
  buttonPrefix: {
    color: "#4aba7c",
    fontSize: "1.2rem",
    padding: "10px 0px 0px 10px",
    fontWeight: "bold"
  },
  innerTriangle: {
    borderRight: "15px solid #d5d5d5",
    borderBottom: "15px solid transparent",
    position: "absolute"
  },
  loading: { width: 60, height: 40 }
}));

const LoadingButton = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.buttonLoading}>
        <Typography className={classes.buttonPrefix}>{"$"}</Typography>
        <div className={classes.loading}>
          <ContentLoader
            height={40}
            width={60}
            speed={1}
            //primaryColor="#f7f8ff"
            //secondaryColor="d9e8df"
            //backgroundcolor="#f7f8ff"
            //foregroundcolor="#d9e8df"
          >
            <rect x="4" y="26" rx="4" ry="4" width="49" height="16" />
          </ContentLoader>
        </div>
      </div>
      <div className={classes.innerTriangle} />
    </div>
  );
};

export default LoadingButton;
