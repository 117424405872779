const getOpcionesDePago = () => {
  let result = [
    {
      label: "Débito con tarjeta de crédito",
      value: "DebitoConTarjetaCredito"
    },
    {
      label: "Débito con CBU",
      value: "DebitoConCBU"
    },
    {
      label: "Cupón de pago",
      value: "CuponPago"
    }
  ];

  return result;
};

export default getOpcionesDePago;
