import React from "react";
import MainMenu from "~componentes/menu/MainMenu";
import { makeStyles } from "~libs/styles";
import TopBarContainer from "~componentes/topBar/TopBarContainer";

const useOldLayoutStyles = makeStyles(theme => ({
  containerFluid: {
    marginRight: "auto",
    marginLeft: "auto",
    paddingLeft: 0,
    paddingRight: 0,
    "&:before": {
      content: '" "',
      display: "table"
    },
    "&:after": {
      content: '" "',
      display: "table"
    }
  },
  row: {
    marginLeft: 0,
    marginRight: 0,
    "&:before": {
      content: '" "',
      display: "table"
    },
    "&:after": {
      clear: "both"
    }
  },
  mainContent: {
    position: "relative",
    minHeight: 1,
    paddingLeft: 0,
    paddingRight: 0,
    float: "left",
    width: "100%",
    "@media (min-width: 994px)": {
      marginLeft: "16.6%",
      width: "calc(100% - 16.6%)"
    }
  },
  sideBar: {
    background: "#fff !important",
    borderRight: "solid #eee",
    position: "fixed",
    minHeight: 1,
    height: "100%",
    zIndex: 98,
    paddingLeft: 0,
    paddingRight: 0,
    width: "16.66666667%",
    "@media (max-width: 994px)": {
      display: "none !important"
    }
  }
}));

const OldLayout = ({ children }) => {
  const classes = useOldLayoutStyles();
  return (
    <div className={classes.containerFluid}>
      <div
        className={classes.row}
        style={{
          height: 64,
          display: "block"
        }}
      >
        <TopBarContainer />
      </div>
      <div className={classes.row}>
        <div className={classes.sideBar}>
          <MainMenu />
        </div>

        <div className={classes.mainContent}>{children}</div>
      </div>
    </div>
  );
};
export default OldLayout;
