import winston from "winston";
import CloudWatchTransport from "winston-aws-cloudwatch";
import environment from "../environment";
import uuidv4 from "uuid/v4";

export function createLogRemoteError() {
  try {
    if (environment.name === "localhost")
      return (...params) => console.error("remote error", params);

    const logStreamName = `${environment.name}-${uuidv4()}`;

    const loggerInstance = winston.createLogger({
      transports: [
        new CloudWatchTransport({
          logGroupName: "creditos-react",
          logStreamName: logStreamName,
          createLogGroup: false,
          createLogStream: true,
          submissionInterval: 2000,
          submissionRetryCount: 1,
          batchSize: 20,
          awsConfig: {
            accessKeyId: environment.awsLogs.apiKey,
            secretAccessKey: environment.awsLogs.apiSecret,
            region: "us-east-1"
          },
          formatLog: item =>
            `${item.level}: ${item.message} ${JSON.stringify(item.meta)}`
        })
      ]
    });

    return (error, func, params, currentState) => {
      try {
        const exception =
          error instanceof Error ? error : JSON.stringify(error);

        loggerInstance.error(exception, {
          function: func.name,
          params: getParamsMetadata(func, params),
          state: currentState
        });
      } catch (error) {
        console.error("remote log not working!!!!!!!!!!!!!!!", error);
      }
    };
  } catch (error) {
    console.error(error);
    return null;
  }
}

const STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/gm;
const ARGUMENT_NAMES = /([^\s,]+)/g;
function getParamNames(func) {
  var fnStr = func.toString().replace(STRIP_COMMENTS, "");
  var result = fnStr
    .slice(fnStr.indexOf("(") + 1, fnStr.indexOf(")"))
    .match(ARGUMENT_NAMES);
  if (result === null) result = [];
  return result;
}

function getParamsMetadata(func, values) {
  const names = getParamNames(func);
  let meta = {};
  names.forEach((name, index) => {
    meta[name] = values[index];
  });

  return meta;
}
