import React from "react";
import { NavbarLayoutClienteFinal } from "~components/styled/layouts/navbarLayout/NavbarLayout";
import ContentSection from "~components/styled/contentSection/ContentSection";

import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import UsuarioIcon from "@material-ui/icons/AccountCircle";
import ActiveIcon from "@material-ui/icons/ChevronRight";
import VersionIcon from "@material-ui/icons/ChangeHistory";
import WhatsappIcon from "@material-ui/icons/WhatsApp";
import CerrarSesionIcon from "@material-ui/icons/ExitToApp";
import pxToRem from "~libs/pxToRem";
import { Link } from "react-router-dom";
import { useAuth0 } from "~componentes/Auth0/useAuth";
import localRepository from "~src/localRepository";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";

import { sendMessageToNative } from "~componentes/nativeAppMessaging/js/actions";
import openFile from "~libs/openFile";
import { useSelector } from "react-redux";
import TopbarWithTitle from "~components/styled/layouts/navbarLayout/TopbarWithTitle";
import DevInfoList from "~components/bussines/devInfo/DevInfoList";
import BetaInfoList from "~components/bussines/devInfo/BetaInfoList";

const socials = {
  facebook: "Facebook",
  "google-oauth2": "Gmail",
  email: "Email",
  twitter: "Twitter",
  windowslive: "Hotmail",
  yahoo: "Yahoo"
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: pxToRem(12),
    backgroundColor: theme.palette.background.paper
  },
  bold: {
    fontWeight: 600
  }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    isOnNativeApp: state.nativeMessagesReducer.isOnNativeApp
  }));

const Info = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { isOnNativeApp } = useReduxSelector();

  const { authState } = useAuth0();

  return (
    <NavbarLayoutClienteFinal
      topbar={() => <TopbarWithTitle title={"Información"} />}
    >
      <ContentSection>
        <List className={classes.root} component="nav" style={{ padding: 0 }}>
          <LightTooltip title={authState.user.email} placement={"top"}>
            <ListItem>
              <ListItemIcon style={{ minWidth: 0, paddingRight: 16 }}>
                {!authState.user.picture && (
                  <UsuarioIcon
                    style={{
                      color: theme.palette.primary.main,
                      height: 48,
                      width: 48
                    }}
                  />
                )}
                {authState.user.picture && (
                  <Avatar
                    alt="perfil"
                    src={authState.user.picture}
                    style={{
                      height: 48,
                      width: 48
                    }}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.bold }}
                primary={authState.user.preferred_username}
                secondaryTypographyProps={{
                  style: {
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                  }
                }}
                secondary={
                  socials[authState.user.socialNetworkId]
                    ? `${socials[authState.user.socialNetworkId]} | ${
                        authState.user.email
                      }`
                    : authState.user.email
                }
              />
            </ListItem>
          </LightTooltip>
          <Divider />
          <ListItem
            button
            onClick={() => {
              const url = "https://api.whatsapp.com/send?phone=5493413967833";
              if (isOnNativeApp) {
                sendMessageToNative({ type: "WEB_OPEN_URL", payload: { url } });
              } else {
                openFile(url);
              }
            }}
          >
            <ListItemIcon style={{ minWidth: 0, paddingRight: 16 }}>
              <WhatsappIcon style={{ color: theme.palette.primary.main }} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.bold }}
              primary="Tengo un problema"
            />
            <ListItemSecondaryAction>
              <ActiveIcon
                style={{ color: theme.palette.primary.main, marginTop: 3 }}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <Divider />
          <ListItemLink
            to={"/signout"}
            style={{ borderRadius: `0 0 12px 12px` }}
          >
            <ListItemIcon style={{ minWidth: 0, paddingRight: 16 }}>
              <CerrarSesionIcon style={{ color: theme.palette.primary.main }} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.bold }}
              primary="Cerrar sesión"
            />
            <ListItemSecondaryAction>
              <ActiveIcon
                style={{ color: theme.palette.primary.main, marginTop: 3 }}
              />
            </ListItemSecondaryAction>
          </ListItemLink>
          <Divider />
          <ListItem disabled>
            <ListItemIcon style={{ minWidth: 0, paddingRight: 16 }}>
              <VersionIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.bold }}
              primary={`Version ${localRepository.version.get()}`}
            />
          </ListItem>
        </List>
        <DevInfoList />
        <BetaInfoList />
      </ContentSection>
    </NavbarLayoutClienteFinal>
  );
};

export default Info;

const ListItemLink = props => {
  return <ListItem button component={Link} {...props} />;
};

const ListItemA = props => {
  return <ListItem button component="a" {...props} />;
};

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: "bold"
  }
}))(Tooltip);
