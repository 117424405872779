import React from "react";
import pxToRem from "~libs/pxToRem";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

const BigCard = ({ title, content, footer }) => {
  return (
    <Card
      style={{
        width: "100%",
        padding: pxToRem(44),
        borderRadius: 16,
        boxShadow: "0 0 20px 0 rgba(102,62,168,0.21)",
        minHeight: pxToRem(516),
        display: "flex",
        flexDirection: "column"
      }}
    >
      <CardHeader
        title={title}
        style={{ padding: 0 }}
        titleTypographyProps={{
          color: "primary",
          style: {
            textAlign: "center",
            fontWeight: 700,
            fontSize: pxToRem(21)
          }
        }}
      />
      <CardContent
        style={{
          padding: 0,
          paddingTop: pxToRem(32),
          paddingBottom: pxToRem(32),
          display: "flex",
          flexDirection: "column",
          flexGrow: 1
        }}
      >
        {content}
      </CardContent>
      {footer && (
        <CardActions
          style={{
            padding: 0,
            display: "flex",
            justifyContent: "center"
          }}
        >
          {footer}
        </CardActions>
      )}
    </Card>
  );
};

export default BigCard;
