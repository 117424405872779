const getEntidadesDePago = () => [
  {
    value: "2",
    label: "Banco Nación"
  },
  {
    value: "3",
    label: "Banco ICBC"
  },
  {
    value: "5",
    label: "Banco Francés"
  },
  {
    value: "9",
    label: "Banco Santander"
  }
];

export default getEntidadesDePago;
