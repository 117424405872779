import React from "react";
import { Route } from "react-router-dom";
import Info from "./Info";
const InfoRoutes = () => {
  return [
    <Route key="info-1" exact path="/info">
      <Info />
    </Route>
  ];
};

export default InfoRoutes;
