const getSituacionesLaborales = () => [
  {
    value: "Empleado_Publico",
    label: "Emp. Público c/ Rec. de Sueldo"
  },
  {
    value: "Empleado_Privado",
    label: "Emp. Privado c/ Rec. de Sueldo"
  },
  {
    value: "Independiente",
    label: "Independiente"
  },
  {
    value: "Jubilado",
    label: "Jubilado"
  },
  {
    value: "No_Definida",
    label: "Desconocida"
  }
];

export default getSituacionesLaborales;
