import React, { useEffect } from "react";
import { useAuth0 } from "../Auth0/useAuth";
import { Redirect } from "react-router-dom";

const Signout = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout();
  }, [logout]);

  return <Redirect to="/loading" />;
};

export default Signout;
