import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setNombre, clearNombre } from "../actions";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

const ENTER_KEY = 13;

const ContactoNombreStep = () => {
  const dispatch = useDispatch();
  const [nombre, setCampoNombre] = useState("");

  useEffect(() => {
    dispatch(clearNombre());
  }, [dispatch]);

  const canContinue = nombre.length > 0 && nombre.includes(" ");

  const handleNext = value => {
    dispatch(setNombre(value));
  };

  const onKeyDown = event => {
    if (event.keyCode === ENTER_KEY && canContinue) handleNext(nombre);
  };

  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={!canContinue}
            label={"Continuar"}
            onClick={() => {
              handleNext(nombre);
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle
        label={"Ingresá el nombre completo del asegurado"}
        marginBottom={60}
      />
      <TextField
        fullWidth={true}
        onKeyDown={onKeyDown}
        autoFocus
        onFocus={event => event.target.select()}
        value={nombre}
        onChange={event => setCampoNombre(event.target.value)}
        placeholder="Juan Pérez"
      ></TextField>
    </ContentSectionWithActions>
  );
};

export default ContactoNombreStep;
