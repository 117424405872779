import { combineReducers } from "redux";
import listadoCreditosVigentesReducer from "./homeCreditos/actions";
import listadoCuotasCreditoReducer from "./detalleCredito/listadoCuotasCredito/actions";
import detalleCreditoReducer from "./detalleCredito/actions";
import informarPagoReducer from "./informarPago/actions";
const combinedReducers = combineReducers({
  listadoCreditosVigentesReducer,
  listadoCuotasCreditoReducer,
  detalleCreditoReducer,
  informarPagoReducer
});
const creditosReducers = {
  key: "creditosReducers",
  value: combinedReducers
};

export default creditosReducers;
