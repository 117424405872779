let createGetCanales = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => () => {
  let request = buildRequestWithAuth("GET");

  return fetch(
    `${environment.catalogosUrl}/concesionarios/solicitudes?format=json`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createPostReloadCanales = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError
}) => () => {
  let request = buildRequestWithAuth("POST", "{}");

  return fetch(
    `${environment.originacionUrl}/nivelesorganizacion/reload`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response;
    })
    .catch(error => {
      let resultError = parseError(error, "createPostReloadCanales");
      console.error(resultError);
      throw resultError;
    });
};

export { createGetCanales, createPostReloadCanales };
