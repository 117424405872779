import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDominio, clearDominio } from "../actions";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/styles/makeStyles";
import pxToRem from "~libs/pxToRem";
import Fab from "@material-ui/core/Fab";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { showNativePopup } from "~componentes/nativeAppMessaging/js/actions";

let platform = require("platform");

const ENTER_KEY = 13;

const useStyles = makeStyles((theme) => ({
  secondaryText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(16),
    fontWeight: "bold",
    lineHeight: "1.36",
    textAlign: "left",
    color: "#7644c9",
  },
  fieldAndCameraContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 32,
  },
  fieldContainer: {
    width: "90%",
  },
  camera: {
    float: "right",
    height: "42px !important",
    width: "42px !important",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    dominioTarjetaVerde: state.nativeMessagesReducer.dominio,
    isOnNativeApp: state.nativeMessagesReducer.isOnNativeApp,
    isScanCedulaEnabled: state.featureFlagReducer.featureFlags["scan-cedula"],
  }));

const DominioStep = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dominio, setCampoDominio] = useState("");

  const {
    isOnNativeApp,
    isScanCedulaEnabled,
    dominioTarjetaVerde,
  } = useReduxSelector();

  const canContinue = dominio.length > 0 && validateDominio(dominio);

  const showCamera =
    isOnNativeApp &&
    !dominioTarjetaVerde &&
    (platform.os.family === "iOS" || isScanCedulaEnabled);

  useEffect(() => {
    dispatch(clearDominio());
  }, [dispatch]);

  useEffect(() => {
    if (dominioTarjetaVerde) {
      setCampoDominio(dominioTarjetaVerde);
    }
  }, [dominioTarjetaVerde]);

  const handleNext = (value) => {
    dispatch(setDominio({ value, selected: true }));
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue) handleNext(dominio);
  };

  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={!canContinue}
            label={"Continuar"}
            onClick={() => {
              handleNext(dominio);
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle
        label={
          !dominioTarjetaVerde
            ? "Ingresá el dominio del vehículo "
            : "Verificá el dominio del vehículo "
        }
        marginBottom={showCamera ? 4 : 60}
      />
      {showCamera && (
        <Link
          className={classes.secondaryText}
          style={{ marginLeft: 5, marginTop: 5 }}
          component="button"
          onClick={() => {
            handleNext("");
          }}
        >
          ó si tenes la cédula verde, sacale una foto...
        </Link>
      )}

      <div className={showCamera ? classes.fieldAndCameraContainer : null}>
        <div className={showCamera ? classes.fieldContainer : null}>
          <TextField
            fullWidth={true}
            onKeyDown={onKeyDown}
            autoFocus
            onFocus={(event) => event.target.select()}
            type="email"
            value={dominio}
            onChange={(event) => {
              if (event.target.value.length <= 7)
                setCampoDominio(event.target.value.toUpperCase());
            }}
            placeholder="Ej: AA000AA"
          ></TextField>
        </div>
        {showCamera && (
          <Fab
            className={classes.camera}
            onClick={() => {
              dispatch(showNativePopup("leercedula"));
            }}
          >
            <PhotoCamera />
          </Fab>
        )}
      </div>
      <div style={{ display: "flex", justifyConent: "space-around" }}>
        <Typography className={classes.secondaryText}>
          <Link
            className={classes.secondaryText}
            style={{ marginLeft: 5, marginTop: 5 }}
            component="button"
            underline="always"
            onClick={() => {
              handleNext("");
            }}
          >
            Omitir este paso
          </Link>
        </Typography>
      </div>
    </ContentSectionWithActions>
  );
};

export default DominioStep;

const validateDominio = (value) => {
  const expression = RegExp(
    "[a-zA-Z]{2,2}[0-9]{3,3}[a-zA-Z]{2,2}|[a-zA-Z]{3,3}[0-9]{3,3}"
  );
  const result = expression.test(value);
  return result && value.length <= 7;
};
