import { createAction, handleActions } from "redux-actions";

const setAgenciaSelected = createAction(
  "NUEVA_AGENCIA_SELECTED",
  agenciaDatos => ({
    agenciaDatos
  })
);

const setAgenciaUbicacionSelected = createAction(
  "NUEVA_AGENCIA_UBICACION_SELECTED",
  ubicacion => ({
    ubicacion
  })
);

const setAgenciaContactoSelected = createAction(
  "NUEVA_AGENCIA_CONTACTO_SELECTED",
  contacto => ({
    contacto
  })
);

const setAgenciaTipoSelected = createAction(
  "NUEVA_AGENCIA_TIPO_SELECTED",
  tipo => ({
    tipo
  })
);

const initialState = {
  isLoading: false,
  agenciaSelected: null,
  ubicacionSelected: null,
  contactoSelected: null,
  tipoSelected: null
};

const registrarAgenciaReducer = handleActions(
  {
    [setAgenciaSelected]: (state, action) => {
      return {
        ...state,
        agenciaSelected: action.payload.agenciaDatos
      };
    },
    [setAgenciaUbicacionSelected]: (state, action) => {
      return {
        ...state,
        ubicacionSelected: action.payload.ubicacion
      };
    },
    [setAgenciaContactoSelected]: (state, action) => {
      return {
        ...state,
        contactoSelected: action.payload.contacto
      };
    },
    [setAgenciaTipoSelected]: (state, action) => {
      return {
        ...state,
        tipoSelected: action.payload.tipo
      };
    }
  },
  initialState
);

export default registrarAgenciaReducer;
export {
  setAgenciaSelected,
  setAgenciaUbicacionSelected,
  setAgenciaContactoSelected
};
