import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearFormaDePago, setFormaDePago } from "../actions";
import makeStyles from "@material-ui/styles/makeStyles";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import FormaDePagoSelector from "~components/bussines/formaDePagoSelector/FormaDePagoSelector";

const useReduxSelector = () =>
  useSelector((state) => {
    const cotizadorReducer = state.segurosReducers.cotizadorReducer;

    return {
      formaDePagoSelected: cotizadorReducer.formaDePagoSelected,
    };
  });

const FormaDePagoStep = memo(() => {
  const dispatch = useDispatch();
  const { formaDePagoSelected } = useReduxSelector();

  useEffect(() => {
    dispatch(clearFormaDePago());
  }, [dispatch]);

  const handleChange = (event) => {
    dispatch(setFormaDePago(event.target.value));
  };

  return (
    <ContentSectionWithActions>
      <PrimaryTitle
        label="¿Cómo va a pagar las cuotas el asegurado?"
        marginBottom={60}
      />
      <FormaDePagoSelector
        value={formaDePagoSelected}
        onChange={handleChange}
      />
    </ContentSectionWithActions>
  );
});

export default FormaDePagoStep;
