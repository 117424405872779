import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCodigoPostalAgencia, clearCodigoPostalAgencia } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";

import CodigoPostalSelector from "~components/bussines/codigoPostalSelector/CodigoPostalSelector";

const useReduxSelector = () =>
  useSelector(state => {
    const signupAgenciaReducer =
      state.authenticationReducers.signupAgenciaReducer;

    return {
      provinciaId: signupAgenciaReducer.provinciaAgencia,
      localidad: signupAgenciaReducer.localidadAgencia
    };
  });

const CodigoPostalStep = () => {
  const dispatch = useDispatch();
  const { provinciaId, localidad } = useReduxSelector();
  useEffect(() => {
    dispatch(clearCodigoPostalAgencia());
  }, [dispatch]);

  const handleSelection = event => {
    dispatch(setCodigoPostalAgencia(event.target.value));
  };

  return (
    <ContentSectionWithActions>
      <PrimaryTitle
        label={"¿Cuál es el código postal de la agencia?"}
        marginBottom={60}
      />
      <CodigoPostalSelector
        provinciaId={provinciaId}
        localidad={localidad}
        onSelected={handleSelection}
      />
    </ContentSectionWithActions>
  );
};

export default CodigoPostalStep;
