import React from "react";
import { Route } from "react-router-dom";
import OldLayout from "~componentes/app/OldLayout";
import { AsyncDevInfo, AsyncInfo } from "~componentes/app/LazyComponents";

const InfoRoutes = () => [
  <Route key="info-1" exact path="/info">
    <OldLayout>
      <AsyncInfo />
    </OldLayout>
  </Route>,
  <Route key="info-2" exact path="/devinfo">
    <OldLayout>
      <AsyncDevInfo />
    </OldLayout>
  </Route>
];

export default InfoRoutes;
