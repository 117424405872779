import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLocalidadAgencia, clearLocalidadAgencia } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import LocalidadSelector from "~components/bussines/localidadSelector/LocalidadSelector";

const useReduxSelector = () =>
  useSelector(state => {
    const signupAgenciaReducer =
      state.authenticationReducers.signupAgenciaReducer;
    return {
      provinciaId: signupAgenciaReducer.provinciaAgencia
    };
  });

const LocalidadStep = () => {
  const dispatch = useDispatch();
  const { provinciaId } = useReduxSelector();

  useEffect(() => {
    dispatch(clearLocalidadAgencia());
  }, [dispatch]);

  const handleSelection = event => {
    dispatch(setLocalidadAgencia(event.target.value));
  };

  return (
    <ContentSectionWithActions>
      <PrimaryTitle
        label={"¿En qué localidad está ubicada la agencia?"}
        marginBottom={60}
      />
      <LocalidadSelector
        label={"Ingresá o busca la localidad"}
        onSelected={handleSelection}
        provinciaId={provinciaId}
      />
    </ContentSectionWithActions>
  );
};

export default LocalidadStep;
