import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentSection from "~components/styled/contentSection/ContentSection";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import SituacionLaboralSelector from "~components/bussines/situacionLaboralSelector/SituacionLaboralSelector";

import { setSituacionLaboral, clearSituacionLaboral } from "../actions";

const SituacionLaboralStep = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearSituacionLaboral());
  }, [dispatch]);

  return (
    <ContentSection>
      <PrimaryTitle label={"¿Cuál es la situación laboral de tu cliente?"} />
      <SituacionLaboralSelector
        onSelected={event => dispatch(setSituacionLaboral(event.target.value))}
      />
    </ContentSection>
  );
};

export default SituacionLaboralStep;
