import React from "react";
import { useSelector } from "react-redux";

const useReduxSelector = flag =>
  useSelector(state => ({
    value: state.featureFlagReducer.featureFlags[flag]
  }));

const FeatureFlagRender = ({ flag, when = true, children }) => {
  const { value } = useReduxSelector(flag);

  if (when !== value) return null;

  return <>{children}</>;
};

export default FeatureFlagRender;
