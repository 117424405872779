import { createAction, handleActions } from "redux-actions";
import { clearOfertas } from "../ofertasForm/js/actions";
import { clearCotizacionesSeguros } from "../seguroForm/js/actions";

export const autoFormInit = createAction("AUTOFORM_INIT");
export const autoFormLoad = createAction("AUTOFORM_LOAD", data => ({ data }));
export const autoFormIsLoading = createAction(
  "AUTOFORM_ISLOADING",
  isLoading => ({ isLoading })
);
export const selectMarca = createAction("AUTOFORM_SELECTMARCA", marca => ({
  marca
}));
export const selectAnio = createAction("AUTOFORM_SELECTANIO", anioSelected => ({
  anioSelected
}));
export const selectEsCeroKM = createAction(
  "AUTOFORM_SELECTESCEROKM",
  esCeroKMSelected => ({ esCeroKMSelected })
);
export const selectModelo = createAction(
  "AUTOFORM_SELECTMODELO",
  modeloSelected => ({ modeloSelected })
);
export const selectTipoUso = createAction(
  "AUTOFORM_SELECTTIPOUSO",
  tipoUsoSelected => ({ tipoUsoSelected })
);
export const selectGnc = createAction("AUTOFORM_SELECTGNC", gncSelected => ({
  gncSelected
}));
export const fetchCodiaPrecioSuccess = createAction(
  "FETCHCODIAPRECIOSSUCCESS",
  infoCodia => ({ infoCodia })
);

export const saveAutoFormData = createAction(
  "SAVEAUTOFORMDATA",
  carData => ({ ValorDeCompra: carData.ValorDeCompra }),
  carData => ({
    analytics: {
      eventName: "solicitud-auto-datos-guardar",
      eventData: {
        id: carData.Id,
        codia: carData.Codia,
        anio: carData.Anio,
        es0Km: carData.Es0KM,
        tieneGNC: carData.TieneGNC,
        uso: carData.Uso,
        valorDeCompra: carData.ValorDeCompra,
        tipoCredito: carData.tipoCredito
      }
    }
  })
);

export const setAutoformCommited = createAction(
  "SET_AUTOFORM_COMMITED",
  commited => ({
    commited
  })
);

export const fetchCodiaPrecio = codia => {
  return async (dispatch, getState, services) => {
    try {
      const response = await services.api.getInfoautoCodiaPrecio(codia);
      if (
        typeof response === "undefined" ||
        response === null ||
        response.length === 0
      ) {
        console.error("error fetching modelos");
      } else {
        dispatch(fetchCodiaPrecioSuccess(response));
      }
    } catch (error) {
      console.error("error", error);
    }
  };
};

export const submitForm = (formData, onComplete) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(autoFormIsLoading(true));
      await services.api.postSolicitudAuto(formData);
      dispatch(clearOfertas());
      dispatch(clearCotizacionesSeguros());
      dispatch(autoFormIsLoading(false));
      if (onComplete) onComplete();
      const tipoCredito = getState().solicitudCreditoUI.tipoCredito;
      dispatch(saveAutoFormData({ ...formData, tipoCredito }));
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        submitForm,
        [formData, onComplete],
        getState()
      );
      dispatch(autoFormIsLoading(false));
      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};
const initialState = {
  isLoading: false,
  selectedMarca: null,
  selectedAnio: null,
  selectedEsCeroKM: false,
  selectedModelo: null,
  selectedTipoUso: null,
  selectedGnc: null,
  ceroKm: false,
  infoCodia: null,
  valorAuto: 0,
  disabled: false,
  stepCommited: false
};

const autoFormReducer = handleActions(
  {
    [autoFormInit]: (state, action) => {
      return { ...initialState };
    },
    [autoFormLoad]: (state, action) => {
      return {
        ...state,
        ...action.payload.data,
        isLoading: false
      };
    },
    [autoFormIsLoading]: (state, action) => {
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    },
    [selectMarca]: (state, action) => {
      return {
        ...state,
        selectedMarca: action.payload.marca,
        selectedAnio: null,
        selectedModelo: null
      };
    },
    [selectAnio]: (state, action) => {
      let selectedEsCeroKM = null;
      if (
        action.payload.anioSelected &&
        action.payload.anioSelected.value <= new Date().getFullYear() - 2
      )
        selectedEsCeroKM = {
          label: "NO",
          value: false
        };
      return {
        ...state,
        selectedAnio: action.payload.anioSelected,
        selectedEsCeroKM: selectedEsCeroKM,
        selectedModelo: null
      };
    },
    [selectEsCeroKM]: (state, action) => {
      return {
        ...state,
        selectedEsCeroKM: action.payload.esCeroKMSelected
      };
    },
    [selectModelo]: (state, action) => {
      return {
        ...state,
        selectedModelo: action.payload.modeloSelected
      };
    },
    [selectTipoUso]: (state, action) => {
      return {
        ...state,
        selectedTipoUso: action.payload.tipoUsoSelected
      };
    },
    [selectGnc]: (state, action) => {
      return {
        ...state,
        selectedGnc: action.payload.gncSelected
      };
    },
    [fetchCodiaPrecioSuccess]: (state, action) => {
      return {
        ...state,
        infoCodia: action.payload.infoCodia
      };
    },
    [saveAutoFormData]: (state, action) => {
      return {
        ...state,
        valorAuto: action.payload.ValorDeCompra
      };
    },
    [setAutoformCommited]: (state, action) => {
      return {
        ...state,
        stepCommited: action.payload.commited
      };
    }
  },
  { ...initialState }
);
export default autoFormReducer;
