const getMediosDePago = () => [
  {
    value: "3",
    label: "Transferencia"
  },
  {
    value: "2",
    label: "Depósito"
  },
  {
    value: "0",
    label: "Otros"
  }
];

export default getMediosDePago;
