import { combineReducers } from "redux";
import listadoSolicitudesReducer from "./listadoSolicitudes/actions";

const combinedReducers = combineReducers({ listadoSolicitudesReducer });
const creditosReducers = {
  key: "creditosReducers",
  value: combinedReducers
};

export default creditosReducers;
