import React, { useEffect } from "react";

import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getDetalleCredito } from "~components/app/clienteFinal/creditos/detalleCredito/actions";
import CreditoSaldado from "./CreditoSaldado";
import CreditoSaldoPendiente from "./CreditoSaldoPendiente";
import CreditoCuotasPendientes from "./CreditoCuotasPendientes";

const useReduxSelector = () =>
  useSelector(state => ({
    credito: state.creditosReducers.detalleCreditoReducer.credito,
    isLoading: state.creditosReducers.detalleCreditoReducer.isLoading
  }));

const ResumenCreditoTab = () => {
  let { operacionId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDetalleCredito(operacionId));
  }, []);

  const { credito, isLoading } = useReduxSelector();

  const estadoCredito = credito && credito.credito.estadoCredito;

  const isSaldado = !isLoading && estadoCredito === "Saldado";

  const isSaldoPendiente = !isLoading && estadoCredito === "SaldoPendiente";

  const isCuotasPendientes =
    !isLoading &&
    (estadoCredito !== "Saldado" && estadoCredito !== "SaldoPendiente");

  return (
    <>
      {isSaldado && <CreditoSaldado />}
      {isSaldoPendiente && <CreditoSaldoPendiente />}
      {(isLoading || isCuotasPendientes) && <CreditoCuotasPendientes />}
    </>
  );
};

export default ResumenCreditoTab;
