import { handleActions, createAction } from "redux-actions";

const autosCotizarVehiculoMarcaSelect = createAction(
  "AUTOS_COTIZAR_VEHICULO_MARCA_SELECT",
  vehiculoMarcaSelected => ({
    vehiculoMarcaSelected: vehiculoMarcaSelected
      ? { ...vehiculoMarcaSelected }
      : null
  }),
  vehiculoMarcaSelected => ({
    analytics: {
      eventName: "AUTOS_COTIZAR_VEHICULO_MARCA_SELECT",
      eventData: {
        vehiculoMarcaSelected: vehiculoMarcaSelected
          ? { ...vehiculoMarcaSelected }
          : null
      }
    }
  })
);

const autosCotizarVehiculoAnioSelect = createAction(
  "AUTOS_COTIZAR_VEHICULO_ANIO_SELECT",
  vehiculoAnioSelected => ({
    vehiculoAnioSelected: vehiculoAnioSelected
      ? { ...vehiculoAnioSelected }
      : null
  }),
  vehiculoAnioSelected => ({
    analytics: {
      eventName: "AUTOS_COTIZAR_VEHICULO_ANIO_SELECT",
      eventData: {
        vehiculoAnioSelected: vehiculoAnioSelected
          ? { ...vehiculoAnioSelected }
          : null
      }
    }
  })
);

const autosCotizarVehiculoModeloSelect = createAction(
  "AUTOS_COTIZAR_VEHICULO_MODELO_SELECT",
  vehiculoModeloSelected => ({
    vehiculoModeloSelected: vehiculoModeloSelected
      ? { ...vehiculoModeloSelected }
      : null
  }),
  vehiculoModeloSelected => ({
    analytics: {
      eventName: "AUTOS_COTIZAR_VEHICULO_MODELO_SELECT",
      eventData: {
        vehiculoModeloSelected: vehiculoModeloSelected
          ? { ...vehiculoModeloSelected }
          : null
      }
    }
  })
);

const clearCotizadorAutos = createAction(
  "AUTOS_COTIZAR_CLEAR",
  () => {},
  () => ({
    analytics: {
      eventName: "AUTOS_COTIZAR_CLEAR"
    }
  })
);

const [
  fetchAutosPreciosInited,
  fetchAutosPreciosCompleted,
  fetchAutosPreciosFailed
] = [
  createAction("AUTOS_COTIZAR_PRECIOS_INITED"),
  createAction(
    "AUTOS_COTIZAR_PRECIOS_COMPLETED",
    response => ({
      preciosPorAnio:
        typeof response !== "undefined" &&
        response !== null &&
        response.length !== 0
          ? { ...response }
          : {}
    }),
    response => ({
      analytics: {
        eventName: "AUTOS_COTIZAR_PRECIOS_COMPLETED",
        eventData: {
          preciosPorAnio:
            typeof response !== "undefined" &&
            response !== null &&
            response.length !== 0
              ? { ...response }
              : {}
        }
      }
    })
  ),
  createAction("AUTOS_COTIZAR_PRECIOS_FAILED", error => error)
];

const fetchAutosPrecios = modelo => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchAutosPreciosInited());

      const result = await services.api.getInfoautoCodiaPrecioAbierto(modelo);

      dispatch(fetchAutosPreciosCompleted(result));
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        fetchAutosPrecios,
        [modelo],
        getState()
      );

      dispatch(fetchAutosPreciosFailed(error));

      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};

const initialState = {
  vehiculoMarcaSelected: null,
  vehiculoAnioSelected: null,
  vehiculoModeloSelected: null,
  isLoading: false,
  preciosPorAnio: []
};

const cotizadorAutoReducer = handleActions(
  {
    [autosCotizarVehiculoMarcaSelect]: (state, { payload }) => {
      return {
        ...state,
        vehiculoModeloSelected: null,
        preciosPorAnio: [],
        vehiculoMarcaSelected: payload.vehiculoMarcaSelected
      };
    },
    [autosCotizarVehiculoAnioSelect]: (state, { payload }) => {
      return {
        ...state,
        vehiculoModeloSelected: null,
        preciosPorAnio: [],
        vehiculoAnioSelected: payload.vehiculoAnioSelected
      };
    },
    [autosCotizarVehiculoModeloSelect]: (state, { payload }) => {
      return {
        ...state,
        preciosPorAnio: [],
        vehiculoModeloSelected: payload.vehiculoModeloSelected
      };
    },
    [clearCotizadorAutos]: (state, action) => {
      return {
        ...initialState
      };
    },
    [fetchAutosPreciosInited]: (state, action) => {
      return {
        ...state,
        preciosPorAnio: [],
        isLoading: true
      };
    },
    [fetchAutosPreciosCompleted]: (state, { payload }) => {
      return {
        ...state,
        preciosPorAnio: payload.preciosPorAnio,
        isLoading: false
      };
    },
    [fetchAutosPreciosFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    }
  },
  initialState
);

export default cotizadorAutoReducer;
export {
  autosCotizarVehiculoMarcaSelect,
  autosCotizarVehiculoAnioSelect,
  autosCotizarVehiculoModeloSelect,
  clearCotizadorAutos,
  fetchAutosPrecios
};
