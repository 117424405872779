import { createAction, handleActions } from "redux-actions";

const datosAutoClear = createAction("COTIZAR_SEGUROS_DATOS_AUTO_CLEAR");

const selectCameraReader = createAction(
  "DATOS_AUTO_CAMERA_READER_SELECT",
  value => ({
    value
  })
);

const selectDominio = createAction("DATOS_AUTO_DOMINIO_SELECT", value => ({
  value
}));

const selectChasis = createAction("DATOS_AUTO_CHASIS_SELECT", value => ({
  value
}));

const selectMotor = createAction("DATOS_AUTO_MOTOR_SELECT", value => ({
  value
}));

const selectDominioClicked = createAction(
  "DATOS_AUTO_DOMINIO_CLICKED_SELECT",
  value => ({
    value
  })
);
const selectChasisClicked = createAction(
  "DATOS_AUTO_CHASIS_CLICKED_SELECT",
  value => ({
    value
  })
);
const selectMotorClicked = createAction(
  "DATOS_AUTO_MOTOR_CLICKED_SELECT",
  value => ({
    value
  })
);

const initialState = {
  dominio: null,
  chasis: null,
  motor: null,
  dominioClicked: false,
  chasisClicked: false,
  motorClicked: false,
  cameraReader: false
};

const datosAutoReducer = handleActions(
  {
    [selectCameraReader]: (state, action) => {
      return {
        ...state,
        cameraReader: action.payload.value
      };
    },
    [datosAutoClear]: (state, action) => {
      return {
        ...initialState
      };
    },
    [selectDominio]: (state, action) => {
      return {
        ...state,
        dominio: action.payload.value
      };
    },
    [selectChasis]: (state, action) => {
      return {
        ...state,
        chasis: action.payload.value
      };
    },
    [selectMotor]: (state, action) => {
      return {
        ...state,
        motor: action.payload.value
      };
    },
    [selectDominioClicked]: (state, action) => {
      return {
        ...state,
        dominioClicked: action.payload.value
      };
    },
    [selectChasisClicked]: (state, action) => {
      return {
        ...state,
        chasisClicked: action.payload.value
      };
    },
    [selectMotorClicked]: (state, action) => {
      return {
        ...state,
        motorClicked: action.payload.value
      };
    }
  },
  initialState
);

export default datosAutoReducer;
export {
  selectCameraReader,
  datosAutoClear,
  selectDominio,
  selectChasis,
  selectMotor,
  selectDominioClicked,
  selectMotorClicked,
  selectChasisClicked
};
