import React, { useState, useEffect } from "react";
import Slider from "@material-ui/core/Slider";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import pxToRem from "~libs/pxToRem";
import TextField from "@material-ui/core/TextField";
import CurrencyInput from "~components/styled/input/CurrencyInput";

const useStyles = makeStyles(theme => ({
  centeredText: {
    fontSize: pxToRem(24),
    textAlign: "center",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    letterSpacing: pxToRem(1)
  }
}));

const DEFAULT_MIN = 5000;
const DEFAULT_MAX = 900000;

const MontoSelector = ({
  minValue = DEFAULT_MIN,
  maxValue = DEFAULT_MAX,
  step = DEFAULT_MIN,
  defaultValue = DEFAULT_MIN,
  onChange,
  marginBottom
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);
  const [min, setMin] = useState(minValue);
  const [max, setMax] = useState(maxValue);

  useEffect(() => {
    setMin(minValue);
  }, [minValue]);
  useEffect(() => {
    setMax(maxValue);
  }, [maxValue]);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleFieldChange = event => {
    let newValue = parseInt(event.target.value, 0);

    if (value !== newValue) {
      setValue(newValue);
    }
  };

  const handleFieldBlur = () => {
    if (value < min) setValue(min);

    if (value > max) setValue(max);

    if (onChange) onChange({ target: { value } });
  };

  return (
    <div style={{ textAlign: "center", marginBottom }}>
      <TextField
        fullWidth={true}
        value={value}
        onChange={handleFieldChange}
        onFocus={event => event.target.select()}
        onBlur={handleFieldBlur}
        InputProps={{
          classes: { input: classes.centeredText },
          inputComponent: CurrencyInput,
          disableUnderline: true
        }}
      />
      <div style={{ marginLeft: 12, marginRight: 12 }}>
        <StyledSlider
          onChangeCommitted={(event, value) =>
            onChange ? onChange({ target: { value } }) : null
          }
          onChange={(event, value) => setValue(value)}
          valueLabelDisplay="off"
          step={step}
          value={value}
          min={min}
          max={max}
        />
      </div>
    </div>
  );
};

export default MontoSelector;

const StyledSlider = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  },
  mark: {
    display: "none"
  },
  markActive: {
    display: "none"
  },
  markLabel: {
    top: 30,
    [theme.breakpoints.mobile]: {
      top: 40
    }
  }
}))(Slider);
