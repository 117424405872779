import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CBUStep from "./CBUStep";
import TarjetaCreditoStep from "./TarjetaCreditoStep";
import { setValidadorDatosPago, clearValidadorDatosPago } from "../actions";

const useReduxSelector = () =>
  useSelector((state) => {
    const cotizadorReducer = state.segurosReducers.cotizadorReducer;

    return {
      formaDePagoSelected: cotizadorReducer.formaDePagoSelected,
      isDatosPagoEnabled: state.featureFlagReducer.featureFlags["datos-pago"],
    };
  });

const DatosPagoStep = () => {
  const { formaDePagoSelected, isDatosPagoEnabled } = useReduxSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearValidadorDatosPago());
  }, [dispatch]);

  useEffect(() => {
    if (!isDatosPagoEnabled || formaDePagoSelected.value === "CuponPago")
      dispatch(setValidadorDatosPago({ value: null, selected: true }));
  }, []);

  if (formaDePagoSelected.value === "DebitoConCBU") return <CBUStep />;
  if (formaDePagoSelected.value === "DebitoConTarjetaCredito")
    return <TarjetaCreditoStep />;

  return <div></div>;
};

export default DatosPagoStep;
