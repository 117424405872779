import { createAction, handleActions } from "redux-actions";

const cotizarSegurosDatosPersonaClear = createAction("DATOS_PERSONA_CLEAR");

const selectIdentidadConfirmada = createAction(
  "DATOS_PERSONA_IDENTIDAD_CONFIRMADA_SELECT",
  value => ({
    value
  })
);

const selectDni = createAction(
  "DATOS_PERSONA_DNI_SELECT",
  value => ({
    value
  }),
  () => ({
    analytics: {
      eventName: `cotizador-seguros-dni-seleccionado`,
      eventData: {}
    }
  })
);

const selectSexo = createAction("DATOS_PERSONA_SEXO_SELECT", value => ({
  value
}));

const selectNombre = createAction("DATOS_PERSONA_NOMBRE_SELECT", value => ({
  value
}));

const selectEmail = createAction("DATOS_PERSONA_EMAIL_SELECT", value => ({
  value
}));

const selectEmailClicked = createAction(
  "DATOS_PERSONA_EMAIL_CLICKED_SELECT",
  value => ({
    value
  })
);

const selectTelefono = createAction("DATOS_PERSONA_TELEFONO_SELECT", value => ({
  value
}));

const selectTelefonoIsMobile = createAction(
  "DATOS_PERSONA_TELEFONO_IS_MOBILE_SELECT",
  value => ({
    value
  })
);

const selectFechaNacimiento = createAction(
  "DATOS_PERSONA_FECHA_NACIMIENTO_SELECT",
  value => ({
    value
  })
);

const selectDireccion = createAction(
  "DATOS_PERSONA_DIRECCION_SELECT",
  value => ({
    value
  })
);

//GET IDENTIDAD RENAPER
const fetchIdentidadInited = createAction("FETCHIDENTIDAD_INITED");
const fetchIdentidadCompleted = createAction(
  "FETCHIDENTIDAD_COMPLETED",
  response => ({ response })
);
const fetchIdentidadFailed = createAction("FETCHIDENTIDAD_FAILED", error => ({
  error
}));

const fetchIdentidad = (documento, sexo) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchIdentidadInited());
      const response = await services.api.getIdentidad(documento, sexo);

      console.log("response", response);

      const nombre = Capitalize(`${response.nombres} ${response.apellido}`);
      const fechaNacimiento = response.fechaNacimiento;
      dispatch(
        fetchIdentidadCompleted({
          nombre: nombre,
          fechaNacimiento
        })
      );
    } catch (error) {
      dispatch(fetchIdentidadFailed(error));
    }
  };
};

const initialState = {
  nombreSelected: null,
  sexoSelected: null,
  direccionSelected: null,
  dniSelected: null,
  emailSelected: null,
  telefonoSelected: null,
  fechaNacimientoSelected: null,
  telefonoIsMobile: true,
  emailClicked: false,
  errorIdentidad: null,
  identidadConfirmada: false,
  isLoading: false
};

const agregarDatosPersonaReducer = handleActions(
  {
    [cotizarSegurosDatosPersonaClear]: (state, action) => {
      return {
        ...initialState
      };
    },
    [selectNombre]: (state, action) => {
      return {
        ...state,
        nombreSelected: action.payload.value,
        confirmed: false
      };
    },
    [selectSexo]: (state, action) => {
      return {
        ...state,
        sexoSelected: action.payload.value,
        confirmed: false
      };
    },
    [selectDni]: (state, action) => {
      return {
        ...state,
        dniSelected: action.payload.value,
        sexoSelected: null,
        confirmed: false
      };
    },
    [selectEmail]: (state, action) => {
      return {
        ...state,
        emailSelected: action.payload.value
      };
    },
    [selectEmailClicked]: (state, action) => {
      return {
        ...state,
        emailClicked: action.payload.value
      };
    },
    [selectDireccion]: (state, action) => {
      return {
        ...state,
        direccionSelected: action.payload.value,
        confirmed: false
      };
    },
    [selectTelefono]: (state, action) => {
      return {
        ...state,
        telefonoSelected: action.payload.value,
        confirmed: false
      };
    },
    [selectTelefonoIsMobile]: (state, action) => {
      return {
        ...state,
        telefonoIsMobile: action.payload.value,
        confirmed: false
      };
    },
    [selectFechaNacimiento]: (state, action) => {
      return {
        ...state,
        fechaNacimientoSelected: action.payload.value,
        confirmed: false
      };
    },
    [selectIdentidadConfirmada]: (state, action) => {
      return {
        ...state,
        identidadConfirmada: action.payload.value
      };
    },
    //GET IDENTIDAD
    [fetchIdentidadInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        nombreSelected: null,
        fechaNacimientoSelected: null,
        errorIdentidad: null,
        identidadConfirmada: false
      };
    },
    [fetchIdentidadCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        nombreSelected: action.payload.response.nombre,
        fechaNacimientoSelected: action.payload.response.fechaNacimiento,
        errorIdentidad: null
      };
    },
    [fetchIdentidadFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        nombreSelected: null,
        fechaNacimientoSelected: null,
        errorIdentidad: action.payload.error
      };
    }
  },
  initialState
);

export default agregarDatosPersonaReducer;
export {
  selectNombre,
  selectEmail,
  selectTelefono,
  selectDni,
  selectSexo,
  selectDireccion,
  selectTelefonoIsMobile,
  selectFechaNacimiento,
  selectEmailClicked,
  cotizarSegurosDatosPersonaClear,
  fetchIdentidad,
  selectIdentidadConfirmada
};

//private functions
const upperCase = str => {
  return str.toUpperCase();
};
const Capitalize = str => {
  str = str.toLowerCase();
  var firstLetterRx = /(^|\s)[a-z]/g;
  return str.replace(firstLetterRx, upperCase);
};
