import React from "react";
import pxToRem from "~libs/pxToRem";
import CardButton from "~components/styled/cardButton/CardButton";
import Typography from "@material-ui/core/Typography";
import ColorLabel from "~components/styled/colorLabel/ColorLabel";
import ContentLoader from "react-content-loader";
import moment from "moment";
import CurrencyText from "~components/styled/texts/CurrencyText";

const OfertaCardButon = ({ oferta, marginBottom, onClick, isLoading }) => {
  const labelStyle = getSituacionStyle(oferta ? oferta.resultado : null);
  return (
    <CardButton
      marginBottom={marginBottom}
      onClick={onClick}
      color={labelStyle.color}
    >
      {isLoading && renderLoadingCard()}
      {!isLoading && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "start",
            minHeight: pxToRem(96),
            flexGrow: 1,
            paddingTop: pxToRem(20),
            paddingRight: pxToRem(20),
            paddingBottom: pxToRem(8),
            paddingLeft: pxToRem(10) //IF no color passed, set this to 20
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Typography
              variant="caption"
              noWrap
              style={{
                fontSize: pxToRem(16),
                fontWeight: "bold",
                lineHeight: 1.38,
                textAlign: "left",
                paddingRight: pxToRem(8)
              }}
            >
              <CurrencyText value={oferta.importeNetoAPercibir} />
            </Typography>
            <span style={{ display: "flex", alignItems: "center" }}>
              <ColorLabel
                text={labelStyle.label}
                variant={labelStyle.variant}
              />
            </span>
          </div>
          <div
            style={{
              display: "flex",
              paddingTop: pxToRem(8)
            }}
          >
            <Typography
              style={{
                fontSize: pxToRem(14),
                fontWeight: 600,
                lineHeight: 1.36,
                textAlign: "left",
                color: "#757575"
              }}
            >
              {"18 cuotas de "}
              <CurrencyText value={oferta.importeCuotaPromedio} />
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              paddingTop: pxToRem(6)
            }}
          >
            <Typography
              noWrap
              style={{
                fontSize: pxToRem(12),
                fontWeight: 600,
                lineHeight: 1.33,
                textAlign: "left",
                color: "#9e9e9e"
              }}
            >
              {oferta.conDocumentacion
                ? "Con comprobante de ingresos"
                : "Sin documentacion"}
            </Typography>
          </div>
        </div>
      )}
    </CardButton>
  );
};

export default OfertaCardButon;

const renderLoadingCard = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        paddingTop: pxToRem(20),
        paddingRight: pxToRem(20),
        paddingBottom: pxToRem(20),
        paddingLeft: pxToRem(10) //IF no color passed, set this to 20
      }}
    >
      <ContentLoader
        height={96}
        width={300}
        speed={1}
        style={{ height: 96 }}
        primaryColor="#f7f8ff"
        secondaryColor="#B9A7D7"
      >
        <rect x="0" y="3" rx="3" ry="3" width="200" height="16" />
        <rect x="225" y="0" rx="3" ry="3" width="75" height="22" />
        <rect x="0" y="28" rx="3" ry="3" width="180" height="14" />
        <rect x="0" y="48" rx="3" ry="3" width="180" height="14" />
        <rect x="0" y="84" rx="3" ry="3" width="65" height="12" />
        <rect x="100" y="84" rx="3" ry="3" width="80" height="12" />
      </ContentLoader>
    </div>
  );
};

const getSituacionStyle = situacion => {
  const otros = "#c7f2fa";
  const info = "#fff7cc";
  const warning = "#f17f00";
  const success = "#d2f6e2";
  const danger = "#ffe0e0";
  if (!situacion)
    return {
      label: "Sin Situacion",
      variant: "",
      color: "#e0e0e0"
    };
  if (situacion === "AprobadoProvisorio")
    return { label: "Aprobado provisorio", variant: "otros", color: otros };
  if (situacion === "Aprobado")
    return {
      label: "Aprobado definitivo",
      variant: "success",
      color: success
    };
};
