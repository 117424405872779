import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setImporte, clearImporte } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import CurrencyInputWithDecimals from "~components/styled/input/CurrencyInputWithDecimals";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import MenuItem from "@material-ui/core/MenuItem";
import StyledSelect from "~components/styled/select/StyledSelect";

const ENTER_KEY = 13;

const monedas = {
  ARS: 0,
  USD: 1
};

const DniStep = () => {
  const dispatch = useDispatch();
  const [monto, setMonto] = useState(0);
  const [moneda, setMoneda] = useState(monedas.ARS);

  useEffect(() => {
    dispatch(clearImporte());
  }, [dispatch]);

  const canContinue = monto > 0;
  const onKeyDown = event => {
    if (event.keyCode === ENTER_KEY && canContinue)
      dispatch(setImporte({ monto, moneda }));
  };
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={!canContinue}
            label={"Continuar"}
            onClick={() => {
              dispatch(setImporte({ monto, moneda }));
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle label={"¿Cuánto abonaste?"} marginBottom={60} />
      <div style={{ display: "flex", flex: 1 }}>
        <div>
          <FormControl>
            <InputLabel id="moneda-label">MONEDA</InputLabel>
            <StyledSelect
              labelId="moneda-label"
              value={moneda}
              onChange={event => setMoneda(event.target.value)}
            >
              <MenuItem value={monedas.ARS}>ARS</MenuItem>
              <MenuItem value={monedas.USD}>USD</MenuItem>
            </StyledSelect>
          </FormControl>
        </div>
        <div style={{ flex: 1, paddingLeft: 16 }}>
          <TextField
            fullWidth={true}
            autoFocus
            onKeyDown={onKeyDown}
            type="tel"
            label={"IMPORTE"}
            onFocus={event => event.target.select()}
            onChange={event => setMonto(event.target.value)}
            InputProps={{
              inputComponent: CurrencyInputWithDecimals
            }}
          />
        </div>
      </div>
    </ContentSectionWithActions>
  );
};

export default DniStep;
