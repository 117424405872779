import React, { useEffect, useState } from "react";
import DateInput from "~components/styled/input/DateInput";
import { useDispatch } from "react-redux";
import { setFechaDePago, clearFechaDePago } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import moment from "moment";

const ENTER_KEY = 13;

const FechaDePagoStep = () => {
  const dispatch = useDispatch();
  const [fechaDePagoTemp, setFechaDePagoTemp] = useState("");
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    dispatch(clearFechaDePago());
  }, [dispatch]);

  const onKeyDown = event => {
    if (event.keyCode === ENTER_KEY) {
      setTouched(true);
      if (!error) dispatch(setFechaDePago(fechaDePagoTemp));
    }
  };

  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={fechaDePagoTemp.length === 0}
            label={"Continuar"}
            onClick={() => {
              if (!error) dispatch(setFechaDePago(fechaDePagoTemp));
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle label={"Indicá la fecha de pago"} marginBottom={60} />
      <TextField
        fullWidth={true}
        value={fechaDePagoTemp}
        onKeyDown={onKeyDown}
        autoFocus
        type="tel"
        onChange={event => {
          const value = event.target.value;

          const error = validateFechaDePago(value);

          setError(error);
          setTouched(false);
          setFechaDePagoTemp(value);
        }}
        onBlur={() => {
          const error = validateFechaDePago(fechaDePagoTemp);

          setError(error);
          setTouched(true);
        }}
        error={error && touched ? true : false}
        helperText={touched ? error : ""}
        placeholder="Ej: 03/02/2019"
        InputProps={{
          inputComponent: DateInput
        }}
      ></TextField>
    </ContentSectionWithActions>
  );
};

export default FechaDePagoStep;

const validateFechaDePago = (value = "") => {
  let error = null;

  if (value.length === 0) error = "Requerido";
  else if (!moment(value, "DD/MM/YYYY", true).isValid())
    error = "La fecha no es válida";
  else if (!moment(value, "DD/MM/YYYY", true).isSameOrBefore(moment()))
    error = "La fecha no puede ser mayor a hoy";

  return error;
};
