import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setNombreAgencia, clearNombreAgencia } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

const ENTER_KEY = 13;

const NombreAgenciaStep = () => {
  const dispatch = useDispatch();
  const [nombre, setNombre] = useState("");

  useEffect(() => {
    dispatch(clearNombreAgencia());
  }, [dispatch]);

  const onKeyDown = event => {
    if (event.keyCode === ENTER_KEY && nombre)
      dispatch(setNombreAgencia(nombre));
  };
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={!nombre}
            label={"Continuar"}
            onClick={() => {
              dispatch(setNombreAgencia(nombre));
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle
        label={"Ingresá el nombre de la agencia"}
        marginBottom={60}
      />
      <TextField
        fullWidth={true}
        onFocus={event => event.target.select()}
        value={nombre}
        onKeyDown={onKeyDown}
        autoFocus
        onChange={event => setNombre(event.target.value)}
        placeholder="Ej: Agencia Juan Perez"
      ></TextField>
    </ContentSectionWithActions>
  );
};

export default NombreAgenciaStep;
