import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListView from "~components/styled/listView/ListView";
import Searcher from "~components/styled/searcher/Searcher";
import filterItems from "~libs/filterItems";
import getAnios from "./getAnios";

const useStyles = makeStyles(theme => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-around"
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2
  }
}));

const AnioSelector = ({ label, onSelected, cantAnios = 20 }) => {
  const aniosList = getAnios(cantAnios);
  const [filter, setFilter] = useState("");

  const handleItemSelected = item => {
    setFilter("");
    if (onSelected) onSelected({ target: { value: item.value } });
  };

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  const classes = useStyles();

  return (
    <div className={classes.searcher}>
      <Searcher
        onClear={() => handleItemSelected({ value: null, label: null })}
        filter={filter}
        onChange={handleFilterChange}
        placeholder={label}
      />
      <ListView
        onItemSelected={handleItemSelected}
        list={filter === "" ? aniosList : filterItems(aniosList, filter)}
      />
    </div>
  );
};

export default AnioSelector;
