import React from "react";
import MaskedInput from "react-text-mask";
import getArea from "./getArea";

const MIN_PREFIJO_LENGTH = 2;
const MAX_AREACODE_LENGTH = 5;
const FIXED_PHONES_DIGITS_LENGTH = 11;
const MOBILE_PHONES_DIGITS_LENGTH = 13;

const PhoneInput = ({ inputRef, isMobile, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      pipe={(conformedValue = "") => {
        const number = conformedValue.replace(/\s|\(|\)|\-|\_/g, "");

        const area = getArea(number);
        const areaCode = area ? area.Code : null;

        const indexesOfPipedChars = [];

        const conformedValueArr = conformedValue.split("");

        const firstDigitIndex = 1;
        const firstDigit = parseInt(conformedValueArr[firstDigitIndex]);
        if (!isNaN(firstDigit) && firstDigit !== 0) {
          conformedValueArr[firstDigitIndex + 1] =
            conformedValueArr[firstDigitIndex];
          conformedValueArr[firstDigitIndex] = "0";

          indexesOfPipedChars.push(firstDigitIndex);
        }

        if (isMobile) {
          const digit1Index = areaCode ? areaCode.length + 3 : null;
          const digit1 = parseInt(conformedValueArr[digit1Index]);

          if (!isNaN(digit1) && digit1 !== 1) {
            conformedValueArr[digit1Index + 1] = conformedValueArr[digit1Index];
            conformedValueArr[digit1Index] = "1";

            indexesOfPipedChars.push(digit1Index);
          }

          const digit5Index = areaCode ? areaCode.length + 4 : null;
          const digit5 = parseInt(conformedValueArr[digit5Index]);
          if (!isNaN(digit5) && digit5 !== 5) {
            conformedValueArr[digit5Index + 1] = conformedValueArr[digit5Index];
            conformedValueArr[digit5Index] = "5";

            indexesOfPipedChars.push(digit5Index);
          }
        }

        return {
          value: conformedValueArr.join(""),
          indexesOfPipedChars,
        };
      }}
      mask={(rawValue = "") => {
        const number = rawValue.replace(/\s|\(|\)|\-|\_/g, "");

        const area = getArea(number);
        const areaCode = area ? area.Code : null;

        const maxLen = isMobile
          ? MOBILE_PHONES_DIGITS_LENGTH
          : FIXED_PHONES_DIGITS_LENGTH;

        const areaChars = areaCode
          ? Array.from(Array(areaCode.length - 1), () => /\d/)
          : Array.from(Array(MAX_AREACODE_LENGTH - 1), () => /\d/);

        const mobileChars = isMobile ? [/\d/, /\d/, " "] : [];

        let prefijoChars = [/\d/, /\d/, /\d/, /\d/];
        let abonadoChars = [/\d/, /\d/, /\d/, /\d/];

        if (areaCode) {
          if (number.length > areaCode.length) {
            let prefijoLength =
              MIN_PREFIJO_LENGTH + (MAX_AREACODE_LENGTH - areaCode.length);
            let abonadoLength =
              maxLen - areaCode.length - prefijoLength - (isMobile ? 2 : 0);

            prefijoChars = Array.from(Array(prefijoLength), () => /\d/);
            abonadoChars = Array.from(Array(abonadoLength), () => /\d/);
          }
        }

        let mask = [
          "(",
          /\d/,
          ...areaChars,
          ")",
          " ",
          ...mobileChars,
          ...prefijoChars,
          "-",
          ...abonadoChars,
        ];

        return mask;
      }}
      placeholderChar={"_"}
    />
  );
};

export default PhoneInput;
