import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/styles";
import LoadingButton from "./LoadingButton";
import Paper from "@material-ui/core/Paper";
import { TC, TR } from "~constants/tipoPoliza";

const useStyles = makeStyles((theme) => ({
  paperTitle: {
    background: "#ffffff",
    borderRadius: "solid 1px 1px 1px 1px",
  },
  normalFont: {
    fontSize: "1.2rem",
  },
  headerTitle: {
    textAlign: "center",
    fontWeight: 800,
    color: "#45459c",
    paddingTop: 5,
    paddingBottom: 5,
    lineHeight: 1.29,
    display: "block",
    flex: 1,
    whiteSpace: "pre-wrap",
    fontSize: "1rem",
  },
  disabled: {},
  button: {
    "&$disabled": {
      background: "#ffffff",
      color: "#5b5d60",
      lineHeight: "16px",
      fontWeight: "bold",
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      padding: 0,
      width: "100%",
      height: "100%",
    },
    justifyContent: "center",
    display: "flex",
    alignItems: "baseline",
    padding: 0,
    width: "100%",
    background: "#ffffff",
    color: "#5b5d60",
    fontWeight: "bold",
    lineHeight: "16px",
    height: "100%",
    borderRadius: 0,
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "strech",
    flexDirection: "row",
  },
  innerTriangle: {
    borderRight: "15px solid #d5d5d5",
    borderBottom: "15px solid transparent",
    position: "absolute",
  },
  innerTriangleSelected: {
    borderRight: "15px solid #44459C",
    borderBottom: "15px solid transparent",
    position: "absolute",
  },
  buttonContainer: {
    display: "flex",
    background: "#ffffff",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
    height: 53,
    borderRight: "1px solid #d5d5d5",
    borderTop: "1px solid #d5d5d5",
  },
  buttonContainerSelected: {
    display: "flex",
    background: "#ffffff",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: 167,
    height: 53,
    border: "2px solid #44459C",
  },
  loading: { width: "100%", marginTop: "1%" },
  buttonContent: {
    display: "flex",
    flexDirection: "column",
    textDecoration: "lowercase",
    justifyContent: "space-around",
    paddingTop: "4px",
  },
  detailsText: {
    fontSize: "12px",
    color: "#4aba7c",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "normal",
    textDecoration: "underline",
    textTransform: "lowercase",
  },
}));

const CompaniaGroup = ({ isLoading, compania, onPolizaSelected }) => {
  const polizaTC = compania.cotizaciones.find((item) => {
    return item.tipoPlan === TC ? item : null;
  });
  const polizaTR = compania.cotizaciones.find((item) => {
    return item.tipoPlan === TR ? item : null;
  });

  const handleClick = (poliza) => () => {
    if (onPolizaSelected) onPolizaSelected({ ...poliza, ...compania });
  };

  const hasPolizas = polizaTC || polizaTR;

  const classes = useStyles();
  return (
    <>
      <div style={{ paddingTop: 15 }}>
        <Paper className={classes.paperTitle}>
          <Typography variant={"h6"} className={classes.headerTitle}>
            {compania.compania}
          </Typography>
          <>
            {isLoading && (
              <div className={classes.loadingContainer}>
                <LoadingButton />
                <LoadingButton />
              </div>
            )}
            {!isLoading && hasPolizas && (
              <div className={classes.loadingContainer}>
                <PolizaButton
                  onClick={handleClick}
                  poliza={polizaTC}
                  selected={false}
                />
                <PolizaButton
                  onClick={handleClick}
                  poliza={polizaTR}
                  selected={false}
                />
              </div>
            )}
            {!isLoading && !hasPolizas && (
              <div className={classes.loadingContainer}>
                <PolizaButton
                  onClick={handleClick}
                  poliza={polizaTC}
                  selected={false}
                />
                <PolizaButton
                  onClick={handleClick}
                  poliza={polizaTR}
                  selected={false}
                />
              </div>
            )}
          </>
        </Paper>
      </div>
    </>
  );
};

export default CompaniaGroup;

const PolizaButton = ({ onClick, poliza, selected }) => {
  const classes = useStyles();

  return (
    <div
      className={
        !selected ? classes.buttonContainer : classes.buttonContainerSelected
      }
    >
      <div className={classes.button}>
        <Button
          classes={{
            root: classes.button,
            disabled: classes.disabled,
          }}
          onClick={onClick(poliza)}
          disabled={!poliza}
        >
          <>
            {poliza ? (
              <div className={classes.buttonContent}>
                <NumberFormat
                  className={classes["normalFont"]}
                  value={poliza.importe}
                  displayType={"text"}
                  decimalScale={0}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$ "}
                  suffix={" "}
                />
                <Typography className={classes.detailsText} variant="subtitle2">
                  ver detalle
                </Typography>
              </div>
            ) : (
              <>&mdash;</>
            )}
          </>
        </Button>
      </div>
      <div
        className={
          !selected ? classes.innerTriangle : classes.innerTriangleSelected
        }
      />
    </div>
  );
};
