import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTelefono, clearTelefono } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import PhoneTipoSelector from "~components/styled/phone/PhoneTipoSelector";
import PhoneInput from "~components/styled/phone/PhoneInput";
import usePhone from "~components/styled/phone/usePhone";

const ENTER_KEY = 13;

const ContactoTelefonoStep = () => {
  const dispatch = useDispatch();

  const [
    phoneInputRef,
    tel,
    setTelTouched,
    onTelNumberChange,
    onTelNumberBlur,
    onTelIsMobileChange
  ] = usePhone("");

  useEffect(() => {
    dispatch(clearTelefono());
  }, [dispatch]);

  const onKeyDown = event => {
    if (event.keyCode === ENTER_KEY) {
      setTelTouched(true);
      if (!tel.error)
        dispatch(setTelefono({ value: tel.number, isMobile: tel.isMobile }));
    }
  };

  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={tel.number.length === 0}
            label={"Continuar"}
            onClick={() => {
              if (!tel.error)
                dispatch(
                  setTelefono({ value: tel.number, isMobile: tel.isMobile })
                );
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle
        label={"Ingresá el número de teléfono de tu cliente"}
        marginBottom={60}
      />

      <div style={{ display: "flex", flex: 1 }}>
        <div>
          <PhoneTipoSelector
            value={tel.isMobile}
            onChange={onTelIsMobileChange}
          />
        </div>
        <div style={{ flex: 1, paddingLeft: 16 }}>
          <TextField
            inputRef={phoneInputRef}
            label="NÚMERO"
            fullWidth={true}
            onFocus={event => event.target.select()}
            onKeyDown={onKeyDown}
            autoFocus
            type="tel"
            value={tel.number}
            error={tel.touched && tel.error ? true : false}
            onChange={onTelNumberChange}
            onBlur={onTelNumberBlur}
            placeholder={
              tel.isMobile ? "Ej: (011) 15 2938-2938" : "Ej: (011) 2938-2938"
            }
            InputProps={{
              inputComponent: PhoneInput
            }}
            inputProps={{ isMobile: tel.isMobile, area: tel.area }}
            helperText={
              tel.touched && tel.error
                ? tel.error
                : tel.area
                ? tel.area.Description
                : ""
            }
          />
        </div>
      </div>
    </ContentSectionWithActions>
  );
};

export default ContactoTelefonoStep;
