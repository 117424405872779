import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setValidadorDatosPago, clearValidadorDatosPago } from "../actions";
import isCBUValid from "~libs/CBUValidator";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import makeStyles from "@material-ui/styles/makeStyles";
import CBUInput from "~components/styled/input/CBUInput";
import pxToRem from "~libs/pxToRem";

const ENTER_KEY = 13;

const useStyles = makeStyles((theme) => ({
  secondaryText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(16),
    fontWeight: "bold",
    lineHeight: "1.36",
    textAlign: "left",
    color: "#7644c9",
  },
}));

const CBUStep = () => {
  const dispatch = useDispatch();
  const [cbu, setCampoCBU] = useState("");
  const classes = useStyles();

  useEffect(() => {
    dispatch(clearValidadorDatosPago());
  }, [dispatch]);

  const canContinue = cbu.length > 0 && isCBUValid(cbu);

  const handleNext = (value) => {
    //dispatch(setCBU({ value, selected: true }));
    dispatch(setValidadorDatosPago({ value, selected: true }));
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue) handleNext(cbu);
  };

  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={!canContinue}
            label={"Continuar"}
            onClick={() => {
              handleNext(cbu);
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle
        label={"Ingresá el CBU de la cuenta con la que realizas el pago"}
        marginBottom={60}
      />
      <TextField
        fullWidth={true}
        onKeyDown={onKeyDown}
        autoFocus
        onFocus={(event) => event.target.select()}
        //type="email"
        value={cbu}
        onChange={(event) => setCampoCBU(event.target.value)}
        placeholder="Ej: 9999999999999999999999"
        InputProps={{
          inputComponent: CBUInput,
        }}
      ></TextField>
    </ContentSectionWithActions>
  );
};

export default CBUStep;
