import React from "react";
import pxToRem from "~libs/pxToRem";
import { fade } from "material-ui/utils/colorManipulator";
import { makeStyles } from "@material-ui/styles";
import Badge from "@material-ui/core/Badge";
const useStyles = makeStyles(theme => ({
  base: props => ({
    backgroundColor: props.backgroundColor
      ? props.backgroundColor
      : fade(theme.palette.primary.light, 0.7),
    color: props.color ? props.color : "white",
    transform: "scale(1)",
    position: "relative"
  }),
  root: {
    display: "inline-block",
    paddingLeft: pxToRem(8),
    paddingRight: pxToRem(8)
  }
}));
const Ellipse = ({ badgeContent, backgroundColor, color }) => {
  const classes = useStyles({ backgroundColor, color });

  return (
    <Badge
      badgeContent={badgeContent}
      classes={{ root: classes.root, badge: classes.base }}
    />
  );
};

export default Ellipse;
