import { createActions, handleActions } from "redux-actions";

export const {
  agregarCodeudorIdentidadSet,
  agregarCodeudorActividadSet,
  agregarCodeudorDireccionSet,
  agregarCodeudorContactoSet,
  agregarCodeudorComplete,
  agregarCodeudorFailed,
  agregarCodeudorIsLoading,
  agregarCodeudorClear
} = createActions(
  "AGREGAR_CODEUDOR_IDENTIDAD_SET",
  "AGREGAR_CODEUDOR_ACTIVIDAD_SET",
  "AGREGAR_CODEUDOR_DIRECCION_SET",
  "AGREGAR_CODEUDOR_CONTACTO_SET",
  "AGREGAR_CODEUDOR_COMPLETE",
  "AGREGAR_CODEUDOR_FAILED",
  "AGREGAR_CODEUDOR_IS_LOADING",
  "AGREGAR_CODEUDOR_CLEAR"
);

export function submitForm(solicitudId) {
  return function(dispatch, getState, services) {
    return new Promise(function(resolve, reject) {
      dispatch(agregarCodeudorIsLoading({ isLoading: true }));
      let store = getState().agregarCodeudorReducer;

      const codeudorData = {
        Id: solicitudId,
        EstadoCivil: "Soltero",
        Documento: store.identidad.dni,
        Nombre: store.identidad.nombres,
        Apellido: store.identidad.apellidos,
        Sexo: store.identidad.sexo.value,
        Cuil: store.identidad.cuil,
        FechaNacimiento: store.identidad.fechaNacimiento,
        Ingreso: store.actividad.ingresos,
        ActividadDeclarada: store.actividad.actividadDeclarada
          ? store.actividad.actividadDeclarada.value
          : null,
        RubroEmpleoPublico: store.actividad.rubro
          ? store.actividad.rubro.value
          : null,
        Antiguedad: store.actividad.antiguedad
          ? store.actividad.antiguedad.value
          : null,
        Provincia: store.direccion.selectedProvince.value,
        Localidad: store.direccion.selectedCity.value,
        CodigoPostal: store.direccion.selectedPostCode.value,
        Direccion: store.direccion.streetAddress,
        Telefonos: store.contacto.phones
          .filter(t => t.numero.length > 0)
          .map(t => ({
            Numero: t.numero,
            TipoTelefono: t.tipoContacto,
            EsMovil: t.esMovil,
            HorarioContacto: t.horarioContacto,
            CorrespondeA: t.nombreContacto
          }))
      };

      services.api
        .postAgregarCodeudor(codeudorData)
        .then(() => {
          dispatch(agregarCodeudorComplete());
        })
        .catch(error => {
          services.sendMessage(error.message, "error");
          dispatch(agregarCodeudorFailed());
          reject();
        });
    });
  };
}

const agregarCodeudorReducer = handleActions(
  {
    AGREGAR_CODEUDOR_IDENTIDAD_SET: (state, action) => {
      return {
        ...state,
        identidad: action.payload,
        actividad: "",
        direccion: "",
        contacto: ""
      };
    },
    AGREGAR_CODEUDOR_ACTIVIDAD_SET: (state, action) => {
      return {
        ...state,
        actividad: action.payload,
        direccion: "",
        contacto: ""
      };
    },
    AGREGAR_CODEUDOR_DIRECCION_SET: (state, action) => {
      return { ...state, direccion: action.payload, contacto: "" };
    },
    AGREGAR_CODEUDOR_CONTACTO_SET: (state, action) => {
      return { ...state, contacto: action.payload };
    },
    AGREGAR_CODEUDOR_COMPLETE: (state, action) => {
      return {
        ...state,
        isLoading: false,
        complete: true,
        error: false
      };
    },
    AGREGAR_CODEUDOR_FAILED: (state, action) => {
      return {
        ...state,
        isLoading: false,
        complete: true,
        error: true
      };
    },
    AGREGAR_CODEUDOR_IS_LOADING: (state, { isLoading }) => {
      return { ...state, isLoading };
    },
    AGREGAR_CODEUDOR_CLEAR: (state, action) => {
      return {
        ...state,
        identidad: "",
        actividad: "",
        direccion: "",
        contacto: "",
        isLoading: false,
        complete: false,
        error: false
      };
    }
  },
  {
    identidad: "",
    actividad: "",
    direccion: "",
    contacto: "",
    isLoading: false,
    complete: false,
    error: false
  }
);

export default agregarCodeudorReducer;
