import {
  OFERTASFORM_INIT,
  OFERTASFORM_LOAD,
  OFERTASFORM_ISLOADING,
  OFERTASFORM_LOADED,
  OFERTASFORM_CAMBIAROFERTA,
  OFERTASFORM_CLEARED,
  STEP_COMMITED,
  SET_OFERTASFORM_COMMITED
} from "./actions";

const initialState = {
  isLoading: false,
  ofertas: [],
  ofertaSeleccionada: null,
  excepcionFaltanDatos: false,
  excepcionNoSeFinanciaUso: false,
  excepcionNoSeFinanciaCalificacion: false,
  excepcionPreaprobado: false,
  excepcionRechazado: false,
  excepcionInformesPendientes: false,
  tieneExcepciones: false,
  stepCommited: false
};

const ofertaFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case OFERTASFORM_INIT:
      return initialState;
    case OFERTASFORM_LOAD:
      return {
        ...state,
        ...action.payload.data,
        isLoading: false
      };
    case OFERTASFORM_ISLOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case OFERTASFORM_LOADED:
      const preaprobado = action.payload.resultado === "PreAprobado";
      const rechazado = action.payload.resultado === "Rechazado";
      const informesPendientes =
        action.payload.resultado === "InformesPendientes" ||
        action.payload.resultado === "NoValidaIdentidad";
      const faltanDatos = action.payload.excepcionFaltanDatos;
      const noFinanciaUso = action.payload.excepcionNoSeFinanciaUso;
      const noFinanciaCalificacion =
        action.payload.excepcionNoSeFinanciaCalificacion;

      return {
        ...state,
        ofertas: action.payload.ofertas,
        excepcionFaltanDatos: faltanDatos,
        excepcionNoSeFinanciaUso: noFinanciaUso,
        excepcionNoSeFinanciaCalificacion: noFinanciaCalificacion,
        excepcionPreaprobado: preaprobado,
        excepcionRechazado: rechazado,
        excepcionInformesPendientes: informesPendientes,
        tieneExcepciones:
          faltanDatos ||
          noFinanciaUso ||
          noFinanciaCalificacion ||
          preaprobado ||
          rechazado ||
          informesPendientes
      };
    case OFERTASFORM_CAMBIAROFERTA:
      return {
        ...state,
        ofertaSeleccionada: state.ofertas[action.payload.i]
      };
    case OFERTASFORM_CLEARED:
      return {
        ...state,
        ofertaSeleccionada: null
      };
    case STEP_COMMITED:
      return {
        ...state
      };
    case SET_OFERTASFORM_COMMITED:
      return {
        ...state,
        stepCommited: action.payload.commited
      };
    default:
      return state;
  }
};

export default ofertaFormReducer;
