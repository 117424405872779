import {
  LOG_IN_ERROR,
  LOG_IN_SUCCESS,
  LOG_OUT,
  LOG_IN_ISAUTHENTICATED,
  LOG_IN_ISLOADING,
  WEB_AUTH_STATUS_CHANGE
} from "../../user/js/actions";
import { MAINMENU_SET, TOGGLE_OPEN_MAINMENU } from "../../topBar/js/actions";
import { sendMessageToNative, SHOW_NATIVE_POPUP } from "./actions";
import localRepository from "../../../localRepository";

export const onTopBarSetMiddleware = store => next => action => {
  if (action.type === MAINMENU_SET) {
    sendMessageToNative(action);
  }
  return next(action);
};

export const onToggleMainMenuMiddleware = store => next => action => {
  if (action.type === TOGGLE_OPEN_MAINMENU) {
    sendMessageToNative(action);
  }
  return next(action);
};

export const onAuthStatusChangeMiddleware = store => next => action => {
  if (
    action.type === LOG_IN_ERROR ||
    action.type === LOG_IN_SUCCESS ||
    action.type === LOG_OUT ||
    action.type === LOG_IN_ISAUTHENTICATED ||
    action.type === LOG_IN_ISLOADING
  ) {
    let accessToken = localRepository.accessToken.get();

    if (action.payload)
      sendMessageToNative({
        type: WEB_AUTH_STATUS_CHANGE,
        payload: {
          token: accessToken,
          version: localRepository.version.get()
        }
      });
  }

  return next(action);
};

export const onShowNativePopupMiddleware = store => next => action => {
  if (action.type === SHOW_NATIVE_POPUP) {
    sendMessageToNative(action);
  }
  return next(action);
};
