import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentSection from "~components/styled/contentSection/ContentSection";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import AnioSelector from "~components/bussines/anioSelector/AnioSelector";

import { setVehiculoAnio, clearVehiculoAnio } from "./actions";

const VehiculoAnioStep = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearVehiculoAnio());
  }, [dispatch]);

  return (
    <ContentSection>
      <PrimaryTitle label={"Elegí el año del auto"} />
      <AnioSelector
        label={"Buscar año"}
        onSelected={event => dispatch(setVehiculoAnio(event.target.value))}
      />
    </ContentSection>
  );
};

export default VehiculoAnioStep;
