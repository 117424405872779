import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import pxToRem from "~libs/pxToRem";
import comprobanteImg from "~assets/ilustrations/comprobanteImg.svg";
import { Typography, Button } from "@material-ui/core";
import AgilizaTramiteModal from "../agilizaTramiteModal/AgilizaTramiteModal";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "calc(100vh - 64px)"
  },
  comprobanteIm: {
    height: "30vh",
    objectFit: "contain"
  },
  textContainer: {
    textAlign: "center",
    paddingTop: pxToRem(8)
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    paddingTop: pxToRem(16),
    paddingBottom: pxToRem(8),
    "@media (max-width: 480px)": {
      paddingTop: pxToRem(24)
    }
  },
  text: {
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal"
  },
  agilizarTramiteContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: pxToRem(40)
  },
  boldText: {
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal"
  },
  bottomText: {
    paddingTop: pxToRem(16),
    fontSize: "0.875rem",
    fontStyle: "italic",
    lineHeight: 1.36,
    textAlign: "center",
    width: "90%"
  },
  bottomTextWhiteSpace: {
    paddingTop: pxToRem(16),
    fontSize: "0.875rem",
    fontStyle: "italic",
    lineHeight: 1.36,
    textAlign: "center",
    whiteSpace: "nowrap"
  },
  buttonText: {
    textTransform: "none"
  },
  agilizaButton: {
    minWidth: pxToRem(160),
    borderRadius: "4px"
  },
  volverButton: {
    width: "100%",
    paddingTop: pxToRem(32)
  }
}));

const ComprobanteSolicitud = ({ boldText, text, subtitleModal }) => {
  const classes = useStyles();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={classes.root}>
      <img alt="" src={comprobanteImg} className={classes.comprobanteIm} />

      <div className={classes.textContainer}>
        <Typography className={classes.title} color="textPrimary">
          ¡Ya recibimos tu solicitud!
        </Typography>
        <span className={classes.text}>En instantes</span>
        <span className={classes.boldText}>{boldText}</span>
        <span className={classes.text}>{text}</span>
      </div>
      <div className={classes.agilizarTramiteContainer}>
        <span className={classes.boldText}>
          Este proceso puede demorar unos minutos.
        </span>
        <div style={{ paddingTop: pxToRem(16) }}>
          <Button
            color="primary"
            variant="outlined"
            classes={{
              label: classes.buttonText,
              root: classes.agilizaButton
            }}
            onClick={() => setShowModal(true)}
          >
            Agilizá el trámite
          </Button>
        </div>
        <span className={classes.bottomText}>
          Ante cualquier duda, comunicate al
          <span className={classes.bottomTextWhiteSpace}>
            {" +549-34-1566-4805"}
          </span>
        </span>
        <AgilizaTramiteModal
          showModal={showModal}
          setShowModal={setShowModal}
          subtitle={subtitleModal}
        />
      </div>
      <div className={classes.volverButton}>
        <CallToActionButton
          label={"Volver"}
          onClick={() => {
            history.push("/");
          }}
        />
      </div>
    </div>
  );
};

export default ComprobanteSolicitud;
