import { identidadFormLoad } from "../../identidadForm/actions";
import { actividadFormLoad } from "../../actividadForm/js/actions";
import { canalFormLoad } from "../../canalForm/actions";
import { direccionFormLoad } from "../../direccionForm/js/actions";
import { contactoFormLoad } from "../../contactoForm/js/actions";
import { autoFormLoad } from "../../autoForm/actions";
import { prestamoFormLoad } from "../../prestamoForm/actions";

import {
  solicitudCreditoUILoad,
  resetSolicitud
} from "../../solicitudCredito/js/actions";

export const DETALLESOLICITUD_ISLOADING = "DETALLESOLICITUD_ISLOADING";
export const DETALLESOLICITUD_FETCHSUCCESS = "DETALLESOLICITUD_FETCHSUCCESS";
export const DETALLESOLICITUD_FETCHFAILED = "DETALLESOLICITUD_FETCHFAILED";
export const DETALLESOLICITUD_INIT = "DETALLESOLICITUD_INIT";

export const detalleSolicitudIsLoading = loading => ({
  type: DETALLESOLICITUD_ISLOADING,
  payload: {
    isLoading: loading
  }
});

export const detalleSolicitudFetchSuccess = formData => ({
  type: DETALLESOLICITUD_FETCHSUCCESS,
  payload: {
    ...formData
  }
});

export const detalleSolicitudFetchFailed = error => ({
  type: DETALLESOLICITUD_FETCHFAILED,
  payload: {
    ...error
  }
});

export const detalleSolicitudInit = () => ({
  type: DETALLESOLICITUD_INIT
});

export function loadSolicitud(detalleSolicitud) {
  return (dispatch, getState, services) => {
    dispatch(resetSolicitud());
    const titular = detalleSolicitud.personas.find(
      x => x.tipoRelacion === "Titular"
    );
    const conyuge = detalleSolicitud.personas.find(
      x => x.tipoRelacion === "Conyuge"
    );
    dispatch(canalFormLoad(getCanal(detalleSolicitud)));
    dispatch(identidadFormLoad(getIdentidad(titular)));
    dispatch(actividadFormLoad(getActividad(titular, conyuge)));
    dispatch(direccionFormLoad(getDireccion(titular)));
    dispatch(contactoFormLoad(getContactos(detalleSolicitud)));
    dispatch(autoFormLoad(getAuto(detalleSolicitud)));
    dispatch(prestamoFormLoad(getPrestamoForm(detalleSolicitud)));
    dispatch(solicitudCreditoUILoad(getSolicitudCreditoUi(detalleSolicitud)));
  };
}

const getCanal = solicitud => ({ selectedCanal: { key: solicitud.canal } });

const getIdentidad = titular => {
  return {
    dni: titular.documento,
    apellido: titular.apellido,
    nombre: titular.nombre,
    sexo: { label: titular.sexo, value: titular.sexo },
    isAptoCredito: true,
    isAptoObtenido: true,
    revisar: true,
    estadoCivil: { label: titular.estadoCivil, value: titular.estadoCivil },
    disabled: true,
    stepCommited: titular.nombre && !titular.dictamenPendiente
  };
};

const getActividad = (titular, conyuge) => {
  if (titular.ingresos === 0) return;
  let actividadConyuge = null;
  if (conyuge)
    actividadConyuge = {
      ingresosConyuge: conyuge.ingresos,
      actividadConyuge: {
        value: conyuge.actividadDeclarada,
        label: conyuge.actividadDeclarada
      }
    };
  return {
    ingresos: titular.ingresos,
    actividadDeclarada: {
      value: titular.actividadDeclarada,
      label: titular.actividadDeclarada
    },
    rubro: {
      value: titular.rubroEmpleoPublico,
      label: titular.rubroEmpleoPublico
    },
    antiguedad: {
      value: titular.antiguedad,
      label:
        titular.antiguedad === 0
          ? 0
          : getAntiguedadLabel(titular.antiguedad).label
    },
    ...actividadConyuge,
    disabled: true,
    stepCommited: true
  };
};

const getAntiguedadLabel = antiguedad =>
  [
    {
      value: 12,
      label: "Más de 11 meses"
    },
    {
      value: 10,
      label: "10 - 11 meses"
    },
    {
      value: 9,
      label: "Menos de 10 meses"
    }
  ].find(x => x.value === antiguedad);

const getDireccion = titular => {
  return {
    selectedProvince: titular.provincia
      ? {
          value: titular.provincia,
          label: titular.provinciaNombre
        }
      : null,
    selectedCity: titular.localidad
      ? {
          value: titular.localidad,
          label: titular.localidad
        }
      : null,
    selectedPostCode: titular.codigoPostal
      ? {
          value: titular.codigoPostal,
          label: titular.codigoPostal
        }
      : null,
    stepCommited: titular.provincia && titular.localidad && titular.codigoPostal
  };
};

const getContactos = solicitud => {
  if (solicitud.telefonos.length === 0) return;
  const telefonos = solicitud.telefonos.map(t => ({
    numero: t.numero,
    tipoContacto: t.tipoTelefono,
    esMovil: t.esMovil,
    horarioContacto: t.horarioContacto,
    nombreContacto: t.correspondeA,
    isValid: true,
    disabled: true
  }));
  if (telefonos.length < 2)
    telefonos.push({
      numero: "",
      tipoContacto: "",
      esMovil: false,
      horarioContacto: "",
      nombreContacto: "",
      isValid: true,
      disabled: false
    });

  const email =
    solicitud.contactosRed &&
    solicitud.contactosRed.length > 0 &&
    solicitud.contactosRed[0].redInternet === "Email"
      ? {
          value: solicitud.contactosRed[0].usuario,
          disabled: true,
          isValid: true
        }
      : "";

  return { telefonos: telefonos, email: email, stepCommited: true };
};

const getAuto = solicitud => {
  if (!solicitud.vehiculo) return;
  return {
    selectedAnio: {
      value: solicitud.vehiculo.anio,
      label: solicitud.vehiculo.anio
    },
    selectedEsCeroKM: {
      value: solicitud.vehiculo.es0Km,
      label: solicitud.vehiculo.es0Km ? "SI" : "NO"
    },
    selectedGnc: {
      value: solicitud.vehiculo.tieneGNC,
      label: solicitud.vehiculo.tieneGNC ? "SI" : "NO"
    },
    selectedMarca: {
      value: solicitud.vehiculo.marca,
      label: solicitud.vehiculo.marca
    },
    selectedModelo: {
      value: solicitud.vehiculo.codia,
      label: solicitud.vehiculo.modelo
    },
    selectedTipoUso: {
      value: solicitud.vehiculo.uso,
      label: solicitud.vehiculo.uso
    },
    valorAuto: solicitud.vehiculo.valorDeCompra,
    valorInfoauto: solicitud.vehiculo.valorInfoauto,
    codia: solicitud.vehiculo.codia,
    stepCommited: true
  };
};

const getPrestamoForm = solicitud => ({
  importe: solicitud.importeSolicitado,
  plazo: solicitud.plazoSolicitado,
  stepCommited: solicitud.importeSolicitado > 0
});

const getSolicitudCreditoUi = solicitud => ({
  solicitudId: solicitud.id,
  continueEnabled: true
});

export function fetchDetalleSolicitud(solicitudId) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(detalleSolicitudIsLoading(true));
      const response = await services.api.getSolicitudById(solicitudId);
      dispatch(detalleSolicitudFetchSuccess(response));
      dispatch(detalleSolicitudIsLoading(false));
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        fetchDetalleSolicitud,
        [solicitudId],
        getState()
      );
      dispatch(detalleSolicitudFetchFailed(error));
      dispatch(detalleSolicitudIsLoading(false));
    }
  };
}
