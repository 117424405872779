import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const DetailsTabs = ({ defaultValue = 0, disabled, labels = [], onChange }) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange({ target: { value: newValue } });
    }
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      centered
      variant="fullWidth"
    >
      {labels.map((label, index) => (
        <Tab
          key={`detail-tab-${index}`}
          label={label}
          disabled={disabled}
          style={{ fontWeight: 800 }}
        />
      ))}
    </Tabs>
  );
};

export default DetailsTabs;
