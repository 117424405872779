import React from "react";
import { Route } from "react-router-dom";

import Credito from "~components/app/clienteFinal/creditos/detalleCredito/DetalleCredito";
import HomeCreditos from "~components/app/clienteFinal/creditos/homeCreditos/HomeCreditos";
import InformarPago from "./informarPago/InformarPago";
const CreditosRoutes = () => [
  <Route key="creditos-1" exact path="/miscreditos/informarpago/:position?">
    <InformarPago />
  </Route>,
  <Route key="creditos-2" path="/miscreditos/:operacionId">
    <Credito />
  </Route>,
  <Route key="creditos-3" path="/miscreditos">
    <HomeCreditos />
  </Route>
];

export default CreditosRoutes;
