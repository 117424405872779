import { combineReducers } from "redux";
import terminosYCondicionesReducer from "./terminosYCondiciones/actions";
import vinculacionReducer from "./vinculacion/actions";
const combinedReducers = combineReducers({
  terminosYCondicionesReducer,
  vinculacionReducer
});
const sharedReducers = {
  key: "sharedReducers",
  value: combinedReducers
};

export default sharedReducers;
