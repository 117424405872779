//import initialState from './initialState';
import {
  DETALLESOLICITUD_ISLOADING,
  DETALLESOLICITUD_FETCHSUCCESS,
  DETALLESOLICITUD_INIT,
  DETALLESOLICITUD_FETCHFAILED
} from "./actions";

const initialState = {
  isLoading: false,
  id: "",
  canal: -1,
  fechaCreacion: "",
  telefonos: [],
  contactosRed: [],
  personas: [],
  domicilio: {
    provincia: "",
    localidad: "",
    codigoPostal: "",
    direccion: ""
  },
  vehiculo: {
    codia: "",
    anio: 0,
    marca: "",
    modelo: "",
    tipo: "",
    es0Km: "",
    tieneGNC: "",
    usoComercial: "",
    valorDeCompra: 0,
    valorInfoauto: 0
  },
  poliza: {
    cotizacionId: "",
    cotizacionPlanId: "",
    cotizacionIdCia: "",
    compania: 0,
    tipoPolizaId: 0,
    cantCuotas: 0,
    premio: 0,
    premioTotal: 0,
    valorAsegurado: 0,
    fechaInicio: "",
    fechaFin: "",
    fechaDeCotizacion: ""
  },
  importeSolicitado: 0,
  plazoSolicitado: 0,
  tareasPendientes: 0,
  error: false,
  errorMessage: null
};

let PhoneNumber = require("awesome-phonenumber");

const detalleSolicitudReducer = (state = initialState, action) => {
  switch (action.type) {
    case DETALLESOLICITUD_ISLOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case DETALLESOLICITUD_FETCHSUCCESS:
      action.payload.telefonos = action.payload.telefonos.map(telefono => {
        return {
          ...telefono,
          format: new PhoneNumber(telefono.numero, "AR")
            .getNumber("national")
            .replace(/-/g, " ")
        };
      });
      return {
        ...action.payload
      };
    case DETALLESOLICITUD_FETCHFAILED:
      return {
        ...state,
        error: true,
        errorMessage: action.payload.error
      };
    case DETALLESOLICITUD_INIT:
      return initialState;
    default:
      return state;
  }
};

export default detalleSolicitudReducer;
