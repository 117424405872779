import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import pxToRem from "~libs/pxToRem";
import { useAuth0 } from "~componentes/Auth0/useAuth";
import { submitSignupAgencia } from "../actions";
import environment from "../../../../../../environment";

import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import Typography from "@material-ui/core/Typography";
import FormImg from "~assets/ilustrations/form-img.svg";

const TIPO_AGENCIA_CONCESIONARIA = 1;
const appData = environment.terminosYCondiciones;

const useReduxSelector = () =>
  useSelector(state => {
    const signupAgenciaReducer =
      state.authenticationReducers.signupAgenciaReducer;

    return {
      cuiTitular: signupAgenciaReducer.cuiTitular,
      nombreAgencia: signupAgenciaReducer.nombreAgencia,
      nombreTitular: signupAgenciaReducer.nombreTitular,
      provinciaAgencia: signupAgenciaReducer.provinciaAgencia,
      localidadAgencia: signupAgenciaReducer.localidadAgencia,
      codigoPostalAgencia: signupAgenciaReducer.codigoPostalAgencia,
      direccionAgencia: signupAgenciaReducer.direccionAgencia,
      tipoAgencia: signupAgenciaReducer.tipoAgencia,
      tipoVehiculo: signupAgenciaReducer.tipoVehiculo,
      nombreContacto: signupAgenciaReducer.nombreContacto,
      telefonoContacto: signupAgenciaReducer.telefonoContacto,
      terminosYCondiciones: signupAgenciaReducer.terminosYCondiciones
    };
  });

const FinalStep = () => {
  const { authState } = useAuth0();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    cuiTitular,
    nombreAgencia,
    nombreTitular,
    provinciaAgencia,
    localidadAgencia,
    codigoPostalAgencia,
    direccionAgencia,
    tipoAgencia,
    tipoVehiculo,
    nombreContacto,
    telefonoContacto,
    terminosYCondiciones
  } = useReduxSelector();

  useEffect(() => {
    if (
      cuiTitular &&
      nombreAgencia &&
      nombreTitular &&
      provinciaAgencia &&
      localidadAgencia &&
      codigoPostalAgencia &&
      direccionAgencia &&
      tipoAgencia &&
      tipoVehiculo &&
      nombreContacto &&
      telefonoContacto &&
      terminosYCondiciones
    )
      dispatch(
        submitSignupAgencia({
          Cui: cuiTitular,
          NombreAgencia: nombreAgencia,
          NombreTitular: nombreTitular,
          Provincia: provinciaAgencia,
          Localidad: localidadAgencia,
          CodigoPostal: codigoPostalAgencia,
          Direccion: direccionAgencia,
          TipoAgencia: tipoAgencia.tipo,
          Marca:
            tipoAgencia.tipo === TIPO_AGENCIA_CONCESIONARIA
              ? tipoAgencia.marca
              : "",
          TipoVehiculo: tipoVehiculo,
          NombreContacto: nombreContacto,
          TelefonoContacto: telefonoContacto,
          EmailContacto: authState.user.email,
          VersionTerminos: appData.version,
          ComoNosConocio: 1
        })
      );
  }, []);
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={false}
            label={"¡Vamos!"}
            onClick={() => history.push("/")}
          />
        </ContentSection>
      }
    >
      <img
        src={FormImg}
        style={{ height: 178, marginTop: 100, marginBottom: 18 }}
      />
      <Typography className={classes.title}>
        ¡Ya recibimos los datos!
      </Typography>
      <Typography className={classes.text}>
        Te vamos a enviar un mail a{" "}
        <span style={{ fontWeight: "bold" }}>{authState.user.email}</span> para
        completar el proceso de alta de agencia.
      </Typography>
    </ContentSectionWithActions>
  );
};

export default FinalStep;

const useStyles = makeStyles(theme => ({
  title: {
    opacity: 0.9,
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(24),
    fontSeight: 600,
    lineHeight: 1.33,
    textAlign: "center",
    color: "#000000"
  },
  text: {
    opacity: 0.75,
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(16),
    lineHeight: 1.38,
    textAlign: "center",
    color: "#000000",
    marginBottom: 35
  }
}));
