import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setEmail, clearEmail } from "../actions";
import EmailValidator from "email-validator";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/styles/makeStyles";
import pxToRem from "~libs/pxToRem";

const ENTER_KEY = 13;

const useStyles = makeStyles(theme => ({
  secondaryText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(16),
    fontWeight: "bold",
    lineHeight: "1.36",
    textAlign: "left",
    color: "#7644c9"
  }
}));

const ContactoEmailStep = () => {
  const dispatch = useDispatch();
  const [email, setCampoEmail] = useState("");
  const classes = useStyles();

  useEffect(() => {
    dispatch(clearEmail());
  }, [dispatch]);
  
  const canContinue = email.length > 0 && EmailValidator.validate(email);

  const onKeyDown = event => {
    if (event.keyCode === ENTER_KEY && canContinue)
      dispatch(setEmail(email));
  };
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={!canContinue}
            label={"Continuar"}
            onClick={() => {
              dispatch(setEmail(email));
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle label={"Ingresá un correo electrónico"} marginBottom={60} />
      <TextField
        fullWidth={true}
        onKeyDown={onKeyDown}
        autoFocus
        onFocus={event => event.target.select()}
        type="email"
        value={email}
        onChange={event => setCampoEmail(event.target.value)}
        placeholder="Ej: micorreo@mail.com"
      ></TextField>
      <div style={{ display: "flex", justifyConent: "space-around" }}>
        <Typography className={classes.secondaryText}>
          <Link
            className={classes.secondaryText}
            style={{ marginLeft: 5, marginTop: 5 }}
            component="button"
            underline="always"
            onClick={() => {
              dispatch(setEmail(""));
            }}
          >
            Omitir este paso
          </Link>
        </Typography>
      </div>
    </ContentSectionWithActions>
  );
};

export default ContactoEmailStep;
