import { createAction } from "redux-actions";

const menuClicked = createAction(
  "MENU_CLICKED",
  () => ({}),
  ({ menu }) => ({
    analytics: {
      eventName: `menu-clicked-${menu}`,
      eventData: {}
    }
  })
);

export { menuClicked };
