import { useCallback, useMemo, useEffect } from "react";
import { useAuth0Context } from "./AuthContext";
import jwt_decode from "jwt-decode";
import getMGUser from "~libs/getMGUser";
import { sendMessage } from "~libs/sendMessage";
import localRepository from "../../localRepository";
import { sendMessageToNative } from "../nativeAppMessaging/js/actions";
import { WEB_AUTH_STATUS_CHANGE, WEB_LOGOUT } from "../user/js/actions";

const useIsAuthenticated = expiresAt => {
  return useMemo(() => {
    return new Date().getTime() < expiresAt;
  }, [expiresAt]);
};

export const useRefreshToken = (isOnNativeApp, nativeCredentials) => {
  const { authState, renewSession, setSession } = useAuth0();
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  useEffect(() => {
    let timerForWebRefresh = null;

    const TWO_MINUTES = 2 * 60 * 1000;
    let timeToExpire = 0;
    if (authState.expiresAt > 0) {
      timeToExpire = authState.expiresAt - new Date().getTime();
      timeToExpire =
        timeToExpire > TWO_MINUTES ? timeToExpire - TWO_MINUTES : 0;
    }

    if (isOnNativeApp) {
      if (
        nativeCredentials &&
        nativeCredentials.accessToken !== authState.accessToken
      ) {
        setSession({
          expiresIn: nativeCredentials.expiresIn,
          accessToken: nativeCredentials.accessToken,
          idToken: nativeCredentials.idToken
        });
      }
    } else {
      timerForWebRefresh = setTimeout(() => {
        if (isLoggedIn) {
          renewSession();
        }
      }, timeToExpire);
    }

    return () => clearTimeout(timerForWebRefresh);
  }, [authState, renewSession, isOnNativeApp, nativeCredentials]);
};

export const useAuth0 = () => {
  const { auth0, authState, updateAuthState } = useAuth0Context();

  const isAuthenticated = useIsAuthenticated(
    authState ? authState.expiresAt : new Date().getTime()
  );

  const loginSocial = useCallback(
    provider => {
      auth0.authorize({
        connection: provider
      });
    },
    [auth0]
  );

  const loginPasswordlessEmail = useCallback(
    email => {
      return new Promise((resolve, reject) => {
        auth0.passwordlessStart(
          {
            connection: "email",
            email: email,
            send: "code"
          },
          err => {
            if (err) reject(err);
            else resolve();
          }
        );
      });
    },
    [auth0]
  );

  const confirmPasswordlessEmail = useCallback(
    (email, verificationCode) => {
      return new Promise((resolve, reject) => {
        auth0.passwordlessLogin(
          {
            connection: "email",
            email: email,
            verificationCode: verificationCode
          },
          err => {
            if (err) reject(err);
            else resolve();
          }
        );
      });
    },
    [auth0]
  );

  const logout = useCallback(
    (returnUrl = "") => {
      localStorage.removeItem("isLoggedIn");

      updateAuthState({
        accessToken: null,
        idToken: null,
        expiresAt: 0,
        user: null
      });

      const isOnNativeApp = localRepository.isOnNativeApp.get();

      //alert("native app: " + isOnNativeApp);

      if (isOnNativeApp) {
        sendMessageToNative({
          type: WEB_LOGOUT
        });

        sendMessageToNative({
          type: WEB_AUTH_STATUS_CHANGE, // TODO: queda solo por compatibilidad con nativo, quitar cuando todos se hayan actualizado
          payload: {
            credentials: "",
            version: localRepository.version.get(),
            logIn: false
          }
        });
      } else {
        auth0.logout({
          returnTo: window.location.origin + returnUrl
        });
      }
    },
    [auth0, updateAuthState]
  );

  const setSession = useCallback(
    authResult => {
      const auth0TokenData = jwt_decode(authResult.accessToken);
      const user = getMGUser(auth0TokenData);

      localStorage.setItem("isLoggedIn", "true");

      let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();

      updateAuthState({
        accessToken: authResult.accessToken,
        idToken: authResult.idToken,
        expiresAt: expiresAt,
        user: user
      });
    },
    [updateAuthState]
  );

  const renewSession = useCallback(() => {
    auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        setSession(authResult);
      } else if (err) {
        logout();
        console.error(
          `Could not get a new token (${err.error}: ${err.error_description}).`
        );
        sendMessage("Hubo un problema, volvé a iniciar sesión.", "warning");
      }
    });
  }, []);

  const handleAuthentication = useCallback(() => {
    auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        setSession(authResult);
      } else if (err) {
        console.error(
          `Error: ${err.error}. Check the console for further details.`
        );
        // sendMessage(
        //   "Hubo un problema con el inicio de sesión, volvé a intentarlo en unos instantes.",
        //   "error"
        // ); // este mensaje no se dispara porque auth0 hace un redirect cuando ejecutamos un logout.

        logout();
      }
    });
  }, []);

  return {
    authState,
    loginSocial,
    loginPasswordlessEmail,
    confirmPasswordlessEmail,
    logout,
    handleAuthentication,
    isAuthenticated,
    renewSession,
    setSession // NO USAR, es solo para lo de native
  };
};
