import { createActions, handleActions } from "redux-actions";

/**** ACTIONS ****/

export const {
  fetchSolicitudInited,
  fetchSolicitudFailed,
  fetchSolicitudCompleted
} = createActions(
  "FETCH_SOLICITUD_INITED",
  "FETCH_SOLICITUD_FAILED",
  "FETCH_SOLICITUD_COMPLETED"
);
export const fetchSolicitud = solicitudId => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchSolicitudInited());
      const response = await services.api.getSolicitudById(solicitudId);
      dispatch(fetchSolicitudCompleted(response));
    } catch (error) {
      dispatch(fetchSolicitudFailed(error));
    }
  };
};

/**** REDUCER ****/

const agregarPersonaReducer = handleActions(
  {
    FETCH_SOLICITUD_INITED: state => {
      return { ...state, isLoading: true };
    },
    FETCH_SOLICITUD_COMPLETED: (state, action) => {
      const situacionesOk = [
        "En_Analisis_Asignada",
        "En_Verificacion_Telefonica",
        "Con_Tareas_Previas_A_Analisis",
        "Informes_Pendientes",
        "En_Espera_De_Analisis"
      ];

      const titular = action.payload.personas.find(
        persona => persona.tipoRelacion === "Titular"
      );
      const conyuge = action.payload.personas.find(
        persona => persona.tipoRelacion === "Conyuge"
      );
      const codeudor = action.payload.personas.find(
        persona => persona.tipoRelacion === "Codeudor"
      );
      const conyugeCodeudor = action.payload.personas.find(
        persona => persona.tipoRelacion === "Conyuge_del_Codeudor"
      );

      return {
        ...state,
        nombresTitular: titular.nombre,
        apellidosTitular: titular.apellido,
        fechaCreacion: action.payload.fechaCreacion,
        importeSolicitado: action.payload.importeNetoAPercibir,
        plazoSolicitado: action.payload.plazoSolicitado,
        situacionOp: action.payload.situacion,
        opPermitida: situacionesOk.includes(action.payload.situacion),
        conyuge: conyuge,
        codeudor: codeudor,
        conyugeCodeudor: conyugeCodeudor,
        isLoading: false,
        error: false
      };
    },
    FETCH_SOLICITUD_FAILED: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    }
  },
  {
    nombresTitular: "",
    apellidosTitular: "",
    fechaCreacion: "",
    importeSolicitado: 0,
    plazoSolicitado: 0,
    situacionOp: "",
    opPermitida: false,
    conyuge: null,
    codeudor: null,
    conyugeCodeudor: null,
    isLoading: false,
    error: false
  }
);

export default agregarPersonaReducer;
