import { createActions, handleActions } from "redux-actions";

const {
  business: {
    localidades: {
      fetchLocalidadesInited,
      fetchLocalidadesCompleted,
      fetchLocalidadesFailed
    }
  }
} = createActions({
  business: {
    localidades: {
      fetchLocalidadesInited: () => ({}),
      fetchLocalidadesCompleted: value => ({ value }),
      fetchLocalidadesFailed: error => ({ error })
    }
  }
});

const fetchLocalidades = provinciaId => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchLocalidadesInited());
      const response = await services.api.getProvinciasLocalidades(provinciaId);
      const localidades = response.localidades
        .filter((v, i) => response.localidades.indexOf(v) === i)
        .filter(i => i !== "")
        .map(item => ({
          value: item,
          label: item
        }));
      dispatch(fetchLocalidadesCompleted(localidades));
    } catch (error) {
      console.error(error);
      dispatch(fetchLocalidadesFailed(error));
    }
  };
};

const initialState = {
  localidades: [],
  isLoading: false
};

const localidadSelectorReducer = handleActions(
  {
    [fetchLocalidadesInited]: () => {
      return {
        ...initialState,
        isLoading: true
      };
    },
    [fetchLocalidadesCompleted]: (state, action) => {
      return {
        ...initialState,
        localidades: action.payload.value,
        isLoading: false
      };
    },
    [fetchLocalidadesFailed]: () => {
      return {
        ...initialState,
        isLoading: false
      };
    }
  },
  initialState
);

export default localidadSelectorReducer;
export {
  fetchLocalidadesInited,
  fetchLocalidadesCompleted,
  fetchLocalidadesFailed,
  fetchLocalidades
};
