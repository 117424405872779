import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import { setImporte, clearImporte, setPlazo, clearPlazo } from "./actions";
import CuotasSelector from "~components/bussines/cuotasSelector/CuotasSelector";
import MontoSelector from "~components/bussines/montoSelector/MontoSelector";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

const ImporteYPlazoStep = () => {
  const dispatch = useDispatch();

  const [importeSelected, setImporteSelected] = useState(5000);
  const [plazoSelected, setPlazoSelected] = useState(18);

  useEffect(() => {
    dispatch(clearImporte());
    dispatch(clearPlazo());
  }, [dispatch]);

  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={importeSelected === null || plazoSelected === null}
            label={"Calcular"}
            onClick={() => {
              dispatch(setImporte(importeSelected));
              dispatch(setPlazo(plazoSelected));
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle label={"Elegí importe y plazo"} marginBottom={60} />
      <MontoSelector
        defaultValue={importeSelected}
        marginBottom={30}
        onChange={event => setImporteSelected(event.target.value)}
      />
      <CuotasSelector
        defaultValue={plazoSelected}
        marginBottom={30}
        onChange={event => setPlazoSelected(event.target.value)}
      />
    </ContentSectionWithActions>
  );
};

export default ImporteYPlazoStep;
