import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAptoCredito } from "../../actions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import AptoForm from "./aptoForm";
import NoAptoForm from "./noAptoForm";
import AptoPendienteForm from "./aptoPendienteForm";
import LimiteAlcanzadoForm from "./errores/limiteAlcanzadoForm";
import SolicitudEnAnalisisForm from "./errores/solicitudEnAnalisisForm";
import ErrorForm from "./errores/errorForm";
import ConexionErrorForm from "./errores/conexionErrorForm";

const useReduxSelector = () =>
  useSelector(state => {
    const nuevaSolicitudReducer = state.creditosReducers.nuevaSolicitudReducer;

    return {
      tipoCredito: nuevaSolicitudReducer.tipoCreditoSelected,
      canal: nuevaSolicitudReducer.canalSelected,
      dniTitular: nuevaSolicitudReducer.dni,
      sexoTitular: nuevaSolicitudReducer.sexoSelected,
      estadoCivilTitular: nuevaSolicitudReducer.estadoCivilSelected,
      dniConyuge: nuevaSolicitudReducer.dniConyuge,
      sexoConyuge: nuevaSolicitudReducer.sexoConyuge,
      identidadPersonas: nuevaSolicitudReducer.identidadPersonas,
      aptoCreditoLoading: nuevaSolicitudReducer.aptoCreditoLoading,
      resultadoAptoCredito: nuevaSolicitudReducer.resultadoAptoCredito,
      aptoCreditoError: nuevaSolicitudReducer.aptoCreditoError
    };
  });

const ResultadoEvaluacionStep = () => {
  const dispatch = useDispatch();
  const {
    tipoCredito,
    canal,
    dniTitular,
    sexoTitular,
    estadoCivilTitular,
    dniConyuge,
    sexoConyuge,
    identidadPersonas,
    aptoCreditoLoading,
    resultadoAptoCredito,
    aptoCreditoError
  } = useReduxSelector();

  useEffect(() => {
    if (
      tipoCredito &&
      dniTitular &&
      sexoTitular &&
      identidadPersonas &&
      estadoCivilTitular
    )
      dispatch(
        fetchAptoCredito(
          canal,
          tipoCredito,
          dniTitular,
          sexoTitular,
          identidadPersonas.titular.nombres,
          identidadPersonas.titular.apellido,
          estadoCivilTitular,
          dniConyuge,
          sexoConyuge,
          identidadPersonas.conyuge ? identidadPersonas.conyuge.nombres : null,
          identidadPersonas.conyuge ? identidadPersonas.conyuge.apellido : null
        )
      );
  }, [
    tipoCredito,
    dniTitular,
    sexoTitular,
    identidadPersonas,
    estadoCivilTitular,
    dniConyuge,
    sexoConyuge,
    dispatch
  ]);

  const conexionError = false;

  const solicitudEnAnalisis =
    aptoCreditoError &&
    aptoCreditoError.responseStatus &&
    aptoCreditoError.responseStatus.errorCode === "SolicitudPreviaEnAnalisis";

  const agenciaBloqueada =
    aptoCreditoError &&
    aptoCreditoError.responseStatus &&
    aptoCreditoError.responseStatus.errorCode === "AgenciaBloqueada";

  return (
    <ContentSection>
      {conexionError && <ConexionErrorForm />}
      {!conexionError && aptoCreditoError && (
        <>
          {solicitudEnAnalisis && <SolicitudEnAnalisisForm />}
          {agenciaBloqueada && <LimiteAlcanzadoForm />}
          {!agenciaBloqueada && !solicitudEnAnalisis && <ErrorForm />}
        </>
      )}

      {!conexionError && !aptoCreditoError && resultadoAptoCredito && (
        <>
          {resultadoAptoCredito.aptoObtenido &&
            resultadoAptoCredito.aptoCredito &&
            !resultadoAptoCredito.revisar && <AptoForm />}
          {(!resultadoAptoCredito.aptoObtenido ||
            (resultadoAptoCredito.aptoCredito &&
              resultadoAptoCredito.revisar)) && <AptoPendienteForm />}
          {resultadoAptoCredito.aptoObtenido &&
            !resultadoAptoCredito.aptoCredito && <NoAptoForm />}
        </>
      )}
    </ContentSection>
  );
};

export default ResultadoEvaluacionStep;
