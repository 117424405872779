import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AnioNoFinanciable from "./AnioNoFinanciable";
import InformesPendientes from "./InformesPendientes";
import SituacionNoFinanciable from "./SituacionNoFinanciable";
import Ofertas from "./Ofertas";
import { clearOferta, fetchOfertas } from "../../actions";
const useReduxSelector = () =>
  useSelector(state => {
    const nuevaSolicitudReducer = state.creditosReducers.nuevaSolicitudReducer;
    return {
      isLoading: nuevaSolicitudReducer.ofertasLoading,
      ofertas: nuevaSolicitudReducer.ofertas
    };
  });
const OfertasStep = () => {
  const { isLoading, ofertas } = useReduxSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearOferta());
  }, []);
  useEffect(() => {
    dispatch(fetchOfertas());
  }, []);
  return (
    <>
      {isLoading && "Cargando ofertas"}
      {ofertas && ofertas.resultado === "Aprobado" && (
        <Ofertas ofertas={ofertas.ofertas} />
      )}
      {ofertas && ofertas.resultado === "Anio_No_Financiable" && (
        <AnioNoFinanciable />
      )}
      {ofertas && ofertas.resultado === "Informes_Pendientes" && (
        <InformesPendientes />
      )}
      {ofertas && ofertas.resultado === "Situacion_No_Financiable" && (
        <SituacionNoFinanciable />
      )}
    </>
  );
};

export default OfertasStep;
