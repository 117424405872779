import { clearOfertas } from "../../ofertasForm/js/actions";
import { clearCotizacionesSeguros } from "../../seguroForm/js/actions";

export const DIRECCIONFORM_ISLOADING = "DIRECCIONFORM_ISLOADING";
export const DIRECCIONFORM_BLOCKEDFORM = "DIRECCIONFORM_BLOCKEDFORM";
export const DIRECCIONFORM_SUBMITFORM = "DIRECCIONFORM_SUBMITFORM";
export const DIRECCIONFORM_INIT = "DIRECCIONFORM_INIT";
export const DIRECCIONFORM_LOAD = "DIRECCIONFORM_LOAD";
export const DIRECCIONFORM_SELECTPROVINCIA = "DIRECCIONFORM_SELECTPROVINCIA";
export const DIRECCIONFORM_SELECTLOCALIDAD = "DIRECCIONFORM_SELECTLOCALIDAD";
export const DIRECCIONFORM_SELECTCODIGOPOSTAL =
  "DIRECCIONFORM_SELECTCODIGOPOSTAL";
export const SET_DIRECCIONFORM_COMMITED = "SET_DIRECCIONFORM_COMMITED";
export const direccionFormIsLoading = loading => ({
  type: DIRECCIONFORM_ISLOADING,
  payload: {
    isLoading: loading
  }
});

export const direccionFormInit = () => ({
  type: DIRECCIONFORM_INIT
});

export const direccionFormLoad = data => ({
  type: DIRECCIONFORM_LOAD,
  payload: {
    data: data
  }
});

export const blockedForm = disabled => ({
  type: DIRECCIONFORM_BLOCKEDFORM,
  payload: {
    disabled: disabled
  }
});

export const selectProvincia = selected => {
  return {
    type: DIRECCIONFORM_SELECTPROVINCIA,
    payload: {
      provinciaSelected: selected
    }
  };
};

export const selectLocalidad = selected => {
  return {
    type: DIRECCIONFORM_SELECTLOCALIDAD,
    payload: {
      localidadSelected: selected
    }
  };
};

export const selectCodigoPostal = selected => {
  return {
    type: DIRECCIONFORM_SELECTCODIGOPOSTAL,
    payload: {
      codigoPostalSelected: selected
    }
  };
};

export const submit = data => ({
  type: DIRECCIONFORM_SUBMITFORM,
  payload: {
    ...data.formData
  },
  analytics: {
    eventName: "solicitud-direccion-guardar",
    eventData: data
  }
});

export const setDireccionFormCommited = commited => {
  return {
    type: SET_DIRECCIONFORM_COMMITED,
    payload: {
      commited: commited
    }
  };
};

export function submitForm(formData, onComplete) {
  return function(dispatch, getState, services) {
    return new Promise(function(resolve, reject) {
      dispatch(direccionFormIsLoading(true));
      services.api
        .postSolicitudDomicilioTitular(formData)
        .then(() => {
          if (
            getState().direccionForm.selectedPostCode !== formData.CodigoPostal
          ) {
            dispatch(clearOfertas());
            dispatch(clearCotizacionesSeguros());
          }
          const tipoCredito = getState().solicitudCreditoUI.tipoCredito;
          dispatch(direccionFormIsLoading(false));
          dispatch(submit({ formData, tipoCredito }));
          if (onComplete) onComplete();
          resolve();
        })
        .catch(error => {
          console.error(error);

          services.errorHandler.logRemoteError(
            error,
            submitForm,
            [formData, onComplete],
            getState()
          );
          dispatch(services.errorHandler.checkErrorAction(error));
          dispatch(direccionFormIsLoading(false));
          reject();
        });
    });
  };
}
