import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

import FileUpload from "~components/styled/fileUpload/FileUpload";
import { setComprobante, clearComprobante } from "../actions";

const ComprobanteStep = () => {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState(null);

  useEffect(() => {
    dispatch(clearComprobante());
  }, [dispatch]);

  useEffect(() => {
    let timer = null;
    if (fileName)
      timer = setTimeout(() => dispatch(setComprobante(fileName)), 1000);

    return function cleanup() {
      clearTimeout(timer);
    };
  }, [fileName]);

  return (
    <ContentSection>
      <PrimaryTitle
        label={"Adjuntá una foto del comprobante"}
        marginBottom={60}
      />
      <FileUpload
        onAdded={event => setFileName(event.target.value)}
        onRemoved={event => setFileName(null)}
        acceptImage={true}
        acceptPdf={false}
      />
    </ContentSection>
  );
};

export default ComprobanteStep;
