import localRepository from "../../../../../localRepository";
import environment from "../../../../../environment";

import { createActions, handleActions } from "redux-actions";
const appData = environment.terminosYCondiciones;

const {
  terminosYCondiciones: {
    checkTerminosYCondicionesInited,
    checkTerminosYCondicionesCompleted,
    checkTerminosYCondicionesFailed,
    aceptarTerminosInited,
    aceptarTerminosCompleted,
    aceptarTerminosFailed
  }
} = createActions({
  terminosYCondiciones: {
    checkTerminosYCondicionesInited: () => ({}),
    checkTerminosYCondicionesCompleted: value => ({ value }),
    checkTerminosYCondicionesFailed: () => ({}),
    aceptarTerminosInited: () => ({}),
    aceptarTerminosCompleted: value => ({ value }),
    aceptarTerminosFailed: () => ({})
  }
});

export function checkTerminosYCondiciones(user) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(checkTerminosYCondicionesInited());
      const mostrarTerminos = await getMostrarTerminos(user, services);
      dispatch(checkTerminosYCondicionesCompleted(mostrarTerminos));
    } catch (error) {
      console.error(error);
      dispatch(services.errorHandler.checkErrorAction(error));
      services.errorHandler.logRemoteError(
        error,
        checkTerminosYCondiciones,
        [user],
        getState()
      );
      dispatch(checkTerminosYCondicionesFailed(error));
    }
  };
}

export const getMostrarTerminos = async (user, services) => {
  try {
    let response = await services.api.getAceptacion(appData.appId, user);
    let mostrarTerminos = !(response.version === appData.version);
    if (response.app && response.version)
      localRepository.terminosYCondiciones.set({
        app: response.app,
        version: response.version,
        idUsuario: user.euroUserId,
        name: user.preferred_username
      });

    return mostrarTerminos;
  } catch (error) {
    throw error;
  }
};

export function aceptarTerminos(idUsuario, userName) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(aceptarTerminosInited());
      const data = {
        app: appData.appId,
        version: appData.version
      };
      await services.api.postAceptarTerminos(data);

      localRepository.terminosYCondiciones.set({
        app: appData.appId,
        version: appData.version,
        idUsuario: idUsuario,
        name: userName
      });
      dispatch(aceptarTerminosCompleted(false));
    } catch (error) {
      console.error(error);
      localRepository.terminosYCondiciones.set({
        app: appData.appId,
        version: appData.version,
        idUsuario: idUsuario,
        name: userName
      });
      services.errorHandler.logRemoteError(
        error,
        aceptarTerminos,
        [idUsuario],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(aceptarTerminosFailed(error));
    }
  };
}

const terminosYCondicionesReducer = handleActions(
  {
    [checkTerminosYCondicionesInited]: (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [checkTerminosYCondicionesCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        mostrarTerminos: action.payload.value
      };
    },
    [checkTerminosYCondicionesFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true,
        mostrarTerminos: false
      };
    },
    [aceptarTerminosInited]: (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [aceptarTerminosCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        mostrarTerminos: action.payload.value
      };
    },
    [aceptarTerminosFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true,
        mostrarTerminos: false
      };
    }
  },
  {
    mostrarTerminos: false,
    isLoading: false,
    error: false
  }
);

export default terminosYCondicionesReducer;
