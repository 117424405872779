import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentSection from "~components/styled/contentSection/ContentSection";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import PolizaSelector from "./PolizaSelector";
import { clearPoliza, fetchCotizaciones } from "../../actions";

const useReduxSelector = () =>
  useSelector(state => {
    const nuevaSolicitudReducer = state.creditosReducers.nuevaSolicitudReducer;
    return {
      isLoading: nuevaSolicitudReducer.polizasLoading,
      cotizaciones: nuevaSolicitudReducer.cotizaciones
    };
  });

const SegurosStep = () => {
  const dispatch = useDispatch();
  const { isLoading, cotizaciones } = useReduxSelector();
  useEffect(() => {
    dispatch(clearPoliza());
  }, []);

  useEffect(() => {
    dispatch(fetchCotizaciones());
  }, []);

  return (
    <ContentSection>
      <PrimaryTitle label={"Elegí un seguro"} />
      {cotizaciones && <PolizaSelector cotizaciones={cotizaciones} />}
    </ContentSection>
  );
};

export default SegurosStep;
