import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import pxToRem from "~libs/pxToRem";
import fileService from "~libs/fileService";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "Nunito",
    fontSize: pxToRem(16),
    marginBottom: 0,
    border: "1px dashed hsl(0, 0%, 74%)",
    borderRadius: 5,
    padding: 10,
    "& .filepond--file-action-button": {
      cursor: "pointer"
    },
    "& .filepond--drop-label": {
      color: "hsl(0, 0%, 74%)"
    },
    "& .filepond--root .filepond--drop-label": { minHeight: "3em" },
    "& .filepond--drop-label label": { fontSize: pxToRem(16) },
    "& .filepond--drop-label.filepond--drop-label label": {
      padding: "0.5em 0"
    },
    "& .filepond--panel-root": { backgroundColor: "transparent" },
    "& .filepond--image-preview-overlay-idle": {
      color: "rgba(40, 40, 40, 0.85)"
    },
    "& .filepond--image-preview-overlay-success": {
      color: "hsl(189, 55%, 47%)"
    },
    "& .filepond--image-preview-overlay-failure": {
      color: "rgb(196, 78, 71)"
    },
    "@media (min-width: 650px)": {
      "& .filepond--item": {
        width: "calc(33.33% - 0.5em)"
      }
    }
  }
}));

const directory = "temp";
const fileServiceTemp = fileService(directory);

const FileUpload = ({
  multiple,
  acceptPdf = true,
  acceptImage = true,
  onAdded,
  onRemoved
}) => {
  const [uploadedFileName, setUploadedFileName] = useState(null);

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.mobile);

  const mobileIdleLabel =
    'Tocá <span class="filepond--label-action">acá</span> para sacar una foto';
  const desktopIdleLabel =
    'Arrastrá o <span class="filepond--label-action">Seleccioná</span> una foto';

  let acceptedFileTypes = [];
  if (acceptImage) acceptedFileTypes.push("image/*");
  if (acceptPdf) acceptedFileTypes.push("application/pdf");

  return (
    <FilePond
      acceptedFileTypes={acceptedFileTypes.join(",")}
      allowMultiple={multiple}
      labelIdle={isMobile ? mobileIdleLabel : desktopIdleLabel}
      labelFileProcessing={"Cargando"}
      labelFileProcessingComplete={"Carga completa"}
      labelFileProcessingError={"Error procesando"}
      labelTapToUndo={"Tap para deshacer"}
      labelTapToCancel={"Tap para cancelar"}
      labelTapToRetry={"Tap para reintentar"}
      className={classes.root}
      server={{
        process: async (
          fieldName,
          file,
          metadata,
          load,
          error,
          progress,
          abort
        ) => {
          try {
            const uploadedFileName = await fileServiceTemp.upload(
              file,
              load,
              progress
            );
            setUploadedFileName(uploadedFileName);
          } catch (e) {
            console.error(e);
            error(e.message);
          }
        },
        revert: async (serverId, load, error) => {
          try {
            load();
            await fileServiceTemp.delete(serverId);
          } catch (e) {
            console.error(e);
            error(e);
          }
        }
      }}
      onprocessfile={(error, file) => {
        if (!error && onAdded) {
          onAdded({
            target: {
              value: uploadedFileName
            }
          });
        }
      }}
      onremovefile={(error, file) => {
        if (!error && onRemoved) {
          onRemoved({
            target: {
              value: uploadedFileName
            }
          });
        }
      }}
    />
  );
};

export default FileUpload;
