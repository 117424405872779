import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setDireccionAgencia, clearDireccionAgencia } from "../actions";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

const ENTER_KEY = 13;

const DireccionStep = () => {
  const dispatch = useDispatch();
  const [direccion, setDireccion] = useState("");

  useEffect(() => {
    dispatch(clearDireccionAgencia());
  }, [dispatch]);
  const onKeyDown = event => {
    if (event.keyCode === ENTER_KEY && direccion)
      dispatch(setDireccionAgencia(direccion));
  };
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={!direccion}
            label={"Continuar"}
            onClick={() => {
              dispatch(setDireccionAgencia(direccion));
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle
        label={"Ingresá la dirección de la agencia"}
        marginBottom={60}
      />
      <TextField
        style={{ marginBottom: 9 }}
        fullWidth={true}
        onFocus={event => event.target.select()}
        value={direccion}
        onKeyDown={onKeyDown}
        autoFocus
        onChange={event => setDireccion(event.target.value)}
        placeholder="Ej: Calle 1234"
      ></TextField>
    </ContentSectionWithActions>
  );
};

export default DireccionStep;
