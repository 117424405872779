import { createAction, handleActions } from "redux-actions";

const segurosCotizarDatosVehiculoClear = createAction(
  "SEGUROS_COTIZAR_VEHICULO_CLEAR",
  vehiculoMarcaSelected => ({ vehiculoMarcaSelected })
);

const segurosCotizarVehiculoMarcaSelect = createAction(
  "SEGUROS_COTIZAR_VEHICULO_MARCA_SELECT",
  vehiculoMarcaSelected => ({ vehiculoMarcaSelected })
);

const segurosCotizarVehiculoAnioSelect = createAction(
  "SEGUROS_COTIZAR_VEHICULO_ANIO_SELECT",
  vehiculoAnioSelected => ({ vehiculoAnioSelected })
);

const segurosCotizarVehiculoModeloSelect = createAction(
  "SEGUROS_COTIZAR_VEHICULO_MODELO_SELECT",
  vehiculoModeloSelected => ({ vehiculoModeloSelected })
);

const segurosCotizarFormaPagoSelect = createAction(
  "SEGUROS_COTIZAR_FORMA_PAGO_SELECT",
  formaPagoSelected => ({ formaPagoSelected })
);

const segurosCotizarVehiculoUsoSelect = createAction(
  "SEGUROS_COTIZAR_VEHICULO_USO_SELECT",
  vehiculoUsoSelected => ({ vehiculoUsoSelected })
);

const segurosCotizarVehiculoGNCSelect = createAction(
  "SEGUROS_COTIZAR_VEHICULO_GNC_SELECT",
  vehiculoGNCSelected => ({ vehiculoGNCSelected })
);

const segurosCotizarProvinciaSelect = createAction(
  "SEGUROS_COTIZAR_PROVINCIA_SELECT",
  provinciaSelected => ({ provinciaSelected })
);

const segurosCotizarCodigoPostalSelect = createAction(
  "SEGUROS_COTIZAR_CODIGO_POSTAL_SELECT",
  codigoPostalSelected => ({ codigoPostalSelected })
);

const initialState = {
  vehiculoMarcaSelected: null,
  vehiculoAnioSelected: null,
  vehiculoModeloSelected: null,
  vehiculoUsoSelected: null,
  vehiculoGNCSelected: null,
  provinciaSelected: null,
  codigoPostalSelected: null,
  formaPagoSelected: null,

  formValid: false
};

const validateFields = state => {
  const {
    vehiculoMarcaSelected,
    vehiculoAnioSelected,
    vehiculoModeloSelected,
    provinciaSelected,
    codigoPostalSelected,
    vehiculoUsoSelected,
    vehiculoGNCSelected,
    formaPagoSelected
  } = state;

  const allCompleted =
    vehiculoMarcaSelected &&
    vehiculoAnioSelected &&
    vehiculoModeloSelected &&
    provinciaSelected &&
    codigoPostalSelected &&
    vehiculoUsoSelected &&
    vehiculoGNCSelected &&
    formaPagoSelected;

  return allCompleted ? true : false;
};

const datosVehiculoReducer = handleActions(
  {
    [segurosCotizarDatosVehiculoClear]: (state, action) => {
      return { ...initialState };
    },
    [segurosCotizarVehiculoMarcaSelect]: (state, action) => {
      const resultState = {
        ...state,
        vehiculoModeloSelected: null,
        vehiculoMarcaSelected: action.payload.vehiculoMarcaSelected
          ? { ...action.payload.vehiculoMarcaSelected }
          : null
      };
      const formValid = validateFields(resultState);
      return { ...resultState, formValid };
    },
    [segurosCotizarVehiculoAnioSelect]: (state, action) => {
      const resultState = {
        ...state,
        vehiculoAnioSelected: action.payload.vehiculoAnioSelected
          ? { ...action.payload.vehiculoAnioSelected }
          : null
      };
      const formValid = validateFields(resultState);
      return { ...resultState, formValid };
    },
    [segurosCotizarVehiculoModeloSelect]: (state, action) => {
      const resultState = {
        ...state,
        vehiculoModeloSelected: action.payload.vehiculoModeloSelected
          ? { ...action.payload.vehiculoModeloSelected }
          : null
      };
      const formValid = validateFields(resultState);
      return { ...resultState, formValid };
    },
    [segurosCotizarFormaPagoSelect]: (state, action) => {
      const resultState = {
        ...state,
        formaPagoSelected: action.payload.formaPagoSelected
          ? { ...action.payload.formaPagoSelected }
          : null
      };
      const formValid = validateFields(resultState);
      return { ...resultState, formValid };
    },
    [segurosCotizarVehiculoUsoSelect]: (state, action) => {
      const resultState = {
        ...state,
        vehiculoUsoSelected: action.payload.vehiculoUsoSelected
          ? { ...action.payload.vehiculoUsoSelected }
          : null
      };
      const formValid = validateFields(resultState);
      return { ...resultState, formValid };
    },
    [segurosCotizarVehiculoGNCSelect]: (state, action) => {
      const resultState = {
        ...state,
        cotizacionId: null,
        vehiculoGNCSelected: action.payload.vehiculoGNCSelected
          ? { ...action.payload.vehiculoGNCSelected }
          : null
      };
      const formValid = validateFields(resultState);
      return { ...resultState, formValid };
    },
    [segurosCotizarProvinciaSelect]: (state, action) => {
      const resultState = {
        ...state,
        cotizacionId: null,
        codigoPostalSelected: null,
        provinciaSelected: action.payload.provinciaSelected
          ? { ...action.payload.provinciaSelected }
          : null
      };
      const formValid = validateFields(resultState);
      return { ...resultState, formValid };
    },
    [segurosCotizarCodigoPostalSelect]: (state, action) => {
      const resultState = {
        ...state,
        cotizacionId: null,
        codigoPostalSelected: action.payload.codigoPostalSelected
          ? { ...action.payload.codigoPostalSelected }
          : null
      };
      const formValid = validateFields(resultState);
      return { ...resultState, formValid };
    }
  },
  initialState
);

export default datosVehiculoReducer;
export {
  segurosCotizarVehiculoMarcaSelect,
  segurosCotizarVehiculoAnioSelect,
  segurosCotizarVehiculoModeloSelect,
  segurosCotizarFormaPagoSelect,
  segurosCotizarVehiculoUsoSelect,
  segurosCotizarVehiculoGNCSelect,
  segurosCotizarProvinciaSelect,
  segurosCotizarCodigoPostalSelect,
  segurosCotizarDatosVehiculoClear
};
