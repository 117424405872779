import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useLocationRef from "~components/app/useLocationRef";
import { useAuth0 } from "~componentes/Auth0/useAuth";

import ProcessLayout from "~components/styled/layouts/processLayout/ProcessLayout";

import CuiStep from "./steps/CuiStep";
import NombreAgenciaStep from "./steps/NombreAgenciaStep";
import NombreTitularStep from "./steps/NombreTitularStep";
import ProvinciaStep from "./steps/ProvinciaStep";
import LocalidadStep from "./steps/LocalidadStep";
import CodigoPostalStep from "./steps/CodigoPostalStep";
import DireccionStep from "./steps/DireccionStep";
import TipoAgenciaStep from "./steps/TipoAgenciaStep";
import TipoVehiculoStep from "./steps/TipoVehiculoStep";
import NombreContactoStep from "./steps/NombreContactoStep";
import TelefonoContactoStep from "./steps/TelefonoContactoStep";
import TerminosYCondicionesStep from "./steps/TerminosYCondicionesStep";
import FinalStep from "./steps/FinalStep";

import { clear } from "./actions";

const steps = {
  cuiTitular: "cuiTitular",
  nombreAgencia: "nombreAgencia",
  nombreTitular: "nombreTitular",
  provincia: "provincia",
  localidad: "localidad",
  codigoPostal: "codigoPostal",
  direccion: "direccion",
  tipoAgencia: "tipoAgencia",
  tipoVehiculo: "tipoVehiculo",
  nombreContacto: "nombreContacto",
  telefonoContacto: "telefonoContacto",
  terminosYCondiciones: "terminosYCondiciones",
  finalStep: "final"
};
const BASE_URL = "/agencias/signup";
const FIRST_STEP = steps.cuiTitular;

const useReduxSelector = () =>
  useSelector(state => {
    const signupAgenciaReducer =
      state.authenticationReducers.signupAgenciaReducer;

    return {
      cuiTitular: signupAgenciaReducer.cuiTitular,
      nombreAgencia: signupAgenciaReducer.nombreAgencia,
      nombreTitular: signupAgenciaReducer.nombreTitular,
      provinciaAgencia: signupAgenciaReducer.provinciaAgencia,
      localidadAgencia: signupAgenciaReducer.localidadAgencia,
      codigoPostalAgencia: signupAgenciaReducer.codigoPostalAgencia,
      direccionAgencia: signupAgenciaReducer.direccionAgencia,
      tipoAgencia: signupAgenciaReducer.tipoAgencia,
      tipoVehiculo: signupAgenciaReducer.tipoVehiculo,
      nombreContacto: signupAgenciaReducer.nombreContacto,
      telefonoContacto: signupAgenciaReducer.telefonoContacto,
      terminosYCondiciones: signupAgenciaReducer.terminosYCondiciones,
      isForward: signupAgenciaReducer.isForward,
      isValidacionIdentidadEnabled:
        state.featureFlagReducer.featureFlags["validacion-identidad"]
    };
  });

const SignupAgencia = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { logout } = useAuth0();
  const { position = FIRST_STEP } = useParams();

  useEffect(() => {
    dispatch(clear());

    return function cleanup() {
      dispatch(clear());
    };
  }, [dispatch]);

  const currentState = useReduxSelector();
  const { isValidacionIdentidadEnabled } = useReduxSelector();
  const currentStep = useCurrentStep(currentState, position);
  return (
    <ProcessLayout
      title={"Cuenta Agenciero"}
      progress={(currentStep.order * 100) / Object.keys(steps).length} // porcentage (0-100)
      onBackClick={() => {
        history.goBack();
      }}
      onCloseClick={() => {
        isValidacionIdentidadEnabled ? logout() : history.push("/");
      }}
      isForward={currentState.isForward}
    >
      {position === steps.cuiTitular && <CuiStep />}
      {position === steps.nombreAgencia && <NombreAgenciaStep />}
      {position === steps.nombreTitular && <NombreTitularStep />}
      {position === steps.provincia && <ProvinciaStep />}
      {position === steps.localidad && <LocalidadStep />}
      {position === steps.codigoPostal && <CodigoPostalStep />}
      {position === steps.direccion && <DireccionStep />}
      {position === steps.tipoAgencia && <TipoAgenciaStep />}
      {position === steps.tipoVehiculo && <TipoVehiculoStep />}
      {position === steps.nombreContacto && <NombreContactoStep />}
      {position === steps.telefonoContacto && <TelefonoContactoStep />}
      {position === steps.terminosYCondiciones && <TerminosYCondicionesStep />}
      {position === steps.finalStep && <FinalStep />}
    </ProcessLayout>
  );
};

export default SignupAgencia;

const useCurrentStep = (currentState, position) => {
  const history = useHistory();
  const locationRef = useLocationRef();
  const validSteps = getValidSteps(currentState);
  const isValidStep = validSteps.find(x => x.index === position) ? true : false;
  const currentStep = validSteps.slice().pop();

  useEffect(() => {
    const stepUrl = `${BASE_URL}`;
    // if not valid step, go to first step.
    if (!isValidStep) {
      history.replace(stepUrl);
    }
  }, [history, isValidStep]);

  useEffect(() => {
    const stepUrl = `${BASE_URL}/${currentStep.index}`;
    const isThisUrl = locationRef.current.pathname === stepUrl;
    const isFirstStep =
      locationRef.current.pathname === `${BASE_URL}` &&
      currentStep.index === FIRST_STEP;
    if (!isThisUrl && !isFirstStep && isValidStep) history.push(stepUrl);
  }, [isValidStep, history, currentStep.index, locationRef]);
  return currentStep;
};

const getValidSteps = ({
  cuiTitular,
  nombreAgencia,
  nombreTitular,
  provinciaAgencia,
  localidadAgencia,
  codigoPostalAgencia,
  direccionAgencia,
  tipoAgencia,
  tipoVehiculo,
  nombreContacto,
  telefonoContacto,
  terminosYCondiciones
}) => {
  const cuiStep = step(steps.cuiTitular, !cuiTitular);
  const agenciaStep = step(steps.nombreAgencia, !nombreAgencia);
  const titularStep = step(steps.nombreTitular, !nombreTitular);
  const provinciaStep = step(steps.provincia, !provinciaAgencia);
  const localidadStep = step(steps.localidad, !localidadAgencia);
  const codigoPostalStep = step(steps.codigoPostal, !codigoPostalAgencia);
  const direccionStep = step(steps.direccion, !direccionAgencia);
  const tipoAgenciaStep = step(steps.tipoAgencia, !tipoAgencia);
  const tipoVehiculoStep = step(steps.tipoVehiculo, !tipoVehiculo);
  const nombreContactoStep = step(steps.nombreContacto, !nombreContacto);
  const telefonoContactoStep = step(steps.telefonoContacto, !telefonoContacto);
  const terminosYCondicionesStep = step(
    steps.terminosYCondiciones,
    !terminosYCondiciones
  );
  const finalStep = step(steps.finalStep, true);
  //set order of steps
  cuiStep
    .next(agenciaStep)
    .next(titularStep)
    .next(provinciaStep)
    .next(localidadStep)
    .next(codigoPostalStep)
    .next(direccionStep)
    .next(tipoAgenciaStep)
    .next(tipoVehiculoStep)
    .next(nombreContactoStep)
    .next(telefonoContactoStep)
    .next(terminosYCondicionesStep)
    .next(finalStep);
  return cuiStep.run();
};

const step = (index, isThisStep) => {
  let next = null;
  let order = 1;

  return {
    index,
    order,
    next(step) {
      if (step) next = { ...step, order: this.order + 1 };
      return next;
    },
    run() {
      return isThisStep ? [this] : [this, ...(next && next.run())];
    }
  };
};
