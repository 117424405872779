import React from "react";
import { useAuth0 } from "~componentes/Auth0/useAuth";

import ClienteFinalRoutes from "~components/app/clienteFinal/Routes";
import AgencieroRoutes from "~components/app/agenciero/Routes";
import InternoRoutes from "~components/app/interno/Routes";

const Routes = () => {
  const { authState } = useAuth0();
  const currentUserType = authState.user.userType;

  return (
    <>
      {currentUserType === "ClienteFinal" && <ClienteFinalRoutes />}
      {currentUserType === "Agenciero" && <AgencieroRoutes />}
      {currentUserType === "Interno" && <InternoRoutes />}
    </>
  );
};

export default Routes;
