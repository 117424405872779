import React from "react";
import { useHistory } from "react-router-dom";
import NavbarLayout from "~components/styled/layouts/navbarLayout/NavbarLayout";
import ContentSection from "~components/styled/contentSection/ContentSection";

import SingleButton from "~components/styled/toolboxButtons/SingleButton";
import addIcon from "~assets/icons/ic_nuevo.svg";
import addImage from "~assets/ilustrations/image-nueva-solicitud.svg";

import {
  ButtonGroup,
  ButtonGroupLabel,
  Button
} from "~components/styled/toolboxButtons/ButtonGroup";
import asegurarIcon from "~assets/icons/ic_asegurar_color.svg";
import calcularIcon from "~assets/icons/ic_calcular_color.svg";
import cotizasIcon from "~assets/icons/ic_cotizas_color.svg";

import ListadoSolicitudes from "~components/app/interno/creditos/listadoSolicitudes/ListadoSolicitudes";

const Inicio = () => {
  const history = useHistory();

  return (
    <NavbarLayout>
      <ContentSection>
        <SingleButton
          onClick={() => history.push("/creditos/solicitudes/nueva")}
          label={"Evaluar Comprador"}
          subLabel={"Nueva Solicitud"}
          image={addImage}
          icon={addIcon}
          marginBottom={20}
        />
        <ButtonGroup marginBottom={35}>
          <Button onClick={() => history.push("/creditos/calcular")}>
            <ButtonGroupLabel
              label={"Calcular"}
              subLabel={"crédito"}
              icon={calcularIcon}
            />
          </Button>
          <Button onClick={() => history.push("/autos")}>
            <ButtonGroupLabel
              label={"Cotizar"}
              subLabel={"un auto"}
              icon={cotizasIcon}
            />
          </Button>
          <Button onClick={() => history.push("/seguros")}>
            <ButtonGroupLabel
              label={"Asegurar"}
              subLabel={"un auto"}
              icon={asegurarIcon}
            />
          </Button>
        </ButtonGroup>
        <ListadoSolicitudes />
      </ContentSection>
    </NavbarLayout>
  );
};

export default Inicio;
