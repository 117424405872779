import React from "react";
import { Route } from "react-router-dom";

import OldLayout from "~componentes/app/OldLayout";
import {
  AsyncSolicitudTabs,
  AsyncSolicitudCredito,
  AsyncListaSolicitudes,
  AsyncSolicitudAutoprenda
} from "~componentes/app/LazyComponents";
import NuevaSolicitud from "~components/app/interno/creditos/nuevaSolicitud/NuevaSolicitud";
import CalcularCredito from "~components/app/interno/creditos/calcularCredito/CalcularCredito";
import NewSolicitud from "~components/app/interno/creditos/newSolicitud/NuevaSolicitud";

const CreditosRoutes = isNuevaSolicitudEnabled => {
  return [
    <Route key="creditos-1" path="/creditos/calcular/:position?">
      <CalcularCredito />
    </Route>,
    ...(isNuevaSolicitudEnabled
      ? [
          <Route
            key="creditos-5"
            exact
            path="/creditos/nuevasolicitud/:position?"
          >
            <NewSolicitud />
          </Route>
        ]
      : []),
    <Route key="creditos-2" exact path="/creditos/solicitudes/nueva">
      <NuevaSolicitud />
    </Route>,
    <Route key="creditos-3" path="/creditos">
      <OldLayout>
        <AsyncSolicitudCredito />
      </OldLayout>
    </Route>,
    <Route key="creditos-4" path="/autoprenda">
      <OldLayout>
        <AsyncSolicitudAutoprenda />
      </OldLayout>
    </Route>,

    <Route key="creditos-6" exact path="/solicitudes">
      <OldLayout>
        <AsyncListaSolicitudes />
      </OldLayout>
    </Route>,
    <Route key="creditos-7" path="/solicitudes/:idSolicitud">
      <OldLayout>
        <AsyncSolicitudTabs />
      </OldLayout>
    </Route>
  ];
};
export default CreditosRoutes;
