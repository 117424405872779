import React from "react";
import PropTypes from "prop-types";
import MainMenu from "../../componentes/menu/MainMenu";
import IconButton from "material-ui/IconButton";
import Drawer from "material-ui/Drawer";
import MenuIcon from "material-ui/svg-icons/navigation/menu";
import BackIcon from "material-ui/svg-icons/navigation/arrow-back";
import { white } from "material-ui/styles/colors";
import { Link } from "react-router-dom";
import TopBarTitle from "./TopBarTitle";

import { makeStyles, useTheme } from "../../libs/styles";

const useStyles = makeStyles(theme => ({
  button: {
    transition: "all .5s linear !important",
    position: "absolute !important",
    bottom: 10,
    left: 5
  },
  menuButton: {
    left: "auto",
    right: 5
  },
  menuButtonHiddenOnNoMobile: {
    "@media (min-width: 994px)": {
      display: "none !important"
    }
  },
  backButton: {
    transform: "rotate(0deg) scale(1)",
    opacity: 1
  },
  backButtonHidden: {
    transform: "rotate(-180deg) scale(0.5)",
    opacity: 0
  },
  backAndMenuGroup: {
    display: "inline-block",
    width: 50
  },
  backAndMenuGroupHiddenOnNoMobile: {
    "@media (min-width: 994px)": {
      display: "none"
    }
  }
}));

class TopBar extends React.Component {
  state = {
    width: window.innerWidth
  };

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  logOutUser = () => {
    this.props.logOutUser();
  };

  handleTouchTapNotifications = event => {
    event.preventDefault();
    this.props.toggleOpenNotificationBar(true);
  };

  toggleMenu = event => {
    event.preventDefault();
    this.props.toggleOpenMainMenu();
  };

  createLeftButton = () => {
    const classes = useStyles();

    let menuButton = classes.button;
    let backButton = classes.button;
    if (this.props.topBar.backUrl) {
      menuButton = [classes.button, classes.menuButton].join(" ");
      backButton = [classes.button, classes.backButton].join(" ");
    } else {
      backButton = [classes.button, classes.backButtonHidden].join(" ");
    }

    const menuGroupClassName = this.props.topBar.backUrl
      ? classes.backAndMenuGroup
      : [
          classes.backAndMenuGroup,
          classes.backAndMenuGroupHiddenOnNoMobile
        ].join(" ");

    return (
      <div className={menuGroupClassName}>
        <Link
          to={{
            pathname: this.props.topBar.backUrl,
            state: { fromTopBar: true }
          }}
        >
          <IconButton
            className={backButton}
            iconStyle={{ height: 24, width: 24, marginTop: 2 }}
          >
            <BackIcon color={"#663ea8"} />
          </IconButton>
        </Link>
        <IconButton
          className={[menuButton, classes.menuButtonHiddenOnNoMobile].join(" ")}
          iconStyle={{ height: 26, width: 26, marginTop: 1 }}
          onClick={event => this.toggleMenu(event)}
        >
          <MenuIcon color={"#663ea8"} />
        </IconButton>
      </div>
    );
  };

  render() {
    const hide = this.props.topBar.hide;

    const theme = useTheme();
    const iconButton = this.createLeftButton();
    const { width } = this.state;
    const isMobile = width <= 992;
    const hasBack = this.props.topBar.backUrl || isMobile ? true : false;

    return (
      <React.Fragment>
        <div
          style={{
            zIndex: 99,
            position: "fixed",
            maxHeight: 64,
            height: 64,
            backgroundColor: white,
            boxShadow: "0 1px 1px 0 #00000029",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingLeft: 10,
            paddingRight: 10
          }}
        >
          {iconButton}
          <React.Fragment>
            {!hasBack && (
              <Link to="/">
                <img
                  style={{
                    maxHeight: 64,
                    width: 48,
                    display: "block",
                    maxWidth: "100%",
                    height: "auto"
                  }}
                  src={theme.logoBlanco}
                  alt="logo"
                />
              </Link>
            )}
            <div
              style={{
                width: "100%"
              }}
            >
              <TopBarTitle
                title={hide ? "" : this.props.topBar.currentTitle}
                titleMenuItems={this.props.topBar.titleMenuItems}
                isMobile={isMobile}
              />
            </div>
          </React.Fragment>
        </div>
        <Drawer
          docked={false}
          width={300}
          open={this.props.topBar.openMainMenu}
          variant={this.props.topBar.variant}
          onRequestChange={open => this.props.toggleOpenMainMenu()}
        >
          <MainMenu />
        </Drawer>
      </React.Fragment>
    );
  }
}

TopBar.propTypes = {
  logOutUser: PropTypes.func.isRequired,
  toggleOpenNotificationBar: PropTypes.func.isRequired,
  toggleOpenMainMenu: PropTypes.func.isRequired,
  topBar: PropTypes.any
};

export default TopBar;
