import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentSection from "~components/styled/contentSection/ContentSection";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import ConceptoPagoSelector from "~components/bussines/conceptoPagoSelector/ConceptoPagoSelector";

import { setConceptoPago, clearConceptoPago } from "../actions";

const ConceptoPagoStep = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearConceptoPago());
  }, [dispatch]);

  return (
    <ContentSection>
      <PrimaryTitle label={"¿Qué pagaste?"} />
      <ConceptoPagoSelector
        onSelected={event => dispatch(setConceptoPago(event.target.value))}
      />
    </ContentSection>
  );
};

export default ConceptoPagoStep;
