import {
  SOLICITUDCREDITO_CHANGEID,
  SOLICITUDCREDITO_INIT,
  SOLICITUDCREDITO_ISLOADING,
  SOLICITUDCREDITO_LOAD,
  SOLICITUDCREDITO_FINISH,
  SOLICITUDCREDITO_FINISHCOMPONENT,
  SOLICITUDCREDITO_LOADDATA,
  SOLICITUDCREDITO_ACTIVEFORMCHANGED,
  SET_TIPOCREDITO_AUTOPRENDA
} from "./actions";

const initialState = {
  isLoading: false,
  rootMenuItem: "creditos",
  activeForm: null,
  path: ``,
  nextUrl: ``,
  backUrl: ``,
  solicitudId: 0,
  finished: false,
  finishedComponentName: null,
  metadata: null,
  continueButtonLabel: "Continuar",
  tipoCredito: 1
};

const forms = [
  { key: "canalForm", path: "/canal" },
  { key: "identidadForm", path: "/identidad" },
  { key: "actividadForm", path: "/actividad" },
  { key: "direccionForm", path: "/direccion" },
  { key: "autoForm", path: "/auto" },
  { key: "prestamoForm", path: "/prestamo" },
  { key: "ofertasForm", path: "/ofertas" },
  { key: "seguroForm", path: "/seguro" },
  { key: "contactoForm", path: "/contacto" },
  { key: "resultadoForm", path: "/resultado" }
];

function getRoute(form, solicitudId) {
  let root = "/creditos/" + solicitudId;

  let formIndex = forms.findIndex(function(f) {
    return f.key === form;
  });

  return {
    backUrl:
      form === "canalForm" || form === "identidadForm"
        ? "/"
        : root + forms[formIndex - 1].path,
    path: root + forms[formIndex].path,
    nextUrl:
      form === "resultadoForm"
        ? "/solicitudes"
        : root + forms[formIndex + 1].path
  };
}

const solicitudCreditoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SOLICITUDCREDITO_INIT:
      return {
        ...initialState
      };
    case SOLICITUDCREDITO_FINISHCOMPONENT:
      return {
        ...state,
        finishedComponentName: action.payload.componentName,
        metadata: action.payload.metadata
      };
    case SOLICITUDCREDITO_CHANGEID:
      let route = getRoute(state.activeForm, action.payload.solicitudId);
      return {
        ...state,
        solicitudId: action.payload.solicitudId,
        backUrl: route.backUrl,
        path: route.path,
        nextUrl: route.nextUrl
      };
    case SOLICITUDCREDITO_ISLOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case SOLICITUDCREDITO_LOAD:
      return {
        ...state,
        ...action.payload
      };
    case SOLICITUDCREDITO_FINISH:
      return {
        ...state,
        finished: action.payload.finished
      };
    case SOLICITUDCREDITO_LOADDATA:
      return {
        ...state,
        ...action.payload.data
      };
    case SOLICITUDCREDITO_ACTIVEFORMCHANGED: {
      let route = getRoute(action.payload.form, state.solicitudId);
      return {
        ...state,
        activeForm: action.payload.form,
        backUrl: route.backUrl,
        path: route.path,
        nextUrl: route.nextUrl
      };
    }
    case SET_TIPOCREDITO_AUTOPRENDA: {
      return {
        ...state,
        tipoCredito: 3
      };
    }
    default:
      return state;
  }
};

export default solicitudCreditoReducer;
