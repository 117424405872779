import React from "react";
import Layout from "./Layout";
import makeStyles from "@material-ui/styles/makeStyles";
import { Paper, Typography, Button } from "@material-ui/core";
import checkIcon from "~images/auth/icon-check-big.svg";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  card: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "8%",
    borderRadius: 16,
    width: "100%",
    boxShadow: "0 0 20px 0 #663ea836",
    height: "70vh"
  },
  brandIcon: {
    height: 140,
    marginTop: "10%",
    marginBottom: "15%"
  },
  text: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.38,
    color: "#616161"
  },
  textBold: {
    fontSize: theme.typography.pxToRem(16),
    color: "#616161",
    lineHeight: 1.38,
    fontWeight: "bold"
  },
  titleText: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: "bold",
    lineHeight: 1.33,
    marginBottom: "5%"
  },
  button: {
    width: "100%",
    height: "7vh",
    backgroundColor: theme.palette.secondary.main
  },
  textButton: {
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.38,
    textTransform: "none"
  }
}));

const IngresarAppSuccess = props => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Layout>
      <Paper className={classes.card}>
        <img src={checkIcon} className={classes.brandIcon} alt="" />
        <div style={{ marginBottom: "8%" }}>
          <Typography className={classes.titleText} color="textPrimary">
            Tu cuenta se actualizó correctamente
          </Typography>
          <Typography className={classes.text}>
            A partir de ahora podés ingresar
          </Typography>

          <Typography className={classes.textBold}>
            directamente con tu red social.
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            alignItems: "flex-end",
            width: "100%"
          }}
        >
          <Button
            classes={{ root: classes.button, text: classes.textButton }}
            onClick={() => history.push(`/validating${props.location.search}`)}
          >
            Ir al inicio
          </Button>
        </div>
      </Paper>
    </Layout>
  );
};

export default IngresarAppSuccess;
