import { combineReducers } from "redux";
//BASE REDUCERS
import topBar from "../componentes/topBar/js/reducer";
import canalForm from "../componentes/canalForm/actions";
import authReducer from "~componentes/auth/actions";
import registrarAgenciaReducer from "~componentes/auth/registrarAgencia/actions";
import nativeMessagesReducer from "../componentes/nativeAppMessaging/js/reducer";
import featureFlagReducer from "../componentes/shared/featureFlags/js/reducer";
import oldLoginReducer from "../componentes/user/js/reducer";
import signupAgenciaReducer from "../componentes/signupAgencia/js/reducer";

//REMOVE SOON
import datosVehiculoReducer from "../componentes/cotizadorSeguros/datosVehiculoStep/actions";

//OTHER REDUCERS - ToDo: Remove this as components migrate to new structure
import solicitudCreditoReducer from "../componentes/solicitudCredito/js/reducer";
import identidadForm from "../componentes/identidadForm/actions";
import actividadForm from "../componentes/actividadForm/js/reducer";
import contactoForm from "../componentes/contactoForm/js/reducer";
import direccionForm from "../componentes/direccionForm/js/reducer";
import autoForm from "../componentes/autoForm/actions";
import prestamoForm from "../componentes/prestamoForm/actions";
import ofertasForm from "../componentes/ofertasForm/js/reducer";
import seguroFormReducer from "../componentes/seguroForm/js/reducer";
import resultadoForm from "../componentes/resultadoForm/js/reducer";
import listaSolicitudes from "../componentes/listaSolicitudes/js/reducer";
import detalleSolicitud from "../componentes/detalleSolicitud/js/reducer";
import calculador from "../componentes/calculador/js/reducer";
import cotizadorAutoReducer from "../componentes/cotizadorAuto/js/actions";
import contactoComercial from "../componentes/contactoComercial/js/reducer";
import vehiculosMarcasReducer from "../componentes/shared/vehiculoMarcaSelector/actions";
import cotizadorSegurosReducer from "../componentes/cotizadorSeguros/actions";
import vehiculosModelosReducer from "../componentes/shared/vehiculoModeloSelector/js/reducer";
import codigosPostalesReducer from "../componentes/shared/codigoPostalSelector/js/reducer";
import localidadReducer from "../componentes/shared/localidadSelector/js/reducer";
import pedidoCambioReducer from "../componentes/pedidoCambio/actions";
import solicitudTabs from "../componentes/solicitudTabs/js/reducer";
import listaTareas from "../componentes/listaTareas/js/reducer";
import agregarConyugeReducer from "../componentes/agregarConyuge/js/actions";
import agregarPersonaReducer from "../componentes/agregarPersona/js/actions";
import identidadReducer from "../componentes/identidadPersona/actions";
import agregarCodeudorReducer from "../componentes/agregarCodeudor/actions";
import direccionPersonaReducer from "../componentes/direccionPersona/actions";
import contactoPersonaReducer from "../componentes/contactoPersona/actions";
import actividadPersonaReducer from "../componentes/actividadPersona/actions";
import capturarAutoReducer from "../componentes/capturarAuto/actions";
import contactoSegurosReducer from "../componentes/cotizadorSeguros/contactoSeguros/actions";
import tareasSolicitudReducer from "../componentes/tareasSolicitud/js/actions";
import TareaConyugeReducer from "../componentes/tareasSolicitud/tareaConyuge/actions";
import AgregarCodeudorTareaReducer from "../componentes/tareasSolicitud/tareaCodeudor/agregarCodeudor/actions";
import AgregarConyugeCodeudorTareaReducer from "../componentes/tareasSolicitud/tareaCodeudor/agregarConyugeCodeudor/actions";
import TareaCodeudorReducer from "../componentes/tareasSolicitud/tareaCodeudor/actions";
import TareaTelefonoReducer from "../componentes/tareasSolicitud/tareaTelefono/actions";
import agregarDatosPersonaReducer from "../componentes/cotizadorSeguros/datosPersonaStep/actions";
import datosAutoReducer from "../componentes/cotizadorSeguros/datosAutosStep/actions";

import codigoPostalSelectorReducer from "~components/bussines/codigoPostalSelector/actions";
import marcaVehiculoSelectorReducer from "~components/bussines/marcaVehiculoSelector/actions";
import modeloVehiculoSelectorReducer from "~components/bussines/modeloVehiculoSelector/actions";
import localidadSelectorReducer from "~components/bussines/localidadSelector/actions";
import polizaSelectorReducer from "~components/bussines/polizaSelector/actions";

export const createReducer = (asyncReducers) => {
  return combineReducers({
    authReducer,
    registrarAgenciaReducer,
    oldLoginReducer,
    featureFlagReducer,
    topBar,
    signupAgencia: signupAgenciaReducer,
    nativeMessagesReducer,
    canalForm,
    //remove soon
    cotizadorSegurosReducer,
    datosVehiculoReducer,
    agregarDatosPersonaReducer,
    contactoSegurosReducer,
    datosAutoReducer,
    //OTHER REDUCERS
    solicitudCreditoUI: solicitudCreditoReducer,
    identidadForm,
    actividadForm,
    contactoForm,
    direccionForm,
    autoForm,
    prestamoForm,
    ofertasForm,
    seguroForm: seguroFormReducer,
    resultadoForm,
    listaSolicitudes,
    detalleSolicitud,
    calculador,
    cotizadorAutoReducer,
    contactoComercial,
    vehiculosMarcasReducer,
    vehiculosModelosReducer,
    codigosPostalesReducer,
    localidadReducer,
    pedidoCambioReducer,
    solicitudTabs,
    listaTareas,
    agregarConyugeReducer,
    agregarPersonaReducer,
    identidadReducer,
    agregarCodeudorReducer,
    direccionPersonaReducer,
    contactoPersonaReducer,
    actividadPersonaReducer,
    capturarAutoReducer,
    tareasSolicitudReducer,
    TareaConyugeReducer,
    AgregarCodeudorTareaReducer,
    AgregarConyugeCodeudorTareaReducer,
    TareaCodeudorReducer,
    TareaTelefonoReducer,
    codigoPostalSelectorReducer,
    marcaVehiculoSelectorReducer,
    modeloVehiculoSelectorReducer,
    localidadSelectorReducer,
    polizaSelectorReducer,
    //ASYNC REDUCERS
    ...asyncReducers,
  });
};
