import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setDni, clearDni } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import TextField from "@material-ui/core/TextField";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import DniInput from "~components/styled/input/DniInput";

const ENTER_KEY = 13;

const DniStep = () => {
  const dispatch = useDispatch();
  const [documento, setDocumento] = useState("");

  useEffect(() => {
    dispatch(clearDni());
  }, [dispatch]);

  const canContinue = documento.length > 6 && documento.length < 9;
  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue) dispatch(setDni(documento));
  };
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={!canContinue}
            label={"Continuar"}
            onClick={() => {
              dispatch(setDni(documento));
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle label={"Ingresá tu DNI"} marginBottom={60} />
      <TextField
        fullWidth={true}
        onFocus={(event) => event.target.select()}
        autoFocus
        value={documento}
        type="tel"
        onKeyDown={onKeyDown}
        onChange={(event) => {
          const value = event.target.value.replace(/\s/g, "");

          setDocumento(value);
        }}
        placeholder="Ej: 11 111 111"
        InputProps={{
          inputComponent: DniInput,
        }}
      ></TextField>
    </ContentSectionWithActions>
  );
};

export default DniStep;
