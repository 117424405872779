import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListView from "~components/styled/listView/ListView";
import Searcher from "~components/styled/searcher/Searcher";
import filterItems from "~libs/filterItems";
import { fetchModelosVehiculos } from "./actions";
const useStyles = makeStyles(theme => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-around"
  }
}));

const useReduxSelector = () =>
  useSelector(state => {
    const modeloVehiculoSelectorReducer = state.modeloVehiculoSelectorReducer;

    return {
      modelos: modeloVehiculoSelectorReducer.modelos,
      isLoading: modeloVehiculoSelectorReducer.isLoading
    };
  });
const ModeloVehiculoSelector = ({ label, onSelected, marca, anio }) => {
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();
  const { modelos, isLoading } = useReduxSelector();

  useEffect(() => {
    if ((marca, anio)) dispatch(fetchModelosVehiculos(marca, anio));
  }, []);

  const handleItemSelected = item => {
    setFilter("");
    if (onSelected) onSelected({ target: { value: item } });
  };

  const handleFilterChange = event => {
    setFilter(event.target.value);
  };

  const classes = useStyles();

  return (
    <div className={classes.searcher}>
      <Searcher
        onClear={() => handleItemSelected({ value: null, label: null })}
        filter={filter}
        onChange={handleFilterChange}
        placeholder={label}
      />
      <ListView
        isLoading={isLoading}
        onItemSelected={handleItemSelected}
        list={filter === "" ? modelos : filterItems(modelos, filter)}
      />
    </div>
  );
};

export default ModeloVehiculoSelector;
