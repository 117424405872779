import React from "react";
import { Route } from "react-router-dom";
import Signout from "~componentes/auth/Signout";
import Inicio from "~components/app/clienteFinal/inicio/Inicio";

const InicioRoutes = () => [
  <Route key="inicio-1" exact path="/">
    <Inicio />
  </Route>,
  <Route key="inicio-2" exact path="/signout">
    <Signout />
  </Route>
];

export default InicioRoutes;
