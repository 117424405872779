import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/styles";

import { clearSexo, setSexo } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ListView from "../../../../../styled/listView/ListView";

const sexoList = [
  { label: "Masculino", value: "M" },
  { label: "Femenino", value: "F" },
];

const SexoStep = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(clearSexo());
  }, [dispatch]);

  const handleClick = (selected) => {
    const value = selected.value;
    dispatch(setSexo({ value, selected: true }));
  };

  return (
    <ContentSectionWithActions>
      <PrimaryTitle
        label={"¿Cuál es el sexo que figura en su DNI?"}
        marginBottom={60}
      />
      <div className={classes.searcher}>
        <ListView roundedTop onItemSelected={handleClick} list={sexoList} />
      </div>
    </ContentSectionWithActions>
  );
};

export default SexoStep;

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 145,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  btnLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    lineHeight: 1.38,
    letterSpacing: 0.48,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
}));
