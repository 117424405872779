const getFranjaHoraria = () => [
  {
    label: "Todo el día",
    value: "Todo_El_Dia"
  },
  {
    label: "De 8 a 12 hs",
    value: "Manana"
  },
  {
    label: "De 12 a 18 hs",
    value: "Tarde"
  },
  {
    label: "De 18 a 24 hs",
    value: "Noche"
  }
];

export default getFranjaHoraria;
