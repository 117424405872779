import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import ContentSection from "~components/styled/contentSection/ContentSection";

import FileUpload from "~components/styled/fileUpload/FileUpload";
import { setFotoFrenteVehiculo, clearFotoFrenteVehiculo } from "../actions";

const useReduxSelector = () =>
  useSelector((state) => {
    return {
      currentPoliza: state.segurosReducers.cotizadorReducer.currentPoliza,
      isFotosVehiculoEnabled:
        state.featureFlagReducer.featureFlags["fotos-vehiculo"],
    };
  });

const FotoFrenteVehiculoStep = () => {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState(null);
  const { currentPoliza, isFotosVehiculoEnabled } = useReduxSelector();

  useEffect(() => {
    dispatch(clearFotoFrenteVehiculo());
  }, [dispatch]);

  useEffect(() => {
    let isCoberturaTodoRiesgo = currentPoliza.value.tipoPlanComercial === "TR";
    if (!isFotosVehiculoEnabled || !isCoberturaTodoRiesgo)
      dispatch(setFotoFrenteVehiculo({ value: null, selected: true }));
  }, [isFotosVehiculoEnabled, currentPoliza]);

  useEffect(() => {
    let timer = null;
    if (fileName)
      timer = setTimeout(
        () =>
          dispatch(setFotoFrenteVehiculo({ value: fileName, selected: true })),
        1000
      );

    return function cleanup() {
      clearTimeout(timer);
    };
  }, [fileName]);

  return (
    <ContentSection>
      <PrimaryTitle
        label={"Adjuntá una foto del frente del vehículo"}
        marginBottom={60}
      />
      <FileUpload
        onAdded={(event) => setFileName(event.target.value)}
        onRemoved={(event) => setFileName(null)}
        acceptImage={true}
        acceptPdf={false}
      />
    </ContentSection>
  );
};

export default FotoFrenteVehiculoStep;
