import { createActions, handleActions } from "redux-actions";

export const {
  identidadDniSet,
  identidadSexoSet,
  fetchIdentidadInited,
  fetchIdentidadFailed,
  fetchIdentidadCompleted,
  clearIdentidad,
  removeIdentidad
} = createActions(
  "IDENTIDAD_DNI_SET",
  "IDENTIDAD_SEXO_SET",
  "FETCH_IDENTIDAD_INITED",
  "FETCH_IDENTIDAD_FAILED",
  "FETCH_IDENTIDAD_COMPLETED",
  "CLEAR_IDENTIDAD",
  "REMOVE_IDENTIDAD"
);

export const fetchIdentidad = (dni, sexo) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchIdentidadInited());
      const response = await services.api.getIdentidad(dni, sexo);
      dispatch(fetchIdentidadCompleted(response));
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        fetchIdentidad,
        [dni, sexo],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(fetchIdentidadFailed(error));
    }
  };
};

const identidadReducer = handleActions(
  {
    [identidadDniSet]: (state, { payload: { dni } }) => {
      return { ...state, dni };
    },
    [identidadSexoSet]: (state, { payload: { sexo } }) => {
      return { ...state, sexo };
    },
    [fetchIdentidadInited]: state => {
      return { ...state, isLoading: true };
    },
    [fetchIdentidadCompleted]: (state, action) => {
      return {
        ...state,
        nombres: action.payload.nombres,
        apellidos: action.payload.apellido,
        fechaNacimiento: action.payload.fechaNacimiento,
        cuil: action.payload.cuil,
        isLoading: false,
        error: false,
        canContinue: true
      };
    },
    [fetchIdentidadFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true,
        canContinue: false
      };
    },
    [clearIdentidad]: state => {
      return {
        ...state,
        nombres: "",
        apellidos: "",
        fechaNacimiento: "",
        canContinue: false
      };
    },
    [removeIdentidad]: state => {
      return {
        ...state,
        dni: "",
        sexo: "",
        nombres: "",
        apellidos: "",
        fechaNacimiento: "",
        cuil: "",
        isLoading: false,
        canContinue: false
      };
    }
  },
  {
    dni: "",
    sexo: "",
    nombres: "",
    apellidos: "",
    fechaNacimiento: "",
    cuil: "",
    isLoading: false,
    canContinue: false
  }
);

export default identidadReducer;
