import {
  RESULTADOFORM_INIT,
  RESULTADOFORM_LOAD,
  RESULTADOFORM_ISLOADING
} from "./actions";

const initialState = {
  isLoading: false
};

const resultadoFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESULTADOFORM_INIT:
      return initialState;
    case RESULTADOFORM_LOAD:
      return {
        ...state,
        ...action.payload.data,
        isLoading: false
      };
    case RESULTADOFORM_ISLOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    default:
      return state;
  }
};

export default resultadoFormReducer;
