import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import pxToRem from "~libs/pxToRem";

const StyledSelect = withStyles(theme => ({
  select: {
    fontSize: pxToRem(18)
  }
}))(Select);

export default StyledSelect;
