import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import {
  clearValorVehiculo,
  setValorVehiculo,
  fetchValorVehiculo
} from "../actions";

import MontoSelector from "~components/bussines/montoSelector/MontoSelector";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";

const useReduxSelector = () =>
  useSelector(state => {
    const nuevaSolicitudReducer = state.creditosReducers.nuevaSolicitudReducer;
    return {
      modelo: nuevaSolicitudReducer.vehiculoModelo,
      anio: nuevaSolicitudReducer.vehiculoAnio,
      isLoading: nuevaSolicitudReducer.valorVehiculoLoading,
      valorInfoauto: nuevaSolicitudReducer.valorInfoauto
    };
  });

const ValorVehiculoStep = () => {
  const dispatch = useDispatch();
  const { isLoading, modelo, anio, valorInfoauto } = useReduxSelector();
  const [importeSelected, setImporteSelected] = useState();
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();
  useEffect(() => {
    dispatch(clearValorVehiculo());
  }, [dispatch]);

  useEffect(() => {
    if ((modelo, anio)) {
      dispatch(fetchValorVehiculo(modelo.value, anio));
    }
  }, [modelo, anio, dispatch]);

  useEffect(() => {
    if (valorInfoauto) {
      setMinValue(valorInfoauto.min);
      setMaxValue(valorInfoauto.max);
      setImporteSelected(valorInfoauto.precio);
    }
  }, [valorInfoauto]);
  return (
    <ContentSectionWithActions
      actions={
        <ContentSection>
          <CallToActionButton
            disabled={importeSelected === null || isLoading}
            label={"Continuar"}
            onClick={() => {
              dispatch(setValorVehiculo(importeSelected));
            }}
          />
        </ContentSection>
      }
    >
      <PrimaryTitle
        label={"¿Cuál es el valor del vehículo?"}
        marginBottom={60}
      />
      {!isLoading && (
        <MontoSelector
          minValue={minValue}
          maxValue={maxValue}
          step={1000}
          defaultValue={importeSelected}
          marginBottom={30}
          onChange={event => setImporteSelected(event.target.value)}
        />
      )}
    </ContentSectionWithActions>
  );
};

export default ValorVehiculoStep;
