import {
  SOLICITUDTABS_ISLOADING,
  SOLICITUDTABS_SETTABINDEX,
  SOLICITUDTABS_INIT,
  TAREASCOUNT_CLEARED,
  CAMBIOSCOUNT_CLEARED
} from "./actions";

import {
  DETALLESOLICITUD_FETCHSUCCESS,
  DETALLESOLICITUD_FETCHFAILED
} from "../../detalleSolicitud/js/actions";

/*import {
  fetchTareasCompleted  ,
  TAREAS_UPDATED 
} from "../../tareasSolicitud/js/actions";*/

const initialState = {
  isLoading: false,
  selectedTabIndex: 0,
  tareasCount: 0,
  cambiosCount: 0,
  showBottomBar: true
};

const solicitudTabsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SOLICITUDTABS_INIT:
      return initialState;
    case SOLICITUDTABS_ISLOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case SOLICITUDTABS_SETTABINDEX:
      return {
        ...state,
        selectedTabIndex: action.payload.tabIndex
      };
    case TAREASCOUNT_CLEARED:
      return {
        ...state,
        tareasCount: 0
      };
    case CAMBIOSCOUNT_CLEARED:
      return {
        ...state,
        cambiosCount: 0
      };
    case DETALLESOLICITUD_FETCHSUCCESS:
      return {
        ...state,
        tareasCount: action.payload.tareasPendientes,
        cambiosCount: action.payload.pedidosDeCambio,
        showBottomBar:
          action.payload.situacion !== "Carga_Incompleta" &&
          action.payload.situacion !== "Revision_Rechazo_Habilitado"
      };
    case DETALLESOLICITUD_FETCHFAILED:
      return {
        ...state,
        showBottomBar: false
      };
    case "FETCH_TAREAS_COMPLETED":
      const pendientes = action.payload
        .filter(tarea => tarea.estado === "Pendiente")
        .reduce(total => total + 1, 0);

      return {
        ...state,
        tareasCount: pendientes
      };
    /* case TAREAS_UPDATED:
      return {
        ...state,
        tareasCount: action.payload.tareasIds.length
      }; */
    case "PEDIDO_CAMBIO_FETCH_COMPLETED":
      return {
        ...state,
        cambiosCount: action.payload.pedidosCambio.length
      };
    case "PEDIDOS_CAMBIO_UPDATED":
      return {
        ...state,
        cambiosCount: action.payload.cambiosCount
      };
    default:
      return state;
  }
};

export default solicitudTabsReducer;
