import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setVehiculoAnio, clearVehiculoAnio } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import AnioSelector from "~components/bussines/anioSelector/AnioSelector";

const VehiculoAnioStep = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearVehiculoAnio());
  }, [dispatch]);

  return (
    <ContentSectionWithActions>
      <PrimaryTitle label={"Elegí el año del vehículo"} marginBottom={60} />
      <AnioSelector
        label={"Buscar año"}
        cantAnios={16}
        onSelected={event => dispatch(setVehiculoAnio(event.target.value))}
      />
    </ContentSectionWithActions>
  );
};

export default VehiculoAnioStep;
