import { createActions, handleActions } from "redux-actions";
import { getCreditosVigentes } from "~components/app/clienteFinal/creditos/homeCreditos/actions";
import { getPolizas } from "~components/app/clienteFinal/seguros/homeSeguros/actions";

const {
  authentication: {
    vinculacion: {
      vincularOperacionesInited,
      vincularOperacionesCompleted,
      vincularOperacionesFailed
    }
  }
} = createActions({
  authentication: {
    vinculacion: {
      vincularOperacionesInited: () => ({}),
      vincularOperacionesCompleted: value => ({ value }),
      vincularOperacionesFailed: () => ({})
    }
  }
});

const initialState = { vinculado: false, isLoading: false };

const tryVincularOperaciones = async (
  dispatch,
  services,
  retries,
  backoffTime
) => {
  try {
    const result = await services.api.postVincularOperaciones();
    if (!result.vinculado) {
      if (retries > 0) {
        setTimeout(async () => {
          await tryVincularOperaciones(
            dispatch,
            services,
            --retries,
            backoffTime * 2
          );
        }, backoffTime);
      }
    }
    if (!retries > 0 || result.vinculado) {
      if (result.vinculado) {
        dispatch(getCreditosVigentes());
        dispatch(getPolizas());
      }
      dispatch(vincularOperacionesCompleted(result.vinculado));
    }
  } catch (error) {
    console.error(error);
    dispatch(vincularOperacionesFailed());
  }
};

const vincularOperaciones = () => {
  return async (dispatch, getState, services) => {
    dispatch(vincularOperacionesInited());
    await tryVincularOperaciones(dispatch, services, 5, 1000);
  };
};

const vinculacionReducer = handleActions(
  {
    [vincularOperacionesInited]: (state, action) => {
      return {
        ...state,
        vinculado: false,
        isLoading: true
      };
    },
    [vincularOperacionesCompleted]: (state, action) => {
      console.log("vincularOperacionesCompleted", action.payload);
      return {
        ...state,
        vinculado: action.payload,
        isLoading: false
      };
    },
    [vincularOperacionesFailed]: (state, action) => {
      return {
        ...state,
        vinculado: false,
        isLoading: false
      };
    }
  },
  initialState
);

export default vinculacionReducer;
export { vincularOperaciones };
