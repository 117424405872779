import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentSection from "~components/styled/contentSection/ContentSection";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

import MediosDePagoSelector from "~components/bussines/medioDePagoSelector/MediosDePagoSelector";

import { setMedioDePago, clearMedioDePago } from "../actions";

const MedioDePagoStep = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMedioDePago());
  }, [dispatch]);

  return (
    <ContentSection>
      <PrimaryTitle label={"¿Qué medio de pago utilizaste?"} />
      <MediosDePagoSelector
        onSelected={event => dispatch(setMedioDePago(event.target.value))}
      />
    </ContentSection>
  );
};

export default MedioDePagoStep;
