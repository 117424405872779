import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";

import facebookIcon from "~images/auth/facebook-f.svg";
import googleIcon from "~images/auth/google.svg";
import microsoftIcon from "~images/auth/microsoft.svg";
import twitterIcon from "~images/auth/twitter.svg";
import yahooIcon from "~images/auth/yahoo-logo.svg";

import SocialButton from "~shared/SocialButton";

import { signInWithSocial } from "./actions";
import WaveLayout from "./WaveLayout";

import { useAuth0 } from "../Auth0/useAuth";

const useStyles = makeStyles(theme => ({
  otherAccounts: {
    fontSize: 14,
    color: "#757575",
    textDecoration: "underline !important",
    lineHeight: 1.36
  }
}));

const useReduxSelector = () =>
  useSelector(state => ({
    isLoading: state.authReducer.isLoading
  }));

const SigninWithSocialNetworks = ({ onChangeToEmailLogin }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isLoading } = useReduxSelector();

  const { loginSocial } = useAuth0();

  return (
    <WaveLayout title={"Ingresá con tu red social"} isLoading={isLoading}>
      <SocialButton
        label={"Facebook"}
        onClick={() =>
          dispatch(signInWithSocial(() => loginSocial("facebook")))
        }
        icon={facebookIcon}
        backgroundColor={"#3e5d9e"}
        backgroundColorIcon={"#33508e"}
      />
      <div style={{ height: 10 }} />
      <SocialButton
        onClick={() =>
          dispatch(signInWithSocial(() => loginSocial("google-oauth2")))
        }
        label={"Gmail"}
        icon={googleIcon}
        backgroundColor={"#ef4837"}
        backgroundColorIcon={"#d93a2a"}
      />
      <div style={{ height: 10 }} />
      <SocialButton
        onClick={() =>
          dispatch(signInWithSocial(() => loginSocial("windowslive")))
        }
        label={"Hotmail"}
        icon={microsoftIcon}
        backgroundColor={"#484848"}
        backgroundColorIcon={"#3e3e3e"}
      />
      <div style={{ height: 10 }} />
      <SocialButton
        onClick={() => dispatch(signInWithSocial(() => loginSocial("twitter")))}
        label={"Twitter"}
        icon={twitterIcon}
        backgroundColor={"#2ba8de"}
        backgroundColorIcon={"#2194c6"}
      />
      <div style={{ height: 10 }} />
      <SocialButton
        onClick={() => dispatch(signInWithSocial(() => loginSocial("yahoo")))}
        label={"Yahoo"}
        icon={yahooIcon}
        backgroundColor={"#842a83"}
        backgroundColorIcon={"#6f1f6e"}
      />
      <div style={{ height: 40 }} />
      <a
        href={"javascript:void(0)"}
        className={classes.otherAccounts}
        onClick={onChangeToEmailLogin}
      >
        O ingresá con tu cuenta corporativa
      </a>
    </WaveLayout>
  );
};

export default SigninWithSocialNetworks;
