import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import pxToRem from "~libs/pxToRem";

import waveMobile from "~assets/shapes/wave-mobile.svg";
import logo from "~assets/logos/logo-mg-white.svg";
import logoDesktop from "~assets/logos/logo-mg-color-horizontal.svg";

import InfiniteScroll from "~shared/layout/InfiniteScroll"; // dejar de usar en cuanto se pueda

import { Link, useLocation } from "react-router-dom";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

import homeActive from "~assets/icons/ic-home-active.svg";
import homeInActive from "~assets/icons/ic-home-inactive.svg";

import autosActive from "~assets/icons/ic-autos-active.svg";
import autosInActive from "~assets/icons/ic-autos-inactive.svg";

import infoActive from "~assets/icons/ic-info-active.svg";
import infoInActive from "~assets/icons/ic-info-inactive.svg";

import creditosActive from "~assets/icons/ic-creditos-active.svg";
import creditosInActive from "~assets/icons/ic-creditos-inactive.svg";

import segurosActive from "~assets/icons/ic-seguros-active.svg";
import segurosInActive from "~assets/icons/ic-seguros-inactive.svg";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Grow from "@material-ui/core/Grow";

import { menuClicked } from "./actions";

const NAV_BAR_HEIGHT = 56;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex"
  },
  main: {
    //overflowX: "hidden",
    backgroundColor: "#f9f7fc",
    flex: 1,
    paddingTop: 0,
    minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
    background:
      "linear-gradient(180deg, rgba(102,62,168,1) 0%, rgba(51,31,84,1) 100%)",
    backgroundSize: "100vw calc(31vh - 90px)",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    [theme.breakpoints.mobile]: {
      backgroundImage: `url(${waveMobile})`,
      backgroundSize: "100vw calc(31vh - 30px)"
    }
  },
  columnCentered: {
    padding: theme.typography.pxToRem(16),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  logoSize: {
    width: "12vh",
    [theme.breakpoints.mobile]: {
      width: "15vh"
    }
  },
  logoFigure: {
    marginTop: "3vh",
    minHeight: "12vh",
    display: "flex",
    margin: 0,
    [theme.breakpoints.mobile]: {
      minHeight: "8vh"
    }
  }
}));

const NavbarLayout = ({ children, navbar = navbarDefaults, topbar }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.mobile);

  return (
    <div className={!isMobile ? classes.container : null}>
      {!isMobile && <DesktopNavBar items={navbar} />}
      <div className={classes.main}>
        <InfiniteScroll /> {/* TODO: remove this when is no needed anymore */}
        {topbar && topbar()}
        {!topbar && (
          <div className={classes.columnCentered}>
            <figure className={classes.logoFigure}>
              <img src={logo} alt="MG Web App" className={classes.logoSize} />
            </figure>
          </div>
        )}
        <GrowAnimation>
          <div className={classes.columnCentered}>{children}</div>
        </GrowAnimation>
      </div>
      {isMobile && <MobileNavBar items={navbar} />}
    </div>
  );
};

const GrowAnimation = ({ children }) => {
  const location = useLocation();

  return (
    <Grow key={location.key} in={true}>
      {children}
    </Grow>
  );
};

const NavbarLayoutClienteFinal = ({ children, ...others }) => {
  let navbarClienteFinal = { ...navbarDefaults };
  delete navbarClienteFinal.autos;
  navbarClienteFinal.seguros = {
    label: "SEGUROS",
    to: "/mispolizas",
    activeIcon: segurosActive,
    inactiveIcon: segurosInActive
  };
  navbarClienteFinal.creditos = {
    label: "CRÉDITOS",
    to: "/miscreditos",
    activeIcon: creditosActive,
    inactiveIcon: creditosInActive
  };
  return (
    <NavbarLayout navbar={navbarClienteFinal} {...others}>
      {children}
    </NavbarLayout>
  );
};

export default NavbarLayout;
export { NavbarLayoutClienteFinal };

const useDesktopNavBarStyles = makeStyles(theme => ({
  root: {
    width: 225,
    height: "100vh",
    background: "#fff",
    display: "flex",
    position: "sticky",
    boxShadow: "0 2px 3px 0 #663ea853",
    top: 0
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  item: {
    borderLeft: `6px solid transparent`,
    minHeight: 56
  },
  selected: {
    backgroundColor: "#faf7fc !important",
    borderLeft: `6px solid ${theme.palette.primary.main}`
  },
  itemText: {
    fontSize: pxToRem(14),
    fontWeight: "bold",
    color: "#757575"
  },
  itemTextSelected: {
    fontSize: pxToRem(14),
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  itemIcon: {
    minWidth: 34
  }
}));

const DesktopNavBar = ({ items }) => {
  const dispatch = useDispatch();
  const classes = useDesktopNavBarStyles();

  const location = useLocation();
  const selected = `/${location.pathname.substring(1).split("/")[0]}`;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <img
          src={logoDesktop}
          style={{ margin: 20, marginTop: 25, alignSelf: "flex-start" }}
          alt="MG Web App"
        />
        <List component="nav">
          {Object.keys(items).map(key => (
            <ListItem
              key={`DesktopNavBar-${key}`}
              classes={{ root: classes.item, selected: classes.selected }}
              button
              selected={selected === items[key].to}
              component={Link}
              to={items[key].to}
              onClick={() => dispatch(menuClicked(key))}
            >
              <ListItemIcon className={classes.itemIcon}>
                {selected === items[key].to ? (
                  <IconFile src={items[key].activeIcon} />
                ) : (
                  <IconFile src={items[key].inactiveIcon} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={items[key].label}
                classes={{
                  primary:
                    selected === items[key].to
                      ? classes.itemTextSelected
                      : classes.itemText
                }}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

const useMobileNavBarStyles = makeStyles(theme => ({
  root: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    boxShadow: "0 -1px 3px 0 rgba(0, 0, 0, 0.1)",
    zIndex: 999,
    "@media only screen and (device-width : 375px) and (device-height : 812px)  and (-webkit-device-pixel-ratio : 3)": {
      paddingBottom: 34,
      height: 56 + 34
    }
  },
  item: {
    padding: 0,
    paddingTop: "5px !important"
  }
}));

const MobileNavBar = ({ items }) => {
  const dispatch = useDispatch();
  const classes = useMobileNavBarStyles();

  const location = useLocation();
  const selected = `/${location.pathname.substring(1).split("/")[0]}`;

  return (
    <BottomNavigation value={selected} showLabels className={classes.root}>
      {Object.keys(items).map(key => (
        <BottomNavigationAction
          key={`MobileNavBar-${key}`}
          className={classes.item}
          component={Link}
          label={items[key].label}
          to={items[key].to}
          value={items[key].to}
          onClick={() => dispatch(menuClicked(key))}
          icon={
            selected === items[key].to ? (
              <IconFile src={items[key].activeIcon} />
            ) : (
              <IconFile src={items[key].inactiveIcon} />
            )
          }
        />
      ))}
    </BottomNavigation>
  );
};

const IconFile = ({ src }) => {
  return <img alt="" src={src} style={{ width: 24, height: 24 }} />;
};

const navbarDefaults = {
  inicio: {
    label: "INICIO",
    to: "/",
    activeIcon: homeActive,
    inactiveIcon: homeInActive
  },
  creditos: {
    label: "CRÉDITOS",
    to: "/solicitudes",
    activeIcon: creditosActive,
    inactiveIcon: creditosInActive
  },
  seguros: {
    label: "SEGUROS",
    to: "/seguros",
    activeIcon: segurosActive,
    inactiveIcon: segurosInActive
  },
  autos: {
    label: "AUTOS",
    to: "/autos",
    activeIcon: autosActive,
    inactiveIcon: autosInActive
  },
  info: {
    label: "INFO",
    to: "/info",
    activeIcon: infoActive,
    inactiveIcon: infoInActive
  }
};
