export const CONTACTOFORM_ISLOADING = "CONTACTOFORM_ISLOADING";
export const CONTACTOFORM_INIT = "CONTACTOFORM_INIT";
export const CONTACTOFORM_LOAD = "CONTACTOFORM_LOAD";
export const CONTACTOFORM_CONTACTOS_SAVED = "CONTACTOFORM_CONTACTOS_SAVED";
export const SET_CONTACTOFORM_COMMITED = "SET_CONTACTOFORM_COMMITED";
export const contactoFormInit = () => ({
  type: CONTACTOFORM_INIT
});

export const contactoFormLoad = data => ({
  type: CONTACTOFORM_LOAD,
  payload: {
    data: data
  }
});

const contactosSaved = (email, telefonos) => {
  return {
    type: CONTACTOFORM_CONTACTOS_SAVED,
    payload: {
      email,
      telefonos
    }
  };
};

export const setContactoFormCommited = commited => {
  return {
    type: SET_CONTACTOFORM_COMMITED,
    payload: {
      commited: commited
    }
  };
};

export function submitForm(solicitudId, email, telefonos, onComplete) {
  return function(dispatch, getState, services) {
    return new Promise(async function(resolve, reject) {
      try {
        dispatch(contactoFormIsLoading(true));
        const data = {
          Telefonos: telefonos
            .filter(t => t.numero.length > 0)
            .map(t => {
              let telefono = {
                Numero: t.numero,
                TipoTelefono: t.tipoContacto,
                EsMovil: t.esMovil,
                HorarioContacto: t.horarioContacto,
                CorrespondeA: t.nombreContacto
              };
              return telefono;
            }),
          Usuario: email && email.value.length > 0 ? email.value : null
        };

        await services.api.postContacto(solicitudId, data);

        dispatch(contactosSaved(email, telefonos));
        if (onComplete) onComplete();
        dispatch(contactoFormIsLoading(false));
        resolve();
      } catch (error) {
        console.error(error);

        services.errorHandler.logRemoteError(
          error,
          submitForm,
          [solicitudId, email, telefonos, onComplete],
          getState()
        );
        dispatch(contactoFormIsLoading(false));
        dispatch(services.errorHandler.checkErrorAction(error));
        reject();
      }
    });
  };
}

const contactoFormIsLoading = loading => ({
  type: CONTACTOFORM_ISLOADING,
  payload: {
    isLoading: loading
  }
});
