import React from "react";
import { Switch, Redirect } from "react-router-dom";
import useAsyncReducer from "~components/app/useAsyncReducer";
import LoadingView from "~components/styled/loadingView/LoadingView";
import { useSelector } from "react-redux";

//Routes
import InicioRoutes from "~components/app/interno/inicio/Routes";
import CreditosRoutes from "~components/app/interno/creditos/Routes";
import SegurosRoutes from "~components/app/interno/seguros/Routes";
import InfoRoutes from "~components/app/interno/info/Routes";
import AutosRoutes from "~components/app/interno/autos/Routes";

//Reducers
import CreditosReducers from "~components/app/interno/creditos/Reducers";
import SegurosReducers from "~components/app/interno/seguros/Reducers";

const useReduxSelector = () =>
  useSelector(state => ({
    isNuevaSolicitudEnabled:
      state.featureFlagReducer.featureFlags["nueva-solicitud"],
    isNewDesignSeguros:
      state.featureFlagReducer.featureFlags["nuevo-cotizador-seguros"]
  }));

const InternoRoutes = () => {
  const loading = useAsyncReducer([CreditosReducers, SegurosReducers]);
  const { isNuevaSolicitudEnabled, isNewDesignSeguros } = useReduxSelector();

  const pathCotizadorSeguros = isNewDesignSeguros
    ? "/seguros/:position?"
    : "/seguros";

  return (
    <>
      {loading && <LoadingView />}
      {!loading && (
        <Switch>
          {InicioRoutes()}
          {CreditosRoutes(isNuevaSolicitudEnabled)}
          {SegurosRoutes(pathCotizadorSeguros)}
          {AutosRoutes()}
          {InfoRoutes()}
          <Redirect to="/" />
        </Switch>
      )}
    </>
  );
};

export default InternoRoutes;
