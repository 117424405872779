import { combineReducers } from "redux";
import listadoSolicitudesReducer from "./listadoSolicitudes/actions";
import calcularCreditoReducer from "./calcularCredito/actions";
import nuevaSolicitudReducer from "./newSolicitud/actions";
const combinedReducers = combineReducers({
  listadoSolicitudesReducer,
  calcularCreditoReducer,
  nuevaSolicitudReducer,
});

const creditosReducers = {
  key: "creditosReducers",
  value: combinedReducers,
};

export default creditosReducers;
