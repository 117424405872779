import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { useDispatch } from "react-redux";
import { setFechaNacimiento } from "../actions";

import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import DatePicker from "~components/styled/datePicker/DatePicker";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";
import PrimaryTitle from "~components/styled/texts/PrimaryTitle";

const ENTER_KEY = 13;

const useStyles = makeStyles(theme => ({
  cotizador: {
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "auto",
    padding: 8,
    paddingBottom: 100,
    width: "100%",
    maxWidth: 500,
    minHeight: "100vh"
  },
  title: {
    paddingBottom: "16px"
  },
  buttonContinuar: {
    paddingTop: 24
  }
}));

const FechaNacimientoStep = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);

  useEffect(() => {
    dispatch(setFechaNacimiento({ value: null, selected: false }));
  }, [dispatch]);

  const canContinue = value && !isNaN(value.getTime());
  const onKeyDown = event => {
    if (event.keyCode === ENTER_KEY && canContinue) {
      dispatch(setFechaNacimiento({ value: value, selected: true }));
    }
  };

  return (
    <ContentSectionWithActions>
      <PrimaryTitle
        label={`¿Ingresá la fecha de nacimiento del asegurado`}
        marginBottom={60}
      />
      <DatePicker
        fullWidth
        className={classes.datePicker}
        onChange={date => {
          setValue(date);
        }}
        value={value}
        onKeyDown={onKeyDown}
      />
      <div className={classes.buttonContinuar}>
        <CallToActionButton
          disabled={!canContinue}
          label={"Continuar"}
          onClick={() => {
            dispatch(setFechaNacimiento({ value: value, selected: true }));
          }}
        />
      </div>
    </ContentSectionWithActions>
  );
};

export default FechaNacimientoStep;
