const ofertasData = [
  {
    id: "123456",
    resultado: "Aprobado",
    importe: 136653.51,
    importeNetoAPercibir: 135000,
    plazo: 18,
    importeCuota: 12388.19,
    importeCuotaPromedio: 13395.41,
    conDocumentacion: false
  },
  {
    id: "123457",
    resultado: "AprobadoProvisorio",
    importe: 136653.51,
    importeNetoAPercibir: 110754,
    plazo: 18,
    importeCuota: 12388.19,
    importeCuotaPromedio: 13395.41,
    conDocumentacion: false
  },
  {
    id: "123458",
    resultado: "AprobadoProvisorio",
    importe: 136653.51,
    importeNetoAPercibir: 100389,
    plazo: 18,
    importeCuota: 12388.19,
    importeCuotaPromedio: 13395.41,
    conDocumentacion: true
  }
];

const mockOfertas = [
  {
    resultado: "Aprobado",
    ofertas: [...ofertasData],
    excepcionFaltanDatos: false,
    excepcionNoSeFinanciaUso: false,
    excepcionNoSeFinanciaCalificacion: false
  }
];

const mockSeguros = {
  cotizacionId: "7b200693-fce9-45a3-b8aa-cb46a8ac712b",
  companias: [
    {
      companiaId: 9,
      compania: "Berkley",
      orden: 2,
      estado: "completa",
      cotizaciones: [
        {
          tipoPolizaId: 21,
          nombre: "Clasica (terceros completo)",
          descripcion:
            "Clásica - Terceros completo - Responsabilidad civil hasta $10.000.000. Robo e incendio total y parcial. Destrucción total. Adicional de cristales y cerraduras por $50.000. Adicional de granizo por $50.000. Grúa hasta 300 km.\n",
          importe: 3699.0,
          cotizacionPlanId: "7263f6bd-97a5-4d12-8261-7279d59def64",
          tipoPlanComercial: "TCBase",
          tipoPlan: "TC",
          idExterno: "C-BKL3"
        }
      ]
    },
    {
      companiaId: 3,
      compania: "Triunfo Seguros",
      orden: 10,
      estado: "completa",
      cotizaciones: []
    },
    {
      companiaId: 11,
      compania: "Sancor Seguros",
      orden: 1,
      estado: "completa",
      cotizaciones: [
        {
          tipoPolizaId: 51,
          nombre: "Premium Max c/Asistencia (7)",
          descripcion:
            "Auto Premium MAX - Responsabilidad Civil / Accidente Total / Incendio Total / Incendio Parcial / Robo Total / Robo Parcial. Granizo, Terremoto e Inundación Parcial y/o Total sin deducible. Extensión de Cobertura a países limítrofes (con límites de Suma Asegurada). Accidentes personales: reintegro por muerte o invalidez total del conductor y/o acompañantes, en caso de accidente. Cristales: reparación o cambio de parabrisas, luneta trasera, cristales laterales y cristales del techo, que resulten dañados o destruidos por cualquiera de los acontecimientos cubiertos por la póliza, sin límites y sin deducibles.\nRotura o Reparación de cristales sin límite de eventos y sin deducibles.\nServicio de Asistencia mecánica y grúa hasta 300 km.\n",
          importe: 3555.5,
          cotizacionPlanId: "d954915b-c640-411a-834a-768c92bf2e76",
          tipoPlanComercial: "TCPremium",
          tipoPlan: "TC",
          idExterno: "7"
        },
        {
          tipoPolizaId: 65,
          nombre: "Todo Riesgo c/Deduc $20.000",
          descripcion:
            "Auto Todo Riesgo 14 - Franquicia Fija $30.000 - Franquicia de $30.000. Responsabilidad Civil $10.000.000. Incendio Total y Parcial. Robo y/o Hurto Total y  Parcial. Accidente Total deducible $30.000. Granizo/terremoto/inundación parcial y/o total según deducible. Rotura de cristales sin límite y sin deducible. Rotura de cerradura sin límite y sin deducible. Destrucción total al 80%. Daños parciales al amparo de Robo Total sin deducible. Reposición a 0KM por Pérdida Total. Seguro de Accidentes personales: reintegro por muerte o invalidez total del conductor y/o acompañantes, en caso de accidente. Servicio de Asistencia Mecánica y Grua hasta 300 km.\n",
          importe: 4707.0,
          cotizacionPlanId: "3c7d6dca-26cc-41b0-ace7-1307cadf92d8",
          tipoPlanComercial: "TR",
          tipoPlan: "TR",
          idExterno: "14"
        },
        {
          tipoPolizaId: 64,
          nombre: "Todo Riesgo c/Deduc $15.000",
          descripcion:
            "Auto Todo Riesgo 12 - Franquicia Fija $22.500 - Franquicia de $22.500. Responsabilidad Civil $10.000.000. Incendio Total y Parcial. Robo y/o Hurto Total y  Parcial. Accidente Total deducible $22.500. Granizo/terremoto/inundación parcial y/o total según deducible. Rotura de cristales sin límite y sin deducible. Rotura de cerradura sin límite y sin deducible. Destrucción total al 80%. Daños parciales al amparo de Robo Total sin deducible. Reposición a 0KM por Pérdida Total. Seguro de Accidentes personales: reintegro por muerte o invalidez total del conductor y/o acompañantes, en caso de accidente. Servicio de Asistencia Mecánica y Grua hasta 300 km.\n",
          importe: 5249.5,
          cotizacionPlanId: "3aadb738-a77f-4933-a4e5-ff5800c8f11f",
          tipoPlanComercial: "TR",
          tipoPlan: "TR",
          idExterno: "12"
        }
      ]
    },
    {
      companiaId: 15,
      compania: "Zurich",
      orden: 4,
      estado: "completa",
      cotizaciones: [
        {
          tipoPolizaId: 83,
          nombre: "Terceros Completo Premium Granizo (CG)",
          descripcion:
            "Responsabilidad Civil limitada hasta $ 6.000.000\r\nRobo y/o hurto total y parcial.\r\nDestrucción total por accidente.\r\nDestrucción total y parcial por incendio.\r\nDaños por intento de robo: cobertura a consolas, vidrios laterales y cerraduras hasta $2.000\r\nCobertura de daños parciales sin franquicia en caso de robo total y posterior recupero del vehículo.\r\nCristales: cobertura sin tope y sin límite para luneta, parabrisas y vidrios laterales, cristal de techo.\r\nDestrucción total al 80 % en función del costo de la reparación del vehículo.\r\nCobertura de daño parcial por granizo: cubre los daños que hayan afectado el vehículo asegurado a consecuencia de granizo sin tope y sin límite.\r\nCobertura de daño parcial por Inundación: cubre los daños que hayan afectado el vehículo asegurado a consecuencia de inundación sin tope y sin límite.",
          importe: 5227.16,
          cotizacionPlanId: "5ec79b57-5472-429c-8c0e-76a51d66b60c",
          tipoPlanComercial: "TCPremium",
          tipoPlan: "TC",
          idExterno: "CG"
        },
        {
          tipoPolizaId: 85,
          nombre: "Todo Riesgo con Franquicia Variable del 4%",
          descripcion:
            "Responsabilidad Civil limitada hasta $ 6.000.000\r\nRobo y/o hurto total y parcial.\r\nDestrucción total y parcial por incendio.\r\nDestrucción total por accidente.\r\nDaños por intento de robo: cobertura a consolas, vidrios laterales y cerraduras hasta $2.000\r\nCristales: cobertura sin tope y sin límite para luneta, parabrisas y vidrios laterales, cristal de techo.\r\nCobertura de daños parciales sin franquicia en caso de robo total y posterior recupero del vehículo.\r\nCobertura de daño parcial por granizo: cubre los daños que hayan afectado el vehículo asegurado a consecuencia de granizo sin tope y sin límite.\r\nCobertura de daño parcial por Inundación: cubre los daños que hayan afectado el vehículo asegurado a consecuencia de inundación sin tope y sin límite.\r\nDaños parciales por accidente con franquicia variable del 4 % del valor del vehículo en plaza al momento del siniestro sujeta a un mínimo de $ 7.500.",
          importe: 8104.33,
          cotizacionPlanId: "98b51c70-d759-404e-9e5f-9abd1a2c80c6",
          tipoPlanComercial: "TR",
          tipoPlan: "TR",
          idExterno: "DV"
        }
      ]
    },
    {
      companiaId: 19,
      compania: "Mercantil Andina",
      orden: 7,
      estado: "completa",
      cotizaciones: [
        {
          tipoPolizaId: 95,
          nombre: "Todo Riesgo (D2)",
          descripcion:
            "Todo Riesgo (D2) - Franquicia Fija $18.000 / $24.000 / $36.000 - Responsabilidad Civil limitada Hasta $10.000.000 por acontecimiento.\nDestrucción Total por accidente. Incendio Total y Parcial/Robo o Hurto total y parcial . Granizo, Cristales y Cerraduras. Daños Parciales con Franquicia Fija $18.000 / $24.000 / $36.000.\n",
          importe: 5555.46,
          cotizacionPlanId: "303afd35-9ff7-4a2c-990e-cb6ec9e22e44",
          tipoPlanComercial: "TR",
          tipoPlan: "TR",
          idExterno: "D2"
        }
      ]
    },
    {
      companiaId: 20,
      compania: "Rio Uruguay",
      orden: 3,
      estado: "pendiente",
      cotizaciones: []
    }
  ]
};

export { mockOfertas, mockSeguros };
