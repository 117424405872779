import React from "react";
import Lottie from "lottie-react-web";
import loading from "~assets/animations/loading.json";

const LoadingView = () => {
  const style = {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "white"
  };

  return (
    <div style={style}>
      <div style={{ width: 60 }}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loading
          }}
        />
      </div>
    </div>
  );
};

export default LoadingView;
