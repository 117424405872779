import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import { List, ListItem, ListItemText } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClickAwayListener from "material-ui/internal/ClickAwayListener";
import pxToRem from "~libs/pxToRem";

const useStyles = makeStyles((theme) => ({
  selectorRoot: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.white,
    borderRadius: 8,
    position: "relative",
  },
  selectorRootBottomSquared: {
    display: "flex",
    flexDirection: "column",
    //background: theme.palette.white,
    borderRadius: "8px 8px 0 0",
  },
  searchPaper: {
    display: "flex",
    position: "relative",
    borderRadius: 8,
    boxShadow: "0 0 4px 0 #7a6e8e3c",
    transition: "all 2s ease",
    padding: "7px 14px",
    "&:disabled": {
      backgroundColor: "#eeeeee",
      color: "#9e9e9e",
    },
  },
  searchPaperDisabled: {
    display: "flex",
    position: "relative",
    borderRadius: 8,
    boxShadow: "0 0 4px 0 #7a6e8e3c",
    transition: "all 2s ease",
    padding: "7px 14px",
    backgroundColor: "#eeeeee",
    color: "#9e9e9e",
  },
  searchPaperBottomSquared: {
    display: "flex",
    position: "relative",
    borderRadius: "8px 8px 0 0",
    boxShadow: "0 0 4px 0 #7a6e8e3c",
    transition: "all 2s ease",
    padding: "7px 14px",
  },
  input: {
    flex: 1,
    fontSize: pxToRem(16),
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "0.15",
    textAlign: "left",
    color: "#212121",
  },
  inputDisabled: {
    flex: 1,
    fontSize: pxToRem(16),
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "0.15",
    textAlign: "left",
    color: "#9e9e9e",
  },
  iconSearch: {
    color: "#bfbfbf",
    padding: 10,
    width: 44,
    height: 44,
  },
  iconButton: {
    color: theme.palette.primary.main,
    padding: 10,
    width: 44,
    height: 44,
  },
}));

const Selector = ({
  value,
  onChange,
  onFilter,
  options,
  disabled,
  onClickDisabled,
}) => {
  const [optionsExpanded, setOptionsExpanded] = useState(false);

  const handleChange = (newValue) => {
    setOptionsExpanded(false);
    if (onChange) onChange(newValue);
    if (onFilter) onFilter();
  };

  const handleClickAway = () => {
    setOptionsExpanded(false);
  };

  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Paper className={classes.selectorRoot}>
        <IconButton
          disabled={disabled}
          onClick={() => {
            disabled
              ? onClickDisabled && onClickDisabled()
              : setOptionsExpanded(!optionsExpanded);
          }}
          className={
            !optionsExpanded
              ? classes.searchPaper
              : classes.searchPaperBottomSquared
          }
        >
          <div className={disabled ? classes.inputDisabled : classes.input}>
            {options[value]}
          </div>
          <div className={classes.iconExpand}>
            <ExpandMoreIcon style={{ width: "1.8rem", height: "100%" }} />
          </div>
        </IconButton>
        {optionsExpanded && (
          <ListView
            list={options}
            onItemSelected={handleChange}
            value={value}
          />
        )}
      </Paper>
    </ClickAwayListener>
  );
};

export default Selector;

const useStylesList = makeStyles((theme) => ({
  root: { padding: 0 },
  textItem: {
    display: "flex",
    height: pxToRem(20),
    fontSize: pxToRem(16),
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: pxToRem(0.15),
    textAlign: "left",
    color: "#212121",
    paddingLeft: pxToRem(6),
  },
  item: {
    width: "100%",
  },
}));

const ListView = ({ list, onItemSelected, emptyListText, value }) => {
  const classes = useStylesList();

  return (
    <div className={classes.listPaper}>
      <List className={classes.root}>
        {Object.keys(list).map(
          (item) =>
            item !== value && (
              <ListViewItem
                key={`lisViewItem-${item}`}
                value={item}
                label={list[item]}
                onItemSelected={onItemSelected}
              />
            )
        )}
        {list.length === 0 && (
          <ListItem className={classes.item}>
            <ListItemText
              className={classes.textItem}
              primary={emptyListText}
              disableTypography
            />
          </ListItem>
        )}
      </List>
    </div>
  );
};

const ListViewItem = ({ value, label, onItemSelected }) => {
  const classes = useStylesList();
  return (
    <ListItem
      button
      divider
      onClick={() => {
        onItemSelected(value);
      }}
      style={{ padding: 8 }}
    >
      <ListItemText
        className={classes.textItem}
        primary={label}
        disableTypography
      />
    </ListItem>
  );
};
