import { createActions, handleActions } from "redux-actions";

export const {
  agregarConyugeIdentidadSet,
  agregarConyugeActividadSet,
  agregarConyugeComplete,
  agregarConyugeFailed,
  agregarConyugeIsLoading,
  agregarConyugeClear
} = createActions(
  "AGREGAR_CONYUGE_IDENTIDAD_SET",
  "AGREGAR_CONYUGE_ACTIVIDAD_SET",
  "AGREGAR_CONYUGE_COMPLETE",
  "AGREGAR_CONYUGE_FAILED",
  "AGREGAR_CONYUGE_IS_LOADING",
  "AGREGAR_CONYUGE_CLEAR"
);

export function submitAgregarConyuge({
  solicitudId,
  dni,
  nombres,
  apellidos,
  sexo,
  fechaNacimiento,
  actividad,
  conyugeCodeudor,
  cuil
}) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(agregarConyugeIsLoading({ isLoading: true }));
      const conyugeData = {
        Id: solicitudId,
        EstadoCivil: "Casado",
        DocumentoConyuge: dni,
        NombreConyuge: nombres,
        ApellidoConyuge: apellidos,
        SexoConyuge: sexo.value,
        Cuil: cuil,
        Ingreso: actividad.ingresos,
        ActividadDeclarada: actividad.actividadDeclarada
          ? actividad.actividadDeclarada.value
          : null,
        RubroEmpleoPublico: actividad.rubro ? actividad.rubro.value : null,
        Antiguedad: actividad.antiguedad ? actividad.antiguedad.value : null,
        FechaNacimiento: fechaNacimiento,
        conyugeCodeudor: conyugeCodeudor
      };
      if (conyugeCodeudor)
        await services.api.postAgregarConyugeCodeudor(conyugeData);
      else await services.api.postAgregarConyuge(conyugeData);
      dispatch(agregarConyugeComplete());
    } catch (error) {
      services.sendMessage(error.message, "error");
      dispatch(agregarConyugeFailed());
      throw error;
    }
  };
}

/*Deprecated, usar submitAgregarConyuge*/
export function submitForm(
  solicitudId,
  dni,
  nombres,
  apellidos,
  sexo,
  fechaNacimiento,
  actividad,
  conyugeCodeudor,
  cuil
) {
  return function(dispatch, getState, services) {
    return new Promise(function(resolve, reject) {
      dispatch(agregarConyugeIsLoading({ isLoading: true }));

      const conyugeData = {
        Id: solicitudId,
        EstadoCivil: "Casado",
        DocumentoConyuge: dni,
        NombreConyuge: nombres,
        ApellidoConyuge: apellidos,
        SexoConyuge: sexo.value,
        Cuil: cuil,
        Ingreso: actividad.ingresos,
        ActividadDeclarada: actividad.actividadDeclarada
          ? actividad.actividadDeclarada.value
          : null,
        RubroEmpleoPublico: actividad.rubro ? actividad.rubro.value : null,
        Antiguedad: actividad.antiguedad ? actividad.antiguedad.value : null,
        FechaNacimiento: fechaNacimiento,
        conyugeCodeudor: conyugeCodeudor
      };
      let request;
      if (conyugeCodeudor)
        request = services.api.postAgregarConyugeCodeudor(conyugeData);
      else request = services.api.postAgregarConyuge(conyugeData);

      request
        .then(() => {
          dispatch(agregarConyugeComplete());
        })
        .catch(error => {
          services.sendMessage(error.message, "error");
          dispatch(agregarConyugeFailed());
          reject();
        });
    });
  };
}

const agregarConyugeReducer = handleActions(
  {
    AGREGAR_CONYUGE_IDENTIDAD_SET: (state, action) => {
      return { ...state, identidad: action.payload, actividad: "" };
    },
    AGREGAR_CONYUGE_ACTIVIDAD_SET: (state, action) => {
      return { ...state, actividad: action.payload };
    },
    AGREGAR_CONYUGE_COMPLETE: (state, action) => {
      return { ...state, isLoading: false, complete: true, error: false };
    },
    AGREGAR_CONYUGE_FAILED: (state, action) => {
      return { ...state, isLoading: false, complete: true, error: true };
    },
    AGREGAR_CONYUGE_IS_LOADING: (state, { isLoading }) => {
      return { ...state, isLoading };
    },
    AGREGAR_CONYUGE_CLEAR: (state, action) => {
      return {
        ...state,
        identidad: "",
        actividad: "",
        isLoading: false,
        complete: false,
        error: false
      };
    }
  },
  {
    identidad: "",
    actividad: "",
    isLoading: false,
    complete: false,
    error: false
  }
);

export default agregarConyugeReducer;
