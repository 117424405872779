import { submitAgregarConyuge } from "../../agregarConyuge/js/actions";

export const FETCH_TAREAS_INIT = "FETCH_TAREAS_INIT";
export const FETCH_TAREAS_SUCCESS = "FETCH_TAREAS_SUCCESS";
export const FETCH_TAREAS_FAILED = "FETCH_TAREAS_FAILED";
export const ARCHIVO_TAREA_DOWNLOADED = "ARCHIVO_TAREA_DOWNLOADED";
export const ARCHIVO_TAREA_ADDED = "ARCHIVO_TAREA_ADDED";
export const ARCHIVO_TAREA_REMOVED = "ARCHIVO_TAREA_REMOVED";
export const TAREA_COMPLETED = "TAREA_COMPLETED";
export const TAREAS_UPDATED = "TAREAS_UPDATED";
export const TAREAS_CLEARED = "TAREAS_CLEARED";

export const FETCH_SOLICITUD_INITED = "FETCH_SOLICITUD_INITED";
export const FETCH_SOLICITUD_FAILED = "FETCH_SOLICITUD_FAILED";
export const FETCH_SOLICITUD_COMPLETED = "FETCH_SOLICITUD_COMPLETED";

/*SOLICITUD*/
export const fetchSolicitud = solicitudId => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchSolicitudInited());
      const response = await services.api.getSolicitudById(solicitudId);
      dispatch(fetchSolicitudCompleted(response));
    } catch (error) {
      dispatch(fetchSolicitudFailed(error));
    }
  };
};

const fetchSolicitudInited = () => ({
  type: FETCH_SOLICITUD_INITED
});

const fetchSolicitudCompleted = solicitud => ({
  type: FETCH_SOLICITUD_COMPLETED,
  payload: solicitud
});

const fetchSolicitudFailed = error => ({
  type: FETCH_SOLICITUD_FAILED,
  payload: error
});

const fetchTareasSolicitudInit = () => ({
  type: FETCH_TAREAS_INIT
});

const fetchTareasSolicitudSuccess = tareas => ({
  type: FETCH_TAREAS_SUCCESS,
  payload: {
    tareas: tareas
  }
});

const fetchTareasSolicitudFailed = () => ({
  type: FETCH_TAREAS_FAILED
});

const archivoDownloaded = archivo => ({
  type: ARCHIVO_TAREA_DOWNLOADED,
  payload: {
    archivo: archivo
  }
});

export function fetchTareasSolicitud(solicitudId) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchTareasSolicitudInit());
      const response = await services.api.getTareasSolicitud(solicitudId);

      const tareas = response.filter(
        tarea =>
          (tarea.estado === "Pendiente" || tarea.estado === "Postergada") &&
          !tarea.interna
      );

      let archivos = tareas.flatMap(t => {
        t.archivos.forEach(a => {
          a.tareaId = t.tareaId;
        });
        return t.archivos;
      });

      archivos.map(async archivo => {
        await services.api.fetchArchivoTarea(archivo.url).then(localUrl => {
          dispatch(
            archivoDownloaded({
              tareaId: archivo.tareaId,
              id: archivo.id,
              url: localUrl,
              name: archivo.url.split("/").pop()
            })
          );
        });
      });

      dispatch(fetchTareasSolicitudSuccess(tareas));
    } catch (error) {
      console.error(error);

      services.errorHandler.logRemoteError(
        error,
        fetchTareasSolicitud,
        [solicitudId],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(fetchTareasSolicitudFailed(error));
    }
  };
}

export const uploadArchivoCompleted = (tareaId, serverId, fileName) => ({
  type: ARCHIVO_TAREA_ADDED,
  payload: {
    tareaId,
    serverId,
    fileName
  }
});

export const removeArchivoCompleted = (tareaId, fileName) => ({
  type: ARCHIVO_TAREA_REMOVED,
  payload: {
    tareaId,
    fileName
  }
});

export const uploadArchivoInited = ({
  tareaId,
  fieldName,
  file,
  metadata,
  load,
  error,
  progress,
  abort
}) => {
  return async (dispatch, getState, services) => {
    const directory = "temp";
    const fileService = services.fileService(directory);
    try {
      await fileService.upload(file, load, progress);
    } catch (e) {
      console.error(e);
      error(e.message);
      services.errorHandler.logRemoteError(
        e,
        uploadArchivoInited,
        [fieldName, file, metadata, load, error, progress, abort],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(e));
    }
  };
};

export const removeArchivoInited = ({ tareaId, serverId, load, error }) => {
  return async (dispatch, getState, services) => {
    const directory = "temp";
    const fileService = services.fileService(directory);
    try {
      load();
      await fileService.delete(serverId);
    } catch (e) {
      console.error(e);
      error(e);
      services.errorHandler.logRemoteError(
        e,
        removeArchivoInited,
        [serverId, load, error],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(e));
    }
  };
};

const tareaCompleted = tareaId => ({
  type: TAREA_COMPLETED,
  payload: {
    tareaId: tareaId
  }
});

const tareasUpdated = tareasIds => ({
  type: TAREAS_UPDATED,
  payload: {
    tareasIds: tareasIds
  }
});

export function agregarConyuge(conyuge, tarea) {
  return async (dispatch, getState, services) => {
    try {
      await dispatch(submitAgregarConyuge(conyuge));
      await dispatch(submitTarea(tarea));
    } catch (error) {
      console.error(error);
      services.errorHandler.logRemoteError(
        error,
        submitTarea,
        [conyuge, tarea],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
}

export function submitTarea(form) {
  return async function(dispatch, getState, services) {
    return new Promise(async function(resolve, reject) {
      try {
        dispatch(tareaCompleted(form.tareaId));
        await services.api.postTarea(form.solicitudId, form.sequence, form);
        await dispatch(fetchTareasSolicitud(form.solicitudId));
        let tareasIds = getState().listaTareas.tareasIds;
        dispatch(tareasUpdated(tareasIds.filter(id => id !== form.tareaId)));
        resolve();
      } catch (error) {
        console.error(error);

        services.errorHandler.logRemoteError(
          error,
          submitTarea,
          [form],
          getState()
        );
        dispatch(services.errorHandler.checkErrorAction(error));
        reject();
      }
    });
  };
}

const tareasCleared = () => ({
  type: TAREAS_CLEARED
});

export function clearTareasSolicitud() {
  return async (dispatch, getState, services) => {
    dispatch(tareasCleared());
  };
}
