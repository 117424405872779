import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setVehiculoModelo, clearVehiculoModelo } from "../actions";

import PrimaryTitle from "~components/styled/texts/PrimaryTitle";
import ContentSectionWithActions from "~components/styled/contentSection/ContentSectionWithActions";

import ModeloVehiculoSelector from "~components/bussines/modeloVehiculoSelector/ModeloVehiculoSelector";

const useReduxSelector = () =>
  useSelector(state => {
    const cotizadorReducer = state.segurosReducers.cotizadorReducer;

    return {
      marcaSelected: cotizadorReducer.marcaSelected.value,
      anioSelected: cotizadorReducer.anioSelected
    };
  });

const ModeloStep = () => {
  const dispatch = useDispatch();
  const { marcaSelected, anioSelected } = useReduxSelector();
  useEffect(() => {
    dispatch(clearVehiculoModelo());
  }, [dispatch]);

  const handleSelection = event => {
    dispatch(setVehiculoModelo(event.target.value));
  };

  return (
    <ContentSectionWithActions>
      <PrimaryTitle label={"Modelo del vehículo"} marginBottom={60} />
      <ModeloVehiculoSelector
        label="Buscar modelo"
        marca={marcaSelected}
        anio={anioSelected.value}
        onSelected={handleSelection}
      />
    </ContentSectionWithActions>
  );
};

export default ModeloStep;
