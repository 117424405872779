import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postDatosContacto } from "../actions";
import ContentSection from "~components/styled/contentSection/ContentSection";
import LoadingView from "~components/styled/loadingView/LoadingView";
import ComprobanteSolicitud from "../../../../../bussines/comprobanteSolicitud/ComprobanteSolicitud";

const useReduxSelector = () =>
  useSelector((state) => {
    const cotizadorReducer = state.segurosReducers.cotizadorReducer;

    return {
      isLoading: cotizadorReducer.isLoading,
      cotizacionId: cotizadorReducer.cotizacionId,
    };
  });

const boldText = " nos comunicaremos ";
const text = "para solicitarte algunos datos adicionales.";
const subtitle =
  "Para agilizar la toma de datos, te sugerimos que tu cliente tenga preparada la siguiente documentación, que le solicitaremos vía whatsapp:";

const FinalStep = ({ currentState }) => {
  const dispatch = useDispatch();
  const { cotizacionId, isLoading } = useReduxSelector();

  useEffect(() => {
    if (cotizacionId) dispatch(postDatosContacto(currentState));
  }, [dispatch]);

  return (
    <ContentSection>
      {isLoading && <LoadingView />}
      {!isLoading && (
        <ComprobanteSolicitud
          boldText={boldText}
          text={text}
          subtitleModal={subtitle}
        />
      )}
    </ContentSection>
  );
};

export default FinalStep;

//cliente final
//const boldText = " nos comunicaremos con tu cliente ";
//const text = "para solicitarle algunos datos adicionales.";
//const subtitle = "Para agilizar la toma de datos, te sugerimos que tengas preparada la siguiente documentación, que te solicitaremos vía whatsapp:";
