import { createMuiTheme } from "@material-ui/core/styles";
import pxToRem from "~libs/pxToRem";
import Grey from "@material-ui/core/colors/grey";

const purple1 = "hsl(263, 46%, 45%)";
const purple2 = "hsl(260, 44%, 45%)";
const purple3 = "hsl(263, 45%, 35%)";
const purple4 = "hsl(231, 44%, 94%)";

const cyan1 = "hsl(190, 63%, 57%)";
const cyan2 = "hsl(190, 52%, 65%)";
const cyan3 = "hsl(190, 63%, 85%)";

const fontFamily = ["Nunito", "Helvetica", "Arial", "sans-serif"].join(",");
const theme = createMuiTheme({
  palette: {
    primary: {
      light: purple1,
      main: purple2,
      dark: purple3,
      contrastText: purple4
    },
    secondary: {
      light: cyan3,
      main: cyan2,
      dark: cyan1
    }
    // text: {
    //   primary: "#fff",
    //   secondary: purple2,
    //   hint: "#757575"
    // }
  },
  typography: {
    fontFamily: fontFamily,
    h1: {
      fontSize: pxToRem(44),
      lineHeight: 1.3,
      fontWeight: 800,
      marginBottom: pxToRem(12.8)
    },
    h3: {
      fontSize: pxToRem(28),
      fontWeight: 600,
      lineHeight: 1.5
    }
  },
  breakpoints: {
    mobile: "@media (max-width: 1023px)",
    desktop: "@media (min-width: 1024px)"
  },
  overrides: {
    MuiBottomNavigationAction: {
      root: {
        transition: "all 0.5s ease",
        width: "initial",
        minWidth: "initial",
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: `${pxToRem(4)} solid transparent`,
        "&$selected": {
          borderBottom: `${pxToRem(4)} solid ${purple1}`
        }
      },
      label: {
        fontSize: `${pxToRem(10.88)} !important`,
        fontWeight: 800,
        lineHeight: 1.36,
        marginTop: 3,
        letterSpacing: pxToRem(0.22)
      }
    },
    MuiOutlinedInput: {
      root: {
        "&$focused": {
          "& fieldset": {
            borderColor: `${purple1} !important`
          }
        },
        "&:hover fieldset": {
          borderColor: `${Grey[500]} !important`
        },
        "& fieldset": {
          borderColor: Grey[500]
        }
      },
      input: {
        caretColor: purple1,
        padding: pxToRem(17),
        fontFamily: fontFamily,
        fontSize: pxToRem(16),
        fontWeight: 500,
        lineHeight: 1.25,
        letterSpacing: pxToRem(0.15),
        color: Grey[800]
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 17px) scale(1)",
        color: Grey[500],
        fontSize: pxToRem(16),
        fontWeight: 500,
        lineHeight: 1.25,
        letterSpacing: pxToRem(0.15),
        "&$focused": { color: purple1 }
      },
      root: {
        "&$focused": { color: purple1 }
      }
    },
    MuiInput: {
      input: {
        fontFamily: fontFamily,
        fontSize: "1.25rem",
        fontWeight: 600,
        lineHeight: 1.35,
        letterSpacing: pxToRem(0.19),
        color: Grey[800],
        caretColor: purple1,
        "&::placeholder": {
          color: Grey[400],
          opacity: 1
        }
      },
      underline: {
        "&:before": {
          borderBottom: `${pxToRem(1)} solid ${Grey[500]}`
        },
        "&:after": {
          borderBottom: `${pxToRem(2)} solid ${purple1}`,
          transform: `scaleX(0)`
        },
        "&$focused:after": {
          borderBottom: `${pxToRem(2.5)} solid ${purple1}`,
          transition: `all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms`,
          transform: `scaleX(1)`
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: `${pxToRem(1)} solid ${Grey[500]}`
        }
      }
    }
  }
});

export default theme;
