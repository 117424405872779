let createGetCalculadorResult = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => data => {
  let request = buildRequestWithAuth("GET", data);

  return fetch(
    `${environment.catalogosUrl}/calculador/prendario?AnioAuto=${
      data.AnioAuto
    }&Plazo=${data.Plazo}&Capital=${data.Capital}`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export { createGetCalculadorResult };
